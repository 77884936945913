import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { ApiFacadeService } from './services/api-facade/api-facade.service';
import { BlobsService } from './services/blobs/blobs.service';
import { CardRegistrationsService } from './services/card-registrations/card-registrations.service';
import { ComponentsService } from './services/components/components.service';
import { DigitalNotesService } from './services/digital-notes/digital-notes.service';
import { InvitationService } from './services/invitation/invitation.service';
import { MatterportApiService } from './services/matterport/matterport.service';
import { OrganizationsService } from './services/organizations/organizations.service';
import { ProjectService } from './services/project/project.service';
import { ProjectsService } from './services/projects/projects.service';
import { PublicLinkService } from './services/public-link/public-link.service';
import { RootNoteCommentsService } from './services/root-note-comments/root-note-comments.service';
import { RootNotesService } from './services/root-notes/root-notes.service';
import { StagesService } from './services/stages/stages.service';
import { ApiSubscriptionService } from './services/subscription/api-subscription.service';
import { TeamTemplateService } from './services/team-template/team-template.service';
import { TeamService } from './services/team/team.service';
import { TokensService } from './services/tokens/tokens.service';
import { UserPreferencesService } from './services/user-preferences/user-preferences.service';
import { UserProfilesService } from './services/user-profiles/user-profiles.service';
import { UserService } from './services/user/user.service';
import { API_URL } from './tokens/api-url.token';

export function provideDataAccess(apiUrl: string): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: API_URL,
      useValue: apiUrl
    },
    ApiFacadeService,
    BlobsService,
    ComponentsService,
    DigitalNotesService,
    OrganizationsService,
    ProjectsService,
    ProjectService,
    RootNoteCommentsService,
    RootNotesService,
    StagesService,
    UserPreferencesService,
    UserProfilesService,
    ApiSubscriptionService,
    UserService,
    InvitationService,
    TeamService,
    TeamTemplateService,
    TokensService,
    PublicLinkService,
    CardRegistrationsService,
    MatterportApiService
  ]);
}
