import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BlobServiceClient } from '@azure/storage-blob';
import * as md5 from 'md5';
import { Observable, from, map, mergeMap, throwError } from 'rxjs';
import { BlobBody } from '../../models/blob-body';
import { BlobsCommand } from '../../models/command/blobs-command';
import { MediaType } from '../../models/media-type';
import { BlobsQuery } from '../../models/query/blobs-query';
import { RefreshUrl } from '../../models/refresh-url';
import { TransferProgressEvent, UploadUrl } from '../../models/upload-url';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class BlobsService implements BlobsCommand, BlobsQuery {
  private readonly url = inject<string>(API_URL);
  private readonly httpClient = inject(HttpClient);
  //COMMAND
  uploadImagesToBlob(payload: FormData): Observable<string> {
    return this.httpClient.post(
      `${this.url}/blobs-module/upload-images-to-blob`,
      payload,
      { responseType: 'text' }
    );
  }

  uploadBase64ImagesToBlob(payload: FormData): Observable<string> {
    return this.httpClient.post(
      `${this.url}/blobs-module/upload-base64-images-to-blob`,
      payload,
      { responseType: 'text' }
    );
  }

  //QUERY
  generateBlobUrlForUpload(payload: {
    projectId: string;
    fileExtension: string;
  }): Observable<UploadUrl> {
    const headers = new HttpHeaders({
      'api-version': '2.0'
    });

    return this.httpClient.get<UploadUrl>(
      `${this.url}/blobs-module/generate-blob-url-for-upload?projectId=${payload.projectId}&fileExtension=${payload.fileExtension}`,
      { headers }
    );
  }

  generateBlobUrlForDownload(payload: { blobUrl: string }): Observable<string> {
    return this.httpClient.get(
      `${this.url}/blobs-module/generate-blob-url-for-download?blobUrl=${payload.blobUrl}`,
      { responseType: 'text' }
    );
  }

  generateBlobUrlForResume(payload: { blobUrl: string }): Observable<boolean> {
    return this.httpClient
      .get(
        `${this.url}/blobs-module/generate-blob-url-for-resume?blobUrl=${payload.blobUrl}`
      )
      .pipe(map((response: any) => response?.canGenerateBlobUrlForResume));
  }

  refreshThumbnailUrl(payload: {
    projectIds: string[];
  }): Observable<RefreshUrl[]> {
    const queryString = payload.projectIds
      .map((projectId: string) => `projectIds=${projectId}`)
      .join('&');

    return this.httpClient.get<RefreshUrl[]>(
      `${this.url}/blobs-module/projects/refresh-thumbnail-url?${queryString}`
    );
  }

  //TODO: (david) should we handle error here?
  sendFileToBlobWithNoteId(
    file: File,
    projectId: string,
    noteId: string,
    type?: MediaType
  ): Observable<BlobBody> {
    const fileExtension: string = file.name.split('.').pop() ?? '';
    if (!fileExtension)
      return throwError(() => new Error('Invalid file extension!'));

    return this.generateBlobUrlForUpload({ projectId, fileExtension }).pipe(
      mergeMap((uploadBlobData: UploadUrl) => {
        return from(this.uploadToBlob(uploadBlobData, file)).pipe(
          map(
            () =>
              ({
                rootNoteId: noteId,
                name: file.name,
                type: type,
                blobUrl: `${uploadBlobData.origin}/${uploadBlobData.containerName}/${uploadBlobData.blobName}`,
                extension: fileExtension
              }) as BlobBody
          )
        );
      })
    );
  }

  sendFileToBlob(file: File, projectId: string): Observable<string> {
    const fileExtension: string = file.name.split('.').pop() ?? '';
    if (!fileExtension)
      return throwError(() => new Error('Invalid file extension!'));

    return this.generateBlobUrlForUpload({ projectId, fileExtension }).pipe(
      mergeMap((uploadBlobData: UploadUrl) => {
        return from(this.uploadToBlob(uploadBlobData, file)).pipe(
          map(
            () =>
              `${uploadBlobData.origin}/${uploadBlobData.containerName}/${uploadBlobData.blobName}`
          )
        );
      })
    );
  }

  //PAUSE & RESUME -> https://github.com/ljian3377/azure-storage-tools/blob/sample/uploadWithPauseAndResume.ts

  //TODO: (david) this catch need more work
  private async uploadToBlob(
    uploadBlobData: UploadUrl,
    file: File,
    onProgress?: (uploadedData: TransferProgressEvent) => void
  ): Promise<void> {
    const blockSize = 8388608;
    const url = `${uploadBlobData.origin}${uploadBlobData.sas}`;

    const blobServiceClient = new BlobServiceClient(url);
    const containerClient = blobServiceClient.getContainerClient(
      uploadBlobData.containerName
    );
    const blockBlobClient = containerClient.getBlockBlobClient(
      uploadBlobData.blobName
    );

    try {
      const mdHash = this._getHashFromFile(await file.arrayBuffer(), blockSize);

      await blockBlobClient.upload(file, file.size, {
        blobHTTPHeaders: { blobContentType: file.type },
        onProgress,
        metadata: {
          blockSize: `${blockSize}`,
          currentHash: mdHash,
          contentType: file.type
        }
      });
    } catch (err) {
      alert(err);
    }
  }

  _getHashFromFile(fileData: ArrayBuffer, blockSize: number): string {
    let hashBlock = new Uint8Array(blockSize);

    if (fileData.byteLength <= 0) {
      throw new Error('File is corrupted!');
    }

    for (let i = 0; i < fileData.byteLength; i += blockSize) {
      const block = new Uint8Array(fileData.slice(i, i + blockSize));

      hashBlock = hashBlock.map(
        (hashElement, indexElement) => hashElement ^ block[indexElement]
      );
    }

    return md5(hashBlock);
  }
}
