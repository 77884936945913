import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupComponent } from './components/group/group.component';
import { TabBodyComponent } from './components/tab-body/tab-body.component';
import { TabComponent } from './components/tabs/tab.component';
import { TabLabelDirective } from './directives/tab-label.directive';

@NgModule({
  declarations: [
    GroupComponent,
    TabComponent,
    TabBodyComponent,
    TabLabelDirective,
  ],
  imports: [CommonModule, PortalModule],
  exports: [GroupComponent, TabComponent, TabLabelDirective],
})
export class UiTabsModule {}
