import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserProfilesCommand } from '../../models/command/user-profiles-command';
import { AcceptedEulaUrls, EulaUrls } from '../../models/eula';
import { UserProfilesQuery } from '../../models/query/user-profiles-query';
import { UserProfile } from '../../models/user-profile';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class UserProfilesService
  implements UserProfilesCommand, UserProfilesQuery
{
  private readonly _url = inject<string>(API_URL);
  private readonly _httpClient = inject(HttpClient);
  //COMMAND
  createUserProfile(payload: {
    userName: string;
    userEmail: string;
  }): Observable<any> {
    return this._httpClient.post(
      `${this._url}/users-module/create-user-profile`,
      payload
    );
  }

  getEula$(): Observable<EulaUrls> {
    return this._httpClient.get<EulaUrls>(
      `${this._url}/users-module/get-latest-eula-url`
    );
  }

  acceptEula$(body: AcceptedEulaUrls): Observable<void> {
    return this._httpClient.post<void>(
      `${this._url}/users-module/accept-eula`,
      body
    );
  }

  //QUERY
  doesUserProfileExist(): Observable<boolean> {
    return this._httpClient.get<boolean>(
      `${this._url}/users-module/does-user-profile-exist`
    );
  }

  hasUserAcceptedEula(): Observable<boolean> {
    return this._httpClient.get<boolean>(
      `${this._url}/users-module/has-user-accepted-eula`
    );
  }

  isUserProfileValid(): Observable<boolean> {
    return this._httpClient.get<boolean>(
      `${this._url}/users-module/is-user-profile-valid`
    );
  }

  getUserProfileByEmail(payload: { email: string }): Observable<UserProfile> {
    return this._httpClient.get<UserProfile>(
      `${this._url}/users-module/get-user-profile-by-email?Email=${payload.email}`
    );
  }
}
