export type RegisterCardToken = {
  status: CardRegistrationStatus;
  cardRegistrationId: string;
  redirectUrl: string;
};

export enum CardRegistrationStatus {
  'Undefined',
  'Success',
  'Error',
  'CriticalError',
  'Redirect',
}
