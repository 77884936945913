import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  of,
  switchMap
} from 'rxjs';
import { ApiDistance } from '../../../data-access/distance-api.service';

import { IErrorResponse } from '@simlab/data-access';
import {
  TAddMeasurementGroups,
  TAddMeasurementsToGroup,
  TDistance,
  TDistanceCount,
  TDistanceGroups,
  TRemoveMeasurementFromGroup,
  TSimpleMeasurement,
  TStageMeasurmentSimpleGroups,
  TUpdateData,
  TUpdateGroupName
} from '@simlab/feature-measurement/models';
import { DistanceApiActions } from './distance.actions';

@Injectable()
export class DistanceMeasurementEffects {
  private actions$ = inject(Actions);
  private _apiDistance = inject(ApiDistance);

  loadStageMeasurements$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.loadStageMeasurements),
      distinctUntilChanged((prev, act) => prev.stageId === act.stageId),
      filter((e) => e.stageId !== undefined),
      switchMap(({ stageId }) =>
        this._apiDistance.getStageMeasurements(stageId).pipe(
          switchMap((result: TDistance[]) =>
            of(DistanceApiActions.loadStageMeasurementsSuccess({ result }))
          ),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.loadStageMeasurementsFailure({ error })
            );
          })
        )
      )
    )
  );

  loadStageMeasurementCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.loadStageMeasurementCount),
      distinctUntilChanged((prev, act) => prev === act),
      switchMap(({ stageId }) =>
        this._apiDistance.getStageMeasurementsCount(stageId).pipe(
          map((result: TDistanceCount) =>
            DistanceApiActions.loadStageMeasurementCountSuccess(result)
          ),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.loadStageMeasurementCountFailure({ error })
            );
          })
        )
      )
    )
  );

  loadProjectMeasurementCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.loadProjectMeasurementCount),
      distinctUntilChanged((prev, act) => prev.projectId === act.projectId),
      switchMap(({ projectId }) =>
        this._apiDistance.getProjectMeasurementsCount(projectId).pipe(
          map((result: TDistanceCount) =>
            DistanceApiActions.loadProjectMeasurementCountSuccess(result)
          ),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.loadProjectMeasurementCountFailure({ error })
            );
          })
        )
      )
    )
  );

  loadStageMeasurementSimpleGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.loadStageMeasurementSimpleGroups),
      distinctUntilChanged((prev, act) => prev.stageId === act.stageId),
      switchMap(({ stageId }) =>
        this._apiDistance.getStageMeasurementSimpleGroups(stageId).pipe(
          map((result: TStageMeasurmentSimpleGroups[]) =>
            DistanceApiActions.loadStageMeasurementSimpleGroupsSuccess({
              result
            })
          ),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.loadStageMeasurementSimpleGroupsFailure({
                error
              })
            );
          })
        )
      )
    )
  );

  loadStageMeasurementGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.loadStageMeasurementGroups),
      distinctUntilChanged((prev, act) => prev.stageId === act.stageId),
      switchMap(({ stageId }) =>
        this._apiDistance.getStageMeasurementGroups(stageId).pipe(
          map((result: TDistanceGroups[]) =>
            DistanceApiActions.loadStageMeasurementGroupsSuccess({ result })
          ),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.loadStageMeasurementGroupsFailure({ error })
            );
          })
        )
      )
    )
  );

  addDistanceMeasurement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.addMeasurement),
      exhaustMap((body) =>
        this._apiDistance.addMeasurement(body.props).pipe(
          switchMap((measurementId) =>
            this._apiDistance.getMeasurement(measurementId).pipe(
              map(
                (res: TSimpleMeasurement) =>
                  ({
                    ...res,
                    id: measurementId,
                    data: body.props.data
                  }) as TDistance
              ),
              map((res: TDistance) => {
                return DistanceApiActions.addMeasurementSuccess(res);
              })
            )
          ),

          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(DistanceApiActions.addMeasurementFailure({ error }));
          })
        )
      )
    )
  );

  addMeasurementToRootNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.addMeasurementToRootNote),
      exhaustMap(({ props }) =>
        this._apiDistance.addMeasurementToRootNote(props).pipe(
          switchMap((id) =>
            this._apiDistance.getMeasurement(id).pipe(
              map(
                (res: TSimpleMeasurement) =>
                  ({
                    ...res,
                    data: props.data,
                    id
                  }) as TDistance
              ),
              map((res: TDistance) => {
                return DistanceApiActions.addMeasurementToRootNoteSuccess(res);
              })
            )
          ),

          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.addMeasurementToRootNoteFailure({ error })
            );
          })
        )
      )
    )
  );

  addMeasurementGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.addMeasurementGroup),
      exhaustMap(({ body }) =>
        this._apiDistance.addMeasurementGroup(body).pipe(
          map((value) =>
            DistanceApiActions.addMeasurementGroupSuccess({ value })
          ),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(DistanceApiActions.addMeasurementGroupFailure({ error }));
          })
        )
      )
    )
  );

  addMeasurementGroups$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.addMeasurementToGroups),
      switchMap((body: TAddMeasurementGroups) =>
        this._apiDistance.addMeasurementToGroups(body).pipe(
          map(() => DistanceApiActions.addMeasurementToGroupsSuccess()),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.addMeasurementToGroupsFailure({ error })
            );
          })
        )
      )
    )
  );

  addMeasurementsToGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.addMeasurementsToGroup),
      switchMap((body: TAddMeasurementsToGroup) =>
        this._apiDistance.addMeasurementsToGroup(body).pipe(
          map(() => DistanceApiActions.addMeasurementsToGroupSuccess()),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.addMeasurementsToGroupFailure({ error })
            );
          })
        )
      )
    )
  );

  assignMeasurementsToTootNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.assignMeasurementsToRootNote),
      switchMap(({ props }) =>
        this._apiDistance.assignMeasurementToRootNote(props).pipe(
          map(() =>
            DistanceApiActions.assignMeasurementsToRootNoteSuccess(props)
          ),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.assignMeasurementsToRootNoteFailure({ error })
            );
          })
        )
      )
    )
  );

  unAssignMeasurementsFromTootNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.unAssignMeasurementsFromRootNote),
      switchMap(({ props }) =>
        this._apiDistance.unAssignMeasurementToRootNote(props).pipe(
          map(() =>
            DistanceApiActions.unAssignMeasurementsFromRootNoteSuccess(props)
          ),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.unAssignMeasurementsFromRootNoteFailure({
                error
              })
            );
          })
        )
      )
    )
  );

  updateMeasurementsTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.updateMeasurementTitle),
      switchMap(({ props }) =>
        this._apiDistance.updateMeasurementTitle(props).pipe(
          map(() => DistanceApiActions.updateMeasurementTitleSuccess(props)),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.updateMeasurementTitleFailure({ error })
            );
          })
        )
      )
    )
  );

  updateMeasurementsColor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.updateMeasurementColor),
      switchMap(({ props }) =>
        this._apiDistance.updateMeasurementColor(props).pipe(
          map(() => DistanceApiActions.updateMeasurementColorSuccess(props)),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.updateMeasurementColorFailure({ error })
            );
          })
        )
      )
    )
  );

  updateMeasurementsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.updateMeasurementData),
      switchMap((body: TUpdateData) =>
        this._apiDistance.updateMeasurementData(body).pipe(
          map(() => DistanceApiActions.updateMeasurementDataSuccess(body)),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.updateMeasurementDataFailure({ error })
            );
          })
        )
      )
    )
  );

  updateMeasurementsGroupName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.updateMeasurementGroupName),
      switchMap((body: TUpdateGroupName) =>
        this._apiDistance.updateMeasurementGroupName(body).pipe(
          map(() => DistanceApiActions.updateMeasurementGroupNameSuccess()),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.updateMeasurementGroupNameFailure({ error })
            );
          })
        )
      )
    )
  );

  deleteMeasurement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.deleteMeasurement),
      exhaustMap(({ id }) =>
        this._apiDistance.removeMeasurement({ id }).pipe(
          map(() => DistanceApiActions.deleteMeasurementSuccess({ id })),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(DistanceApiActions.deleteMeasurementFailure({ error }));
          })
        )
      )
    )
  );

  deleteMeasurementFromGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.deleteMeasurementFromGroup),
      exhaustMap((body: TRemoveMeasurementFromGroup) =>
        this._apiDistance.removeMeasurementFromGroup(body).pipe(
          map(() => DistanceApiActions.deleteMeasurementFromGroupSuccess()),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(
              DistanceApiActions.deleteMeasurementFromGroupFailure({ error })
            );
          })
        )
      )
    )
  );

  deleteMeasurementGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DistanceApiActions.deleteMeasurementGroup),
      exhaustMap(({ id }) =>
        this._apiDistance.removeMeasurementGroup({ id }).pipe(
          map(() => DistanceApiActions.deleteMeasurementGroupSuccess()),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(DistanceApiActions.deleteMeasurementGroupFailure(error));
          })
        )
      )
    )
  );
}
