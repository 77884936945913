import {
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Self,
} from '@angular/core';
import { Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { ButtonLoaderBase } from '../components/button/button-loader.component';
import { ButtonBase } from '../components/button/button.component';

@Directive({
  selector: 'button[uiFireOnClick], a[uiFireOnClick]',
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['loading'],
})
export class FireOnClickDirective implements OnDestroy {
  private readonly _destroySource: Subject<void> = new Subject<void>();

  @HostBinding('class.ui-loading-button')
  get uiButtonLoading(): boolean {
    return this.buttonComponent.loading ?? false;
  }

  /**
   * Example:
   *
   * <button ui-raised-button [uiFireOnClick]="something$">
   * <ui-button-loader [direction]="'end'"></ui-button-loader>
   * Click me
   * <i ui-font fontName="assets" fontSize="2rem" [direction]="'begin'"></i>
   * </button>
   */
  @Input()
  set uiFireOnClick(stream$: Observable<unknown>) {
    this._fireOnClick$ = stream$;
  }
  private _fireOnClick$!: Observable<unknown>;

  @ContentChild(ButtonLoaderBase)
  set buttonLoaderRef(buttonLoaderBase: ButtonLoaderBase) {
    if (!buttonLoaderBase) {
      throw new Error('ui-button-loading is missing inside button element!');
    }

    this._buttonLoaderBase = buttonLoaderBase;
    const hostElementHeight = (this.hostElement.nativeElement as HTMLElement)
      .clientHeight;
    this._buttonLoaderBase.setHeight(hostElementHeight);
  }
  private _buttonLoaderBase!: ButtonLoaderBase;

  @HostListener('click', ['$event.target']) onClick(): void {
    if (!this._fireOnClick$) {
      throw new Error('given stream is invalid!');
    }
    this.buttonComponent.loadingBegin();
    this._fireOnClick$
      .pipe(
        tap(() => {
          this.buttonComponent.loadingEnd();
          //TODO: mby in the feature we will need to have @Output?
        }),
        takeUntil(this._destroySource),
        take(1)
      )
      .subscribe();
  }

  constructor(
    private readonly hostElement: ElementRef,
    @Self()
    private readonly buttonComponent: ButtonBase
  ) {}

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
}
