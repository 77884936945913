export interface InvoiceAddress {
  organizationId?: string;
  firstName: string;
  lastName: string;
  companyName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zipCode: string;
  vatNumber: string;
  isCompany: boolean;
  isVatPayer: boolean;
}
