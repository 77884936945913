import { Injectable } from '@angular/core';
import { fromEvent, map, shareReplay, startWith } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MediaObserverService {
  private readonly _touchMatchMedia = matchMedia('(pointer: coarse)');

  readonly isTouchableScreen$ = fromEvent<MediaQueryList>(
    this._touchMatchMedia,
    'change',
  ).pipe(
    map(({ matches }) => matches),
    startWith(matchMedia('(pointer: coarse)').matches),
    shareReplay(1),
  );

  readonly isTouchableScreen = this._touchMatchMedia.matches;
}
