import {
  FocusOrigin,
  isFakeTouchstartFromScreenReader,
} from '@angular/cdk/a11y';
import { Direction, Directionality } from '@angular/cdk/bidi';
import {
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewContainerRef,
} from '@angular/core';
import {
  merge,
  Observable,
  of,
  Subject,
  Subscription,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { MenuItemComponent } from '../components/menu-item/menu-item.component';
import {
  MenuCloseReason,
  MenuPanelComponent,
} from '../components/menu-panel/menu-panel.component';

const passiveEventListenerOptions = normalizePassiveListenerOptions({
  passive: true,
});
@Directive({
  selector: '[designMenuTriggerFor]',
  standalone: true,
})
export class MenuTriggerForDirective implements AfterContentInit, OnDestroy {
  protected readonly _destroySource: Subject<void> = new Subject<void>();
  @Output() readonly menuOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() readonly menuClosed: EventEmitter<void> = new EventEmitter<void>();

  @Input() clickAble$: Observable<boolean> | undefined;

  private _overlayRef: OverlayRef | null = null;
  private _portal!: TemplatePortal;

  private _closingActionsSubscription = Subscription.EMPTY;
  private _menuCloseSubscription = Subscription.EMPTY;

  get menuOpen(): boolean {
    return this._menuOpen;
  }
  private _menuOpen = false;

  private _uiOffsetY: number | undefined;
  @Input('uiOffsetY')
  get uiOffsetY(): number | undefined {
    return this._uiOffsetY;
  }
  set uiOffsetY(value: number | undefined) {
    this._uiOffsetY = value;
  }

  @Input('designMenuTriggerFor')
  get menu(): MenuPanelComponent {
    return this._menuPanel;
  }
  set menu(menuPanel: MenuPanelComponent) {
    if (this._menuPanel === menuPanel) {
      return;
    }

    this._menuPanel = menuPanel;
    this._menuCloseSubscription.unsubscribe();

    if (menuPanel) {
      this._menuCloseSubscription = this._menuPanel.closed.subscribe(
        (reason: MenuCloseReason) => {
          this._destroyMenu(reason);
        },
      );
    }
  }
  private _menuPanel!: MenuPanelComponent;

  /** The text direction of the containing app. */
  get direction(): Direction {
    return this.directionality && this.directionality.value === 'rtl'
      ? 'rtl'
      : 'ltr';
  }

  // Tracking input type is necessary so it's possible to only auto-focus
  // the first item of the list when the menu is opened via the keyboard
  _openedBy: Exclude<FocusOrigin, 'program' | null> | undefined = undefined;

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    if (this.clickAble$ === undefined) {
      event.stopPropagation();
      this.toggleMenu();
    } else {
      this.clickAble$
        .pipe(
          tap((editable: boolean) => {
            if (editable) {
              event.stopPropagation();
              this.toggleMenu();
            }
          }),
          take(1),
          takeUntil(this._destroySource),
        )
        .subscribe();
    }
  }

  constructor(
    private readonly overlay: Overlay,
    private readonly element: ElementRef<HTMLElement>,
    private readonly viewContainerRef: ViewContainerRef,
    @Optional()
    @Self()
    private readonly menuItemInstance: MenuItemComponent,
    @Optional()
    private readonly directionality: Directionality,
  ) {
    element.nativeElement.addEventListener(
      'touchstart',
      this._handleTouchStart,
      passiveEventListenerOptions,
    );
  }

  ngAfterContentInit(): void {
    this._checkMenu();
  }

  ngOnDestroy(): void {
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
    }

    this._menuCloseSubscription.unsubscribe();

    this.element.nativeElement.removeEventListener(
      'touchstart',
      this._handleTouchStart,
      passiveEventListenerOptions,
    );

    this._destroySource.next();
    this._destroySource.complete();
  }

  toggleMenu(): void {
    return this._menuOpen ? this.closeMenu() : this.openMenu();
  }

  openMenu(): void {
    if (this._menuOpen) {
      return;
    }

    const overlayRef = this._createOverlay();
    const overlayConfig = overlayRef.getConfig();
    const positionStrategy =
      overlayConfig.positionStrategy as FlexibleConnectedPositionStrategy;
    this._setPosition(positionStrategy);

    overlayRef.attach(this._getPortal());

    this._closingActionsSubscription = this._menuClosingActions().subscribe(
      () => {
        this.closeMenu();
      },
    );

    this._initMenu();
  }

  closeMenu(): void {
    this._menuPanel.closed.emit();
  }

  private _destroyMenu(reason: MenuCloseReason) {
    if (!this._overlayRef || !this.menuOpen) {
      return;
    }

    this._closingActionsSubscription.unsubscribe();
    this._overlayRef.detach();
    this._setIsMenuOpen(false);
  }

  private _initMenu(): void {
    this._menuPanel.direction = this.direction;
    this._setIsMenuOpen(true);
  }

  private _setIsMenuOpen(isOpen: boolean): void {
    this._menuOpen = isOpen;
    this._menuOpen ? this.menuOpened.emit() : this.menuClosed.emit();
  }

  private _createOverlay(): OverlayRef {
    if (!this._overlayRef) {
      const config = this._getOverlayConfig();
      this._overlayRef = this.overlay.create(config);
    }

    return this._overlayRef;
  }

  private _getOverlayConfig(): OverlayConfig {
    return new OverlayConfig({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.element)
        .withLockedPosition()
        .withGrowAfterOpen()
        .withTransformOriginOn('.design-menu-panel'),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      direction: this.directionality,
    });
  }

  private _getPortal(): TemplatePortal {
    if (
      !this._portal ||
      (this._portal && this._portal.templateRef !== this.menu.templateRef)
    ) {
      this._portal = new TemplatePortal(
        this._menuPanel.templateRef,
        this.viewContainerRef,
      );
    }

    return this._portal;
  }

  /**
   * Handles touch start events on the trigger.
   * Needs to be an arrow function so we can easily use addEventListener and removeEventListener.
   */
  private _handleTouchStart = (event: TouchEvent) => {
    if (!isFakeTouchstartFromScreenReader(event)) {
      this._openedBy = 'touch';
    }
  };

  private _menuClosingActions(): Observable<any> {
    if (!this._overlayRef) {
      return of();
    }

    const backdrop = this._overlayRef.backdropClick();
    const detachments = this._overlayRef.detachments();

    return merge(backdrop, detachments);
  }

  private _setPosition(positionStrategy: FlexibleConnectedPositionStrategy) {
    const [originX, originFallbackX]: HorizontalConnectionPos[] =
      this.menu.xPosition === 'before' ? ['end', 'start'] : ['start', 'end'];

    const [overlayY, overlayFallbackY]: VerticalConnectionPos[] =
      this.menu.yPosition === 'above' ? ['bottom', 'top'] : ['top', 'bottom'];

    const [originY, originFallbackY] = [overlayY, overlayFallbackY];
    const [overlayX, overlayFallbackX] = [originX, originFallbackX];
    const offsetY = this.uiOffsetY ? this.uiOffsetY : 0;

    positionStrategy.withPositions([
      { originX, originY, overlayX, overlayY, offsetY },
      {
        originX: originFallbackX,
        originY,
        overlayX: overlayFallbackX,
        overlayY,
        offsetY,
      },
      {
        originX,
        originY: originFallbackY,
        overlayX,
        overlayY: overlayFallbackY,
        offsetY: -offsetY,
      },
      {
        originX: originFallbackX,
        originY: originFallbackY,
        overlayX: overlayFallbackX,
        overlayY: overlayFallbackY,
        offsetY: -offsetY,
      },
    ]);
  }

  private _checkMenu() {
    if (!this.menu) {
      throw Error(
        `designMenuTriggerFor: must pass in an design-menu-panel instance.

        Example:
            <design-menu-panel #menu></design-menu-panel>
            <button [designMenuTriggerFor]="menu"></button>
        `,
      );
    }
  }
}
