import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ProjectCommand } from '../../models/command/project-command';
import { OrganizationPossibleProjectTeam } from '../../models/organization-possible-project-team';
import { OrganizationPossibleProjectUser } from '../../models/organization-possible-project-user';
import {
  OrganizationProject,
  PaginationPage,
} from '../../models/organization-project';
import { OrganizationProjectTeam } from '../../models/organization-project-team';
import {
  OrganizationProjectUser,
  OrganizationProjectUserBase,
} from '../../models/organization-project-user';
import { Project } from '../../models/project';
import { ProjectsSort } from '../../models/projects-sort';
import { PublicLink } from '../../models/public-link';
import { ProjectQuery } from '../../models/query/project-query';
import { SimpleProject } from '../../models/simple-project';
import { UserPermissionsInOrganizationProject } from '../../models/user-permissions-in-organization-project';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class ProjectService implements ProjectQuery, ProjectCommand {
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);
  //COMMAND
  deleteProject$(payload: { id: string }): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/organizations-module/delete-project`,
      { body: payload }
    );
  }

  addUsersToProject$(payload: {
    projectId: string;
    usersIds: string[];
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/add-users-to-project`,
      payload
    );
  }

  addTeamsToProject$(payload: {
    projectId: string;
    teamsIds: string[];
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/add-teams-to-project`,
      payload
    );
  }

  setUserPermissionsInProject$(payload: {
    projectId: string;
    userId: string;
    allowedPermissions: number;
    forbiddenPermissions: number;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/set-user-permissions-in-project`,
      payload
    );
  }

  archiveProject$(payload: { id: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/set-idle`,
      payload
    );
  }
  activateProject$(payload: { id: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/set-active`,
      payload
    );
  }

  //QUERY
  getSimpleOrganizationProjects$(payload: {
    organizationId: string;
  }): Observable<SimpleProject[]> {
    return this.httpClient.get<SimpleProject[]>(
      `${this.url}/organizations-module/get-simple-organization-projects?organizationId=${payload.organizationId}`
    );
  }

  getOrganizationProjects$(payload: {
    organizationId: string;
    projectName?: string;
    sortBy?: ProjectsSort;
  }): Observable<PaginationPage<OrganizationProject>> {
    const queryParameters: string[] = [];
    if (payload) {
      queryParameters.push(...[`organizationId=${payload.organizationId}`]);
      queryParameters.push(...this.sortBy(payload.sortBy));
      if (payload.projectName) {
        queryParameters.push(...[`ProjectName=${payload.projectName}`]);
      }
    }
    return this.httpClient.get<PaginationPage<OrganizationProject>>(
      `${
        this.url
      }/organizations-module/get-organization-projects?${queryParameters.join(
        '&'
      )}`
    );
  }

  getOrganizationProjectUsers$(payload: {
    projectId: string;
  }): Observable<OrganizationProjectUser[]> {
    return this.httpClient.get<OrganizationProjectUser[]>(
      `${this.url}/organizations-module/get-organization-project-users?projectId=${payload.projectId}`
    );
  }
  getOrganizationProjectUsersByUsername$(payload: {
    projectId: string;
    userName: string | undefined;
  }): Observable<OrganizationProjectUserBase[]> {
    const userName = payload.userName ? `&userName=${payload.userName}` : '';
    return this.httpClient.get<OrganizationProjectUserBase[]>(
      `${this.url}/organizations-module/get-organization-project-users-by-username?projectId=${payload.projectId}${userName}`
    );
  }

  getOrganizationProjectTeams$(payload: {
    projectId: string;
  }): Observable<PaginationPage<OrganizationProjectTeam>> {
    return this.httpClient.get<PaginationPage<OrganizationProjectTeam>>(
      `${this.url}/organizations-module/get-organization-project-teams?ProjectId=${payload.projectId}`
    );
  }

  getOrganizationPossibleProjectUsers$(payload: {
    projectId: string;
  }): Observable<OrganizationPossibleProjectUser[]> {
    return this.httpClient.get<OrganizationPossibleProjectUser[]>(
      `${this.url}/organizations-module/get-organization-possible-project-users?projectId=${payload.projectId}`
    );
  }

  getOrganizationPossibleProjectTeams$(payload: {
    projectId: string;
  }): Observable<OrganizationPossibleProjectTeam[]> {
    return this.httpClient.get<OrganizationPossibleProjectTeam[]>(
      `${this.url}/organizations-module/get-organization-possible-project-teams?projectId=${payload.projectId}`
    );
  }

  getUserPermissionsInOrganizationProject$(payload: {
    projectId: string;
    userId: string;
  }): Observable<UserPermissionsInOrganizationProject> {
    return this.httpClient.get<UserPermissionsInOrganizationProject>(
      `${this.url}/organizations-module/get-user-permissions-in-organization-project?projectId=${payload.projectId}&userId=${payload.userId}`
    );
  }

  getProjectName(payload: { projectId: string }): Observable<string> {
    return this.httpClient
      .get<Project>(
        `${this.url}/construction-site-management-module/get-project?Id=${payload.projectId}`
      )
      .pipe(map((project) => project.name));
  }

  getProjectPublicLinksTokens$(payload: {
    projectId: string;
  }): Observable<PublicLink[]> {
    return this.httpClient.get<PublicLink[]>(
      `${this.url}/organizations-module/get-project-public-links-tokens?projectId=${payload.projectId}`
    );
  }

  private sortBy(sortBy?: ProjectsSort): string[] {
    const buffer: string[] = [];

    if (sortBy?.type) {
      buffer.push(`SortColumn=${sortBy.type}`);
    }

    if (sortBy?.ascending) {
      buffer.push(`IsAscending=${sortBy.ascending}`);
    }

    return buffer;
  }
}
