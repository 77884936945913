import { MediaType } from './media-type';

export interface BlobBody {
  rootNoteId: string;
  name: string;
  type?: MediaType;
  blobUrl: string;
  extension: string;
}

export enum ContextType {
  Project,
  Organization,
}
