import { UnitsPreferredByUserPipe } from '@simlab/feature-measurement/pipes';
import { SortOption } from '../../ui/src/search-and-sort-filter/search-and-sort-filter.component';
import { TMeasurement, TMeasurementExtended } from './common-models';
import { SystemOfMeasurement } from './system-measurement';

export type TDisplayValueFunction = (
  value: TMeasurement,
  units: SystemOfMeasurement,
  unitsPreferredByUserPipe: UnitsPreferredByUserPipe
) => string;

export type TParamsToSortFunction = (
  value: TMeasurementExtended,
  sortByValue: SortOption
) => string;
