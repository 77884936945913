import { formatDate } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  ApiFacadeService,
  Media,
  MediaType,
  Note,
  NoteBase,
  NoteFilters,
  Page
} from '@simlab/data-access';
import { Observable, firstValueFrom, map } from 'rxjs';
import * as NotesActions from './notes.actions';
import * as NotesSelectors from './notes.selectors';

export const NEW_NOTE_LOCALLY_ID = 'TMP_NOTE';

export const SIMPLE_EMPTY_NOTE = (
  stageId: string,
  userFormatDate = 'yyyy-MM-d HH:mm:ss'
): Omit<NoteBase, 'id'> & Pick<Note, 'stageId'> => ({
  name: formatDate(Date.now(), userFormatDate, $localize.locale || 'en'),
  status: 'None',
  type: 'Information',
  marker: undefined,
  stageId
});
export const FULL_EMPTY_NOTE = (
  stageId: string,
  formatDate = 'yyyy-MM-d HH:mm:ss'
): Note => ({
  ...SIMPLE_EMPTY_NOTE(stageId, formatDate),
  description: '',
  stakeholderId: '',
  id: NEW_NOTE_LOCALLY_ID,
  creatorId: '',
  lastEditorId: '',
  stakeholderName: '',
  audioNotes: [],
  documentNotes: [],
  photoNotes: [],
  procoreDocuments: [],
  procoreDrawings: [],
  procoreImages: [],
  measurementNotes: [],
  distanceMeasurements: [],
  areaMeasurements: [],
  brushNotes: [],
  videoNotes: [],
  hasComments: false,
  isOwner: false,
  notificationsEnabled: false,
  isFrozen: false,

  creatorName: '',
  createdAt: '',
  lastEditorName: '',
  modifiedAt: ''
});

@Injectable()
export class NotesFacade {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly api = inject(ApiFacadeService);
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  readonly loaded$ = this.store.pipe(select(NotesSelectors.getNoteLoaded));
  readonly allNote$ = this.store.pipe(select(NotesSelectors.getAllNote));
  readonly selectedNote$ = this.store.pipe(select(NotesSelectors.getSelected));
  readonly selectedNoteId$ = this.store.pipe(
    select(NotesSelectors.getSelectedId)
  );
  readonly photos$ = this.store.pipe(select(NotesSelectors.getAllPhotos));
  readonly videos$ = this.store.pipe(select(NotesSelectors.getAllVideos));
  readonly audios$ = this.store.pipe(select(NotesSelectors.getAllAudios));
  readonly docs$ = this.store.pipe(select(NotesSelectors.getAllDocs));
  readonly metadata$ = this.store.pipe(select(NotesSelectors.metadata));
  readonly filters$ = this.store.pipe(select(NotesSelectors.filters));
  readonly counts$ = this.store.pipe(select(NotesSelectors.counts));

  readonly changeNoteStatusSuccess$ = this.actions$.pipe(
    ofType(NotesActions.changeNoteStatusSuccess)
  );

  readonly deleteNoteSuccess$ = this.actions$.pipe(
    ofType(NotesActions.deleteNoteSuccess),
    map((data) => data.noteId)
  );

  readonly updateMedia$ = this.actions$.pipe(ofType(NotesActions.updateMedia));
  readonly noteAdded$: Observable<Note> = this.actions$.pipe(
    ofType(NotesActions.addSimpleNoteSuccess),
    map((action) => action.note)
  );

  readonly getSimpleRootNotesForStage$ = (payload: { stageId: string }) =>
    this.api.rootNotes.getSimpleRootNotesForStage(payload);

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  initStore(
    payload: {
      stageId: string[] | null;
      page: { pageSize?: number; pageNumber: number };
      rootNotesFilter?: NoteFilters;
    },
    noteId?: string
  ) {
    this.store.dispatch(NotesActions.init({ payload, noteId }));
  }

  clearStore() {
    this.store.dispatch(NotesActions.clearStore());
  }

  selectedNoteId(noteId: string | undefined) {
    this.store.dispatch(NotesActions.selectedNoteId({ noteId }));
  }
  selectedNote(note: Note) {
    this.store.dispatch(NotesActions.selectedNote({ note }));
  }
  async saveLocalNote() {
    const { marker, name, status, type, stageId } = await firstValueFrom(
      this.selectedNote$
    );

    this.store.dispatch(
      NotesActions.addSimpleNote({
        note: { marker, name, status, type, stageId }
      })
    );
  }
  setLocallyNoteAsSelected(note: Note) {
    this.store.dispatch(NotesActions.selectedNoteLocally({ note }));
  }

  deleteNote(rootNoteId: string, stageId: string) {
    this.store.dispatch(NotesActions.deleteNote({ rootNoteId, stageId }));
  }

  deleteNoteLocally() {
    this.store.dispatch(NotesActions.deleteNoteLocally());
  }

  loadNoteSuccess(note: Note[]) {
    this.store.dispatch(NotesActions.loadNoteSuccess({ note }));
  }

  loadNoteFailure(error: any) {
    this.store.dispatch(NotesActions.loadNoteFailure({ error }));
  }

  addDigitalNote(payload: Media, mediaType: MediaType) {
    this.store.dispatch(NotesActions.addDigitalNote({ payload, mediaType }));
  }

  addDigitalNoteByID(
    payload: Media,
    mediaType: MediaType | (string & {}),
    nodeId: string
  ) {
    this.store.dispatch(
      NotesActions.addDigitalNoteByID({ payload, mediaType, nodeId })
    );
  }

  removeEmptyDigitalNote(mediaType: MediaType | (string & {})) {
    this.store.dispatch(NotesActions.removeEmptyDigitalNote({ mediaType }));
  }

  removeDigitalNote(id: string, mediaType: MediaType) {
    this.store.dispatch(NotesActions.removeDigitalNote({ id, mediaType }));
  }

  changeNoteStatus(payload: { status: string }) {
    this.store.dispatch(NotesActions.changeNoteStatus(payload));
  }

  changeNoteType(payload: { nodeType: string }) {
    this.store.dispatch(NotesActions.changeNoteType(payload));
  }

  changeNoteDescription(payload: { nodeDescription: string }) {
    this.store.dispatch(NotesActions.changeNoteDescription(payload));
  }

  changeNoteStakeholder(payload: { stakeholderId: string }) {
    this.store.dispatch(NotesActions.changeNoteStakeholder(payload));
  }

  changeNoteName(payload: { name: string }) {
    this.store.dispatch(NotesActions.changeNoteName(payload));
  }

  metadata(metadata: Page) {
    this.store.dispatch(NotesActions.metadata({ metadata }));
  }

  addNote(note: Note) {
    this.store.dispatch(NotesActions.addNote({ note }));
  }

  addSimpleNote(note: Omit<NoteBase, 'id'> & Pick<Note, 'stageId'>) {
    this.store.dispatch(NotesActions.addSimpleNote({ note }));
  }

  addNotes(notes: Note[], stageId: string) {
    this.store.dispatch(NotesActions.addNotes({ notes, stageId }));
  }

  removeNote(response: {
    rootNoteId: string;
    stageId: string;
    status: string;
    type: string;
  }) {
    this.store.dispatch(NotesActions.removeNote({ response }));
  }

  updateNote(note: Note) {
    this.store.dispatch(NotesActions.updateNote({ note }));
  }

  selectFirstNote() {
    this.store.dispatch(NotesActions.selectFirst());
  }

  removeMarker(noteId: string) {
    this.store.dispatch(NotesActions.removeMarker({ noteId }));
  }

  addMedia(
    projectId: string,
    noteId: string,
    file: File,
    mediaType: MediaType,
    fileType?: string
  ): void {
    this.store.dispatch(
      NotesActions.addMedia({ projectId, noteId, file, mediaType, fileType })
    );
  }

  updateMedia(id: string, mediaType: MediaType, file: Media): void {
    this.store.dispatch(NotesActions.updateMedia({ id, mediaType, file }));
  }

  changeNoteStatusSuccess(note: Note) {
    this.store.dispatch(NotesActions.changeNoteStatusSuccess({ note }));
  }

  updateNoteNotification(id: string, enable: boolean): void {
    this.store.dispatch(
      NotesActions.updateNoteNotification({
        rootNoteId: id,
        areEnabled: enable
      })
    );
  }
}
