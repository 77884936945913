export type NoteMeasurement = {
  id: string;
  name: string;
  creatorId: string;
};

export interface IVector3 {
  x: number;
  y: number;
  z: number;
}

export type TVerticle = {
  index: number;
} & IVector3;

export type TypeMeasurement = 'measurement-area' | 'measurement-distance';

export type IUpdateMeasurementTitle = Pick<TMeasurementBase, 'title' | 'id'>;
export type IUpdateMeasurementColor = Pick<TMeasurementBase, 'color' | 'id'>;

export type TTriangle = {
  index: number;
  firstVertice: number;
  secondVertice: number;
  thirdVertice: number;
};
export type TMeasurementData = {
  vertices: TVerticle[];
  triangles: TTriangle[];
  surfaceSize: number;
};

export type TMeasurementExtended = {
  displayValue: string;
} & TMeasurement;

export type TCalculateAreaMeasurementData = {
  data: TMeasurementData;
} & ICreationInfo &
  IBaseMeasurementInfo;

export type TCalculateDistanceMeasurementData = {
  data: TVerticle[];
} & ICreationInfo &
  IBaseMeasurementInfo;

export type TMeasurement = {
  data: TMeasurementData | TVerticle[];
} & ICreationInfo &
  IBaseMeasurementInfo;

export interface IBaseMeasurementInfo {
  groupIds: string[];
  rootNoteId: string | undefined;
  rootNoteCreatorId: string | undefined;
  id: string;
  title: string;
  color: string;
}

export interface ICreationInfo {
  creatorName: string;
  createdAt: string;
  creatorId: string;
  lastEditorName: string;
  modifiedAt: string;
}

export type TMeasurementGroup = {
  measurements: TMeasurement[];
  id: string;
  name: string;
} & ICreationInfo;

export type TMeasurementBase = {
  id: string;
  title: string;
  color: string;
  data: IVector3[] | TMeasurementData;
};

export type TAddBase = {
  title: string;
  color: string;
  data: IVector3[] | TMeasurementData;
};

export type TAddRootNote = {
  rootNoteId: string;
} & TAddBase;

export type TUpdateData = {
  id: string;
  data: IVector3[] | TMeasurementData;
};

export interface IAddMeasurementToStage extends Omit<TMeasurementBase, 'id'> {
  stageId: string;
}

export interface IAssignMeasurementToRootNote {
  id: string;
  rootNoteId?: string;
  name?: string;
}

export interface IUnassignMeasurementFromRootNote {
  id: string;
}

export type TMeasurementGroupBase = {
  id: string;
  name: string;
};

export type TAddMeasurementGroup = {
  stageId: string;
  name: string;
};

export type TAddMeasurementGroups = {
  id: string;
  groupsIds: string[];
};

export type TAddMeasurementsToGroup = {
  groupId: string;
  ids: string[];
};

export type TBaseMeasurement = { id: string };

export type TAssignToRootNote = {
  rootNoteId: string;
} & TBaseMeasurement;

export type TUpdateTitle = { title: string } & TBaseMeasurement;
export type TUpdateColor = { color: string } & TBaseMeasurement;
export type TUpdateGroupName = { name: string } & TBaseMeasurement;
export type TRemoveFromGroup = {
  groupId: string;
} & TBaseMeasurement;

export type TSimpleMeasurement = {
  groupIds: string[];
  rootNoteId: string | undefined;
  color: string;
  title: string;
} & ICreationInfo;

export type TStageMeasurmentSimpleGroups = {
  id: string;
  creatorId: string;
  name: string;
};

export type TDistanceCount = {
  count: number;
  groupsCount: number;
};
