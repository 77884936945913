import { OrganizationLevel } from './organization-level';
import { AccessType } from './private-license';

export interface BaseUserInfo {
  userName: string;
  userEmail: string;
  userId?: string;
}

export interface User extends BaseUserInfo {
  userId: string;
  invitationId: string;
  organizationRoleId: OrganizationLevel;
  organizationRoleName: 'Admin' | 'Project Manager' | 'Basic' | 'Viewer';
  invitationPending: boolean;
  teamsCount: number;
  projectsCount: number;
  tagsCount: number;
  accessType: AccessType;
  addedDate: string;
  membershipState: MembershipState;
  hasOrganizationLicense: boolean;
}

export const ROLE_DESCRIPTION_STRING: Record<OrganizationLevel, string> = {
  [OrganizationLevel.Viewer]: $localize`:@@VIEWER_DESCRIPTION:The Viewer role in the system is designed for users with limited permissions, primarily focusing on viewing and accessing information without the ability to make organizational or project-related changes.`,
  [OrganizationLevel.Basic]: $localize`:@@BASIC_DESCRIPTION:The Basic role in the system is limited in its permissions, primarily focusing on individual tasks without organizational or administrative capabilities. However, there is a possibility to modify (increase or decrease) the permission level for the Basic role at the project-specific permission level.`,
  [OrganizationLevel.Admin]: $localize`:@@ADMIN_DESCRIPTION:The Admin role encompasses extensive permissions for managing organizational settings and overseeing user activities.`,
  [OrganizationLevel.Owner]: $localize`:@@OWNER_DESCRIPTION:Owner`,
  [OrganizationLevel.ProjectManager]: $localize`:@@PROJECT_MANAGER_DESCRIPTION:The role of a Project Manager is closely related to the administrator's role; however, their permissions are limited to the projects they manage. There is no capability to intervene in organization settings or financial operations.`,
};

export enum MembershipState {
  Member = 0,
  InvitationPending = 1,
  InvitationRejected = 2,
}

export enum SeatLicenseType {
  Seat = 1,
  OwnLicense = 2,
  AssignLicense = 3,
}
