import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export interface DatepickerConfig {
  closeOnSelect?: boolean;
  closeOnSelectDelay?: number;
  showNearMonthDays?: boolean;
  hideInputContainer?: boolean;
  format?: 'DD/MM/YYYY' | 'MM/DD/YYYY';
}

export type FormatDateDataPicker = 'DD/MM/YYYY' | 'MM/DD/YYYY';

export const DATA_PICKER_FORMAT_CONFIG = new InjectionToken<
  Observable<FormatDateDataPicker>
>('DATA_PICKER_FORMAT_CONFIG');
