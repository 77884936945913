import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DesignFlatButton } from '@simlab/design/button';
import { UiButtonModule } from '../ui-button/ui-button.module';
import { PaginatorComponent } from './components/paginator/paginator.component';

@NgModule({
  declarations: [PaginatorComponent],
  imports: [CommonModule, UiButtonModule, DesignFlatButton],
  exports: [PaginatorComponent],
})
export class UiPaginatorModule {}
