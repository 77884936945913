import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output
} from '@angular/core';

@Directive({
  selector: 'img[loaded]',
  standalone: true
})
export class ImageBaseDirective {
  @Output() loaded = new EventEmitter<boolean>();

  _src = '';
  @Input()
  set src(value: string | undefined) {
    if (value) {
      this.elRef.nativeElement.style.display = 'none';
      this.elRef.nativeElement.src = value;
    }
  }

  @HostListener('load')
  onLoad(): void {
    this.loaded.emit(true);
    this.elRef.nativeElement.style.display = 'initial';
  }

  constructor(private elRef: ElementRef<HTMLImageElement>) {
    this.elRef.nativeElement.style.display = 'none';
    if (this.elRef.nativeElement.complete) {
      this.loaded.emit(true);
    }
  }
}
