<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i ui-icon name="icon-warning-red" defaultColor customSize="7.25rem"></i>
      <h2 designLabel>Warning</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    {{ modalData.text }}
  </ng-container>

  <ng-container designDialogFooter>
    <button design-stroked-button (click)="cancel()">Cancel</button>
    <button design-stroked-button designButtonColor="error" (click)="notSave()">
      Don't save
    </button>
    <button design-flat-button designButtonColor="error" (click)="save()">
      Save
    </button>
  </ng-container>
</design-dialog-wrapper>
