import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractConstructor, Constructor } from './constructor';

/** @docs-private */
export interface CanSelect {
  /** Whether the component is selected. */
  selected: boolean;
}

type CanSelectCtor = Constructor<CanSelect> & AbstractConstructor<CanSelect>;

/** Mixin to augment a directive with a `selected` property. */
export function mixinSelected<T extends AbstractConstructor<{}>>(
  base: T
): CanSelectCtor & T;
export function mixinSelected<T extends Constructor<{}>>(
  base: T
): CanSelectCtor & T {
  return class extends base {
    protected _selected = false;

    get selected(): boolean {
      return this._selected;
    }
    set selected(value: BooleanInput) {
      this._selected = coerceBooleanProperty(value);
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
