import { AccessType } from './private-license';

export interface RoleMember {
  userId: string;
  userName: string;
  userEmail: string;
  teamsCount: number;
  projectsCount: number;
  lastSeen: string;
  organizationRoleName: string; //NOTE: extended
  hasOrganizationLicense: boolean;
  organizationRoleId: number;
  usesLicense: boolean;
  accessType: AccessType;
}
