import { ModelSweep } from '@simlab/matterport/api';
import { Property } from './property';
import { ICreationInfo } from './stages-base.interace';

interface BaseInterface {
  stageId: string;
  name: string;
  extension?: string;
  componentUrl: string;
  sweeps?: ModelSweep[];
}
export interface StageComponent extends BaseInterface,ICreationInfo {
  id: string;
  createdAt: string;
  creatorId: string;
  offset: string;
  isSynchronizingAccepted: boolean;
  lastEditorId: string;
  sweepsUpdating?: boolean;
  modelType: TComponentType;

  //extend
  objs: Property;
  mtl: string;
  textures: Property;
  ifcOriginal: string;
  ifcConvertedToCollada: string;
  ifcBimDataToXml: string;
  visible: boolean | undefined;
}
export const ComponentTypes = {
  Blueprint: 1,
  Model3D: 2,
  Matterport: 3,
} as const;
export type TComponentTypeKeys = keyof typeof ComponentTypes;
export type TComponentTypeValues =
  typeof ComponentTypes[keyof typeof ComponentTypes];
export type TComponentType = TComponentTypeKeys | TComponentTypeValues;

export interface CreateComponent extends BaseInterface {
  type: string;
}
