import { inject, Injectable } from '@angular/core';
import { select } from '@ngrx/store';

import { ofType } from '@ngrx/effects';

import {
  IAddAreaMeasurementToGroups,
  IAddMeasurementToStage,
  IUnassignAreaMeasurementFromRootNote,
  IUpdateAreaMeasurementColor,
  IUpdateMeasurementColor,
  IUpdateMeasurementTitle,
  TAddAreaToRootNote,
  TAddMeasurementGroup,
  TAddMeasurementsToGroup,
  TAddRootNote,
  TAreaMeasurement,
  TAreaMeasurementGroup,
  TAreaMeasurementGroupBase,
  TAssignToRootNote,
  TMeasurement,
  TMeasurementData,
  TMeasurementGroups,
  TRemoveAreaMeasurementFromGroup,
  TUpdateData
} from '@simlab/feature-measurement/models';
import { map, Observable } from 'rxjs';
import { ApiArea } from '../../../data-access/area-api.service';
import { MeasurementFacade } from '../base.facade';
import { AreaActions } from './area.actions';
import { AreaMeasurementSelectors } from './area.selectors';

@Injectable()
export class AreaFacade extends MeasurementFacade<
  TAreaMeasurement,
  TAreaMeasurementGroup
> {
  private _apiArea = inject(ApiArea);

  readonly addMeasurementGroupSuccess$: Observable<string> =
    this._actions$.pipe(
      ofType(AreaActions.addMeasurementGroupSuccess),
      map(({ value }) => value)
    );

  override getMeasurementsGroup$(
    stageId: string
  ): Observable<TMeasurementGroups[]> {
    return this._apiArea.getStageMeasurementGroups(stageId).pipe(
      map((e) => {
        return e.map((a) => {
          return {
            ...a,
            measurements: a.items.map((e) => {
              const a: TMeasurement = { ...e } as unknown as TMeasurement;
              return a;
            })
          } as TMeasurementGroups;
        });
      })
    );
  }
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  readonly loaded$ = this._store.pipe(
    select(AreaMeasurementSelectors.selectAreaMeasurementLoaded)
  );
  readonly allMeasurementsHidden$ = this._store.pipe(
    select(AreaMeasurementSelectors.selectAllAreaHidden)
  );
  readonly allMeasurement$ = this._store.pipe(
    select(AreaMeasurementSelectors.selectAllAreaMeasurement)
  );
  readonly selectedMeasurement$ = this._store.pipe(
    select(AreaMeasurementSelectors.selectEntity)
  );
  readonly selectedMeasurementId$ = this._store
    .pipe(select(AreaMeasurementSelectors.selectEntity))
    .pipe(map((e) => e?.id));
  readonly deleteSuccess$ = this._actions$.pipe(
    ofType(AreaActions.deleteMeasurementSuccess),
    map((data) => data.id)
  );
  readonly showMeasurementsSegments$ = this._store.pipe(
    select(AreaMeasurementSelectors.showMeasurementsSegments)
  );
  readonly addSuccess$ = this._actions$.pipe(
    ofType(AreaActions.addMeasurementSuccess),
    map((data) => data)
  );
  readonly updateDataSuccess$ = this._actions$.pipe(
    ofType(AreaActions.updateMeasurementData),
    map((data) => data)
  );
  readonly addToNoteSuccess$ = this._actions$.pipe(
    ofType(AreaActions.addMeasurementToRootNoteSuccess),
    map((data) => data)
  );
  readonly updateColorSuccess$: Observable<IUpdateAreaMeasurementColor> =
    this._actions$.pipe(
      ofType(AreaActions.updateMeasurementColorSuccess),
      map(
        ({ id, color }) =>
          ({
            id,
            color
          }) as IUpdateAreaMeasurementColor
      )
    );

  exportMeasurements(resultFileName: string, ids: string[]) {
    return this._apiArea.exportStageMeasurements(resultFileName, ids);
  }
  checkExist$ = (id: string): Observable<boolean> =>
    this._store.pipe(select(AreaMeasurementSelectors.checkExist(id)));

  areaMeasurementsGroup$(stageId: string): Observable<TAreaMeasurementGroup[]> {
    return this._apiArea.getStageMeasurementGroups(stageId);
  }
  addMeasurementGroup(payload: TAddMeasurementGroup) {
    this._store.dispatch(AreaActions.addMeasurementGroup({ body: payload }));
  }
  addMeasurementsToGroup$(payload: TAddMeasurementsToGroup) {
    return this._apiArea.addMeasurementsToGroup(payload);
  }
  addMeasurementToGroups$(payload: IAddAreaMeasurementToGroups) {
    return this._apiArea.addMeasurementToGroups(payload);
  }
  deleteMeasurementGroup(id: string) {
    return this._apiArea.removeMeasurementGroup({ id });
  }
  removeMeasurementFromGroup$(payload: TRemoveAreaMeasurementFromGroup) {
    return this._apiArea.removeMeasurementFromGroup(payload);
  }
  renameMeasurementGroup(payload: TAreaMeasurementGroupBase) {
    return this._apiArea.updateMeasurementGroupName(payload);
  }
  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  init(stageId: string) {
    this._store.dispatch(AreaActions.loadStageMeasurements({ stageId }));
  }
  clearStore() {
    this._store.dispatch(AreaActions.clearStore());
  }

  updateMeasurementData(payload: TUpdateData) {
    this._store.dispatch(
      AreaActions.updateMeasurementData({
        id: payload.id,
        data: payload.data as TMeasurementData
      })
    );
  }
  updateMeasurementTitle(payload: IUpdateMeasurementTitle) {
    this._store.dispatch(AreaActions.updateMeasurementTitle(payload));
  }
  updateMeasurementColor(payload: IUpdateMeasurementColor) {
    this._store.dispatch(AreaActions.updateMeasurementColor(payload));
  }

  assignMeasurementToNote(props: TAssignToRootNote) {
    this._store.dispatch(AreaActions.attachMeasurementToNote(props));
  }
  deleteMeasurement(id: string) {
    this._store.dispatch(AreaActions.deleteMeasurement({ id }));
  }

  assignedNoteHasBeenDeleted(payload: IUnassignAreaMeasurementFromRootNote) {
    this._store.dispatch(AreaActions.assignedNoteHasBeenDeleted(payload));
  }
  unassignMeasurementFromNote(payload: IUnassignAreaMeasurementFromRootNote) {
    this._store.dispatch(AreaActions.unassignMeasurementFromNote(payload));
  }
  addMeasurement(payload: IAddMeasurementToStage) {
    this._store.dispatch(AreaActions.addMeasurement({ payload }));
  }
  addMeasurementToRootNote(payload: TAddRootNote) {
    this._store.dispatch(
      AreaActions.addMeasurementToRootNote({
        payload: { ...payload } as TAddAreaToRootNote
      })
    );
  }
  selectedMeasurement(id: string | undefined) {
    this._store.dispatch(AreaActions.selectMeasurement({ id }));
  }

  hideAllMeasurements(hideAll: boolean) {
    this._store.dispatch(AreaActions.hideAllMeasurements({ hideAll }));
  }
  showMeasurementsSegments(visibility: boolean) {
    this._store.dispatch(AreaActions.showMeasurementsSegments({ visibility }));
  }
  toggleMeasurementSegmentsVisibility() {
    this._store.dispatch(AreaActions.toggleMeasurementSegmentsVisibility());
  }
}
