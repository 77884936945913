<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel>{{ modalData.title }}</h2>
  </ng-container>

  <ng-container designDialogContent>
    {{ modalData.text }}
  </ng-container>

  <ng-container designDialogFooter>
    <button design-stroked-button (click)="cancel()">
      <div i18n="@@BACK">Back</div>
    </button>
    <button design-flat-button designButtonColor="accent" (click)="confirm()">
      {{ modalData.confirmButton ?? 'Confirm' }}
    </button>
  </ng-container>
</design-dialog-wrapper>
