import { TimelineMode } from './user-preferences';

export interface PublicLinkValid {
  isProjectActive: boolean;
  isValid: boolean;
}
export interface PublicLink {
  id: string;
  name: string;
  entrances?: number;
  jwtToken: string;
  isValid: boolean;
  expirationDate: Date | string;
}

export interface CreatePublicLink
  extends Omit<PublicLink, 'id' | 'jwtToken' | 'isValid' | 'expirationDate'> {
  projectId: string;
  timeline: TimelineMode;
  expirationDate: Date | string | undefined;
}

export interface EditPublicLink extends Omit<CreatePublicLink, 'timeline'> {
  id: string;
}

export interface DeletePublicLink {
  projectId: string;
  id: string;
}
