export enum OrganizationPermission {
  None = 0,
  All = ~0,

  // organization

  ViewOrganization = 1 << 0,
  EditOrganizationProfile = 1 << 1,
  DeleteOrganization = 1 << 2,
  TransferOrganization = 1 << 3,

  // organization roles

  ViewOrganizationRoles = 1 << 4,

  // users

  ViewUsers = 1 << 5,
  InviteNewUsers = 1 << 6,
  ChangeUserOrganizationRole = 1 << 7,
  DeleteUsers = 1 << 8,
  AddRemoveTagsToUser = 1 << 9,

  // projects

  ViewProjects = 1 << 10,
  CreateDeleteProjects = 1 << 11,
  AddDeleteTeamsInProjects = 1 << 12,
  ManageIndividualUserPermissionsInProject = 1 << 13,
  ChangeProjectArchivizationStatus = 1 << 14,
  TransferProject = 1 << 15,
  CanGenerateProjectReport = 1 << 16,
  TransferToSimon = 1 << 28,

  // teams

  ViewTeams = 1 << 17,
  ManageDeleteTeams = 1 << 18,
  CreateManageDeleteOwnTeams = 1 << 19,

  // tags

  ViewTags = 1 << 20,
  AddRemoveOwnTags = 1 << 21,
  RemoveOthersTags = 1 << 22,

  // subscription plan

  ViewSubscriptionPlans = 1 << 23,
  ChangeSubscriptionPlan = 1 << 24,
  ManagePayMethod = 1 << 25,
  ViewDownloadInvoices = 1 << 26,

  // public link
  ManagePublicLinkTokens = 1 << 27,

  // licenses
  ManageLicenses = 1 << 29,
}
