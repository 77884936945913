import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { InvitationCommand } from '../../models/command/invitation-command';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class InvitationService implements InvitationCommand {
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);
  //COMMANDS
  acceptInvitation(payload: { invitationId: string }): Observable<string> {
    return this.httpClient.post<string>(
      `${this.url}/organizations-module/accept-invitation`,
      payload
    );
  }

  cancelInvitation(payload: { invitationId: string }): Observable<any> {
    return this.httpClient.post(
      `${this.url}/organizations-module/cancel-invitation`,
      payload
    );
  }

  createInvitation(payload: {
    organizationId: string;
    email: string;
    organizationRoleId: number;
    useUserLicense: boolean;
    projects: string[];
    licenseId?: string;
  }): Observable<any> {
    return this.httpClient.post(
      `${this.url}/organizations-module/create-invitation`,
      payload
    );
  }

  rejectInvitation(payload: { invitationId: string }): Observable<any> {
    return this.httpClient.post(
      `${this.url}/organizations-module/reject-invitation`,
      payload
    );
  }

  resendInvitation(payload: { invitationId: string }): Observable<any> {
    return this.httpClient.post(
      `${this.url}/organizations-module/resend-invitation`,
      payload
    );
  }
}
