import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
  input
} from '@angular/core';

/** Event object emitted by MatChip when selected or deselected. */
export class MatChipSelectionChange {
  constructor(
    /** Reference to the chip that emitted the event. */
    public source: ChipComponent,
    /** Whether the chip that emitted the event is selected. */
    public selected: boolean,
    /** Whether the selection change was a result of a user interaction. */
    public isUserInput = false
  ) {}
}
@Component({
  selector: 'ui-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipComponent {
  @Output() eClose: EventEmitter<void> = new EventEmitter<void>();
  @HostBinding('class.chip') class = true;
  @HostBinding('class.chip--removable') canRemovable() {
    return this.removable;
  }

  readonly label = input<string>();

  @Input() removable: boolean = false;

  close() {
    this.eClose.next();
  }
}
