import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DesignIcon } from '@simlab/design/icon';
import { UiButtonModule } from '../ui-button/ui-button.module';
import { UiIconModule } from '../ui-icon/public-api';
import { UiProgressSpinnerModule } from '../ui-progress-spinner/ui-progress-spinner.module';
import { UiSlideshowComponent } from './components/ui-slideshow/ui-slideshow.component';
import { ImageBaseDirective } from './directives/image-base.directive';
import { DesignFabButton } from '@simlab/design/button';

@NgModule({
  declarations: [UiSlideshowComponent],
  imports: [
    CommonModule,
    UiButtonModule,
    ImageBaseDirective,
    UiIconModule,
    DesignFabButton,
    DesignIcon,
    UiProgressSpinnerModule,
  ],
  exports: [UiSlideshowComponent, ImageBaseDirective],
})
export class UiSlideshowModule {}
