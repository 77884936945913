import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import { NoteExtendStatus, NoteType } from '../../models/src/lib/notes-type';

export type NoteFiltersState = {
  filter: {
    stagesRange: string[];
    noteTypes: NoteType[];
    noteStatuses: NoteExtendStatus<NoteType>[];
  };
};

const initialState: NoteFiltersState = {
  filter: {
    stagesRange: [],
    noteTypes: [],
    noteStatuses: []
  }
};
function toggleValue<T>(noteTypes: T[], type: T) {
  return noteTypes.includes(type)
    ? noteTypes.filter((currentType) => currentType !== type)
    : [...noteTypes, type];
}

export const NoteFiltersStore = signalStore(
  withState(initialState),
  withMethods((store) => ({
    resetFilters(): void {
      patchState(store, () => ({
        ...initialState
      }));
    },
    setFilters(newFilter: Partial<NoteFiltersState['filter']>): void {
      patchState(store, ({ filter }) => ({
        filter: { ...filter, ...newFilter }
      }));
    },
    toggleNoteType(type: NoteType): void {
      patchState(store, (state) => ({
        filter: {
          ...state.filter,
          noteTypes: [...toggleValue(state.filter.noteTypes, type)]
        }
      }));
    },
    toggleNoteStatus(status: NoteExtendStatus<NoteType>): void {
      patchState(store, (state) => ({
        filter: {
          ...state.filter,
          noteStatuses: [...toggleValue(state.filter.noteStatuses, status)]
        }
      }));
    },
    toggleStage(stage: string): void {
      patchState(store, (state) => ({
        filter: {
          ...state.filter,
          stagesRange: [...toggleValue(state.filter.stagesRange, stage)]
        }
      }));
    }
  })),
  withComputed(({ filter }) => ({
    filtersLength: computed(() => {
      return (
        filter().noteTypes.filter((type) => type !== 'Issue').length +
        filter().stagesRange.length +
        filter().noteStatuses.length
      );
    })
  }))
);
