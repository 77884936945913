import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { MenuPanelComponent } from './components/menu-panel/menu-panel.component';
import { MenuTriggerForDirective } from './directives/menu-trigger-for.directive';

@NgModule({
  imports: [
    CommonModule,
    MenuPanelComponent,
    MenuItemComponent,
    MenuTriggerForDirective,
  ],
  exports: [MenuPanelComponent, MenuItemComponent, MenuTriggerForDirective],
})
export class UiMenuPanelModule {}
