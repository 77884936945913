import { NgModule } from '@angular/core';
import { DesignLabel } from '../components/label.directive';
import { DesignPrefix } from '../components/prefix.directive';
import { DesignSuffix } from '../components/suffix.directive';

@NgModule({
  imports: [DesignPrefix, DesignSuffix, DesignLabel],
  exports: [DesignPrefix, DesignSuffix, DesignLabel],
})
export class DesignCommonModule {}
