import { NgModule } from '@angular/core';
import { ColorDirective } from './directives/color.directive';
import { RawColorDirective } from './directives/raw-color.directive';

@NgModule({
  declarations: [RawColorDirective],
  imports: [ColorDirective],
  exports: [ColorDirective, RawColorDirective],
})
export class DesignHelperModule {}
