import { DesignSize, DesignSizeInput } from '../models/primitives';
import { AbstractConstructor, Constructor } from './constructor';

/** @docs-private */
export interface CanSize {
  size: DesignSize | undefined;
}

type CanSizeCtor = Constructor<CanSize> & AbstractConstructor<CanSize>;

/** Mixin to augment a directive with a `loaded` property. */
export function mixinSize<T extends AbstractConstructor<{}>>(
  base: T
): CanSizeCtor & T;
export function mixinSize<T extends Constructor<{}>>(base: T): CanSizeCtor & T {
  return class extends base {
    private _size: DesignSize = DesignSize.Medium;

    get size(): DesignSize | undefined {
      return this._size;
    }
    set size(value: DesignSizeInput) {
      this._size = value as DesignSize;
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
