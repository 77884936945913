/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonBase } from './button-base.directive';

@Component({
  selector: 'a[design-anchor-button]',
  exportAs: 'designAnchor',
  standalone: true,
  imports: [CommonModule],
  template: '<ng-content></ng-content>',
  styleUrls: ['./anchor-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-base-button design-anchor-button',
  },
  inputs: [
    'disabled',
    'disabled: designButtonDisabled',
    'loaded: designButtonLoaded',
    'color: designButtonColor',
  ],
})
export class DesignAnchor extends ButtonBase {}
