import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiFacadeService, ProjectPermissions } from '@simlab/data-access';
import { catchError, map, of, switchMap } from 'rxjs';
import { ProjectPrivilegesActions } from './privileges.actions';

@Injectable()
export class ProjectPrivilegesEffects {
      private readonly actions$ = inject(Actions);
      private readonly apiFacadeService = inject(ApiFacadeService);
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectPrivilegesActions.loadProjectPermissions),
      switchMap(({ projectId }) =>
        this.apiFacadeService.projects
          .getUserPermissionsInProject({ projectId })
          .pipe(
            map((projectPermissions: ProjectPermissions) =>
              ProjectPrivilegesActions.loadProjectPermissionsSuccess({
                projectPermissions
              })
            ),
            catchError((error: any) =>
              of(
                ProjectPrivilegesActions.loadProjectPermissionsFailure({
                  error
                })
              )
            )
          )
      )
    )
  );
}
