import { ComponentConfiguration } from '@simlab/simlab-facility-management/scene-object';
import { TransformConverter } from '@simlab/transform';
import { Quaternion, Vector3 } from 'three';

export function movePositionByOffset(
  component: Partial<
    Pick<
      ComponentConfiguration,
      'position' | 'normal' | 'stemHeight' | 'rotation' | 'scale'
    >
  >,
  offset: TransformConverter,
  isBlueprint: boolean = false
): {
  position: Vector3;
  rotation: Quaternion;
  scale: Vector3;
  normal: Vector3;
} {
  const position = new Vector3();
  if (component.position) {
    const {
      x: px,
      y: py,
      z: pz,
    } = offset.flipPositionZ(
      new Vector3(
        component.position.x,
        component.position.y,
        component.position.z
      )
    );
    position.set(px, py, pz);
  }
  const normal = new Vector3();

  let rotation = new Quaternion(0, 0, 0, 1);
  if (component.rotation) {
    const euler = offset.unityQuaternionToThreejsRotation(
      new Quaternion(
        component.rotation.x,
        component.rotation.y,
        component.rotation.z,
        component.rotation.w
      )
    );
    rotation = new Quaternion().setFromEuler(euler);

    if (isBlueprint) {
      //TODO: (olek)
      // rotation = this.rotationUnityToMatterport(
      //   new Quaternion().setFromEuler(new Euler(Math.PI * 0.5, Math.PI, 0)),
      //   rotation
      // );
    }
  }

  const scale = new Vector3();
  if (component.scale) {
    const {
      x: xs,
      y: ys,
      z: zs,
    } = offset.toMatterportScale(
      new Vector3(component.scale.x, component.scale.y, component.scale.z)
    );
    scale.set(xs, ys, zs);
  }

  return {
    normal,
    position,
    rotation,
    scale,
  };
}
