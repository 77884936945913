export interface ICreationInfo {
  creatorName: string;
  createdAt: string;
  creatorId: string;
  lastEditorName: string;
  modifiedAt: string;
}
export interface IErrorResponse {
  errorMessage: string;
  fieldName: string;
  errorCode: string;
  responseGeneratedOn: string;
}
