import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Organizations } from '@simlab/data-access';
import {
  ORGANIZATIONS_FEATURE_KEY,
  State,
  organizationsAdapter,
} from './organizations.reducer';

// Lookup the 'Organizations' feature state managed by NgRx
export const getOrganizationsState = createFeatureSelector<State>(
  ORGANIZATIONS_FEATURE_KEY
);

const { selectAll, selectEntities } = organizationsAdapter.getSelectors();

export const getOrganizationsLoaded = createSelector(
  getOrganizationsState,
  (state: State) => state.loaded
);

export const getOrganizationById = (id: string) =>
  createSelector(getOrganizationsState, (state: State) => state.entities[id]);

export const getOrganizationsError = createSelector(
  getOrganizationsState,
  (state: State) => state.error
);

export const getOrganizationUploadingImage = createSelector(
  getOrganizationsState,
  (state: State) => state.uploadingImage
);

export const getAllOrganizations = createSelector(
  getOrganizationsState,
  (state: State) => selectAll(state)
);

export const getOrganizationsEntities = createSelector(
  getOrganizationsState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getOrganizationsState,
  (state: State) => state.selectedId
);

export const getInfoAboutTransfer = createSelector(
  getOrganizationsEntities,
  getSelectedId,
  (entities, selectedId) =>
    selectedId ? entities[selectedId]?.transfer : undefined
);

export const getFirstOrganizationsEntities = createSelector(
  getAllOrganizations,
  (organization: Organizations[]) => {
    const activeOrganization = organization.find(
      (organization: Organizations) => organization.invitationPending !== true
    );
    return activeOrganization ? activeOrganization.id : undefined;
  }
);
