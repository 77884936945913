import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { ThemeType } from './models/theme-type';
import { ThemeService } from './services/theme.service';
import { THEME_OPTIONS } from './tokens/options.token';

export function initialize(themeService: ThemeService) {
  return () => themeService.init();
}
@NgModule({})
export class UiThemeModule {
  static forRoot(config: {
    defaultTheme: ThemeType;
  }): ModuleWithProviders<UiThemeModule> {
    return {
      ngModule: UiThemeModule,
      providers: [
        ThemeService,
        { provide: THEME_OPTIONS, useValue: config.defaultTheme },
        {
          provide: APP_INITIALIZER,
          useFactory: initialize,
          deps: [ThemeService],
          multi: true,
        },
      ],
    };
  }
}
