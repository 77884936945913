<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i ui-icon name="icon-warning-red" defaultColor customSize="7.25rem"></i>
      <h2 designLabel>{{ title }}</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    <div class="context-text">
      {{ modalData.text }}
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="cancel()"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="error"
      (click)="confirm()"
      data-cy="confirm-delete-dialog"
    >
      {{ confirmButton }}
    </button>
  </ng-container>
</design-dialog-wrapper>
