<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i
        ui-icon
        name="icon_success_color"
        customSize="7.25rem"
        defaultColor
      ></i>
      <h2 designLabel>{{ data.title }}</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    <div class="content">
      {{ data.context }}
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button design-flat-button designButtonColor="success" (click)="confirm()">
      <div i18n="@@CONTINUE">Continue</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
