import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { ENVIRONMENT_CONFIG } from '@simlab/util-shared';
import {
  errorHandlerFactory,
  InsightsService
} from '../services/insights.service';

@NgModule()
export class InsightsModule {
  static forRoot(): ModuleWithProviders<InsightsModule> {
    return {
      ngModule: InsightsModule,
      providers: [
        InsightsService,
        // {
        //   provide: ErrorHandler,
        //   useClass: ApplicationinsightsAngularpluginErrorService
        // },
        //TO CHECK!
        {
          provide: ErrorHandler,
          useFactory: errorHandlerFactory,
          deps: [ENVIRONMENT_CONFIG]
        }
      ]
    };
  }

  //NOTE: we use 'InsightsService' here to get insights running when 'InsightsModule.forRoot()' is initialized
  constructor(private readonly _insightsService: InsightsService) {}
}
