import { Marker, Page } from '@simlab/data-access';

export interface RFIListResponse extends Page {
  items: RFIListItem[];
}

export type RFIListItem = {
  id: string;
  procoreCreatorId: number;
  procoreId: number;
  number: string | undefined;
  title: string;
  status: RFIStatus;
  modyfiedAt: string;
  ballInCourts: string[];
  marker: Marker | undefined;
};

export const RFIStatus = {
  Draft: 1,
  Open: 2,
  Closed: 3
} as const;
export type RFIStatus = (typeof RFIStatus)[keyof typeof RFIStatus];

export type ProcoreUser = {
  id: number;
  login: string;
  name: string;
  companyName: string;
};

export type CostCode = {
  id: 0;
  name: string;
  fullCode: string;
};

export type ProcoreStatus = {
  status: string;
  value: 0;
};

export enum ProcoreBallInCourtRoleType {
  Assignees = 1,
  Creator,
  RfiManager
}

export type SpecificationSection = {
  id: number;
  number: string;
  description: string;
  label: string;
  currentRevisionId: number;
};

export type ResponsibleContractor = {
  id: number;
  name: string;
};

export type Attachment = {
  id: number;
  canBeViewed: boolean;
  filename: string;
  name: string;
  url: string;
  viewable: boolean;
};

export type Answer = {
  id: number;
  answerDate: string;
  richTextBody: string;
  plainTextBody: string;
  attachments: Attachment[];
  createdBy: string;
  createdById: number;
  official: boolean;
};

export type Question = {
  id: number;
  body: string;
  plainTextBody: string;
  richTextBody: string;
  answers: Answer[];
};

export type RFI = {
  id: string;
  stageId: string;
  accepted: boolean;
  assignee: ProcoreUser;
  assignees: ProcoreUser[];
  ballInCourts: ProcoreUser[];
  ballInCourt: ProcoreUser;
  ballInCourtRole: ProcoreBallInCourtRoleType;
  costCode: CostCode;
  costImpact: ProcoreStatus;
  createdAt: string;
  createdBy: ProcoreUser;
  deletedAt: string;
  deleted: boolean;
  drawingNumber: number;
  drawingIds: number[];
  dueDate: string;
  draft: boolean;
  fullNumber: string;
  initiatedAt: string;
  link: string;
  number: string;
  prefix: string;
  private: boolean;
  rfiManager: ProcoreUser;
  status: RFIStatus;
  specificationSection: SpecificationSection;
  subject: string;
  scheduleImpact: ProcoreStatus;
  updatedAt: string;
  translatedStatus: string;
  timeResolved: string;
  title: string;
  distributionlist: ProcoreUser[];
  receivedFrom: ProcoreUser;
  reference: string;
  responsibleContractor: ResponsibleContractor;
  questions: Question[];
  marker: Marker | undefined;
  customFields: {
    additionalProp1: {
      dataType: string;
      value: string;
    };
    additionalProp2: {
      dataType: string;
      value: string;
    };
    additionalProp3: {
      dataType: string;
      value: string;
    };
  };
  itemUrl: string;
};

export type RfiAllowedActions = {
  create: true;
  update: true;
  delete: true;
  retrieve: true;
  createAsOpen: true;
  createAsDraft: true;
  createAsSendforReview: true;
  close: true;
  openRfi: true;
  reopenRfi: true;
  forwardForReview: true;
  addAssignee: true;
  addResponse: true;
  deleteResponse: true;
  markResponsesAsOfficial: true;
  allowedDocumentAttachments: true;
  allowedImageAttachments: true;
  allowedDrawingAttachments: true;
  changeBallInCourt: true;
};
