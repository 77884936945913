<div class="google_map">
  <h3 *ngIf="usedExternally === false" i18n="@@LOCATION">Location</h3>
  <ui-form-field
    [ngClass]="
      usedExternally ? 'google_map--address-externally' : 'google_map--address'
    "
    [hideLabel]="true"
  >
    <label *ngIf="usedExternally === false" i18n="@@ADDRESS">ADDRESS</label>
    <ui-input
      #inputComponent
      i18n-placeholder="@@SEARCH_FOR_LOCATION"
      placeholder="Search for location"
      data-cy="ui-input-location"
      [readonly]="readonly"
      (valueChange)="inputValueChange($event)"
      [prefix]="
        (markerSetted | async) ? input_prefix_location : input_prefix_search
      "
    >
      <ng-template #input_prefix_search>
        <i ui-font fontName="search" fontSize="1.7rem"></i>
      </ng-template>

      <ng-template #input_prefix_location>
        <i ui-font fontName="location-note" fontSize="2rem"></i>
      </ng-template>
    </ui-input>
  </ui-form-field>
  <div class="google_map google_map--configure" #mapElement></div>
</div>

<div id="customButtons" class="action-buttons">
  <button
    *ngIf="geolocationSupport"
    ui-mini-fab-button
    (click)="setGeolocation()"
  >
    <i ui-font fontName="location-map" fontSize="1.5rem"></i>
  </button>
  <button ui-mini-fab-button (click)="zoomOut()">
    <i ui-font fontName="remove" fontSize="2rem"></i>
  </button>
  <button ui-mini-fab-button (click)="zoomIn()">
    <i ui-font fontName="add" fontSize="2rem"></i>
  </button>
</div>
