import { inject, InjectionToken } from '@angular/core';
import { Params, Router, CanActivateFn, CanLoadFn } from '@angular/router';

export class QueryGuard {
  static canActivateWithParams(params: string[]): InjectionToken<{
    canActivate: CanActivateFn;
}> {
    return new InjectionToken<{
    canActivate: CanActivateFn;
}>('QueryGuardWithParams', {
      providedIn: 'root',
      factory: () => {
        const router = inject(Router);
        const queryParams: Params = router.getCurrentNavigation()?.extractedUrl
          .queryParams as Params;

        return {
          canActivate(): boolean {
            return Object.keys(queryParams)
              .map((value: string) => {
                return params.includes(value);
              })
              .every((hasKey: boolean) => hasKey);
          },
        };
      },
    });
  }

  static canLoadWithParams(params: string[]): InjectionToken<{
    canLoad: CanLoadFn;
}> {
    return new InjectionToken<{
    canLoad: CanLoadFn;
}>('QueryGuardWithParams', {
      providedIn: 'root',
      factory: () => {
        const router = inject(Router);
        const queryParams: Params = router.getCurrentNavigation()?.extractedUrl
          .queryParams as Params;

        return {
          canLoad(): boolean {
            return Object.keys(queryParams)
              .map((value: string) => {
                return params.includes(value);
              })
              .every((hasKey: boolean) => hasKey);
          },
        };
      },
    });
  }
}
