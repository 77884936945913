import { ICreationInfo } from './stages-base.interace';

export interface Stage extends ICreationInfo {
  id: string;
  name: string;
  stageDate: string;
  description: string;
  creatorId: string;
  lastEditorId: string;
  hasRootNotes: boolean;
  hasComponents: boolean;
  hasSynchronizedModel: boolean;
  hasSynchronizedMatterport: boolean;
  sequenceNumber?: number;
}
