//SOURCE: https://github.com/angular/components/blob/083f437abf58ac59b00a24cd5a4edd1dc0b0a5be/src/material/core/common-behaviors/constructor.ts

/** @docs-private */
export type Constructor<T> = new (...args: any[]) => T;

/**
 * This is a permissive type for abstract class constructors.
 * @docs-private
 */
export type AbstractConstructor<T = object> = abstract new (
  ...args: any[]
) => T;
