import {
  EnvironmentProviders,
  importProvidersFrom,
  inject,
  makeEnvironmentProviders
} from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import {
  MatterportApiConfig,
  MatterportApiModule
} from '@simlab/matterport/api';
import { ComponentsEffects } from './components/components.effects';
import { ComponentsFacade } from './components/components.facade';
import { NotesEffects } from './notes/notes.effects';
import { NotesFacade } from './notes/notes.facade';
import { ProjectsEffects } from './projects/projects.effects';
import { ProjectsFacade } from './projects/projects.facade';
import { ShowcaseNotesEffects } from './showcase-notes/showcase-notes.effects';

import { StagesEffects } from './stages/stages.effects';
import { StagesFacade } from './stages/stages.facade';

import { ENVIRONMENT_CONFIG, Environment } from '@simlab/util-shared';
import { UserPreferenceEffects } from './user-preferences/user-preference.effects';
import { UserPreferenceFacade } from './user-preferences/user-preference.facade';

export function provideDataStore(
  environment: Environment
): EnvironmentProviders {
  return makeEnvironmentProviders([
    ProjectsFacade,
    ComponentsFacade,
    NotesFacade,
    StagesFacade,
    UserPreferenceFacade,
    {
      provide: MatterportApiConfig,
      useFactory: () => {
        const environment = inject(ENVIRONMENT_CONFIG);
        return {
          applicationKey: environment.configuration.matterport.apiKey,
          clientId: environment.configuration.matterport.clientId,
          redirectOrigin: `${window.location.origin}${environment.configuration.matterport.redirectAfterLoginUrl}`,
          matterportModelApiUrl:
            environment.configuration.matterport.apiGraphUrl,
          matterportShowcaseApiUrl:
            environment.configuration.matterport.showcaseGraphUrl
        } as MatterportApiConfig;
      }
    },
    importProvidersFrom(MatterportApiModule),

    provideEffects(
      ShowcaseNotesEffects,
      ComponentsEffects,
      ProjectsEffects,
      StagesEffects,
      UserPreferenceEffects,
      NotesEffects
    )
  ]);
}
