<!-- <img class="info-image" [src]="imageSrc" alt="" draggable="false" /> -->
<i ui-icon [name]="imageSrc" size="img"></i>
<span class="text text--header" data-cy="image-info-title">{{ header }}</span>
<span
  class="text text--description"
  data-cy="image-info-description"
  *ngFor="let description of descriptions"
>
  {{ description }}
</span>
@if (button(); as button) {
  <button
    design-flat-button
    designButtonColor="accent"
    class="image-info-button"
    [designFireOnClick]="button.action$"
  >
    <design-button-loader
      designIconSuffix
      designColor="surface"
    ></design-button-loader>
    @if (button.icon) {
      <i [designIcon]="button.icon" designSize="1.5rem"></i>
    }
    <div>{{ button.title }}</div>
  </button>
}
