import { InjectionToken } from '@angular/core';
import { fromEvent, map, Observable, ReplaySubject, take } from 'rxjs';

export const API_GOOGLE_URL = new InjectionToken<string>('API_GOOGLE_URL');

export const API_GOOGLE = new InjectionToken<ReplaySubject<boolean>>(
  'API_GOOGLE'
);

declare global {
  interface Window {
    initMap: () => void;
  }
}

export function loadGoogleScript(
  document: Document,
  apiUrl: string
): Observable<boolean> {
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiUrl}&libraries=places&callback=initMap&language=${$localize.locale}`;
  document.body.appendChild(script);

  window.initMap = function () {
    console.log(
      `%cInit Api map`,
      'background-color: black; color: lightgreen; padding: 2px; text-transform: Uppercase'
    );
  };

  const loaded$ = new ReplaySubject<boolean>(1);

  fromEvent(script, 'load')
    .pipe(
      map(() => {
        return loaded$.next(true);
      }),
      take(1)
    )
    .subscribe();

  return loaded$;
}
