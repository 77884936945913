import { Observable } from 'rxjs';
import { Color, Quaternion, Sprite, Vector3 } from 'three';
import {
  BlueprintComponent,
  PlaneConfiguration,
} from '../base-objects/blueprint.base';
import { MeshComponent, MeshConfiguration } from '../base-objects/mesh.base';
import {
  SpriteComponent,
  SpriteConfiguration,
} from '../base-objects/sprite.base';
import { TextComponent, TextConfiguration } from '../base-objects/text.base';
import { ThreeObjectBase } from '../base-objects/three-object.base';

export interface ICustomComponent<T> {
  /**
   * @description  (string | undefined): The ID of the currently selected tag component.
   */
  set selectedNote(noteId: string | undefined);
  get selectedNote(): string | undefined;
  /**
   * @description  (Observable): An observable that emits the ID of the selected tag component when it changes.
   */
  readonly selectedNote$: Observable<string | undefined>;
  /**
   * @description  (Observable): An observable that emits click event emitters when a tag component is clicked.
   */
  readonly componentClicked$: Observable<ClickEventEmitter>;
  /**
   * @param { ComponentConfiguration} component : {@link ComponentConfiguration}.
   * @description (function): Adds a tag component to the scene and returns an observable that emits the added component.
   */
  addComponent$: (component: ComponentConfiguration) => Observable<T>;
  /**
   * @param { ComponentConfiguration} component : {@link ComponentConfiguration}.
   * @description (function): Adds a tag component to the scene with a position offset calculated using the offset object, and returns an observable that emits the added component.
   */
  addComponentWithOffset$: (
    component: ComponentConfiguration,
    isBlueprint?: boolean
  ) => Observable<T>;
  /**
   * @param { ComponentConfiguration} component : {@link ComponentConfiguration}.
   * @description   (function): Updates the position, normal, and stem height of a tag component and returns an observable that emits when the update is complete.
   */
  updateNote$: (
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight'
    >
  ) => Observable<void>;
  /**
   *
   * @description   (function): Updates the position, normal, and stem height of a tag component .
   */
  updateNote: (
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight'
    >
  ) => void;
  /**
   * @description   (function): Updates the position, normal, stem height, rotation, and scale of a tag component with a position offset calculated using the offset object.
   */
  updatePositionWithOffset: (
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight' | 'rotation' | 'scale'
    >
  ) => void;
  /**
   * @description  (function): Same as updatePositionWithOffset, but returns an observable.
   */
  updatePositionWithOffset$: (
    component: Pick<
      ComponentConfiguration,
      'id' | 'position' | 'normal' | 'stemHeight' | 'rotation' | 'scale'
    >
  ) => Observable<void>;
  /**
   * @description  (function): Deletes a tag component from the scene.
   */
  deleteNote: (noteId: string) => string;
  /**
   * @description  (function): Same as deleteNote, but returns an observable.
   */
  deleteNote$: (noteId: string) => Observable<string>;
  /**
   * @description   (function): Deletes all tag components from the scene.
   */
  clearAllNotes: () => void;
  /**
   * @description (function): Same as clearAllNotes, but returns an observable.
   */
  clearAllNotes$: () => Observable<void>;
}

export type Inputs = Transformation & {
  id: string;
  visible?: boolean;
  renderOrder?: number;
  opacity?: number;
  scale: Vector3;
  isCollider?: boolean;
  autoScale?: boolean;
  userData?:
    | (Record<string, unknown> & { type?: UComponentType | string,  annotationType?: AnnotationType; })
    | undefined
    | null;
  depthTest?: boolean;
  transparent?: boolean;
  lookAt?: boolean;
  dollhouseView?: boolean;
};
export const ComponentTypes = ['portal', 'blueprint'] as const;
export type UComponentType = typeof ComponentTypes[number];
export type Transformation = {
  position: Vector3;
  rotation?: Quaternion;
  normal: Vector3;
  stemHeight: number;
};

export interface ComponentTextDefinition {
  text: string;
  value?: unknown;
  weight?: number;
  propertyType?: string;
  backgroundColor?: Color;
}

export type ComponentsTypeClass =
  | SpriteComponent
  | TextComponent
  | MeshComponent
  | BlueprintComponent;

export type ClickEventEmitter = {
  id: string;
  userData: Record<string, unknown> | undefined | null;
};

export type NoteComponentClickData = {
  collider: Sprite;
  input: ClickEvent;
  normal: null;
  point: Vector3;
  hover?: boolean;
  userData?: unknown;
};
export type ClickEvent = {
  button: number;
  eventType: string;
  position: Vector3;
};

export type ChildConfiguration =
  | TextConfiguration
  | SpriteConfiguration
  | PlaneConfiguration
  | MeshConfiguration;

export type ComponentConfiguration = Inputs & {
  objects: ChildConfiguration[];
};

// export type ThreeObject<T = ThreeObjectBase> = T extends { icon: string }
//   ? SpriteConfiguration
//   : TextConfiguration;

export function generateClassInstance<
  T extends ThreeObjectBase,
  W extends ObjectConfig<T>
>(type: IConstructor<T>, three: typeof import('three'), configuration: W): T {
  return new type(three, configuration.config);
}
export type IAbstractConstructor<T = object> = abstract new (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ...args: any[]
) => T;
export interface IConstructor<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  new (...args: any[]): T;
}
export type ObjectConfig<T> = {
  config: Partial<Omit<Inputs, 'id'>>;
  get instance(): T;
  init(three: typeof import('three')): void;
};
export type PropertiesOf<T> = { [K in keyof T]: T[K] };
export type AnnotationType = 'note' | 'punch-item' | 'rfi';