import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { RootNoteCommentsCommand } from '../../models/command/root-note-comments-command';
import { Comment } from '../../models/comment';
import { RootNoteCommentsQuery } from '../../models/query/root-note-comments-query';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class RootNoteCommentsService
  implements RootNoteCommentsCommand, RootNoteCommentsQuery
{
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);
  //COMMAND
  getCommentsForRootNote(payload: {
    rootNoteId: string;
  }): Observable<Comment[]> {
    return this.httpClient
      .get<Comment[]>(
        `${this.url}/construction-site-management-module/get-comments-for-root-note?rootNoteId=${payload.rootNoteId}`
      )
      .pipe(map((response: any) => response.items));
  }

  //QUERY
  addCommentToRootNote(payload: {
    rootNoteId: string;
    text: string;
  }): Observable<{ commandResponseData: string }> {
    return this.httpClient.post<{ commandResponseData: string }>(
      `${this.url}/construction-site-management-module/add-comment-to-root-note`,
      payload
    );
  }
  editComment(payload: {
    rootNoteCommentId: string;
    text: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/edit-comment`,
      payload
    );
  }
  replyToComment(payload: {
    inReplyToRootNoteCommentId: string;
    text: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/reply-to-comment`,
      payload
    );
  }
  removeCommentFromRootNote(payload: {
    rootNoteCommentId: string;
    rootNoteId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/remove-comment-from-root-note`,
      payload
    );
  }
}
