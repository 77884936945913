<ng-container [formGroup]="formGroup">
  <label i18n="@@TIMELINE_VIEW"> Timeline view </label>
  <design-radio-accordion hasSpacing formControlName="timelineMode">
    <div class="timeline-mode">
      <design-radio-expansion-panel
        class="time-line-panel"
        [value]="timelineMode.Expanded"
        alwaysExpanded
        id="drep-expanded"
        #expanded
      >
        <design-radio-expansion-panel-header
          color="secondary"
          data-cy="apperance-timeline-detailed"
        >
          <label designLabel i18n="@@TIMELINE_DETAILED">Detailed</label>
        </design-radio-expansion-panel-header>
        <ng-template designRadioExpansionPanelContent>
          <img src="assets/ui/images/timeline_expanded_light.svg" />
        </ng-template>
      </design-radio-expansion-panel>

      <design-radio-expansion-panel
        class="time-line-panel"
        [value]="timelineMode.Condensed"
        alwaysExpanded
        id="drep-condensed"
        #condensed
      >
        <design-radio-expansion-panel-header
          color="secondary"
          data-cy="apperance-timeline-simplified"
        >
          <label designLabel i18n="@@TIMELINE_SIMPLIFIED">Simplified</label>
        </design-radio-expansion-panel-header>
        <ng-template designRadioExpansionPanelContent>
          <img src="assets/ui/images/timeline_condensed.svg" />
        </ng-template>
      </design-radio-expansion-panel>
    </div>
  </design-radio-accordion>
</ng-container>
