import { computed, inject, Injector } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  withComputed,
  withHooks,
  withMethods,
  withState
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { GlobalFiltersStore } from '@simlab/annotations-filters/services';
import { Page, ProjectPaging, RFIItemWithMarker } from '@simlab/data-access';
import {
  StagesFacade,
  StageWithCount,
  UserPreferenceFacade
} from '@simlab/data-store';
import { DesignDateValidators } from '@simlab/design/datepicker';
import { EmptyObject, SortModel } from '@simlab/design/sort-switch';
import { NoteFiltersStore } from '@simlab/feature-stages/services';
import { canOpenProcoreTab } from '@simlab/feature/documents';
import { ApiProcoreService } from '@simlab/procore/data-access';
import {
  DeleteProcoreItemPayload,
  RFIGetPayload,
  RFIGetPayloadFilters,
  RFIListItem
} from '@simlab/procore/models';
import {
  catchError,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  EMPTY,
  exhaustMap,
  filter,
  map,
  merge,
  of,
  pipe,
  startWith,
  switchMap,
  tap
} from 'rxjs';
import { ProcoreFiltersStore } from './procore-filters.store';

const isProjectConnectedWithProcore$ = (
  activatedRouteSnapshot: ActivatedRouteSnapshot
) =>
  switchMap(() =>
    canOpenProcoreTab(activatedRouteSnapshot).pipe(
      filter((isProjectConnectedWithProcore) => isProjectConnectedWithProcore)
    )
  );

const INIT_PAGING_STATE = { pageSize: 10, pageNumber: 1 } as const;

type State = {
  projectId: string;
  pageStageId: string;
  items: RFIListItem[];
  isLoading: boolean;
  metaData: Page | null;
  searchInput: string;
  sort: SortModel | EmptyObject;
  page: ProjectPaging;
  filters: Partial<RFIGetPayloadFilters>;
  itemSelected: RFIListItem | undefined;
  activateMarkers: RFIItemWithMarker[];
};

const initState: State = {
  projectId: '',
  pageStageId: '',
  items: [],
  isLoading: true,
  metaData: null,
  searchInput: '',
  sort: {},
  page: INIT_PAGING_STATE,
  itemSelected: undefined,
  filters: {},
  activateMarkers: []
};

export const RFIListStore = signalStore(
  withState(initState),
  withComputed((store) => ({
    fetchQuery: computed(() => ({
      projectId: store.projectId(),
      filter: {
        ...store.filters(),
        search: store.searchInput(),
        stages: [...(store.filters().stages ?? []), store.pageStageId()]
      },
      page: {
        pageSize: store.page().pageSize,
        pageNumber: store.page().pageNumber
      },
      sortColumn: store.sort().sortColumn ?? null,
      isAscending: store.sort().isAscending ?? null
    }))
  })),
  withMethods(
    (
      store,
      api = inject(ApiProcoreService),
      globalFiltersStore = inject(GlobalFiltersStore),
      procoreFiltersStore = inject(ProcoreFiltersStore, { optional: true }),
      noteFiltersStore = inject(NoteFiltersStore),
      userPreferences = inject(UserPreferenceFacade),
      stageFacade = inject(StagesFacade),
      activatedRoute = inject(ActivatedRoute),
      injector = inject(Injector)
    ) => ({
      updateProjectId: (projectId: State['projectId']) => {
        patchState(store, { projectId });
      },
      updatePageStageId: (pageStageId: State['pageStageId']) => {
        patchState(store, { pageStageId, items: [], isLoading: true });
      },
      updateSearch: (searchInput: State['searchInput']) => {
        patchState(store, {
          searchInput,
          items: [],
          isLoading: true,
          page: INIT_PAGING_STATE,
          sort: {}
        });
      },
      updateSort: (sort: State['sort']) => {
        patchState(store, { sort, items: [], isLoading: true });
      },
      updatePage: (page: State['page']) => {
        patchState(store, { page, items: [], isLoading: true, sort: {} });
      },
      setSelectedItemId: (itemSelected: State['itemSelected']) => {
        patchState(store, { itemSelected });
      },
      resetState: () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { projectId, pageStageId, page, ...initStateWithoutProjectId } =
          initState;
        // NOTE (Łukasz): recreate page state, because we wanna trigger change reference procedure.
        patchState(store, {
          ...{ ...initStateWithoutProjectId, page: { ...page } }
        });

        globalFiltersStore.resetFilters();
        procoreFiltersStore?.resetFilters();
        noteFiltersStore.resetFilters();
      },
      deleteItem: rxMethod<DeleteProcoreItemPayload>(
        pipe(
          exhaustMap((payload) =>
            api.deleteRfi(payload).pipe(
              tapResponse({
                next: () => {
                  patchState(store, ({ items, activateMarkers }) => {
                    return {
                      itemSelected: undefined,
                      items: items.filter(
                        (item) => item.procoreId !== payload.procoreId
                      ),
                      activateMarkers: activateMarkers.filter(
                        (item) => item.procoreId !== payload.procoreId
                      )
                    };
                  });
                },
                error: console.error,
                finalize: () => {
                  patchState(store, { isLoading: false });
                }
              })
            )
          )
        )
      ),
      deleteItemFromStore: (itemId: State['items'][number]['id']) => {
        const items = store.items().filter((item) => item.id !== itemId);
        patchState(store, { items });
      },
      setSelected: (itemSelected: State['itemSelected']) => {
        patchState(store, { itemSelected });
      },
      setSelectedById: (id: State['items'][number]['id']) => {
        const foundElement = store.items().find((element) => element.id === id);
        if (foundElement === undefined) {
          throw new Error(`Cannot find element with id: ${id}`);
        }

        patchState(store, { itemSelected: foundElement });
      },
      updateSelectedItemMarker: (marker: State['items'][number]['marker']) => {
        const selectedItem = store.itemSelected();
        if (selectedItem === undefined)
          throw new Error('Selected item is undefined');

        patchState(store, (actualData) => {
          const actualActiveMarkers = actualData.activateMarkers;

          const activeMarkerToUpdate = actualActiveMarkers.find(
            (activeMarker) => activeMarker.id === selectedItem.id
          );
          if (activeMarkerToUpdate !== undefined) {
            activeMarkerToUpdate.marker = marker;
          } else {
            if (marker !== undefined)
              actualActiveMarkers.push({
                ...selectedItem,
                marker
              } satisfies RFIItemWithMarker);
          }

          return {
            itemSelected: { ...selectedItem, marker },
            activateMarkers: [...actualActiveMarkers]
          };
        });
      },
      autoMarkersFetch: rxMethod<void>(
        pipe(
          isProjectConnectedWithProcore$(activatedRoute.snapshot),
          switchMap(() =>
            merge(
              // NOTE (Łukasz) stages changed in matterport -> timeline comp
              stageFacade.selectedStages$.pipe(
                filter(
                  (
                    stage: StageWithCount | undefined
                  ): stage is StageWithCount => !!stage
                ),
                map((stage: StageWithCount) => stage.id),
                distinctUntilChanged(),
                map(
                  (stageId) =>
                    ({
                      filter: { stages: [stageId] },
                      projectId: store.projectId()
                    }) satisfies Omit<RFIGetPayload, 'page'>
                )
              ),
              // NOTE (Łukasz) triggered filters/normal fetch
              toObservable(store.fetchQuery, { injector }).pipe(
                filter(
                  ({ projectId, filter: { stages } }) =>
                    projectId !== '' && !!stages[0]
                )
              )
            ).pipe(
              switchMap((fetchQuery) =>
                api.getRfisWithMarkersForStages(fetchQuery).pipe(
                  tap(({ items }) => {
                    const activateMarkers = items.filter(
                      ({ marker }) => marker !== undefined
                    );
                    patchState(store, { activateMarkers });
                  }),
                  catchError(() => of([]))
                )
              )
            )
          )
        )
      ),

      autoFiltersFetch: rxMethod<void>(() => {
        const procoreFilters$ =
          procoreFiltersStore?.filter !== undefined
            ? toObservable(procoreFiltersStore.filter).pipe(
                startWith(procoreFiltersStore.filter())
              )
            : EMPTY;

        return combineLatest([
          toObservable(globalFiltersStore.filter).pipe(
            startWith(globalFiltersStore.filter())
          ),
          procoreFilters$,
          toObservable(noteFiltersStore.filter).pipe(
            startWith(noteFiltersStore.filter())
          )
        ]).pipe(
          map(([globalFilters, procoreFilters, noteFilters]) => ({
            globalFilters,
            procoreFilters,
            noteFilters
          })),
          tap(({ globalFilters, procoreFilters, noteFilters }) => {
            const formattedDueDate = procoreFilters.rfiDueDate
              ? DesignDateValidators.transformStringDateToDate(
                  procoreFilters.rfiDueDate as string,
                  userPreferences.getDateFormat()
                ).toISOString()
              : undefined;

            patchState(store, {
              filters: {
                ...store.filters(),
                authors: globalFilters.authors
                  .map(({ procoreUserId }) => procoreUserId)
                  .filter((id): id is number => id !== undefined),
                assignees: globalFilters.stakeholders
                  .map(({ procoreUserId }) => procoreUserId)
                  .filter((id): id is number => id !== undefined),
                createdAtFrom: globalFilters.dateCreation.from || undefined,
                createdAtTo: globalFilters.dateCreation.to || undefined,
                modyfiedAtFrom:
                  globalFilters.dateModification.from || undefined,
                modyfiedAtTo: globalFilters.dateModification.to || undefined,
                statuses: procoreFilters.rfiStatuses as unknown as string,
                ballInCourts: procoreFilters.rfiBallInCourt,
                dueDate: formattedDueDate ?? undefined,
                stages: noteFilters.stagesRange
              },
              isLoading: true,
              items: [],
              page: INIT_PAGING_STATE,
              sort: {},
              searchInput: ''
            });
          })
        );
      }),
      autoFetch: rxMethod<void>(
        pipe(
          isProjectConnectedWithProcore$(activatedRoute.snapshot),
          concatMap(() =>
            toObservable(store.fetchQuery).pipe(
              filter(
                ({ projectId, filter: { stages } }) =>
                  projectId !== '' && !!stages[0]
              ),
              concatMap((dataQuery) =>
                api.getRfiList(dataQuery).pipe(
                  tapResponse({
                    next: (data) => {
                      const { items, ...metaData } = data;
                      patchState(store, (actualData) => ({
                        ...actualData,
                        items: [...actualData.items, ...items],
                        metaData
                      }));
                    },
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    error: (err) => {
                      console.error(err);
                    },
                    finalize: () => {
                      patchState(store, { isLoading: false });
                    }
                  })
                )
              )
            )
          )
        )
      ),
      getItemSelected$: () =>
        toObservable(store.itemSelected).pipe(
          filter(
            (itemSelected): itemSelected is RFIListItem =>
              itemSelected !== undefined
          )
        ),
      getSelectedItemId$: () =>
        toObservable(store.itemSelected).pipe(
          filter(
            (itemSelected): itemSelected is RFIListItem =>
              itemSelected !== undefined
          ),
          map(({ id }) => id)
        )
    })
  ),
  withHooks({
    onInit(store, activatedRoute = inject(ActivatedRoute)) {
      activatedRoute.params
        .pipe(
          map((params) => params['projectId'] as string),
          distinctUntilChanged(),
          takeUntilDestroyed()
        )
        .subscribe((id) => {
          if (!id) return;
          store.updateProjectId(id);
        });

      activatedRoute.queryParams
        .pipe(
          map((params) => params['stageId'] as string),
          distinctUntilChanged(),
          takeUntilDestroyed()
        )
        .subscribe((id) => {
          if (!id) return;

          store.updatePageStageId(id);
        });

      store.autoFetch();
      store.autoFiltersFetch();
      store.autoMarkersFetch();
    }
  })
);
