import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  distinctUntilChanged,
  exhaustMap,
  filter,
  map,
  of,
  switchMap
} from 'rxjs';

import {
  IErrorResponse,
  TAreaMeasurement,
  TSimpleMeasurement
} from '@simlab/feature-measurement/models';
import { ApiArea } from '../../../data-access/area-api.service';
import { AreaActions } from './area.actions';

@Injectable()
export class AreaMeasurementEffects {
  private actions$ = inject(Actions);
  private _apiArea = inject(ApiArea);
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.loadStageMeasurements),
      distinctUntilChanged((prev, act) => prev.stageId === act.stageId),
      filter((e) => e.stageId !== undefined),
      switchMap((action) =>
        this._apiArea.getStageMeasurements(action.stageId).pipe(
          switchMap((measurements: TAreaMeasurement[]) =>
            of(
              AreaActions.loadStageMeasurementsSuccess({
                measurements
              })
            )
          ),

          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(AreaActions.loadStageMeasurementsFailure({ error }));
          })
        )
      )
    )
  );

  addMeasurementGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.addMeasurementGroup),
      exhaustMap(({ body }) =>
        this._apiArea.addMeasurementGroup(body).pipe(
          map((value) => AreaActions.addMeasurementGroupSuccess({ value })),
          catchError((error: IErrorResponse) => {
            console.error('Error', error);
            return of(AreaActions.addMeasurementGroupFailure({ error }));
          })
        )
      )
    )
  );

  updateColor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.updateMeasurementColor),
      exhaustMap(({ color, id }) =>
        this._apiArea.updateMeasurementColor({ id, color }).pipe(
          map(() =>
            AreaActions.updateMeasurementColorSuccess({
              id,
              color
            })
          ),
          catchError((error: IErrorResponse) => {
            console.log(error);
            return of(AreaActions.updateMeasurementColorFailure({ error }));
          })
        )
      )
    )
  );
  updateData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.updateMeasurementData),
      exhaustMap(({ data, id }) =>
        this._apiArea.updateMeasurementData({ id, data }).pipe(
          map(() =>
            AreaActions.updateMeasurementDataSuccess({
              id,
              data
            })
          ),
          catchError((error: IErrorResponse) => {
            console.log(error);
            return of(AreaActions.updateMeasurementDataFailure({ error }));
          })
        )
      )
    )
  );
  updateTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.updateMeasurementTitle),
      exhaustMap(({ title, id }) =>
        this._apiArea.updateMeasurementTitle({ id, title }).pipe(
          map(() =>
            AreaActions.updateMeasurementTitleSuccess({
              id,
              title
            })
          ),
          catchError((error: IErrorResponse) => {
            console.log(error);
            return of(AreaActions.updateMeasurementTitleFailure({ error }));
          })
        )
      )
    )
  );
  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.deleteMeasurement),
      exhaustMap(({ id }) =>
        this._apiArea.removeMeasurement({ id }).pipe(
          map(() => AreaActions.deleteMeasurementSuccess({ id })),
          catchError((error: IErrorResponse) => {
            console.log(error);
            return of(AreaActions.deleteMeasurementFailure({ error }));
          })
        )
      )
    )
  );
  add$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.addMeasurement),
      exhaustMap((action) =>
        this._apiArea.addMeasurement(action.payload).pipe(
          switchMap((areaId: string) =>
            this._apiArea.getMeasurement(areaId).pipe(
              map(
                (simpleMeasurement: TSimpleMeasurement) =>
                  ({
                    ...simpleMeasurement,
                    data: action.payload.data,
                    color: action.payload.color,
                    title: action.payload.title,
                    id: areaId
                  }) as TAreaMeasurement
              ),
              map((area) => AreaActions.addMeasurementSuccess(area)),
              catchError((error: IErrorResponse) => {
                console.log(error);
                return of(AreaActions.addMeasurementFailure({ error }));
              })
            )
          )
        )
      )
    )
  );
  addToRootNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.addMeasurementToRootNote),
      exhaustMap((action) =>
        this._apiArea.addMeasurementToRootNote(action.payload).pipe(
          switchMap((areaId: string) =>
            this._apiArea.getMeasurement(areaId).pipe(
              map(
                (simpleMeasurement: TSimpleMeasurement) =>
                  ({
                    ...action.payload,
                    ...simpleMeasurement,
                    data: action.payload.data,
                    id: areaId
                  }) as TAreaMeasurement
              ),
              map((area) => AreaActions.addMeasurementToRootNoteSuccess(area)),
              catchError((error: IErrorResponse) => {
                console.log(error);
                return of(
                  AreaActions.addMeasurementToRootNoteFailure({
                    error
                  })
                );
              })
            )
          )
        )
      )
    )
  );

  attachToNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.attachMeasurementToNote),
      exhaustMap(({ id, rootNoteId }) =>
        this._apiArea.assignMeasurementToRootNote({ id, rootNoteId }).pipe(
          map(() =>
            AreaActions.attachMeasurementToNoteSuccess({
              id,
              rootNoteId
            })
          ),
          catchError((error: IErrorResponse) => {
            console.log(error);
            return of(AreaActions.attachMeasurementToNoteFailure({ error }));
          })
        )
      )
    )
  );
  detachFromNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.unassignMeasurementFromNote),
      exhaustMap(({ id }) =>
        this._apiArea.unAssignMeasurementToRootNote({ id }).pipe(
          map(() =>
            AreaActions.unassignMeasurementFromNoteSuccess({
              id
            })
          ),
          catchError((error: IErrorResponse) => {
            console.log(error);
            return of(
              AreaActions.unassignMeasurementFromNoteFailure({
                error
              })
            );
          })
        )
      )
    )
  );
  addSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AreaActions.addMeasurementSuccess),
      distinctUntilChanged((prev, act) => prev.id === act.id),
      map(({ id }) => AreaActions.selectMeasurement({ id }))
    )
  );
}
