import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { PublicLinkCommand } from '../../models/command/public-link-command';
import {
  CreatePublicLink,
  DeletePublicLink,
  EditPublicLink,
  PublicLink,
  PublicLinkValid,
} from '../../models/public-link';
import { PublicLinkQuery } from '../../models/query/public-link-query';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class PublicLinkService implements PublicLinkCommand, PublicLinkQuery {
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);

  getProjectPublicLinksTokens(projectId: string): Observable<PublicLink[]> {
    return this.httpClient.get<PublicLink[]>(
      `${this.url}/organizations-module/get-project-public-links-tokens?ProjectId=${projectId}`
    );
  }

  getPublicLinkTokenIsValid(): Observable<PublicLinkValid> {
    return this.httpClient.get<PublicLinkValid>(
      `${this.url}/organizations-module/check-public-link-token-is-valid`
    );
  }

  publicLinkOpened(): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/public-link-opened`,
      null,
      {
        withCredentials: true,
      }
    );
  }

  createPublicLink(payload: CreatePublicLink): Observable<string> {
    return this.httpClient.post(
      `${this.url}/organizations-module/create-public-link-token`,
      payload,
      { responseType: 'text' }
    );
  }

  editPublicLink(payload: EditPublicLink): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/organizations-module/edit-public-link-token`,
      payload
    );
  }

  deletePublicLink(payload: DeletePublicLink): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/organizations-module/delete-public-link-token?ProjectId=${payload.projectId}&Id=${payload.id}`
    );
  }
}
