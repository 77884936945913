export interface UserProject {
  projectId: string;
  projectName: string;
  ownerName: string;
  ownerEmail: string;
  teamsCount: number;
  usersCount: number;
  creationDate: string;
  addedAsIndividualUser: boolean;
}
