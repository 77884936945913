/* eslint-disable @angular-eslint/directive-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { Directive } from '@angular/core';
import { designLabelToken } from '../tokens/label.token';

@Directive({
  selector: 'label[designLabel]',
  standalone: true,
  host: {
    class: 'design-label',
  },
  providers: [{ provide: designLabelToken, useExisting: DesignLabel }],
})
export class DesignLabel {}
