/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { drawerAnimations } from '../animations/drwaer-animations';
import { DrawerComponent } from '../drawer/drawer.component';

@Component({
  selector: 'ui-sidenav',
  templateUrl: './sidenav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [drawerAnimations.transformDrawer],
  host: {
    class: 'ui-drawer ui-sidenav',
    tabIndex: '-1',
    // must prevent the browser from aligning text based on value
    '[attr.align]': 'null',
    '[class.ui-drawer-end]': 'position === "end"',
    '[class.ui-drawer-over]': 'mode === "over"',
    '[class.ui-drawer-push]': 'mode === "push"',
    '[class.ui-drawer-side]': 'mode === "side"',
    '[class.ui-drawer-opened]': 'opened',
  },
})
export class SidenavComponent extends DrawerComponent {}
