import { BehaviorSubject, Subject, fromEvent, map, takeUntil, tap } from 'rxjs';
import { Camera, Intersection, Layers, Raycaster, Scene, Vector2 } from 'three';

export class RaycasterHelper {
  readonly raycaster: Raycaster = new Raycaster();
  private readonly _destroy: Subject<void> = new Subject<void>();
  private readonly _selectedLayers: BehaviorSubject<number[]> =
    new BehaviorSubject<number[]>([2, 3, 4, 9]);
  private readonly _event = (
    domElement: HTMLElement,
    event: string,
    showHidden = false
  ) =>
    fromEvent<PointerEvent>(domElement, event).pipe(
      map((pointer: PointerEvent) => {
        const _pointer = new Vector2(
          (pointer.clientX / window.innerWidth) * 2 - 1,
          -(pointer.clientY / window.innerHeight) * 2 + 1
        );

        this.setFromCamera(_pointer);
        return this.getIntersectObjectsFromXY(true).filter((object) => {
          return showHidden || object.object.visible;
        });
      })
    );
  readonly pointerDown$ = (domElement: HTMLElement, showHidden = false) =>
    this._event(domElement, 'pointerdown', showHidden).pipe(
      tap((e) => console.log(e))
    );
  readonly pointerMove$ = (domElement: HTMLElement, showHidden = false) =>
    this._event(domElement, 'pointermove', showHidden);

  constructor(private readonly camera: Camera, private readonly scene: Scene) {
    this._initRaycaster();
  }

  private _initRaycaster() {
    this._selectedLayersObserver();
    this.setFromCamera();

    /**
     * How far raycaster get objects
     */
    this.raycaster.far = 4;
  }

  set threshold(threshold: THREE.RaycasterParameters) {
    // this.raycaster.params.Mesh = threshold;
    // this.raycaster.params.Sprite = threshold;
    // if (this.raycaster.params?.Points)
    //   this.raycaster.params.Points.threshold = threshold;

    this.raycaster.params = {
      ...this.raycaster.params,
      ...threshold,
    };
  }
  setFromCamera(pointer?: Vector2) {
    this.raycaster.setFromCamera(pointer ?? new Vector2(1, 1), this.camera);
  }
  get layers(): Layers {
    return this.raycaster.layers;
  }
  private _setActiveLayers(layers: number[]) {
    /**
     * Looking for walls, floors and our note sprite.
     * Disable all layers and select only needed
     */
    this.raycaster.layers.disableAll();
    layers.forEach((layer: number) => {
      this.raycaster.layers.enable(layer);
    });
    // this.raycaster.layers.enableAll();
  }

  destroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  private _selectedLayersObserver() {
    this._selectedLayers
      .asObservable()
      .pipe(
        tap((layers: number[]) => this._setActiveLayers(layers)),
        takeUntil(this._destroy)
      )
      .subscribe();
  }
  set selectedLayers(layers: number[]) {
    this._selectedLayers.next(layers);
  }

  enableAllLayers() {
    this.raycaster.layers.enableAll();
  }

  set far(far: number) {
    this.raycaster.far = far;
  }

  getIntersectObjects(
    cameraPosition: THREE.Vector3,
    notePosition: THREE.Vector3,
    pointer?: THREE.Vector2,
    far?: number
  ): Intersection[] {
    this.far = far || cameraPosition.distanceTo(notePosition) + 0.5;
    this.camera.updateMatrixWorld();
    if (pointer) {
      this.setFromCamera(pointer);
    } else {
      this.raycaster.set(
        cameraPosition,
        notePosition.clone().sub(cameraPosition).normalize()
      );
    }

    return this.raycaster.intersectObjects(this.scene.children, true);
  }

  getIntersectObjectsWithPoint(pointer: THREE.Vector3): Intersection[] {
    const cameraPosition = this.camera.parent?.position;
    if (!cameraPosition) throw Error('unknown camera position');
    this.raycaster.params = {
      Mesh: {},
      Line: { threshold: 0.1 },
      LOD: {},
      Points: { threshold: 0.1 },
      Sprite: { threshold: 0.3 },
    };
    this.raycaster.set(
      cameraPosition,
      pointer.clone().sub(cameraPosition).normalize()
    );

    return this.raycaster.intersectObjects(this.scene.children);
  }

  getIntersectObjectsFromXY(recursive: boolean) {
    return this.raycaster.intersectObjects(this.scene.children, recursive);
  }
}
