import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AREA_MEASUREMENT_FEATURE_KEY,
  AreaMeasurementState,
  areaMeasurementAdapter
} from './area.reducer';

// Lookup the 'AreaMeasurement' feature state managed by NgRx

const selectAreaMeasurementState = createFeatureSelector<AreaMeasurementState>(
  AREA_MEASUREMENT_FEATURE_KEY
);

const { selectAll, selectEntities } = areaMeasurementAdapter.getSelectors();

const selectAreaMeasurementLoaded = createSelector(
  selectAreaMeasurementState,
  (state: AreaMeasurementState) => state.loaded
);

const selectAreaMeasurementError = createSelector(
  selectAreaMeasurementState,
  (state: AreaMeasurementState) => state.error
);

const selectAllAreaMeasurement = createSelector(
  selectAreaMeasurementState,
  (state: AreaMeasurementState) => selectAll(state)
);

const selectAreaMeasurementEntities = createSelector(
  selectAreaMeasurementState,
  (state: AreaMeasurementState) => selectEntities(state)
);

const selectSelectedId = createSelector(
  selectAreaMeasurementState,
  (state: AreaMeasurementState) => state.selectedId
);
const selectAllAreaHidden = createSelector(
  selectAreaMeasurementState,
  (state: AreaMeasurementState) => state.hideAll
);
const showMeasurementsSegments = createSelector(
  selectAreaMeasurementState,
  (state: AreaMeasurementState) => state.segmentsVisible
);
const checkExist = (id: string) =>
  createSelector(selectAreaMeasurementEntities, (state) => !!state[id]);

const selectEntity = createSelector(
  selectAreaMeasurementEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
export const AreaMeasurementSelectors = {
  selectEntity,
  selectSelectedId,
  selectAllAreaMeasurement,
  selectAreaMeasurementError,
  selectAreaMeasurementLoaded,
  checkExist,
  selectAllAreaHidden,
  showMeasurementsSegments
};
