<div class="title-section">
  <div class="title-section__title">
    <h4 class="title-section__title__name">
      {{ title() }}
    </h4>
    @if (countOfElements() > 1) {
      <div class="title-section__title__count">
        {{ currentElement() }} of {{ countOfElements() }}
      </div>
    }
  </div>
  @if (subTitle()) {
    <h6 class="title-section__subtitle">{{ subTitle() }}</h6>
  }
</div>

@if (status(); as status) {
  @if (status.workStatus === 'WORKING') {
    <div class="progress-bar">
      <ui-progress-bar
        class="progress-bar__bar"
        [value]="progress()"
      ></ui-progress-bar>
      <button
        design-icon-button
        class="progress-bar__btn"
        (click)="cancel.emit()"
      >
        <i class="color" designIcon="cross" designSize="2rem"></i>
      </button>
    </div>
  }
  @if (
    status.failed.length > 0 || status.workStatus === 'COMPLETED_WITH_FAILURES'
  ) {
    <div class="failed">
      <div class="failed__info">
        <i
          class="icon-procore"
          ui-icon
          name="error"
          size="img"
          defaultColor
        ></i>
        <div class="failed__text">
          @if (status.failed.length > 1) {
            <span class="failed__text--length">{{ status.failed.length }}</span>
          }
          <div i18n="@@FAILED">Failed</div>
        </div>
      </div>
      @if (status.showErrorDetails) {
        <button
          class="show-error-btn"
          (click)="status?.errorDetails(status.failed)"
        >
          <div i18n="@@SHOW_ERROR_DETAILS">Show error details</div>
        </button>
      }
    </div>
  }
}
