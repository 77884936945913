import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs';

export type LinkTarget = '_self' | '_blank' | '_parent' | '_top';

@Injectable({
  providedIn: 'root'
})
export class DownloadFilesService {
  public readonly _httpClient = inject(HttpClient);
  private readonly _DOCUMENT = inject(DOCUMENT);

  /**
   * Downloads a file from the provided blob URL and triggers the download in the browser.
   *
   * The method fetches a file as a blob from the given URL, creates a downloadable object URL,
   * and programmatically triggers a download for the specified filename. After the download
   * is triggered, the object URL and the temporary link are cleaned up to free resources.
   *
   * @param blobUrl - The URL of the blob file to be downloaded.
   * @param fileName - The name that will be used when saving the file.
   * @returns An observable that completes once the file has been downloaded.
   */
  downloadFile(blobUrl: string, fileName: string) {
    return this._downloadBlob(blobUrl).pipe(
      tap((blob: Blob) => {
        const downloadURL = window.URL.createObjectURL(blob);
        const link = this._DOCUMENT.createElement('a');
        link.href = downloadURL;
        link.download = fileName;
        link.click();
        link.remove();
      })
    );
  }

  private _downloadBlob(fileUrl: string) {
    return this._httpClient.get(fileUrl, { responseType: 'blob' });
  }

  downloadFileByLink(
    href: string,
    fileName: string,
    target: LinkTarget = '_self'
  ) {
    const link = this._DOCUMENT.createElement('a');
    link.href = href;
    link.download = fileName;
    link.target = target;
    link.click();
    link.remove();
  }
}
