import { Injectable, inject } from '@angular/core';
import { BlobsService } from '../blobs/blobs.service';
import { ComponentsService } from '../components/components.service';
import { DigitalNotesService } from '../digital-notes/digital-notes.service';
import { InvitationService } from '../invitation/invitation.service';
import { MatterportApiService } from '../matterport/matterport.service';
import { OrganizationsService } from '../organizations/organizations.service';
import { PrivateLicenseService } from '../private-license/private-license.service';
import { ProjectService } from '../project/project.service';
import { ProjectsService } from '../projects/projects.service';
import { PublicLinkService } from '../public-link/public-link.service';
import { RootNoteCommentsService } from '../root-note-comments/root-note-comments.service';
import { RootNotesService } from '../root-notes/root-notes.service';
import { StagesService } from '../stages/stages.service';
import { ApiSubscriptionService } from '../subscription/api-subscription.service';
import { TeamTemplateService } from '../team-template/team-template.service';
import { TeamService } from '../team/team.service';
import { TokensService } from '../tokens/tokens.service';
import { UserPreferencesService } from '../user-preferences/user-preferences.service';
import { UserProfilesService } from '../user-profiles/user-profiles.service';
import { UserService } from '../user/user.service';

@Injectable()
export class ApiFacadeService {
  private readonly blobsService = inject(BlobsService);
  private readonly componentsService = inject(ComponentsService);
  private readonly digitalNotesService = inject(DigitalNotesService);
  private readonly organizationsService = inject(OrganizationsService);
  private readonly projectsService = inject(ProjectsService);
  private readonly projectService = inject(ProjectService);
  private readonly rootNoteCommentsService = inject(RootNoteCommentsService);
  private readonly rootNotesService = inject(RootNotesService);
  private readonly stagesService = inject(StagesService);
  private readonly userPreferencesService = inject(UserPreferencesService);
  private readonly userProfilesService = inject(UserProfilesService);
  private readonly userService = inject(UserService);
  private readonly invitationService = inject(InvitationService);
  private readonly teamService = inject(TeamService);
  private readonly teamTemplateService = inject(TeamTemplateService);
  private readonly tokensService = inject(TokensService);
  private readonly matterportService = inject(MatterportApiService);
  private readonly privateLicenseService = inject(PrivateLicenseService);
  private readonly apiSubscriptionService = inject(ApiSubscriptionService);
  public readonly publicLinkService = inject(PublicLinkService);

  get blobs(): BlobsService {
    return this.blobsService;
  }

  get components(): ComponentsService {
    return this.componentsService;
  }

  get digitalNotes(): DigitalNotesService {
    return this.digitalNotesService;
  }

  get organizations(): OrganizationsService {
    return this.organizationsService;
  }

  get projects(): ProjectsService {
    return this.projectsService;
  }

  get project(): ProjectService {
    return this.projectService;
  }

  get privateLicense(): PrivateLicenseService {
    return this.privateLicenseService;
  }

  get rootNoteComments(): RootNoteCommentsService {
    return this.rootNoteCommentsService;
  }

  get rootNotes(): RootNotesService {
    return this.rootNotesService;
  }

  get stages(): StagesService {
    return this.stagesService;
  }

  get userPreferences(): UserPreferencesService {
    return this.userPreferencesService;
  }

  get userProfiles(): UserProfilesService {
    return this.userProfilesService;
  }

  get user(): UserService {
    return this.userService;
  }

  get invitation(): InvitationService {
    return this.invitationService;
  }

  get team(): TeamService {
    return this.teamService;
  }
  get teamTemplate(): TeamTemplateService {
    return this.teamTemplateService;
  }

  get tokens(): TokensService {
    return this.tokensService;
  }

  get matterport(): MatterportApiService {
    return this.matterportService;
  }

  get subscriptionService(): ApiSubscriptionService {
    return this.apiSubscriptionService;
  }
}
