import { ApplicationRef, Injectable, NgZone, inject } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { ModalService } from '@simlab/ui/modal';
import {
  Observable,
  catchError,
  filter,
  first,
  firstValueFrom,
  from,
  map,
  of,
  switchMap,
  tap
} from 'rxjs';
import { NewUpdateModalComponent } from './components/new-update-modal/new-update-modal.component';

@Injectable()
export class CheckForUpdateService {
      private readonly appRef = inject(ApplicationRef);
      private readonly ngZone = inject(NgZone);
      private readonly modalService = inject(ModalService);
      private readonly updates = inject(SwUpdate);

  checkForUpdate$() {
    return this.ngZone.onStable.pipe(
      first(),
      switchMap(() => {
        return from(this.updates.checkForUpdate());
      }),

      switchMap((userAcceptOrThereIsNoUpdate: boolean) => {
        if (userAcceptOrThereIsNoUpdate) {
          return this.updates.versionUpdates.pipe(
            tap((evt) => console.log(evt)),
            filter(
              (evt): evt is VersionReadyEvent =>
                evt.type === 'VERSION_READY' ||
                evt.type === 'NO_NEW_VERSION_DETECTED'
            ),
            switchMap((evt: VersionReadyEvent) => {
              if (
                evt.type === 'VERSION_READY' ||
                evt.type === 'NO_NEW_VERSION_DETECTED'
              ) {
                return this.updateApplicationAndReload().pipe(
                  tap((reload: boolean) => reload && document.location.reload())
                );
              }
              return of(false);
            })
          );
        } else {
          return of(userAcceptOrThereIsNoUpdate);
        }
      }),
      catchError((e) => {
        return of(e);
      })
    );
  }

  async checkForUpdate() {
    firstValueFrom(this.checkForUpdate$())
      .then((e) => console.log(e))
      .catch(console.log);
  }

  updateApplicationAndReload(): Observable<boolean> {
    const dialogRef = this.modalService.createModalWithProviders<
      NewUpdateModalComponent,
      boolean
    >(NewUpdateModalComponent, {
      centered: true,
      offset: {
        x: 0,
        y: 0
      },
      maxWidth: 'min(90%, 350px)'
    });

    return dialogRef.events$.pipe(
      map((response) => {
        console.log(response);
        return response.state || false;
      })
    );
  }
}
