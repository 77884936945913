/**
 * This file is generated automatically.
 * All changes will be overridden by 'libs/design/fonts-generator.js' script
 */
export enum DesignSymbol {
  Add = 'add',
  AddAudio = 'add_audio',
  AddCircle = 'add_circle',
  AddDocs = 'add_docs',
  AddPicture = 'add_picture',
  AddVideo = 'add_video',
  AreaMeasurements = 'area_measurements',
  ArrowBack = 'arrow_back',
  ArrowBig = 'arrow_big',
  ArrowDropDown = 'arrow_drop_down',
  ArrowForward = 'arrow_forward',
  ArrowRight = 'arrow_right',
  ArrowSmall = 'arrow_small',
  Assets = 'assets',
  AttachedNote = 'attached_note',
  Audio = 'audio',
  AudioFile = 'audio_file',
  Back = 'back',
  Bell = 'bell',
  BellFilled = 'bell_filled',
  Brush = 'brush',
  BrushFirst = 'brush_first',
  Calendar = 'calendar',
  Camera = 'camera',
  CameraOn = 'camera_on',
  Card = 'card',
  Check = 'check',
  Circle = 'circle',
  CircleFill = 'circle_fill',
  Cloud = 'cloud',
  CloudFilled = 'cloud_filled',
  CompareView = 'compare_view',
  Cross = 'cross',
  CrossRounded = 'cross_rounded',
  Cursor = 'cursor',
  CursorFill = 'cursor_fill',
  Dashboard = 'dashboard',
  DateRange = 'date_range',
  Delete = 'delete',
  Details = 'details',
  Docks = 'docks',
  Document = 'document',
  DocumentFile = 'document_file',
  Dolar = 'dolar',
  Download = 'download',
  EditFirst = 'edit_first',
  EditSecond = 'edit_second',
  Error = 'error',
  ErrorFill = 'error_fill',
  Euro = 'euro',
  Events = 'events',
  ExecutiveFill = 'executive_fill',
  ExpandMore = 'expand_more',
  Export = 'export',
  File = 'file',
  FileDownload = 'file_download',
  FileInfo = 'file_info',
  Filter = 'filter',
  FilterFilled = 'filter_filled',
  Folder = 'folder',
  FolderCopy = 'folder_copy',
  FolderCopy2 = 'folder_copy_2',
  FolderFill = 'folder_fill',
  Forward = 'forward',
  FullScreen = 'full_screen',
  FullScreenSecond = 'full_screen_second',
  GridList = 'grid_list',
  Hamburger = 'hamburger',
  Home = 'home',
  IcBaselineFolder = 'ic_baseline_folder',
  ImageFile = 'image_file',
  ImageSearch = 'image_search',
  Info = 'info',
  InfoFilled = 'info_filled',
  InfoFirst = 'info_first',
  Invisible = 'invisible',
  Jpg = 'jpg',
  Letter = 'letter',
  LetterFill = 'letter_fill',
  Link = 'link',
  LinkBold = 'link_bold',
  LinkFirst = 'link_first',
  List = 'list',
  Location = 'location',
  Location1 = 'location_1',
  Location2 = 'location_2',
  Lock = 'lock',
  Map = 'map',
  MapLocation = 'map_location',
  MapLocationFull = 'map_location_full',
  MeasureTool = 'measure_tool',
  Minus = 'minus',
  Model3d = 'model_3d',
  MoreFirst = 'more_first',
  MoreHoriz = 'more_horiz',
  MoreSecond = 'more_second',
  Move = 'move',
  Note = 'note',
  Notes = 'notes',
  Organization = 'organization',
  OrganizationFilled = 'organization_filled',
  OrganizationRoles = 'organization_roles',
  OrganizationRolesFilled = 'organization_roles_filled',
  Overview = 'overview',
  OverviewFilled = 'overview_filled',
  PaypalSvgrepoCom = 'paypal_svgrepo_com',
  Picture = 'picture',
  Play = 'play',
  PlaySecond = 'play_second',
  Plus = 'plus',
  Preferences = 'preferences',
  Procore = 'procore',
  Profile = 'profile',
  ProjectList = 'project_list',
  ProjectListFilled = 'project_list_filled',
  Prtscr = 'prtscr',
  RemoveCircle = 'remove_circle',
  Rename = 'rename',
  Replace = 'replace',
  Rotate = 'rotate',
  Rotation = 'rotation',
  Save = 'save',
  Screens = 'screens',
  Search = 'search',
  SearchBold = 'search_bold',
  Secured = 'secured',
  Send = 'send',
  Settings = 'settings',
  SettingsFilled = 'settings_filled',
  Sort = 'sort',
  SortSecond = 'sort_second',
  Square = 'square',
  SquareFill = 'square_fill',
  Stages = 'stages',
  Star = 'star',
  StarFilled = 'star_filled',
  Statistics = 'statistics',
  Stop = 'stop',
  Subscription = 'subscription',
  SubscriptionFilled = 'subscription_filled',
  Success = 'success',
  SuccessFill = 'success_fill',
  Sync = 'sync',
  TableList = 'table_list',
  Tags = 'tags',
  TagsFilled = 'tags_filled',
  Teams = 'teams',
  TeamsFilled = 'teams_filled',
  TeamTemplates = 'team_templates',
  TeamTemplatesFilled = 'team_templates_filled',
  TeamTemplatesSecond = 'team_templates_second',
  Time = 'time',
  TimeTrigger = 'time_trigger',
  Trash = 'trash',
  Unlock = 'unlock',
  Upload = 'upload',
  UploadFile = 'upload_file',
  Users = 'users',
  UserFilled = 'user_filled',
  Video = 'video',
  ViewFirst = 'view_first',
  ViewSecond = 'view_second',
  ViewThird = 'view_third',
  Visible = 'visible',
  Volume = 'volume',
  VolumeX = 'volume_x',
  Warning = 'warning',
  WarningFilled = 'warning_filled',
  World = 'world',
  ZoomIn = 'zoom_in',
  ZoomInFill = 'zoom_in_fill',
  ZoomOut = 'zoom_out',
  ZoomOutFill = 'zoom_out_fill',
}

export type DesignSymbolAsUnion = keyof typeof DesignSymbol;

export type DesignSymbolInput = DesignSymbol | DesignSymbolAsUnion | undefined;
