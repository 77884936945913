import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ModelSweep } from '@simlab/matterport/api';
import { Observable, map } from 'rxjs';
import { ComponentsCommand } from '../../models/command/components-command';
import { ComponentsQuery } from '../../models/query/components-query';
import {
  CreateComponent,
  StageComponent,
  TComponentType
} from '../../models/stage-component';
import { IVector3 } from '../../models/vector3';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class ComponentsService implements ComponentsCommand, ComponentsQuery {
  private readonly url = inject<string>(API_URL);
  private readonly httpClient = inject(HttpClient);
  //COMPONENT
  removeComponentFromStage(payload: {
    stageId: string;
    componentId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/remove-component-from-stage`,
      payload
    );
  }

  editComponentName(payload: {
    newName: string;
    componentId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/edit-component-name`,
      payload
    );
  }

  addComponentToStage(payload: CreateComponent): Observable<string> {
    const headers = new HttpHeaders({
      'api-version': '2.0'
    });

    return this.httpClient.post<string>(
      `${this.url}/construction-site-management-module/add-component-to-stage`,
      payload,
      { headers }
    );
  }

  synchronizeWithOffset(payload: {
    offset: string;
    componentId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/synchronize-with-offset`,
      payload
    );
  }

  synchronizeComponent(payload: {
    referenceComponent: string;
    referenceStage: string;
    referencePoint1: IVector3;
    referencePoint2: IVector3;
    referencePoint3: IVector3;
    targetComponent: string;
    targetStage: string;
    targetPoint1: IVector3;
    targetPoint2: IVector3;
    targetPoint3: IVector3;
  }): Observable<StageComponent> {
    return this.httpClient.post<StageComponent>(
      `${this.url}/construction-site-management-module/synchronize-component`,
      payload
    );
  }

  markAsSynchronized(payload: { componentId: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/mark-as-synchronized`,
      payload
    );
  }

  markAsDesynchronized(payload: { componentId: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/mark-as-unsynchronized`,
      payload
    );
  }

  //QUERY
  getComponentsForStage(payload: {
    stageId: string;
  }): Observable<StageComponent[]> {
    const headers = new HttpHeaders({
      'api-version': '2.0'
    });

    return this.httpClient
      .get(
        `${this.url}/construction-site-management-module/get-components-for-stage?StageId=${payload.stageId}`,
        { headers }
      )
      .pipe(map((response: any) => response.matterports));
  }
  getComponentsForProject(payload: {
    projectId: string;
    types: TComponentType[];
  }): Observable<StageComponent[]> {
    const typesStringify = payload.types.reduce(
      (prev, curr) => `${prev}&ModelTypes=${curr}`,
      ''
    );
    return this.httpClient.get<StageComponent[]>(
      `${this.url}/construction-site-management-module/get-components-for-project?ProjectId=${payload.projectId}${typesStringify}`
    );
  }
  getComponentForStage(payload: {
    stageId: string;
    componentId: string;
  }): Observable<StageComponent> {
    const headers = new HttpHeaders({
      'api-version': '2.0'
    });
    return this.httpClient.get<StageComponent>(
      `${this.url}/construction-site-management-module/get-component-for-stage?StageId=${payload.stageId}&ComponentId=${payload.componentId}`,
      { headers }
    );
  }

  getSynchronizedComponentsForStage<T = StageComponent>(payload: {
    stageId: string;
  }): Observable<T[]> {
    const headers = new HttpHeaders({
      'api-version': '2.0'
    });

    return this.httpClient
      .get<T>(
        `${this.url}/construction-site-management-module/get-synchronized-components-for-stage?StageId=${payload.stageId}`,
        { headers }
      )
      .pipe(map((response: any) => response.matterports));
  }

  updateComponentMatterportSweeps$(payload: {
    componentId: string;
    token?: string;
  }): Observable<ModelSweep[]> {
    return this.httpClient.put<ModelSweep[]>(
      `${this.url}/construction-site-management-module/update-component-matterport-sweeps`,
      payload
    );
  }
}
