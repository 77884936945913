import { InjectionToken } from "@angular/core";
import { Coordinates } from "./coordinates";

export interface GoogleMapBaseWindow extends Window {
    setPlace: (place: Place) => void;
    onSetPlace: (place: FunctionResponse) => void;
    onGeolocation: (payload: FunctionResponse) => void;
    onEmptyAddress: (payload: FunctionResponse) => void;
  }

  export const GOOGLE_BASE_WINDOW = new InjectionToken<GoogleMapBaseWindow>(
    'GOOGLE_BASE_WINDOW',
    {
      factory: () => {
        return window as unknown as GoogleMapBaseWindow;
      },
    }
  );

  export interface FunctionResponse<T = any> {
    status: Status;
    data?: T | Place;
    error?: any;
  }

  export declare type Status = 'Success' | 'Failure';

  export interface Place {
    address: string;
    coordinates: Coordinates;
  }
