import { Directive, ElementRef, Input, inject } from '@angular/core';
import { ThemeColors } from '../models/theme-colors';

@Directive({
  selector: '[designColor]',
  standalone: true,
})
export class ColorDirective {
      private readonly el = inject(ElementRef);

  @Input() set designColor(value: ThemeColors) {
    this.el.nativeElement.classList.forEach((el: any) => {
      if (el.includes('design-color--')) {
        this.el.nativeElement.classList.remove(el);
      }
    });
    this.el.nativeElement.classList.add(`design-color--${value}`);
  }
}
