import { createAction, props } from '@ngrx/store';
import { IErrorResponse, UserPreferences } from '@simlab/data-access';

export const init = createAction('[UserPreference/API] Init UserPreferences');
export const initSuccess = createAction(
  '[UserPreference/API] Init UserPreferences Success',
  props<{ userPreferences: UserPreferences }>()
);
export const initFailure = createAction(
  '[UserPreference/API] Init UserPreferences Failure'
);
export const updateUserPreferences = createAction(
  '[UserPreference/API] Update User Preferences',
  props<{ userPreferences: UserPreferences }>()
);
export const updateUserPreferencesSuccess = createAction(
  '[UserPreference/API] Update User Preferences Success',
  props<{ userPreferences: UserPreferences }>()
);
export const updateUserPreferencesFailure = createAction(
  '[UserPreference/API] Update User Preferences Failure',
  props<{ error: IErrorResponse }>()
);
export const userLoggedToProcore = createAction(
  '[UserPreference/API] User logged to Procore'
);
export const userLoggedToProcoreSuccess = createAction(
  '[UserPreference/API] User logged to Procore Success',
  props<{ userLoggedToProcore: boolean }>()
);
export const userLoggedToProcoreFailure = createAction(
  '[UserPreference/API] User logged to Procore Failure',
  props<{ error: IErrorResponse }>()
);
