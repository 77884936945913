import { Quaternion, Vector3 } from 'three';

export class Transform {
  static get default(): Transform {
    return <Transform>{
      position: new Vector3(0, 0, 0),
      rotation: new Quaternion(0, 0, 0, 1),
      scale: new Vector3(1, 1, 1),
    };
  }

  readonly position: Vector3;
  readonly rotation: Quaternion;
  readonly scale: Vector3;

  constructor(deserialized: any) {
    if (
      typeof deserialized !== 'object' ||
      !('position' in deserialized) ||
      !('rotation' in deserialized) ||
      !('scale' in deserialized)
    ) {
      throw new Error('invalid parameters!');
    }

    this.position = Object.assign(new Vector3(), deserialized.position);
    this.rotation = Object.assign(new Quaternion(), deserialized.rotation);
    this.scale = Object.assign(new Vector3(), deserialized.scale);
  }
}
