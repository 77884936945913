import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { UiIconModule } from '../../../ui-icon/ui-icon.module';
import { ConfirmationModalRef } from '../../models/confirmation-modal-ref';
import { MODAL_DATA } from '../../tokens/modal-data.token';

@Component({
  selector: 'ui-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DesignCommonModule,
    DesignDialogWrapperModule,
    DesignFlatButton,
    DesignStrokedButton,
    UiIconModule,
  ],
})
export class WarningModalComponent {
  constructor(
    @Inject(MODAL_DATA)
    readonly data: {
      mainText: string;
      content: string;
      buttonText?: string;
      cancelButton?: boolean;
    },
    private readonly modalRef: ConfirmationModalRef<unknown>
  ) {}

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }
}
