/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  NgModule,
  ViewEncapsulation,
} from '@angular/core';
import { ColorDirective } from '@simlab/design/helper';
import { DirectionDirective } from '../directives/direction.directive';
import { FireOnClickDirective } from '../directives/fire-on-click.directive';
import { ButtonBase } from './button-base.directive';
import { ButtonLoaderComponent } from './button-loader.component';
import { DesignButton } from './button.component';

@Component({
  selector: 'button[design-stroked-button]',
  exportAs: 'designStrokedButton',
  standalone: true,
  imports: [CommonModule],
  template: ` <ng-content select="[designIconPrefix]"></ng-content>
    <ng-content></ng-content>
    <ng-content select="[designIconSuffix]"></ng-content>`,
  styleUrls: ['./stroked-button.component.scss'],
  providers: [{ provide: ButtonBase, useExisting: DesignStrokedButton }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-stroked-button',
    '[class.design-color--primary]':
      'color === "primary" || color === undefined',
    '[class.design-color--secondary]': 'color === "secondary"',
    '[class.design-color--accent]': 'color === "accent"',
    '[class.design-color--error]': 'color === "error"',
  },
  inputs: [
    'disabled',
    'disabled: designButtonDisabled',
    'selected: designButtonSelected',
    'loaded: designButtonLoaded',
    'color: designButtonColor',
  ],
})
export class DesignStrokedButton extends ButtonBase {
  @HostBinding('class.design-stroked-button--suffix')
  get hasIconSuffix(): boolean {
    return this._hasIconSuffix;
  }

  @HostBinding('class.design-stroked-button--prefix')
  get hasIconPrefix(): boolean {
    return this._hasIconPrefix;
  }
}

@NgModule({
  exports: [
    DesignStrokedButton,
    FireOnClickDirective,
    ButtonLoaderComponent,
    ColorDirective,
  ],
  imports: [
    DesignStrokedButton,
    DesignButton,
    ColorDirective,
    FireOnClickDirective,
    DirectionDirective,
    ButtonLoaderComponent,
  ],
})
export class DesignStrokedButtonModule {}
