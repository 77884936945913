import { Vector2, Vector3 } from 'three';
import {
  IndexedVertice,
  MeshTriangle,
} from '../types/measurement-tool.interface';
import { Units } from '../types/units.type';

export function handlePointPositionModification(
  newPoint: Vector3,
  currentPoints: Vector3[]
): Vector3 {
  if (currentPoints.length >= 3) {
    let planeNormal = getFirstThreePointsPlaneNormal(currentPoints);
    if (
      planeNormal.angleTo(newPoint) >
      planeNormal.clone().negate().angleTo(newPoint)
    ) {
      planeNormal = planeNormal.clone().negate();
    }
    const distance = planeNormal
      .clone()
      .dot(currentPoints[0].clone().sub(newPoint));
    newPoint.add(
      planeNormal.multiply(new Vector3(distance, distance, distance))
    );
  }

  // if (_firstPoint == null)
  // {
  //     _firstPoint = position;
  // }
  return newPoint;
}

export function getFirstThreePointsPlaneNormal(
  currentPoints: Vector3[]
): Vector3 {
  if (currentPoints.length < 3) {
    return new Vector3();
  } else {
    const firstPlaneVector = currentPoints[1].clone().sub(currentPoints[0]);
    const secondPlaneVector = currentPoints[2].clone().sub(currentPoints[0]);
    return firstPlaneVector.cross(secondPlaneVector).normalize();
  }
}

export function calcPlaneNormalRotation(currentPoints: Vector3[]): Vector3 {
  const planeNormal = getFirstThreePointsPlaneNormal(currentPoints);
  const { x: xn, y: yn, z: zn } = planeNormal;
  if (xn === 0 && yn == 0 && zn == 1) {
    return new Vector3();
  }

  return planeNormal.cross(new Vector3(0, 0, 1));
}

export function convert3DPointsTo2DShape(currentPoints: Vector3[]) {
  const currentPointsCpy = [...currentPoints];
  const rotation = calcPlaneNormalRotation(currentPointsCpy);
  const result: Vector2[] = [];
  for (let i = 0; i < currentPointsCpy.length; i++) {
    const rotatedPoint = currentPointsCpy[i].multiply(rotation);
    result.push(new Vector2(rotatedPoint.x, rotatedPoint.y));
  }
  return result;
}
export function calculateSurfaceArea(
  triangles: MeshTriangle[],
  vertices: IndexedVertice[]
) {
  let sum = 0.0;

  for (let i = 0; i < triangles.length; i++) {
    const triangle = triangles[i];
    const corner = new Vector3(
      vertices[triangle.firstVertice].x,
      vertices[triangle.firstVertice].y,
      vertices[triangle.firstVertice].z
    );
    const a = new Vector3(
      vertices[triangle.secondVertice].x,
      vertices[triangle.secondVertice].y,
      vertices[triangle.secondVertice].z
    ).sub(corner);
    const b = new Vector3(
      vertices[triangle.thirdVertice].x,
      vertices[triangle.thirdVertice].y,
      vertices[triangle.thirdVertice].z
    ).sub(corner);

    sum += a.cross(b).length();
  }

  return Number(sum / 2.0);
}

const AREA_CONVERSION: Record<
  Units,
  {
    fn: (area: number) => number;
    unit: string;
  }
> = {
  metric: {
    fn: function (area: number): number {
      if (!area) return 0;
      return area;
    },
    unit: 'm²',
  },
  imperial: {
    fn: function (area: number): number {
      if (!area) return 0;
      return area * 10.7639104;
    },
    unit: 'ft²',
  },
};

export function areaPlanText(
  areaInSquareMeters: number,
  dstUnit: Units
): string {
  const conversion = AREA_CONVERSION[dstUnit];
  const convertedArea = conversion.fn(areaInSquareMeters);
  const convertedUnit = conversion.unit;
  return `${convertedArea.toFixed(4)}${convertedUnit}`;
}

const UNIT_CONVERSION: Record<
  Units,
  {
    fn: (distance: number) => number;
    unit: string;
  }
> = {
  metric: {
    fn: function (distance: number): number {
      if (!distance) return 0;
      return distance;
    },
    unit: 'm',
  },
  imperial: {
    fn: function (distance: number): number {
      if (!distance) return 0;
      return distance * 3.2808399;
    },
    unit: 'ft',
  },
};

export function distanceText(distance: number, dstUnit: Units): string {
  const conversion = UNIT_CONVERSION[dstUnit];
  const convertedDistance = conversion.fn(distance);
  const convertedUnit = conversion.unit;
  return `${convertedDistance.toFixed(4)}${convertedUnit}`;
}
