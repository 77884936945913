import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Organizations } from '@simlab/data-access';
import * as OrganizationsActions from './organizations.actions';
import { OrganizationApiActions } from './organizations.actions';

export const ORGANIZATIONS_FEATURE_KEY = 'organizations';

export interface State extends EntityState<Organizations> {
  selectedId?: string; // which Organizations record has been selected
  loaded: boolean; // has the Organizations list been loaded
  uploadingImage: boolean;
  error?: string | null; // last known error (if any)
}

export interface OrganizationsPartialState {
  readonly [ORGANIZATIONS_FEATURE_KEY]: State;
}

export const organizationsAdapter: EntityAdapter<Organizations> =
  createEntityAdapter<Organizations>();

export const initialState: State = organizationsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  uploadingImage: false
});

export const organizationsReducer = createReducer(
  initialState,
  on(OrganizationsActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(OrganizationApiActions.editOrganizationThumbnail, (state) => ({
    ...state,
    loaded: false,
    uploadingImage: true,
    error: null
  })),
  on(OrganizationApiActions.editOrganizationThumbnailSuccess, (state) => ({
    ...state,
    loaded: false,
    uploadingImage: false,
    error: null
  })),
  on(OrganizationApiActions.editOrganizationThumbnailFailure, (state) => ({
    ...state,
    loaded: false,
    uploadingImage: false,
    error: null
  })),
  on(OrganizationApiActions.createOrganization, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(
    OrganizationApiActions.createOrganizationSuccess,
    (state, { id, name, invitationPending }) =>
      organizationsAdapter.addOne(
        { id, name, invitationPending },
        { ...state, loaded: true }
      )
  ),
  on(OrganizationApiActions.createOrganizationFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(OrganizationApiActions.acceptInvitation, (state, { invitationId }) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(
    OrganizationApiActions.acceptInvitationSuccess,
    (state, { organizationId }) => {
      const organization: Update<Organizations> = {
        id: organizationId,
        changes: {
          invitationPending: false
        }
      };
      return organizationsAdapter.updateOne(organization, state);
    }
  ),

  on(
    OrganizationApiActions.acceptInvitationFailure,
    (state, { error, organizationId }) => ({
      ...organizationsAdapter.removeOne(organizationId, state),
      error
    })
  ),
  on(OrganizationApiActions.rejectInvitation, (state, { id }) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(
    OrganizationApiActions.rejectInvitationFailure,
    (state, { error, organizationId }) => ({
      ...organizationsAdapter.removeOne(organizationId, state),
      error
    })
  ),
  on(OrganizationApiActions.rejectInvitationSuccess, (state, { id }) => {
    return organizationsAdapter.removeOne(id, state);
  }),

  on(
    OrganizationApiActions.loadOrganizationsSuccess,
    (state, { organizations }) =>
      organizationsAdapter.setAll(organizations, {
        ...state,
        loaded: true
        //NOTE: set first organization on init!
        // selectedId: organizations.length > 0 ? organizations[0].id : undefined,
      })
  ),
  on(OrganizationApiActions.loadOrganizationsFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(OrganizationsActions.selectOrganization, (state, { id }) => ({
    ...state,
    selectedId: id,
    uploadingImage: false
  })),

  on(OrganizationApiActions.editOrganization, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(OrganizationApiActions.editOrganizationSuccess, (state, action) =>
    organizationsAdapter.updateOne(action.update, { ...state, loaded: true })
  ),
  on(OrganizationApiActions.editOrganizationFailure, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),

  on(OrganizationApiActions.editOrganizationDescription, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(OrganizationApiActions.editOrganizationDescriptionFailure, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),

  on(OrganizationApiActions.deleteOrganization, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(OrganizationApiActions.deleteOrganizationSuccess, (state, action) =>
    organizationsAdapter.removeOne(action.organizationId, {
      ...state,
      loaded: true
    })
  ),
  on(OrganizationApiActions.deleteOrganizationFailure, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),

  on(OrganizationApiActions.leaveOrganization, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(OrganizationApiActions.leaveOrganizationSuccess, (state, action) =>
    organizationsAdapter.removeOne(action.organizationId, {
      ...state,
      selectedId: undefined,
      loaded: true
    })
  ),
  on(OrganizationApiActions.leaveOrganizationFailure, (state) => ({
    ...state,
    loaded: false,
    error: null
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return organizationsReducer(state, action);
}
