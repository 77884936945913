import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { ApiArea } from '../../../data-access/area-api.service';
import { AreaMeasurementEffects } from './area.effects';
import {
  AREA_MEASUREMENT_FEATURE_KEY,
  areaMeasurementReducer
} from './area.reducer';
import { AreaFacade } from './area.facade';

export function provideAreaMeasurment(): EnvironmentProviders[] {
  return [
    provideState(AREA_MEASUREMENT_FEATURE_KEY, areaMeasurementReducer),
    provideEffects(AreaMeasurementEffects),
    makeEnvironmentProviders([ApiArea, AreaFacade])
  ];
}
