import { ActivatedRouteSnapshot, Params } from '@angular/router';
import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const getRouterState = createFeatureSelector<RouterReducerState>('router');

//  const {
//   selectCurrentRoute, // select the current route
//   selectFragment, // select the current route fragment
//   selectQueryParams, // select the current route query params
//   selectQueryParam, // factory function to select a query param
//   selectRouteParams, // select the current route params
//   selectRouteParam, // factory function to select a route param
//   selectRouteData, // select the current route data
//   selectUrl, // select the current url
// } = getSelectors();

const getRouteNestedParams = createSelector(getRouterState, (router) => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  let queryParams: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
    queryParams = {
      ...queryParams,
      ...currentRoute.queryParams,
    };
  }
  return { params, queryParams };
});

const getRoute = createSelector(getRouterState, (router) => router?.state?.url);

const distinctUntilChangedComparator = (
  prev: { params: Params; queryParams: Params },
  next: { params: Params; queryParams: Params }
) => JSON.stringify(prev) === JSON.stringify(next);
export const getNthNestedParam = (n: number) =>
  createSelector(getRouterState, (router) => {
    let state: ActivatedRouteSnapshot | null = router.state.root.firstChild;
    for (let i = 0; i < n; i++) {
      if (!state) break;
      state = state.firstChild;
    }
    return state;
  });
export const routerSelectors = {
  getRouteNestedParams,
  getRoute,
  getNthNestedParam,
};

export const routerHelpers = {
  distinctUntilChangedComparator,
};

export interface RouterStoreParams {
  params: Params;
  queryParams: Params;
}
