<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@CROP_THUMBNAIL">Crop thumbnail</h2>
  </ng-container>

  <ng-container designDialogContent>
    <label>{{ modalData.labelTitleThumbnail }}</label>
    <div class="crop__cropper">
      <image-cropper
        class="image-cropper"
        data-cy="image-cropper"
        [ngClass]="{ 'crop__cropper--loading': !imageLoaded }"
        (imageLoaded)="imageLoaded = true"
        [aspectRatio]="16 / 9"
        [maintainAspectRatio]="true"
        (imageCropped)="imageCropped($event)"
        [imageFile]="modalData.imageData"
        (loadImageFailed)="loadedImageFailure()"
        [resizeToWidth]="1920"
      >
      </image-cropper>

      <ng-container *ngIf="!imageLoaded">
        <ui-progress-spinner
          data-cy="crop-spinner"
          class="crop__spinner"
          uiColor="background"
        ></ui-progress-spinner>
      </ng-container>
    </div>
    <label
      *ngIf="imageSizeExceeded === true"
      class="crop__label--error"
      i18n="@@TO_LARGE_PICTURE"
    >
      Picture is too large. (Max {{ MAX_IMAGE_SIZE }} MB)
    </label>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="handleCancel()"
      data-cy="crop-image-dialog-cancel"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="accent"
      [disabled]="!imageLoaded || imageSizeExceeded"
      (click)="handleConfirm()"
      data-cy="crop-image-dialog-confirm"
    >
      <div i18n="@@CONFIRM">Confirm</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
