import { inject, Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import {
  Coordinates,
  ImageData,
  ProcoreConnectionStatusEnum,
  ProjectPaging,
  ProjectsFilterBase,
  ProjectsSort
} from '@simlab/data-access';
import * as ProjectsActions from './projects.actions';
import * as ProjectsSelectors from './projects.selectors';

export interface ProjectForm {
  organizationId: string;
  name: string;
  description: string;
  address: string;
  thumbnail?: string;
  imageData?: ImageData;
  geolocation?: Coordinates;
}

@Injectable()
export class ProjectsFacade {
  private readonly store = inject(Store);
  private readonly action$ = inject(ActionsSubject);
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  createdProject$ = this.action$.pipe(
    ofType(ProjectsActions.createProjectSuccess)
  );

  updateProjectSuccess$ = this.action$.pipe(
    ofType(ProjectsActions.updateProjectSuccess)
  );

  metadata$ = this.store.pipe(select(ProjectsSelectors.getProjectsMetadata));
  loaded$ = this.store.pipe(select(ProjectsSelectors.getProjectsLoaded));
  allProjects$ = this.store.pipe(select(ProjectsSelectors.getAllProjects));
  projectsLoaded$ = this.action$.pipe(
    ofType(ProjectsActions.loadProjectsSuccess)
  );
  selectedProjects$ = this.store.pipe(select(ProjectsSelectors.getSelected));
  projectConnectedWithProcore$ = (projectId: string) =>
    this.store.pipe(
      select(ProjectsSelectors.isSelectedProjectConnectedToProcore(projectId))
    );
  readonly procoreConnectionStatus$ = (projectId: string) =>
    this.store.pipe(
      select(
        ProjectsSelectors.selectedProjectConnectionProcoreStatus(projectId)
      )
    );

  selectProjectById$ = (projectId: string) =>
    this.store.pipe(select(ProjectsSelectors.getProjectById(projectId)));

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  initStore(payload: {
    filters?: ProjectsFilterBase;
    sort?: ProjectsSort;
    paging?: ProjectPaging;
  }) {
    //TODO: [BUG] this is called two times for some reason?? -> CODE: 79525
    this.store.dispatch(ProjectsActions.initProjects({ ...payload }));
  }

  setProjectFavoriteState(projectId: string, flag: boolean): void {
    this.store.dispatch(ProjectsActions.toggleFavorite({ projectId, flag }));
  }

  assignProcoreProject(projectId: string): void {
    this.store.dispatch(ProjectsActions.assignProjectProcore({ projectId }));
  }

  unassignProcoreProject(projectId: string): void {
    this.store.dispatch(ProjectsActions.unassignProjectProcore({ projectId }));
  }

  initProcoreProjectStatus(
    projectId: string,
    status: ProcoreConnectionStatusEnum
  ): void {
    this.store.dispatch(
      ProjectsActions.initProcoreProjectStatus({ projectId, status })
    );
  }

  disconnectingProcoreProject(projectId: string): void {
    this.store.dispatch(
      ProjectsActions.disconnectingProcoreProject({ projectId })
    );
  }

  reconnectingProcoreProject(projectId: string): void {
    this.store.dispatch(
      ProjectsActions.reconnectingProcoreProject({ projectId })
    );
  }

  getProjectById(projectId: string) {
    this.store.dispatch(ProjectsActions.getProject({ projectId }));
  }

  createProject(project: ProjectForm) {
    this.store.dispatch(ProjectsActions.createProject({ project }));
  }

  removeProject(projectId: string) {
    this.store.dispatch(ProjectsActions.removeProject({ projectId }));
  }

  removeProjectSuccess(projectId: string) {
    this.store.dispatch(ProjectsActions.removeProjectSuccess({ projectId }));
  }

  updateProject(projectId: string, data: ProjectForm) {
    this.store.dispatch(ProjectsActions.updateProject({ projectId, data }));
  }
}
