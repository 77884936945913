import { Observable } from 'rxjs';
import {
  TAddMeasurementGroup,
  TAddMeasurementGroups,
  TAddMeasurementsToGroup,
  TAssignToRootNote,
  TBaseMeasurement,
  TRemoveFromGroup,
  TUpdateColor,
  TUpdateGroupName,
  TUpdateTitle
} from '../common-models';

export interface MeasurementCommand {
  addMeasurementGroup: (body: TAddMeasurementGroup) => Observable<string>;
  addMeasurementToGroups: (body: TAddMeasurementGroups) => Observable<void>;
  addMeasurementsToGroup: (body: TAddMeasurementsToGroup) => Observable<string>;
  assignMeasurementToRootNote: (body: TAssignToRootNote) => Observable<void>;
  unAssignMeasurementToRootNote: (body: TBaseMeasurement) => Observable<void>;
  updateMeasurementTitle: (body: TUpdateTitle) => Observable<void>;
  updateMeasurementColor: (body: TUpdateColor) => Observable<void>;
  updateMeasurementGroupName: (body: TUpdateGroupName) => Observable<void>;
  removeMeasurement: (body: TBaseMeasurement) => Observable<void>;
  removeMeasurementGroup: (body: TBaseMeasurement) => Observable<void>;
  removeMeasurementFromGroup: (query: TRemoveFromGroup) => Observable<void>;
}
