import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DISTANCE_MEASUREMENT_FEATURE_KEY,
  DistanceState,
  distanceAdapter
} from './distance.reducer';

// Lookup the 'AreaMeasurement' feature state managed by NgRx

const selectDistanceState = createFeatureSelector<DistanceState>(
  DISTANCE_MEASUREMENT_FEATURE_KEY
);

const { selectAll, selectEntities } = distanceAdapter.getSelectors();

const selectAreaMeasurementLoaded = createSelector(
  selectDistanceState,
  (state: DistanceState) => state.loaded
);

const selectAreaMeasurementError = createSelector(
  selectDistanceState,
  (state: DistanceState) => state.error
);

const selectAllAreaMeasurement = createSelector(
  selectDistanceState,
  (state: DistanceState) => selectAll(state)
);

const selectAreaMeasurementEntities = createSelector(
  selectDistanceState,
  (state: DistanceState) => selectEntities(state)
);

const selectSelectedId = createSelector(
  selectDistanceState,
  (state: DistanceState) => state.selectedId
);
const selectAllAreaHidden = createSelector(
  selectDistanceState,
  (state: DistanceState) => state.hideAll
);
const showMeasurementsSegments = createSelector(
  selectDistanceState,
  (state: DistanceState) => state.segmentsVisible
);
const checkExist = (id: string) =>
  createSelector(selectAreaMeasurementEntities, (state) => !!state[id]);

const selectEntity = createSelector(
  selectAreaMeasurementEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
export const DistanceMeasurementSelectors = {
  selectEntity,
  selectSelectedId,
  selectAllAreaMeasurement,
  selectAreaMeasurementError,
  selectAreaMeasurementLoaded,
  checkExist,
  selectAllAreaHidden,
  showMeasurementsSegments
};
