<div class="text" i18n="@@PAGE_X_OF_Y">
  Page
  <b>{{ metadata.currentPage === 0 ? 1 : metadata.currentPage }}</b>
  of
  <b>{{ metadata.totalPages === 0 ? 1 : metadata.totalPages }}</b>
</div>
<span style="flex: 1"></span>
<button
  design-flat-button
  [disabled]="!metadata.hasPrevious || loading"
  (click)="
    pagingUpdated.emit({
      pageSize: metadata.pageSize,
      pageNumber: metadata.currentPage - 1
    })
  "
>
  <div i18n="@@PREVIOUS">Previous</div>
</button>
<button
  design-flat-button
  [disabled]="!metadata.hasNext || loading"
  (click)="
    pagingUpdated.emit({
      pageSize: metadata.pageSize,
      pageNumber: metadata.currentPage + 1
    })
  "
>
  <div i18n="@@NEXT">Next</div>
</button>
