import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import { UserElementFilterExtended } from '@simlab/annotations-filters/models';

export type Filters = {
  authors: string[];
  stakeholders: string[];
  dateCreation: {
    from: string | Date | undefined;
    to: string | Date | undefined;
  };
  dateModification: {
    from: string | Date | undefined;
    to: string | Date | undefined;
  };
};

export type GlobalFiltersState = {
  filter: {
    authors: UserElementFilterExtended[];
    stakeholders: UserElementFilterExtended[];
    dateCreation: Partial<{
      from: string | Date | undefined;
      to: string | Date | undefined;
    }>;
    dateModification: Partial<{
      from: string | Date | undefined;
      to: string | Date | undefined;
    }>;
  };
};

const initialState: GlobalFiltersState = {
  filter: {
    authors: [],
    stakeholders: [],
    dateCreation: {
      from: undefined,
      to: undefined
    },
    dateModification: {
      from: undefined,
      to: undefined
    }
  }
};

export const GlobalFiltersStore = signalStore(
  withState(initialState),
  withMethods((store) => ({
    resetFilters(): void {
      patchState(store, () => ({
        ...initialState
      }));
    },
    setFilters(newFilter: Partial<GlobalFiltersState['filter']>): void {
      patchState(store, ({ filter }) => ({
        filter: { ...filter, ...newFilter }
      }));
    }
  })),
  withComputed(({ filter }) => ({
    filtersLength: computed(
      () =>
        filter().authors.length +
        filter().stakeholders.length +
        Number(!!filter().dateCreation.from) +
        Number(!!filter().dateCreation.to) +
        Number(!!filter().dateModification.from) +
        Number(!!filter()?.dateModification.to)
    )
  }))
);
