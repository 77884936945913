import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

declare const ngDevMode: boolean;

type FormatDate = 'MM/dd/yyyy' | 'dd/MM/yyyy';
//NOTE: we have to propagate our error from FormGroup to FormControl to get error within DesignError component
export class DesignDateValidators {
  /**
   * Check if date match 'dd-mm-yyyy' pattern with leap years and proper month length
   */
  static DDMMYYYY_PATTERN = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/,
  );

  static MMDDYYYY_PATTERN = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(((0[13578]|1[02])\/(0[1-9]|[12]\d|3[01])\/((19|[2-9]\d)\d{2}))|((0[13456789]|1[012])\/(0[1-9]|[12]\d|30)\/((19|[2-9]\d)\d{2}))|(02\/(0[1-9]|1\d|2[0-8])\/((19|[2-9]\d)\d{2}))|(02\/29\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/,
  );
  static NO_WHITE_SPACE = new RegExp(/\s/g);
  static CONTAIN_WHITE_SPACE = new RegExp(/^\S+$/g);

  static transformStringDateToDate(value: string, formatDate: FormatDate) {
    return formatDate === 'dd/MM/yyyy'
      ? DesignDateValidators.convertToDateByEuropeanFormat(value)
      : DesignDateValidators.convertToDateByUSAFormat(value);
  }
  static lessThan(
    fromDateFieldName: string,
    toDateFieldName: string,
    validatorField: { [key: string]: boolean },
    formatDate: FormatDate = 'dd/MM/yyyy',
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const fromDateFormControl = control.get(fromDateFieldName);
      const toDateFormControl = control.get(toDateFieldName);

      if (
        (typeof ngDevMode === 'undefined' || ngDevMode) &&
        (!fromDateFormControl || !toDateFormControl)
      ) {
        throw new Error('One of the controls does not exist!');
      }

      if (
        fromDateFormControl?.value &&
        fromDateFormControl.touched &&
        toDateFormControl?.value &&
        toDateFormControl.touched
      ) {
        const fromDate = this.transformStringDateToDate(
          fromDateFormControl?.value,
          formatDate,
        ).toISOString();

        const toDate = this.transformStringDateToDate(
          toDateFormControl?.value,
          formatDate,
        ).toISOString();

        if (fromDate >= toDate) {
          toDateFormControl?.setErrors(validatorField);

          toDateFormControl?.markAsDirty();
          toDateFormControl?.markAsTouched();

          return validatorField;
        }
      }

      return null;
    };
  }

  /**
   * Validates start with whitespace
   * @param control
   * @returns
   */
  static whitespaceOnStartValidator(validatorField: {
    [key: string]: boolean;
  }): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value.startsWith(' ') ? validatorField : null;
    };
  }

  /**
   * Validates check min non whitespace characters
   * @param control
   * @returns
   */
  static minNonWhitespaceChars(
    minChars: number,
    validatorField: {
      [key: string]: boolean;
    },
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '' && !control.hasValidator(Validators.required))
        return null;

      const regex = new RegExp(`^(?=(?:\\S.*){${minChars}})[\\s\\S]*$`);
      const result = regex.test(control.value);
      return result ? null : validatorField;
    };
  }
  /**
   * Validates date based on pattern
   * @param control
   * @returns
   */
  static checkPattern(
    pattern: RegExp,
    validatorField: { [key: string]: boolean },
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '' && !control.hasValidator(Validators.required))
        return null;
      const result = pattern.test(control.value);
      return result ? null : validatorField;
    };
  }

  /**
   * Validates date based on pattern
   * @param control
   * @returns
   */
  static checkPatternByFormatDate(
    formatDate: FormatDate,
    validatorField: { [key: string]: boolean },
  ): ValidatorFn {
    const pattern =
      formatDate === 'dd/MM/yyyy'
        ? DesignDateValidators.DDMMYYYY_PATTERN
        : DesignDateValidators.MMDDYYYY_PATTERN;

    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '' && !control.hasValidator(Validators.required))
        return null;
      const result = pattern.test(control.value);
      return result ? null : validatorField;
    };
  }

  /**
   * Validates date based on pattern
   * @param control
   * @returns
   */
  static minData(
    date: Date,
    validatorField: { [key: string]: boolean },
    formatDate: FormatDate,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '') return null;
      let dateUser: Date;
      if (formatDate === 'dd/MM/yyyy') {
        dateUser = this.convertToDateByEuropeanFormat(control.value);
      } else {
        dateUser = new Date(control.value);
      }

      const result = date < dateUser;
      return result ? null : validatorField;
    };
  }

  static correctDate(
    formatDate: FormatDate,
    validatorField: { [key: string]: boolean },
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '') return null;
      let dateUser: Date;
      if (formatDate === 'dd/MM/yyyy') {
        dateUser = this.convertToDateByEuropeanFormat(control.value);
      } else {
        dateUser = new Date(control.value);
      }
      return isNaN(dateUser.getTime()) ? validatorField : null;
    };
  }

  /**
   * Convert date from DD/MM/YYYY to MM/DD/YYYY with add timezone
   */
  static convertToDateByEuropeanFormat(dateString: string): Date {
    //  Convert a "dd/MM/yyyy" string into a Date object
    const d = dateString.split('/');
    const dat = new Date(d[2] + '/' + d[1] + '/' + d[0]);
    return new Date(dat.getTime() - dat.getTimezoneOffset() * 60000);
  }

  /**
   * Convert date MM/DD/YYYY with add timezone
   */
  static convertToDateByUSAFormat(dateString: string): Date {
    //  Convert a "MM/dd/yyyy" string into a Date object
    const d = dateString.split('/');
    const dat = new Date(d[2] + '/' + d[0] + '/' + d[1]);
    return new Date(dat.getTime() - dat.getTimezoneOffset() * 60000);
  }
}
