import { InjectionToken } from '@angular/core';

export interface MatOptionParentComponent {
  disableRipple?: boolean;
  multiple?: boolean;
  inertGroups?: boolean;
}

export const MAT_OPTION_PARENT_COMPONENT =
  new InjectionToken<MatOptionParentComponent>('MAT_OPTION_PARENT_COMPONENT');

type ModifierKey = 'altKey' | 'shiftKey' | 'ctrlKey' | 'metaKey';

/**
 * Checks whether a modifier key is pressed.
 * @param event Event to be checked.
 */
export function hasModifierKey(
  event: KeyboardEvent,
  ...modifiers: ModifierKey[]
): boolean {
  if (modifiers.length) {
    return modifiers.some((modifier) => event[modifier]);
  }

  return event.altKey || event.shiftKey || event.ctrlKey || event.metaKey;
}
