export type EulaUrls = {
  eulaUrl: string;
  marketingEmailsUrl: string;
  processingPersonalDataUrl: string;
};

export type AcceptedEulaUrls = {
  eulaUrl: string;
  marketingEmailsPermission: boolean;
  processingPersonalDataPermission: boolean;
};
