@if (hasUserProcoreToken()) {
  <div class="top-connected">
    <div class="top-connected__icon-wrapper">
      <i ui-icon name="icon_procore_logo" size="img" defaultColor></i>
    </div>
    <div class="connected">
      <div class="circle"></div>
      <div i18n="@@CONNECTED">Connected</div>
    </div>
    <button
      class="top-connected__btn-disconnected"
      design-flat-button
      designButtonColor="secondary"
      type="button"
      [designFireOnClick]="disconnect$"
    >
      <div i18n="@@DISCONNECT_ACCOUNT">Disconnect Account</div>
      <design-button-loader
        designIconSuffix
        designColor="surface"
      ></design-button-loader>
    </button>

    <button
      class="top-connected__btn-disconnected-mobile"
      design-icon-button
      data-cy="stage-options-button"
      type="button"
      [designMenuTriggerFor]="menu"
    >
      <i designIcon="more_second" designSize="1.5rem"></i>
    </button>

    <design-menu-panel #menu>
      <button design-menu-item type="button" (click)="disconnect()">
        <ng-container i18n="@@DISCONNECT">Disconnect</ng-container>
      </button>
    </design-menu-panel>
  </div>
  <div class="description">
    <div i18n="@@CONNECTED_ACCOUNT_PROCORE">
      Your stages account is successfully linked to your Procore account.
      <br />
      <br />
      1.You can now link a Stages project to a Procore project (if you have such
      permissions in both Stage and Procore.)
      <br />
      2.You can now use the Procore features in the stages app if you are in a
      project that has been already linked.
    </div>
  </div>
} @else {
  <div class="top">
    <div class="top__icon-wrapper">
      <i ui-icon name="icon_procore_logo" size="img" defaultColor></i>
    </div>
    <button
      design-flat-button
      designButtonColor="accent"
      type="button"
      [designFireOnClick]="connect$"
    >
      <div class="top__button" i18n="@@CONNECT_ACCOUNT">Connect Account</div>
      <div class="top__button-mobile" i18n="@@CONNECT">Connect</div>
      <design-button-loader
        designIconSuffix
        designColor="surface"
      ></design-button-loader>
    </button>
  </div>

  <div class="description">
    <div i18n="@@CONNECT_ACCOUNT_PROCORE">
      Connect your account to your Procore account.
    </div>
  </div>
}
