import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Author } from '../../models/author';
import { Collaborator } from '../../models/collaborator';
import { ProjectsCommand } from '../../models/command/projects-command';
import { CreateProject } from '../../models/create-project';
import { DateRange } from '../../models/date-range';
import { EditProjectData } from '../../models/edit-project';
import { ImageSize } from '../../models/image-size';
import { NoteFilters } from '../../models/note-filters';
import { PagedResponse } from '../../models/paged-response';
import {
  GenerateProjectReportPayload,
  Project,
  ProjectPermissions
} from '../../models/project';
import { ProjectFiles } from '../../models/project-files';
import { ProjectPaging } from '../../models/project-paging';
import { ProjectRootNoteStatus } from '../../models/project-root-note-status';
import { ProjectsFilterBase } from '../../models/projects-filter';
import { ProjectsSort } from '../../models/projects-sort';
import { ProjectsQuery } from '../../models/query/projects-query';
import { UpdateProjectThumbnailData } from '../../models/update-project-thumbnail';
import { UserFiles } from '../../models/user-files';
import { SupportedLanguage } from '../../models/user-preferences';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class ProjectsService implements ProjectsCommand, ProjectsQuery {
  private readonly url = inject<string>(API_URL);
  private readonly httpClient = inject(HttpClient);
  //COMMAND
  createProject(payload: CreateProject): Observable<string> {
    return this.httpClient
      .post<any>(
        `${this.url}/construction-site-management-module/create-project`,
        payload
      )
      .pipe(
        map((response: any) => {
          return response.commandResponseData;
        })
      );
  }

  editProjectMainAttributes(payload: EditProjectData): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/edit-project-main-attributes`,
      payload
    );
  }

  updateProjectThumbnail(
    payload: UpdateProjectThumbnailData
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/update-project-thumbnail`,
      payload
    );
  }

  deleteProject(payload: { id: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/delete-project`,
      payload
    );
  }

  addFavoriteMark(payload: { projectId: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/add-favourite-mark`,
      payload
    );
  }

  removeFavoriteMark(payload: { projectId: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/remove-favourite-mark`,
      payload
    );
  }

  //QUERY
  generateProjectReport(
    payload: GenerateProjectReportPayload,
    lang: SupportedLanguage = 'en',
    sendAs: 'GET' | 'POST' = 'GET',
    rootNotesFilter?: NoteFilters
  ): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({
      Accept: 'application/pdf'
    });

    if (sendAs === 'GET') {
      const queryParameters = rootNotesFilter
        ? '&' + this.filtersBy(rootNotesFilter).join('&')
        : payload.stageDateFrom && payload.stageDateTo
          ? `&StageDateFrom=${payload.stageDateFrom}&StageDateTo=${payload.stageDateTo}`
          : `${payload.stagesIds
              ?.map((id: string) => `&StagesIds=${id}`)
              .join('')}`;

      return this.httpClient.get<Blob>(
        `${this.url}/construction-site-management-module/generate-project-report?ProjectId=${payload.projectId}&lang=${lang}${queryParameters}`,
        {
          observe: 'response',
          responseType: 'blob' as 'json',
          headers: headers
        }
      );
    }

    return this.httpClient.post<Blob>(
      `${this.url}/construction-site-management-module/generate-project-report`,
      payload,
      { observe: 'response', responseType: 'blob' as 'json', headers: headers }
    );
  }

  private filtersBy(rootNotesFilter: NoteFilters): string[] {
    const buffer: string[] = [];
    const base = 'Filter';
    (Object.keys(rootNotesFilter) as Array<keyof NoteFilters>).forEach(
      (key: keyof NoteFilters) => {
        if (rootNotesFilter[key]) {
          if (Array.isArray(rootNotesFilter[key])) {
            (rootNotesFilter[key] as string[]).forEach((value: string) => {
              buffer.push(`${base}.${key}=${value}`);
            });
          } else if (typeof rootNotesFilter[key] === 'object') {
            if ((rootNotesFilter[key] as DateRange).from) {
              buffer.push(
                `${base}.${key}From=${(rootNotesFilter[key] as DateRange).from}`
              );
              buffer.push(
                `${base}.${key}To=${(rootNotesFilter[key] as DateRange).to}`
              );
            }
          } else {
            buffer.push(`${base}.${key}=${rootNotesFilter[key]}`);
          }
        }
      }
    );
    return buffer;
  }

  checkSynchronizationInStages(payload: {
    projectId: string;
  }): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/construction-site-management-module/check-synchronization-in-stages?ProjectId=${payload.projectId}`
    );
  }

  getProject(payload: { id: string }): Observable<Project> {
    return this.httpClient.get<Project>(
      `${this.url}/construction-site-management-module/get-project?Id=${payload.id}`
    );
  }
  getUsedMattertags(payload: {
    projectId: string;
    scanId: string;
  }): Observable<string[]> {
    return this.httpClient.get<string[]>(
      `${this.url}/construction-site-management-module/get-used-mattertags?projectId=${payload.projectId}&scanId=${payload.scanId}`
    );
  }

  getUserPermissionsInProject(payload: {
    projectId: string;
  }): Observable<ProjectPermissions> {
    return this.httpClient.get<ProjectPermissions>(
      `${this.url}/construction-site-management-module/get-user-permissions-in-project?ProjectId=${payload.projectId}`
    );
  }

  getAllProjects(payload?: {
    paging?: ProjectPaging | undefined;
    filterBy?: ProjectsFilterBase | undefined;
    sortBy?: ProjectsSort | undefined;
    imageSize?: ImageSize | undefined;
  }): Observable<PagedResponse<Project[]>> {
    const queryParameters: string[] = [];

    if (payload) {
      queryParameters.push(...this.filterBy(payload.filterBy));
      queryParameters.push(...this.sortBy(payload.sortBy));

      if (payload.paging) {
        queryParameters.push(`Page.PageSize=${payload.paging.pageSize}`);
        queryParameters.push(`Page.PageNumber=${payload.paging.pageNumber}`);
      }

      if (payload.imageSize) {
        queryParameters.push(`RequestedImageSize=${payload.imageSize}`);
      }
    }

    return this.httpClient
      .get<
        PagedResponse<Project[]>
      >(`${this.url}/construction-site-management-module/get-all-projects?${queryParameters.join('&')}`)
      .pipe(
        map(
          (response: any) =>
            <PagedResponse<Project[]>>{
              metadata: {
                totalCount: response.totalCount,
                pageSize: response.pageSize,
                currentPage: response.currentPage,
                totalPages: response.totalPages,
                hasPrevious: response.hasPrevious,
                hasNext: response.hasNext
              },
              records: response.items
            }
        ),
        //NOTE: david (FIX for \v \u000b)
        map((page: PagedResponse<Project[]>) => {
          page.records.forEach((project: Project) => {
            if (project.description) {
              project.description = project.description
                .split('\u000b')
                .join('\n');
            }
          });

          return page;
        })
      );
  }

  getAllProjectsAuthors(): Observable<Author[]> {
    return this.httpClient
      .get(
        `${this.url}/construction-site-management-module/get-all-projects-authors`
      )
      .pipe(map((response: any) => response.items));
  }

  getProjectCollaborators(payload: {
    projectId: string;
    excludeViewers?: boolean;
  }): Observable<Collaborator[]> {
    return this.httpClient
      .get(
        `${
          this.url
        }/construction-site-management-module/get-project-collaborators?ProjectId=${
          payload.projectId
        }&ExcludeViewers=${
          payload.excludeViewers ? payload.excludeViewers : false
        }`
      )
      .pipe(map((response: any) => response.items));
  }

  countProjectsRootNotesByStatus(payload: {
    projectIds: string[];
  }): Observable<ProjectRootNoteStatus[]> {
    const queryString = payload.projectIds
      .map((projectId: string) => `ProjectIds=${projectId}`)
      .join('&');

    return this.httpClient
      .get(
        `${this.url}/construction-site-management-module/count-projects-root-notes-by-status?${queryString}`
      )
      .pipe(map((response: any) => response.items));
  }

  getProjectFreshFilesForSynchronization(payload: {
    projectId: string;
  }): Observable<ProjectFiles> {
    return this.httpClient.get<ProjectFiles>(
      `${this.url}/construction-site-management-module/get-project-fresh-files-for-synchronization?ProjectId=${payload.projectId}`
    );
  }

  getUserFreshFilesForSynchronization(): Observable<UserFiles> {
    return this.httpClient.get<UserFiles>(
      `${this.url}/construction-site-management-module/get-user-fresh-files-for-synchronization`
    );
  }

  private filterBy(filterBy?: ProjectsFilterBase): string[] {
    const buffer: string[] = [];

    if (filterBy?.sortByFavourites) {
      buffer.push(`SortByFavourites=${filterBy.sortByFavourites}`);
    }

    if (filterBy?.owner) {
      buffer.push(`ProjectsFilter.OnlyMyProjects=${filterBy.owner}`);
    }

    buffer.push(
      `ProjectsFilter.IncludeIdle=${filterBy?.archived ? true : false}`
    );

    if (filterBy?.organizationsId && filterBy.organizationsId.length > 0) {
      filterBy.organizationsId.forEach((organizationId) => {
        buffer.push(`ProjectsFilter.OrganizationsIds=${organizationId}`);
      });
    }

    if (filterBy?.projectAuthors && filterBy.projectAuthors.length > 0) {
      filterBy.projectAuthors.forEach((author) => {
        buffer.push(`ProjectsFilter.ProjectAuthors=${author}`);
      });
    }

    if (filterBy?.projectName) {
      buffer.push(`ProjectName=${filterBy.projectName}`);
    }

    if (filterBy?.modified && filterBy.modified.from && filterBy.modified.to) {
      buffer.push(
        `ProjectsFilter.ProjectModifiedFrom=${filterBy.modified.from}`
      );
      buffer.push(`ProjectsFilter.ProjectModifiedTo=${filterBy.modified.to}`);
    }

    if (filterBy?.createdFrom) {
      buffer.push(
        `ProjectsFilter.ProjectCreatedFrom=${this.stringToDate(
          filterBy.createdFrom
        )}`
      );
    }
    if (filterBy?.createdTo) {
      buffer.push(
        `ProjectsFilter.ProjectCreatedTo=${this.stringToDate(filterBy.createdTo)}`
      );
    }

    return buffer;
  }

  private stringToDate(value: string): string {
    return new Date(value).toISOString();
  }

  private sortBy(sortBy?: ProjectsSort): string[] {
    const buffer: string[] = [];

    if (sortBy?.type) {
      buffer.push(`SortColumn=${sortBy.type}`);
    }

    if (sortBy?.ascending) {
      buffer.push(`IsAscending=${sortBy.ascending}`);
    }

    return buffer;
  }
}
