@if (views()?.length) {
  @if (hasFailedEvents()) {
    <div class="clear-warning">
      <div i18n="@@CLEAR_WARNING_MESSAGES">Clear warning messages</div>
      <button
        design-icon-button
        class="context__progress-bar__btn"
        (click)="cleaningAllFailedTask()"
      >
        <i class="color" designIcon="cross" designSize="1.4rem"></i>
      </button>
    </div>
  }
  @for (item of views(); track $index) {
    <ng-container
      [ngTemplateOutlet]="item.templateRef!"
      [ngTemplateOutletContext]="{ data: item.data, context: item.context }"
    ></ng-container>
  }
}
