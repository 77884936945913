import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import {
  AfterContentChecked,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  contentChildren,
  input,
  model,
  TemplateRef,
} from '@angular/core';
import { TabAlign, TabsPosition } from '../../models/tab-align';
import { TabComponent } from '../tabs/tab.component';

@Component({
  selector: 'design-tab-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  host: {
    '[style.--align]': 'tabAlign()',
    '[class]': 'tabPosition()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupComponent implements AfterContentChecked {
  private _allTabs = contentChildren(TabComponent, { descendants: true });
  private _indexToSelect: number | null = 0;
  readonly footer = input<TemplateRef<any>>();
  readonly headerEnd = input<TemplateRef<any>>();
  readonly spaceAroundTab = input(false, { transform: booleanAttribute });
  readonly selectIndex = model<number | null>(null);
  readonly tabAlign = input<TabAlign>('start');
  readonly tabPosition = input<TabsPosition>('top');
  ngAfterContentChecked(): void {
    this._init();
  }
  private _init(): void {
    if (this.selectIndex() == null) {
      this.selectIndex.set(this._indexToSelect);
    }
  }
  changeStep(stepIdx: number): void {
    this.selectIndex.set(stepIdx);
  }
  get selectedIndex(): number | null {
    return this.selectIndex();
  }
  set selectedIndex(value: NumberInput) {
    this._indexToSelect = coerceNumberProperty(value, null);
  }
  public get allTabs(): readonly TabComponent[] {
    return this._allTabs();
  }
}
