<ng-content select="design-radio-expansion-panel-header"></ng-content>
<ng-content select="design-checkbox-expansion-panel-header"></ng-content>
<ng-content select="design-expansion-panel-header"></ng-content>
<ng-container *ngIf="emptyContent">
  <div
    class="design-expansion-panel-content"
    [@bodyExpansion]="_getExpandedState()"
    (@bodyExpansion.done)="_bodyAnimationDone.next($event)"
  >
    <div class="design-expansion-panel-body">
      <ng-content></ng-content>
      <ng-template [cdkPortalOutlet]="_portal"></ng-template>
    </div>
  </div>
</ng-container>
