import { InjectionToken } from '@angular/core';

type ToastTypeDeclare = 'info' | 'warning' | 'error' | 'success' | 'pending';
export type ToastType = Capitalize<ToastTypeDeclare>;

export class ToastConfig {
  /**
   * toast time to live in milliseconds
   * default: 5000
   */
  timeOut?: number = 5000;
  /**
   * animation ease time on toast
   * default: 300
   */
  easeTime?: number = 400;
  /**
   * animation easing on toast
   * default: ease-in
   */
  message = '';
  easing?: string = 'ease-in';
  closeButton?: boolean = true;
  onActivateTick?: boolean;
  payload?: string;
  toastType: ToastType = 'Info';
  positionClass = 'toast-container-bottom-right';
  showType = true;
}

export const TOAST_DATA = new InjectionToken<ToastConfig>('ToastData');
