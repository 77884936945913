import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken, inject } from '@angular/core';
import { Observable } from 'rxjs';

export const HttpClientBypass = new InjectionToken(
  'http client ovverride bypass'
);

export function createNewHttpClient(httpBackend: HttpBackend) {
  return new HttpClient(httpBackend);
}

@Injectable()
export class EulaService {
  public readonly httpClient = inject<HttpClient>(HttpClientBypass);

  getEulaText$(eulaUrl: string): Observable<any> {
    return this.httpClient.get(eulaUrl, {
      responseType: 'text'
    });
  }
}
