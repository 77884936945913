<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i designIcon="rotate" designSize="15rem"></i>
      <h2 designLabel i18n="@@PU_NEW_UPDATE">New Update</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    <div class="generate-report_description-container">
      <div class="main-text" i18n="@@PU_APPLICATION_HAS_BEEN_UPDATED_INFO">
        The application has been updated. Click "Refresh" to access the latest
        version or cancel to continue working.
      </div>
    </div>
    <div class="generate-report_description-container">
      <div
        class="postscript-text"
        i18n="@@PU_APPLICATION_AUTOMATICALLY_UPDATED"
      >
        The application will be automatically updated when you refresh the page.
      </div>
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="cancel()"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button design-flat-button designButtonColor="accent" (click)="confirm()">
      <div i18n="@@REFRESH">Refresh</div>
      <design-button-loader></design-button-loader>
    </button>
  </ng-container>
</design-dialog-wrapper>
