import { InjectionToken } from '@angular/core';
import { TimelineType } from '../models/user-preferences';

export const projectIdPublicToken = new InjectionToken<string>(
  'projectIdPublicToken'
);

export const timelinePublicToken = new InjectionToken<TimelineType>(
  'timelinePublicToken'
);
