/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive, TemplateRef, inject } from '@angular/core';
import { designRadioExpansionPanelBaseToken } from '../../tokens/radio-expansion-panel.token';
import { DesignRadioExpansionPanelBase } from './radio-expansion-panel.component';

@Directive({
  selector: 'ng-template[designRadioExpansionPanelContent]',
  standalone: true,
})
export class DesignRadioExpansionPanelContent {
      public _template = inject<TemplateRef<any>>(TemplateRef<any>);
      public _expansionPanel = inject<DesignRadioExpansionPanelBase>(designRadioExpansionPanelBaseToken);
}
