export type TBreadcrumb =
| 'templates'
|'users'
| 'roles'
| 'basic'
| 'projects'
| 'teams'
| 'subscription';

export const BREADCRUMB_CONST = {
  templates: 'templates',
  users: 'users',
  roles: 'roles',
  basic: 'basic',
  projects: 'projects',
  teams: 'teams',
  subscription: 'subscription',
}  satisfies  Record<TBreadcrumb, string>;



export const BREADCRUMB_LABEL= {
  templates: $localize`:@@TEMPLATES:Templates`,
  users: $localize`:@@USERS:Users`,
  roles: $localize`:@@ROLES:Roles`,
  basic: $localize`:@@BASIC:Basic`,
  projects: $localize`:@@PROJECTS:Projects`,
  teams: $localize`:@@TEAMS:Teams`,
  subscription: $localize`:@@SUBSCRIPTION:Subscription`,
} satisfies  Record<TBreadcrumb, string>;



