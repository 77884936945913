import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimelineMode } from '@simlab/data-access';
import { UserPreferenceFacade } from '@simlab/data-store';
import { DesignRadioAccordionModule } from '@simlab/design/accordion';
import { AppearanceForm } from '../appearance.component';

@Component({
  selector: 'simlab-timeline-mode',
  standalone: true,
  providers: [UserPreferenceFacade],
  imports: [
    CommonModule,
    DesignRadioAccordionModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './timeline-mode.component.html',
  styleUrls: ['./timeline-mode.component.scss'],
})
export class TimelineModeComponent {
  @Input({ required: true }) formGroup!: FormGroup<AppearanceForm>;
  readonly timelineMode = TimelineMode;
}
