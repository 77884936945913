import { Vector3 } from "three";
import { IndexedPoint } from "../types/line-measurement.interface";

export function calculateDistance(points:IndexedPoint[]):number{
    const pointsVectors = points.map((point)=> new Vector3(point.x,point.y,point.z));
    return  pointsVectors.reduce((previous:number,current:Vector3,idx:number)=> {
        const nextIdx =  points.length === idx + 1 ? 0 : idx + 1;
        if(!nextIdx || nextIdx === 0) return previous
        return previous + (current.distanceTo(pointsVectors[nextIdx]))
    },0)
}