import { computed, Injectable } from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { TEventItemStatus, TEventWorkStatus } from '@simlab/event-queue';
import {
  TEventQueue,
  TEventQueueItemSetup,
  TQueueState
} from '@simlab/event-queue/models';
import {
  BehaviorSubject,
  catchError,
  finalize,
  map,
  mergeMap,
  of,
  Subject,
  tap
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  /**
   * custom behavior of Queue count
   * count is clear after all job in queue are finished
   * else count incremented on add
   */
  private readonly _queueCount = new BehaviorSubject<TQueueState | undefined>(
    undefined
  );
  private readonly _viewQueue: BehaviorSubject<TEventQueue[]> =
    new BehaviorSubject<TEventQueue[]>([]);

  private readonly _taskScheduler: Subject<TEventQueue> =
    new Subject<TEventQueue>();

  readonly queueState = toSignal(this._queueCount.asObservable());
  readonly views = toSignal(
    this._viewQueue.pipe(
      map((views) => [...views.filter((view) => !!view.templateRef)])
    )
  );

  readonly viewInProgress = computed(() => {
    const views = this.views();
    if (!views) return 0;
    return views.filter(
      ({ context }) =>
        'status' in context &&
        (context.status as TEventItemStatus).workStatus === 'WORKING'
    ).length;
  });

  readonly failedResults = computed(() => {
    const views = this.views();
    if (!views) return 0;

    return views.filter(
      ({ context }) =>
        'status' in context &&
        (context.status as TEventItemStatus).workStatus ===
          'COMPLETED_WITH_FAILURES'
    ).length;
  });

  constructor() {
    this._queueObserver();
  }

  removeAllFailed() {
    this.removeAllByStatus('COMPLETED_WITH_FAILURES');
  }

  removeAllByStatus(status: TEventWorkStatus) {
    const views = this.views();
    if (!views) return;

    const setElement = views.filter(
      ({ context }) =>
        'status' in context &&
        (context.status as TEventItemStatus).workStatus !== status
    );
    this._viewQueue.next([...setElement]);
  }

  addEvent<TData, TContext>(event: TEventQueueItemSetup<TData, TContext>) {
    const eventWithId = { ...event, id: crypto.randomUUID(), data: undefined };
    event.templateRef &&
      this._viewQueue.next([...this._viewQueue.getValue(), eventWithId]);
    this._incrementQueueState();
    this._taskScheduler.next(eventWithId);
    return eventWithId.id;
  }

  private _queueObserver() {
    this._taskScheduler
      .pipe(
        mergeMap((queuedEvent) => {
          queuedEvent.start && queuedEvent.start(queuedEvent);
          let responseCache: unknown = undefined;
          return queuedEvent.execute$.pipe(
            tap((response) => {
              this._viewQueue.next([
                ...this._viewQueue
                  .getValue()
                  .map((event) =>
                    event.id !== queuedEvent.id
                      ? event
                      : { ...event, data: response }
                  )
              ]);
              responseCache = response;
            }),
            finalize(() => {
              queuedEvent.end && queuedEvent.end(responseCache, queuedEvent);
              this.removeAllByStatus('COMPLETED');
            }),
            catchError((error) => {
              queuedEvent.error && queuedEvent.error(error);
              return of(error);
            })
          );
        }, 3),
        takeUntilDestroyed()
      )
      .subscribe();
  }

  updateView(id: string, context: any) {
    this._viewQueue.next([
      ...this._viewQueue
        .getValue()
        .map((event) => (event.id !== id ? event : { ...event, context }))
    ]);
  }

  getViewContext(id: string) {
    const event = this._viewQueue.getValue().find((e) => e.id === id);
    if (event) return event.context;

    return undefined;
  }

  private _incrementQueueState() {
    const actState = this._queueCount.getValue();
    const from = (actState?.from || 0) + 1;
    const finished = actState?.finished || 0;
    this._queueCount.next({ from, finished });
  }

  private _decrementQueueState() {
    const actState = this._queueCount.getValue();
    const from = (actState?.from || 0) - 1;
    const finished = actState?.finished || 0;
    this._queueCount.next({ from, finished });
  }

  private _clearQueueState() {
    this._queueCount.next(undefined);
  }
}
