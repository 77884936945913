import { InversionAxis } from './inversion-axis';

export enum SystemOfMeasurement {
  Metric = 1,
  Imperial = 2
}

export type FormatDate = 'MM/dd/yyyy' | 'dd/MM/yyyy';
export type RegionType = 'GLOBAL' | 'CN';

export enum Theme {
  Dark,
  Light
}
export type ThemeType = 'light' | 'dark';
export const ThemeRecord: Record<Theme, ThemeType> = {
  [Theme.Dark]: 'dark',
  [Theme.Light]: 'light'
};

export enum TimelineMode {
  Condensed,
  Expanded
}
export type TimelineType = keyof typeof TimelineMode;

export interface UserPreferences {
  id: string;
  userProfileId: string;
  systemOfMeasurement: SystemOfMeasurement;
  language: SupportedLanguage;
  dateFormat: FormatDate;
  mouseInversion: InversionAxis;
  matterportServerLocalization: unknown; // TO Delete in future
  theme: Theme;
  timeline: TimelineMode;
}
export type UpdateUserPreferences = Omit<UserPreferences, 'id'>;

export type SupportedLanguage = 'pl' | 'ja' | 'en' | 'de' | 'es';

export const LANGUAGES_LIST: {
  displayName: string;
  value: SupportedLanguage;
}[] = [
  {
    displayName: `English`,
    value: 'en'
  },
  {
    displayName: `日本語`,
    value: 'ja'
  },
  {
    displayName: `Polski (experimental)`,
    value: 'pl'
  },
  {
    displayName: `Español (experimental)`,
    value: 'es'
  },
  {
    displayName: `Deutsch (experimental)`,
    value: 'de'
  }
];
