import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  TDistanceCount,
  TSimpleMeasurement,
  TStageMeasurmentSimpleGroups
} from '../common-models';

export interface MeasurementQuery {
  getMeasurement: (id: string) => Observable<TSimpleMeasurement>;
  getStageMeasurementSimpleGroups: (
    stageId: string
  ) => Observable<TStageMeasurmentSimpleGroups[]>;
  exportStageMeasurements: (
    resultFileName: string,
    ids: string[]
  ) => Observable<HttpResponse<Blob>>;

  getProjectMeasurementsCount: (
    projectId: string
  ) => Observable<TDistanceCount>;
  getStageMeasurementsCount: (stageId: string) => Observable<TDistanceCount>;
}
