import { InjectionToken } from '@angular/core';
import { DesignColor, DesignColorInput } from '@simlab/design/internal';

/** Default `mat-checkbox` options that can be overridden. */
export interface DesignCheckboxDefaultOptions {
  /** Default theme color palette to be used for checkboxes. */
  color?: DesignColorInput;
  /** Default checkbox click action for checkboxes. */
  clickAction?: DesignCheckboxClickAction;
}

/** Injection token to be used to override the default options for `mat-checkbox`. */
export const DESIGN_CHECKBOX_DEFAULT_OPTIONS =
  new InjectionToken<DesignCheckboxDefaultOptions>(
    'mat-checkbox-default-options',
    {
      providedIn: 'root',
      factory: DESIGN_CHECKBOX_DEFAULT_OPTIONS_FACTORY,
    }
  );

/** @docs-private */
export function DESIGN_CHECKBOX_DEFAULT_OPTIONS_FACTORY(): DesignCheckboxDefaultOptions {
  return {
    color: DesignColor.accent,
    clickAction: 'check-indeterminate',
  };
}

/**
 * Checkbox click action when user click on input element.
 * noop: Do not toggle checked or indeterminate.
 * check: Only toggle checked status, ignore indeterminate.
 * check-indeterminate: Toggle checked status, set indeterminate to false. Default behavior.
 * undefined: Same as `check-indeterminate`.
 */
export type DesignCheckboxClickAction =
  | 'noop'
  | 'check'
  | 'check-indeterminate'
  | undefined;
