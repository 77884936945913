export const ACCEPTED_IMG_TAG_EXTENSION = [
  'apng',
  'bmp',
  'gif',
  'jpeg',
  'jpg',
  'png',
  'webp',
  'svg'
] as const;

export const ACCEPTED_VIDEO_TAG_EXTENSION: string[] = [
  'mp4',
  'webm',
  'ogv',
  'mov'
] as const;
export const ACCEPTED_AUDIO_TAG_EXTENSION: string[] = [
  'mp3',
  'wav',
  'ogg'
] as const;
