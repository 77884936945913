import { Injectable, inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NoteFilters } from '@simlab/data-access';
import * as ShowcaseNotesActions from './showcase-notes.actions';
import * as ShowcaseNotesSelectors from './showcase-notes.selectors';

@Injectable()
export class ShowcaseNotesFacade {
      private readonly store = inject(Store);
  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  loaded$ = this.store.pipe(
    select(ShowcaseNotesSelectors.getShowcaseNotesLoaded)
  );
  allShowcaseNotes$ = this.store.pipe(
    select(ShowcaseNotesSelectors.getAllShowcaseNotes)
  );
  selectedShowcaseNote$ = this.store.pipe(
    select(ShowcaseNotesSelectors.getSelected)
  );

  notesHidden$ = this.store.pipe(select(ShowcaseNotesSelectors.getNotesHidden));

  /**
   * Use the initialization action to perform one
   * or more tasks in your Effects.
   */
  initStore(payload: {
    stageId: string[] | null;
    rootNotesFilter?: NoteFilters | undefined;
  }) {
    this.store.dispatch(ShowcaseNotesActions.init({ payload }));
  }

  clearStore() {
    this.store.dispatch(ShowcaseNotesActions.clearStore());
  }

  selectedNoteId(noteId: string) {
    this.store.dispatch(ShowcaseNotesActions.selectNote({ noteId }));
  }

  hideAllNotes(hideAll: boolean) {
    this.store.dispatch(ShowcaseNotesActions.hideAll({ hideAll }));
  }
  // addNote(note: Note) {
  //   this.store.dispatch(ShowcaseNotesActions.addNote({ note }));
  // }
}
