import { Coordinates } from './coordinates';

export interface EditProjectData {
  id: string;
  newName?: string;
  newDescription?: string;
  newAddress?: string;
  newProjectThumbnailUrl?: string;
  newGeolocation?: Coordinates;
}
