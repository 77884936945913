import { InjectionToken } from '@angular/core';

export type FormFieldError = {
  [key: string]: (...params: any[]) => string;
};

export const DEFAULT_FORM_FIELD_ERRORS: FormFieldError = {
  required: () => `Field is required`,
  minlength: (requiredLength: number, actualLength: number) =>
    `The entered value is too short`,
  maxlength: (requiredLength: number, actualLength: number) =>
    `The entered value is too long`,
};

export const designErrorToken = new InjectionToken<FormFieldError>(
  'designErrorToken'
);
