// eslint-disable-next-line @nx/nx/enforce-module-boundaries
import { ModelSweep } from '@simlab/matterport/api';
import { Transform } from '@simlab/transform';
import { Vector3 } from 'three';

export interface IPortals {
  createPortal: (payload: PortalConfiguration) => Promise<void>;
}
export class CreatePortal {
  constructor(
    readonly id: string,
    readonly currentPosition: Vector3,
    readonly currentMtpSweeps: ModelSweep[],
    readonly currentMtpOffset: Transform,
    readonly otherMtpOffset: Transform,
    readonly otherMtpSweeps: ModelSweep[],
    readonly componentName?: string
  ) {}
}
export type PortalConfiguration = Pick<
  CreatePortal,
  'otherMtpOffset' | 'otherMtpSweeps' | 'id'
> &
  Partial<CreatePortal> & { calculatedPosition?: Vector3 };
