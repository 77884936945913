import { DateRange } from './date-range';

export interface NoteFilters {
  rootNoteTypes: string[];
  rootNoteStatuses: string[];
  rootNoteCreated: DateRange;
  rootNoteModified: DateRange;
  rootNoteStageDate: DateRange;
  onlyMyNotes: boolean;
  rootNoteAuthors: string[];
  stakeholders: string[];
  byNoteAuthor: boolean;
  selectedStakeholders: boolean;
  name?: string;
}
