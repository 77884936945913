import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StageComponent } from '@simlab/data-access';
import {
  componentsAdapter,
  COMPONENTS_FEATURE_KEY,
  State,
} from './components.reducer';

// Lookup the 'Components' feature state managed by NgRx
export const getComponentsState = createFeatureSelector<State>(
  COMPONENTS_FEATURE_KEY
);

const { selectAll, selectEntities } = componentsAdapter.getSelectors();

export const getComponentsLoaded = createSelector(
  getComponentsState,
  (state: State) => state.loaded
);

export const getComponentsError = createSelector(
  getComponentsState,
  (state: State) => state.error
);

export const getAllComponents = createSelector(
  getComponentsState,
  (state: State) => (state.loaded ? selectAll(state) : [])
);

export const getComponentsInStage = (stageId: string) =>
  createSelector(getComponentsState, (state: State) =>
    selectAll(state).filter((component) => component.stageId === stageId)
  );
export const getMatterportComponentsInStage = (stageId: string) =>
  createSelector(
    getComponentsInStage(stageId),
    (components: StageComponent[]) =>
      components.filter((component) => !component.extension)
  );

export const getBlueprintComponentsInStage = (
  stageId: string,
  outOfSync: boolean = false
) =>
  createSelector(
    getComponentsInStage(stageId),
    (components: StageComponent[]) =>
      components.filter(
        (component) =>
          component.extension &&
          (outOfSync || component.isSynchronizingAccepted)
      )
  );

export const getComponentsEntities = createSelector(
  getComponentsState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getComponentsState,
  (state: State) => state.selectedId
);

export const getLoaded = createSelector(
  getComponentsState,
  (state: State) => state.loaded
);

export const getSelected = createSelector(
  getComponentsEntities,
  getSelectedId,
  (entities, selectedId) => {
    return selectedId ?? undefined;
  }
);

export const getSelectedComponent = createSelector(
  getComponentsState,
  (state: State) => {
    if (state.selectedId === undefined) return undefined;
    return state.entities[state.selectedId as string];
  }
);

export const componentById = (componentId: string) =>
  createSelector(getComponentsEntities, (entities) => {
    return entities[componentId];
  });

export const hasComponent = (componentId: string) =>
  createSelector(getComponentsEntities, (entities) => {
    return !!entities[componentId];
  });
