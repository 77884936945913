import { Units, segmentsTexture } from "@simlab/simlab-facility-management/sub-features/measurement";
import { Color, CanvasTexture, SpriteMaterial, Sprite, PlaneGeometry, Mesh, Vector3, DoubleSide } from "three";

export function segmentMesh(
  threeContext: {
    Color: typeof Color;
    CanvasTexture: typeof CanvasTexture;
    SpriteMaterial: typeof SpriteMaterial;
    Sprite: typeof Sprite;

    PlaneGeometry: typeof PlaneGeometry;
    Mesh: typeof Mesh;
  },
  size: number,
  position: Vector3,
  unit: Units = Units.Metric
) {
  const { texture, textLength } = segmentsTexture(threeContext, size, unit);
  const material = new threeContext.SpriteMaterial({
    alphaTest: 0.2,
    polygonOffset: false,
    opacity: 1,
    transparent: false,
    side: DoubleSide,
    polygonOffsetFactor: 0,
    polygonOffsetUnits: 0,
  });

  const mesh = new threeContext.Sprite( material);
  mesh.renderOrder = 2;
  mesh.material.dispose();
  mesh.material.map = texture;
  mesh.scale.set(textLength * 0.03, 0.08, 0);
  mesh.position.copy(position);
  return mesh;
}
