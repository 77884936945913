import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  OnInit,
  signal
} from '@angular/core';
import { UserPreferences } from '@simlab/data-access';
import {
  DesignFlatButtonModule,
  DesignIconButton
} from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { ToastService } from '@simlab/design/toast';
import { ProcoreAuthorizationService } from '@simlab/procore/services';
import { UiIconModule } from '@simlab/ui/icon';
import { catchError, firstValueFrom, of, switchMap, tap } from 'rxjs';
import { PersonalSettingBase } from '../../../personal-settings.component';

@Component({
  selector: 'simlab-procore-connection',
  standalone: true,
  imports: [
    UiIconModule,
    DesignFlatButtonModule,
    DesignIconButton,
    DesignIcon,
    UiMenuPanelModule
  ],
  providers: [ProcoreAuthorizationService],
  templateUrl: './procore-connection.component.html',
  styleUrl: './procore-connection.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcoreConnectionComponent implements PersonalSettingBase, OnInit {
  userPreferencesChange!: EventEmitter<UserPreferences>;
  userPreferences!: UserPreferences;

  readonly connected = input.required<boolean>();
  private readonly _authorization = inject(ProcoreAuthorizationService);
  private readonly _toastService = inject(ToastService);

  readonly hasUserProcoreToken = signal(false);

  ngOnInit(): void {
    firstValueFrom(this._getUserProcoreStatusToken$);
  }

  readonly disconnect$ = this._authorization.disconnect$.pipe(
    switchMap(() => this._getUserProcoreStatusToken$)
  );

  readonly connect$ = this._authorization.fullAuthorization$().pipe(
    switchMap((e) => {
      if (e === 'CLOSE') {
        throw new Error('Window Closed');
      }
      return this._getUserProcoreStatusToken$;
    }),

    catchError((e) => {
      console.error(e);
      this._toastService.open(
        $localize`:@@WINDOW_CLOSED:Window was closed without logging in`,
        'Warning'
      );
      return of(e);
    })
  );

  private _getUserProcoreStatusToken$ = this._authorization.userConnected$.pipe(
    tap((e: boolean) => {
      this.hasUserProcoreToken.set(e);
    })
  );
}
