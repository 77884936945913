export interface OrganizationProject {
  projectId: string;
  projectName: string;
  authorName: string;
  authorEmail?: string;
  teamsCount: number;
  collaboratorsCount: number;
  creationDate: string;
  isActive: boolean;
  transferData?: TransferData;
  isProcoreProject: boolean;
}

export interface PaginationPage<T> {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  hasPrevious: boolean;
  hasNext: boolean;
  items: T[];
}

export interface TransferData {
  organizationName: string;
  status: StatusTransferProject;
  transferringUser: TransferringUser;
}

export enum StatusTransferProject {
  TransferToPending = 0,
  Pending = 1
}

export interface TransferringUser {
  userEmail: string;
  userName: string;
}
