import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserPreferencesCommand } from '../../models/command/user-preferences-command';
import { UserPreferencesQuery } from '../../models/query/user-preferences-query';
import {
  UpdateUserPreferences,
  UserPreferences
} from '../../models/user-preferences';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class UserPreferencesService
  implements UserPreferencesCommand, UserPreferencesQuery
{
  private readonly _url = inject<string>(API_URL);
  private readonly _httpClient = inject(HttpClient);
  //COMMAND
  updateUserPreferences(
    userPreferences: UpdateUserPreferences
  ): Observable<void> {
    return this._httpClient.post<void>(
      `${this._url}/users-module/update-user-preferences`,
      userPreferences
    );
  }

  hasUserProcoreToken(): Observable<boolean> {
    return this._httpClient.get<boolean>(
      `${this._url}/users-module/has-user-procore-token`
    );
  }

  //QUERY
  getUserPreferences(): Observable<UserPreferences> {
    return this._httpClient.get<UserPreferences>(
      `${this._url}/users-module/get-user-preferences`
    );
  }
}
