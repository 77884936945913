import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Routes } from '@angular/router';
import { ProjectsFacade } from '@simlab/data-store';
import { ProcoreAuthorizationService } from '@simlab/procore/services';

export const canOpenProcoreTab = (route: ActivatedRouteSnapshot) => {
  const projectFacade = inject(ProjectsFacade);
  const projectId = route.params['projectId'];
  return projectFacade.projectConnectedWithProcore$(projectId);
};

export const documentRoutes: Routes = [
  {
    path: 'stages',
    loadComponent: () =>
      import('./stages-documents/stages-documents.component').then(
        (e) => e.StagesDocumentsComponent
      )
  },
  {
    path: 'procore',
    providers: [ProcoreAuthorizationService],
    canMatch: [() => inject(ProcoreAuthorizationService).userConnected$],

    canActivate: [canOpenProcoreTab],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('./procore-wrapper/procore-wrapper.component').then(
            (e) => e.ProcoreWrapperComponent
          )
      },
      {
        path: 'documents',
        loadComponent: () =>
          import('./procore-documents/procore-documents.component').then(
            (e) => e.ProcoreDocumentsComponent
          )
      },
      {
        path: 'drawings',
        loadComponent: () =>
          import('./procore-drawings/procore-drawings.component').then(
            (e) => e.ProcoreDrawingsComponent
          )
      },
      {
        path: 'pictures',
        loadComponent: () =>
          import('./procore-pictures/procore-pictures.component').then(
            (e) => e.ProcorePicturesComponent
          )
      }
    ]
  },
  {
    path: 'procore',
    canActivate: [canOpenProcoreTab],
    loadComponent: () =>
      import(
        './procore-not-connected-account/procore-not-connected-account.component'
      ).then((e) => e.ProcoreNotConnectedAccountComponent)
  },
  {
    path: '',
    redirectTo: 'stages',
    pathMatch: 'full'
  }
];
