import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiHelperModule } from '../ui-helper/ui-helper.module';
import { HintComponent } from './components/hint/hint.component';

@NgModule({
  declarations: [HintComponent],
  imports: [CommonModule, UiHelperModule],
  exports: [UiHelperModule, HintComponent],
})
export class UiHintModule {}
