import { Mesh, Object3D, Vector3 } from 'three';

export const DEFAULT_SCALE_SPACE = 0.05;
export function getElementPosition(
  newPos: Vector3,
  newNorm: Vector3,
  scale?: number
) {
  if (!scale) scale = DEFAULT_SCALE_SPACE;
  const stem = {
    x: scale * newNorm.x,
    y: scale * newNorm.y,
    z: scale * newNorm.z,
  };

  return {
    x: newPos.x + stem.x,
    y: newPos.y + stem.y,
    z: newPos.z + stem.z,
  };
}
export function disposeNode(node: Object3D) {
  if (node.type === 'Mesh') {
    const nodeMesh = node as Mesh<any, any>;
    if (nodeMesh.geometry) {
      nodeMesh.geometry.dispose();
    }
    const disposeMaterial = (material: any) => {
      if (material) {
        if (material.map) material.map.dispose();
        if (material.lightMap) material.lightMap.dispose();
        if (material.bumpMap) material.bumpMap.dispose();
        if (material.normalMap) material.normalMap.dispose();
        if (material.specularMap) material.specularMap.dispose();
        if (material.envMap) material.envMap.dispose();
        if (material.alphaMap) material.alphaMap.dispose();
        if (material.aoMap) material.aoMap.dispose();
        if (material.displacementMap) material.displacementMap.dispose();
        if (material.emissiveMap) material.emissiveMap.dispose();
        if (material.gradientMap) material.gradientMap.dispose();
        if (material.metalnessMap) material.metalnessMap.dispose();
        if (material.roughnessMap) material.roughnessMap.dispose();
        material.dispose(); // disposes any programs associated with the materia
      }
    };
    if (nodeMesh.material) {
      if (Array.isArray(nodeMesh.material)) {
        nodeMesh.material.forEach((material) => disposeMaterial(material));
      } else {
        disposeMaterial(nodeMesh.material);
      }
    }
  }

  return;
} // disposeNode
export function disposeHierarchy(
  node: Object3D,
  callback: (node: Object3D) => unknown
) {
  for (let i = node.children.length - 1; i >= 0; i--) {
    const child = node.children[i];
    disposeHierarchy(child, callback);

    callback(child);
  }
}
