import { ErrorHandler, Inject, Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  AngularPlugin,
  ApplicationinsightsAngularpluginErrorService,
} from '@microsoft/applicationinsights-angularplugin-js';
import {
  ApplicationInsights,
  ICustomProperties,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web';
import { ENVIRONMENT_CONFIG, Environment } from '@simlab/util-shared';

//-------------------------------------------------------------------------
// Special Note: The Errorservice has an implicit dependency on the
// `@microsoft/applicationinsights-analytics-js' extension, which is included
// with the '@microsoft/applicationinsights-web' module, if the analytics
// extension is not included during initialization of the SDK this Error Service
// will not be able to send any caught unhandled errors.
//-------------------------------------------------------------------------

export function errorHandlerFactory(environment: Environment) {
  if (environment.production) {
    return new ApplicationinsightsAngularpluginErrorService();
  }
  return new ErrorHandler();
}

@Injectable()
export class InsightsService {
      private readonly _environment = inject<Environment>(ENVIRONMENT_CONFIG);
      private readonly _router = inject(Router);
  private readonly _angularPlugin = new AngularPlugin();
  private readonly _appInsights = new ApplicationInsights({
    config: {
      connectionString: this.connectionString,
      extensions: [this._angularPlugin],
      extensionConfig: {
        [this._angularPlugin.identifier]: {
          router: this._router,
          //NOTE: re-enable the default behavior by adding default error handler to errorServices collection
        },
      },
    },
  });

  get connectionString(): string {
    const { instrumentationKey, ingestionEndpoint, liveEndpoint } =
      this._environment.configuration.microsoft || {};
    return `InstrumentationKey=${instrumentationKey};IngestionEndpoint=${ingestionEndpoint};LiveEndpoint=${liveEndpoint}`;
  }

  constructor(
) {
    // if (this._environment.production) {
    this._appInsights.loadAppInsights();
    // }
  }
  trackException(
    exception: IExceptionTelemetry,
    properties?: ICustomProperties
  ) {
    !location.origin.includes('localhost') &&
      this._appInsights.trackException(exception, properties);
  }
  trackEvent(name: string): void {
    this._appInsights.trackEvent({ name });
  }

  trackTrace(message: string): void {
    this._appInsights.trackTrace({ message });
  }
}
