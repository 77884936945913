/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { EMPTY, Observable, defer, filter, map } from 'rxjs';
import {
  DEFAULT_FORM_FIELD_ERRORS,
  FormFieldError,
  designErrorToken,
} from '../tokens/default-error.token';
import { designFormFieldErrorToken } from '../tokens/error.token';
import { DesignFormFieldControl } from './form-field-control.directive';
import { DesignFormField } from './form-field.component';

@Component({
  selector: 'design-error',
  template: `{{ lastKnownError$ | async }}`,
  standalone: true,
  imports: [AsyncPipe],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-error',
  },
  providers: [
    { provide: designFormFieldErrorToken, useExisting: DesignFormFieldError },
  ],
})
export class DesignFormFieldError {
  private _parentFormField = inject(DesignFormField, { host: true });
  private _customErrors = inject<FormFieldError>(designErrorToken, {
    optional: true,
  });
  readonly lastKnownError$: Observable<any> = defer(() =>
    (this.parentControl?.stateChanges ?? EMPTY).pipe(
      filter(() => !!this.errors),
      map(() => this.errors && this.errors[0]),
      map(() => {
        const firstAvailableKey: string | undefined = Object.keys(
          <ValidationErrors>this.errors,
        )[0];

        const firstAvailableValues: any[] = Object.values(
          <ValidationErrors>this.errors,
        )[0];

        if (!this.availableErrors[firstAvailableKey]) {
          throw new Error(`Given key '${firstAvailableKey}' is not defined!`);
        }

        return this.availableErrors[firstAvailableKey](firstAvailableValues);
      }),
    ),
  );

  get parentControl(): DesignFormFieldControl<any> {
    return this._parentFormField.control;
  }

  get errors(): ValidationErrors | null | undefined {
    return <ValidationErrors | null>this.parentControl.ngControl?.errors;
  }

  get availableErrors() {
    return { ...DEFAULT_FORM_FIELD_ERRORS, ...this._customErrors };
  }
}
