import { Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiSubscriptionService } from './api-subscription.service';

@Injectable()
export class OrganizationLimitsFacade {
      private readonly apiSubscriptionService = inject(ApiSubscriptionService);

  canAddProject(organizationId: string): Observable<boolean> {
    return this.apiSubscriptionService
      .getRemainingActiveProjectLimitsForOrganization$({ organizationId })
      .pipe(
        map((limit: number) => {
          if (limit === null) return true;
          return limit > 0;
        })
      );
  }

  canAddStage(projectId: string): Observable<boolean> {
    return this.apiSubscriptionService
      .getRemainingStageLimitsForProject$({ projectId })
      .pipe(
        map((limit: number) => {
          if (limit === null) return true;
          return limit > 0;
        })
      );
  }

  canAddNoteToStage(projectId: string): Observable<boolean> {
    return this.apiSubscriptionService
      .getRemainingRootNoteLimitsForStage$({ projectId })
      .pipe(
        map((limit: number) => {
          if (limit === null) return true;
          return limit > 0;
        })
      );
  }

  canInviteUserToOrganization(
    organizationId: string
  ): Observable<{ remainingSeatsLimit: number; canInviteViewers: boolean }> {
    return this.apiSubscriptionService.getRemainingSeatsLimitsForOrganization$({
      organizationId,
    });
  }

  limitNotExceededOfOrganization(): Observable<boolean> {
    return this.apiSubscriptionService.getRemainingLimitOfOrganization$().pipe(
      map((limit: number) => {
        if (limit === null) return true;
        return limit > 0;
      })
    );
  }

  getOrganizationId(projectId: string): Observable<string> {
    return this.apiSubscriptionService.getOrganizationIdByProjectId$({
      projectId,
    });
  }
}
