export enum ProjectPermission {
  None = 0,
  // project

  EditProjectInfo = 1 << 0,

  // stages

  AddEditDeleteStagesInOwnProjects = 1 << 1,
  AddEditDeleteStagesInOtherUsersProjects = 1 << 2,

  // components

  AddEditDeleteComponentsInOwnProjects = 1 << 3,
  AddEditDeleteComponentsInOtherUsersProjects = 1 << 4,

  // notes

  AddEditDeleteOwnNotes = 1 << 5,
  DeleteNote = 1 << 6,
  RenameNoteAndChangeDescription = 1 << 7,
  ChangeNoteStatus = 1 << 8,
  ChangeStakeholder = 1 << 9,

  // root note elements

  CanViewNoteElements = 1 << 10,
  AddEditDeleteOwnElements = 1 << 11,
  EditDeleteOtherUserElements = 1 << 12,
  AddReplaceMarker = 1 << 13,
  AddEditDeleteOwnComments = 1 << 14,
  EditDeleteOtherUserComments = 1 << 15,

  // synchronization

  CanAccessSynchronizationPanel = 1 << 16,

  // measurements

  AddEditDeleteOwnMeasurements = 1 << 17,
  EditDeleteOtherUsersMeasurements = 1 << 18,
  AddEditDeleteOwnMeasurementGroups = 1 << 19,
  EditDeleteOtherUsersMeasurementGroups = 1 << 20,

  // documents

  CanAddFiles = 1 << 21,
  CanDeleteFiles = 1 << 22,
  CanEditFiles = 1 << 23
}
export type TProjectPermission = { [prop in ProjectPermission]: boolean };
