<div class="table__content--wrapper">
  <ng-container *ngIf="loadingData">
    <div class="progress-bar">
      <ui-progress-spinner
        [diameter]="75"
        [strokeWidth]="8"
        uiColor="primary"
      ></ui-progress-spinner>
    </div>
  </ng-container>
  <table
    class="ui-table"
    cdk-table
    [dataSource]="dataSource"
    hasOverflownParent
  >
    <ng-content></ng-content>

    <tr
      class="ui-header-row"
      cdk-header-row
      *cdkHeaderRowDef="displayedColumns"
    ></tr>
    <tr
      class="ui-row"
      cdk-row
      *cdkRowDef="let row; columns: displayedColumns"
    ></tr>
  </table>
</div>
<ng-content select="[uiPaginator]"></ng-content>
