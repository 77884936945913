import { BaseUserInfo } from './user';

/**
 * Interface for the 'Organizations' data
 */
export interface Organizations {
  id: string; // Primary ID
  name: string;
  invitationPending: boolean;
  invitationRequiresOwnLicense?: boolean;
  invitationId?: string;
  description?: string;
  transfer?: TransferOrganization;
}
export interface TransferOrganization {
  createdAt: string;
  daysExpiry: number;
  direction: OrganizationTransferDirection;
  receivingUser: BaseUserInfo;
  transferringUser: BaseUserInfo;
}

export enum OrganizationTransferDirection {
  TransferFrom = 0,
  TransferTo = 1,
}
export interface OrganizationInfo {
  id?: string;
  owner?: string;
  emailOwner?: string;
  description?: string;
  name?: string;
  thumbnail?: string;
  created?: string;
}
