import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { OpenIdConfiguration, provideAuth } from 'angular-auth-oidc-client';
import { TokenInjectorInterceptor } from '../interceptors/token-injector.interceptor';
export function provideAuthConfig(
  config: OpenIdConfiguration
): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInjectorInterceptor,
      multi: true,
    },
    provideAuth({ config }),
  ]);
}
