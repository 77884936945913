import { createAction, props } from '@ngrx/store';
import {
  Page,
  ProcoreConnectionStatusEnum,
  Project,
  ProjectsFilterBase,
  ProjectsSort
} from '@simlab/data-access';
import { ProjectForm } from './projects.facade';

export const initProjects = createAction(
  '[Projects Page] Init',
  props<{
    filters?: ProjectsFilterBase;
    sort?: ProjectsSort;
  }>()
);

export const loadProjectsSuccess = createAction(
  '[Projects/API] Load Projects Success',
  props<{ projects: Project[]; metadata: Page }>()
);

export const getProject = createAction(
  '[Get Project] Get Project',
  props<{ projectId: string }>()
);

export const getProjectSuccess = createAction(
  '[Get Project] Get Project Success',
  props<{ project: Project }>()
);

export const getProjectFailure = createAction(
  '[Get Project] Get Project Failure',
  props<{ error: any }>()
);

export const createProject = createAction(
  '[Create project] Create Project',
  props<{ project: ProjectForm }>()
);

export const createProjectSuccess = createAction(
  '[Create project] Create Project Success',
  props<{ project: Project }>()
);

export const createProjectFailure = createAction(
  '[Create project] Create Project Failure',
  props<{ error: any }>()
);

export const loadProjectsFailure = createAction(
  '[Projects/API] Load Projects Failure',
  props<{ error: any }>()
);

export const toggleFavorite = createAction(
  '[Projects/API] Mark project as Favorite',
  props<{ projectId: string; flag: boolean }>()
);

export const unassignProjectProcore = createAction(
  '[Projects] Unassign Project Procore',
  props<{ projectId: string }>()
);

export const initProcoreProjectStatus = createAction(
  '[Projects] Init Procore Project Status',
  props<{ projectId: string; status: ProcoreConnectionStatusEnum }>()
);

export const disconnectingProcoreProject = createAction(
  '[Projects] Disconnecting Procore Project',
  props<{ projectId: string }>()
);

export const reconnectingProcoreProject = createAction(
  '[Projects] Reconnecting Procore Project',
  props<{ projectId: string }>()
);

export const assignProjectProcore = createAction(
  '[Projects] Assign Project Procore',
  props<{ projectId: string }>()
);

export const removeProject = createAction(
  '[Projects/API] Remove project',
  props<{ projectId: string }>()
);

export const removeProjectSuccess = createAction(
  '[Projects/API] Remove Project Success',
  props<{ projectId: string }>()
);

export const removeProjectFailure = createAction(
  '[Projects/API] Remove Project Failure',
  props<{ error: any }>()
);

export const updateProject = createAction(
  '[Projects/API] Update project',
  props<{ projectId: string; data: ProjectForm }>()
);

export const updateProjectSuccess = createAction(
  '[Projects/API] Update Project Success',
  props<{ project: Project }>()
);

export const updateProjectFailure = createAction(
  '[Projects/API] Update Project Failure',
  props<{ error: any }>()
);
