import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { StagesCommand } from '../../models/command/stages-command';
import { StagesQuery } from '../../models/query/stages-query';
import { Stage } from '../../models/stage';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class StagesService implements StagesCommand, StagesQuery {
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);
  //COMMAND
  addStageToProject(payload: {
    projectId: string;
    name: string;
    stageDate: string;
  }): Observable<string> {
    return this.httpClient
      .post<string>(
        `${this.url}/construction-site-management-module/add-stage-to-project`,
        payload
      )
      .pipe(map((response: any) => response.commandResponseData));
  }

  removeStageFromProject(payload: {
    stageId: string;
    projectId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/remove-stage-from-project`,
      payload
    );
  }

  editStage(payload: {
    id: string;
    newName: string;
    newStageDate: string;
  }): Observable<{
    lastEditedBy: string;
    lastEditorName: string;
    modifiedAt: string;
  }> {
    return this.httpClient.post<{
      lastEditedBy: string;
      lastEditorName: string;
      modifiedAt: string;
    }>(
      `${this.url}/construction-site-management-module/edit-stage-name-and-date`,
      payload
    );
  }

  editStageName(payload: { id: string; newName: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/edit-stage-name`,
      payload
    );
  }

  editStageDate(payload: {
    id: string;
    newStageDate: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/set-stage-date`,
      payload
    );
  }

  editStageDescription(payload: {
    id: string;
    newDescription: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/edit-stage-description`,
      payload
    );
  }

  //QUERY
  getStagesForProject(payload: { projectId: string }): Observable<Stage[]> {
    return this.httpClient
      .get(
        `${this.url}/construction-site-management-module/get-stages-for-project?ProjectId=${payload.projectId}`
      )
      .pipe(
        map((response: any) => response.items),
        //NOTE: david (FIX for \v \u000b)
        map((stages: Stage[]) => {
          stages.forEach((stage: Stage) => {
            if (stage.description) {
              stage.description = stage.description.split('\u000b').join('\n');
            }
          });

          return stages;
        })
      );
  }

  getStageForProject(payload: {
    projectId: string;
    stageId: string;
  }): Observable<Stage> {
    return this.httpClient
      .get<Stage>(
        `${this.url}/construction-site-management-module/get-stage-for-project?ProjectId=${payload.projectId}&StageId=${payload.stageId}`
      )
      .pipe(
        map((stage: Stage) => {
          if (stage.description) {
            return {
              ...stage,
              description: stage.description.split('\u000b').join('\n'),
            };
          } else {
            return stage;
          }
        })
      );
  }
}
