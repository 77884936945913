import { Mattertag } from '@simlab/matterport/api';
import { Observable } from 'rxjs';

import { Vector3 } from 'three';
import { StartPlacingConfig, TagNote, TagNoteTypes } from './dto';
import { MatterportComponent } from './matterport-tag-component.type';

export interface IMattertags {
  readonly noteClicked$: Observable<string>;
  readonly tagPlacementAccepted$: Observable<{
    tagNote: TagNote;
    comp: MatterportComponent | undefined;
  }>;
  removeNote$: (noteId: string) => Observable<void | string[]>;

  addNote$: (note: TagNote) => Observable<void>;
  addNoteWithOffset$: (
    noteId: string,
    markerPosition: Vector3,
    markerType: TagNoteTypes
  ) => Observable<void>;
  editIcon: (noteId: string, noteType: TagNoteTypes) => Observable<void>;
  clearAllTagNotes: () => void;
  abandonPlacingComponent: () => void;
  startPlacingComponent$: (
    placingConfig: StartPlacingConfig
  ) => Observable<void>;
  loadMattertags$: (tags: Mattertag[]) => Observable<string[]>;
}
export enum ViewMode {
  INSIDE = 'mode.inside',
  OUTSIDE = 'mode.outside',
  DOLLHOUSE = 'mode.dollhouse',
  FLOORPLAN = 'mode.floorplan',
  TRANSITIONING = 'mode.transitioning',
}
