import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Update } from '@ngrx/entity';
import {
  ApiFacadeService,
  ContextType,
  OrganizationResponse,
  Organizations
} from '@simlab/data-access';
import { ToastService } from '@simlab/design/toast';
import { prepareFormData } from '@simlab/util-shared';
import {
  catchError,
  concat,
  exhaustMap,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import * as OrganizationsActions from './organizations.actions';
import { OrganizationApiActions } from './organizations.actions';
import { OrganizationsFacade } from './organizations.facade';

const NEW_OWNER_REQUEST_MAIL = $localize`:@@NEW_OWNER_REQUEST_MAIL:An email requesting ownership transfer has been sent.`;

@Injectable()
export class OrganizationsEffects {
  private readonly _actions$ = inject(Actions);
  private readonly _apiFacadeService = inject(ApiFacadeService);
  private readonly _organizationsFacade = inject(OrganizationsFacade);
  private readonly _toastService = inject(ToastService);
  private readonly _router = inject(Router);
  init$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationsActions.init),
      switchMap(() => {
        return this._apiFacadeService.organizations.getOrganizations$();
      }),
      map((result: Organizations[]) => {
        //NOTE: discard organizations without name set
        return OrganizationApiActions.loadOrganizationsSuccess({
          organizations: result.filter((item: Organizations) => item.name)
        });
      }),
      catchError((error) =>
        of(OrganizationApiActions.loadOrganizationsFailure({ error }))
      )
    )
  );

  organizationLoaded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType(OrganizationApiActions.loadOrganizationsSuccess),
        tap((action: { organizations: Organizations[] }) => {
          const organizationIdFromRoute = this._getOrganizationIdFromUrl();
          if (organizationIdFromRoute && organizationIdFromRoute !== 'blank') {
            this._organizationsFacade.selectOrganization(
              organizationIdFromRoute
            );
          } else if (action.organizations.length > 0) {
            const firstActiveOrganization = action.organizations.find(
              (organization: Organizations) =>
                organization.invitationPending !== true
            );
            if (firstActiveOrganization !== undefined)
              this._organizationsFacade.selectOrganization(
                firstActiveOrganization.id
              );
          }
        })
      ),
    { dispatch: false }
  );

  acceptInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.acceptInvitation),
      mergeMap(({ invitationId, organizationId }) =>
        this._apiFacadeService.invitation
          .acceptInvitation({ invitationId })
          .pipe(
            mergeMap(() =>
              concat([
                OrganizationApiActions.acceptInvitationSuccess({
                  organizationId
                }),
                OrganizationsActions.selectOrganization({ id: organizationId })
              ])
            ),
            catchError((error) =>
              of(
                OrganizationApiActions.acceptInvitationFailure({
                  error,
                  organizationId
                })
              )
            )
          )
      )
    )
  );

  rejectInvitation$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.rejectInvitation),
      switchMap(({ id, organizationId }) =>
        this._apiFacadeService.invitation
          .rejectInvitation({
            invitationId: id
          })
          .pipe(
            map(() => {
              if (organizationId) {
                return OrganizationApiActions.rejectInvitationSuccess({
                  id: organizationId
                });
              } else {
                throw new Error('Cannot find selected organization id');
              }
            }),
            catchError((error) => {
              return of(
                OrganizationApiActions.rejectInvitationFailure({
                  error,
                  organizationId
                })
              );
            })
          )
      )
    )
  );

  create$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.createOrganization),
      switchMap(({ name }) => {
        return this._apiFacadeService.organizations.createOrganization$({
          name
        });
      }),
      map((result: Organizations) => {
        return OrganizationApiActions.createOrganizationSuccess({
          id: result.id,
          name: result.name,
          invitationPending: result.invitationPending
        });
      }),
      catchError((error) =>
        of(OrganizationApiActions.createOrganizationFailure({ error }))
      )
    )
  );

  createOrganizationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.createOrganizationSuccess),
      map((result) => {
        return OrganizationsActions.selectOrganization({ id: result.id });
      }),
      tap((result) => {
        this._router.navigate([`/organizations/${result.id}/overview`]);
      }),
      catchError((error) =>
        of(OrganizationApiActions.createOrganizationFailure({ error }))
      )
    )
  );

  editOrganizationThumbnail$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.editOrganizationThumbnail),
      switchMap((action) =>
        this._apiFacadeService.blobs
          .uploadImagesToBlob(
            prepareFormData(
              action.thumbnail,
              action.organizationId,
              ContextType.Organization
            )
          )
          .pipe(
            switchMap((result: string) => {
              return this._apiFacadeService.organizations
                .editOrganizationThumbnail$({
                  organizationId: action.organizationId,
                  thumbnailUrl: result
                })
                .pipe(
                  map((url: string) => {
                    return OrganizationApiActions.editOrganizationThumbnailSuccess(
                      {
                        organizationId: action.organizationId,
                        thumbnailUrl: url
                      }
                    );
                  })
                );
            })
          )
      ),
      catchError((error) =>
        of(OrganizationApiActions.editOrganizationThumbnailFailure({ error }))
      )
    )
  );

  delete$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.deleteOrganization),
      switchMap((action: { organizationId: string }) => {
        return this._apiFacadeService.organizations.deleteOrganization$({
          organizationId: action.organizationId
        });
      }),
      map((result: OrganizationResponse) => {
        return OrganizationApiActions.deleteOrganizationSuccess({
          organizationId: result.organizationId,
          name: result.name
        });
      }),
      catchError((error) =>
        of(OrganizationApiActions.deleteOrganizationFailure({ error }))
      )
    )
  );

  deleteSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.deleteOrganizationSuccess),
      switchMap(() => this._organizationsFacade.selectedFirstId$),
      map((organizationId: string | number | undefined) => {
        if (organizationId) {
          return OrganizationsActions.selectOrganization({
            id: organizationId.toString()
          });
        } else {
          this._router.navigateByUrl('/organizations');
          return OrganizationsActions.selectOrganization({
            id: undefined
          });
        }
      }),
      catchError((error) =>
        of(OrganizationApiActions.deleteOrganizationFailure({ error }))
      )
    )
  );

  editName$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.editOrganization),
      switchMap((action: { organizationId: string; name: string }) => {
        return this._apiFacadeService.organizations
          .editOrganizationName$({
            organizationId: action.organizationId,
            name: action.name
          })
          .pipe(map(() => action));
      }),
      map((action: { organizationId: string; name: string }) => {
        const update: Update<{ organizationId: string; name: string }> = {
          changes: {
            name: action.name
          },
          id: action.organizationId
        };
        return OrganizationApiActions.editOrganizationSuccess({ update });
      }),
      catchError((error) =>
        of(OrganizationApiActions.editOrganizationFailure({ error }))
      )
    )
  );

  editDescription$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.editOrganizationDescription),
      switchMap((action) => {
        return this._apiFacadeService.organizations
          .editOrganizationDescription$({
            organizationId: action.organizationId,
            description: action.description
          })
          .pipe(map(() => action));
      }),
      map((action) => {
        const update: Update<{ organizationId: string; description: string }> =
          {
            changes: {
              description: action.description
            },
            id: action.organizationId
          };
        return OrganizationApiActions.editOrganizationDescriptionSuccess({
          update
        });
      }),
      catchError((error) =>
        of(OrganizationApiActions.editOrganizationDescriptionFailure({ error }))
      )
    )
  );

  leave$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.leaveOrganization),
      switchMap((action: { organizationId: string }) => {
        return this._apiFacadeService.organizations
          .leaveOrganization$({
            organizationId: action.organizationId
          })
          .pipe(map(() => action.organizationId));
      }),
      map((result: string) => {
        return OrganizationApiActions.leaveOrganizationSuccess({
          organizationId: result
        });
      }),
      catchError((error) =>
        of(OrganizationApiActions.leaveOrganizationFailure({ error }))
      )
    )
  );

  leaveSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.leaveOrganizationSuccess),
      take(1),
      switchMap(() =>
        this._organizationsFacade.allOrganizations$.pipe(
          tap((organization: Organizations[]) => {
            if (organization.length === 0) {
              this._router.navigateByUrl('/organizations/blank');
            }
          })
        )
      ),
      filter((organization: Organizations[]) => organization.length > 1),
      switchMap(() => this._organizationsFacade.selectedFirstId$),
      map((organizationId: string | number | undefined) => {
        if (organizationId) {
          return OrganizationsActions.selectOrganization({
            id: organizationId.toString()
          });
        } else {
          throw new Error('Cannot find selected organization id');
        }
      }),
      catchError((error) =>
        of(OrganizationApiActions.leaveOrganizationFailure({ error }))
      )
    )
  );

  transferOrganization$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.transferOrganization),
      exhaustMap((payload) =>
        this._apiFacadeService.organizations
          .transferOrganization$({
            organizationId: payload.organizationId,
            newOwnerId: payload.newOwnerId
          })
          .pipe(map(() => OrganizationApiActions.transferSuccess()))
      ),

      catchError((error) => of(OrganizationApiActions.transferFailure()))
    )
  );

  transferOrganizationTYpeSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(
        OrganizationsActions.confirmTransferOrganizationSuccess,
        OrganizationApiActions.rejectTransferOrganizationSuccess,
        OrganizationApiActions.cancelTransferOrganizationSuccess
      ),
      map(() => OrganizationsActions.init())
    )
  );

  transferOrganizationSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.transferSuccess),
      map(() => {
        this._toastService.open(NEW_OWNER_REQUEST_MAIL, 'Success');
        return OrganizationsActions.init();
      })
    )
  );

  rejectTransferOrganization$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.rejectTransferOrganization),
      exhaustMap((payload) =>
        this._apiFacadeService.organizations.rejectTransferOrganization$({
          organizationId: payload.organizationId
        })
      ),
      map(() => OrganizationApiActions.rejectTransferOrganizationSuccess()),
      catchError((error) =>
        of(OrganizationApiActions.rejectTransferOrganizationFailure())
      )
    )
  );

  acceptTransferOrganization$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.acceptTransferOrganization),
      exhaustMap((payload) =>
        this._apiFacadeService.organizations.acceptTransferOrganization$({
          organizationId: payload.organizationId
        })
      ),
      map(() => OrganizationApiActions.acceptTransferOrganizationSuccess()),
      catchError((error) =>
        of(OrganizationApiActions.acceptTransferOrganizationFailure())
      )
    )
  );

  cancelTransferOrganization$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OrganizationApiActions.cancelTransferOrganization),
      exhaustMap((payload) =>
        this._apiFacadeService.organizations.cancelTransferOrganization$({
          organizationId: payload.organizationId
        })
      ),
      map(() => OrganizationApiActions.cancelTransferOrganizationSuccess()),
      catchError((error) =>
        of(OrganizationApiActions.cancelTransferOrganizationFailure())
      )
    )
  );

  private _getOrganizationIdFromUrl(): string {
    if (window.location.href.includes('organizations/')) {
      const _organizationId = window.location.href.split('/')[5];
      return _organizationId;
    }
    return '';
  }
}
