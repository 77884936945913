import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  DesignFlatButtonModule,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';

@Component({
  selector: 'simlab-open-new-tab-safari',
  standalone: true,
  imports: [
    DesignDialogWrapperModule,
    DesignFlatButtonModule,
    DesignStrokedButton,
    DesignIcon
  ],
  templateUrl: './open-new-tab-safari.component.html',
  styleUrl: './open-new-tab-safari.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenNewTabSafariComponent {
  public readonly modalData: string = inject(DIALOG_DATA);
  private readonly modalRef = inject(DialogRef<Window | null>);

  close() {
    this.modalRef.close();
  }
  open() {
    const ref = window.open(this.modalData, 'OAuth', 'width=800,height=800');
    this.modalRef.close(ref);
  }
}
