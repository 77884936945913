import { OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { DialogResponse } from './dialog-response';

export type ConfirmationResult = 'decline' | 'accept';

export class ConfirmationModalRef<T> {
  private readonly _eventsSource = new BehaviorSubject<
    DialogResponse<T> | undefined
  >(undefined);
  private readonly _eventsObservableSource = this._eventsSource.asObservable();

  get events$(): Observable<DialogResponse<T>> {
    return this._eventsObservableSource.pipe(
      filter((event: DialogResponse<T> | undefined) => !!event),
      map((event: DialogResponse<T> | undefined) => event as DialogResponse<T>)
    );
  }

  constructor(private overlayRef: OverlayRef) {}

  close(): void {
    this.overlayRef.dispose();
  }

  emit(action: DialogResponse<T> | undefined): void {
    this._eventsSource.next(action);
  }
}
