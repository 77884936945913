import { coerceCssPixelValue } from '@angular/cdk/coercion';
import {
  ComponentType,
  Overlay,
  OverlayConfig,
  OverlayRef
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector, StaticProvider } from '@angular/core';
import { ConfirmationModalRef } from '../models/confirmation-modal-ref';
import { ModalConfig } from '../models/modal-config';
import { ModalRef } from '../models/modal-ref';
import { MODAL_DATA } from '../tokens/modal-data.token';

@Injectable()
export class ModalService {
  constructor(private readonly overlay: Overlay) {}
  createModal<T, R, W>(
    component: ComponentType<T>,
    config: ModalConfig,
    data?: W
  ): ConfirmationModalRef<R> {
    const overlayRef = this.createOverlay(config);
    const confirmationModalRef = new ConfirmationModalRef<R>(overlayRef);
    const injector = Injector.create({
      providers: [
        { provide: ConfirmationModalRef, useValue: confirmationModalRef },
        { provide: MODAL_DATA, useValue: data }
      ]
    });

    const userProfilePortal = new ComponentPortal(component, null, injector);
    overlayRef.attach(userProfilePortal);

    return confirmationModalRef;
  }

  createModalWithProviders<T, R>(
    component: ComponentType<T>,
    config: ModalConfig,
    staticProviders: StaticProvider[] = []
  ): ConfirmationModalRef<R> {
    const overlayRef = this.createOverlay(config);
    const confirmationModalRef = new ConfirmationModalRef<R>(overlayRef);
    const injector = Injector.create({
      providers: [
        { provide: ConfirmationModalRef, useValue: confirmationModalRef },
        ...staticProviders
      ]
    });

    const userProfilePortal = new ComponentPortal(component, null, injector);
    overlayRef.attach(userProfilePortal);

    return confirmationModalRef;
  }

  create<T>(component: ComponentType<T>, config: ModalConfig): ModalRef<T> {
    const overlayRef = this.createOverlay(config);

    const userProfilePortal = new ComponentPortal(component);
    return {
      overlayRef: overlayRef,
      componentRef: overlayRef.attach(userProfilePortal)
    };
  }

  private createOverlay(config: ModalConfig): OverlayRef {
    const overlayConfig = this.createConfig(config);

    return this.overlay.create(overlayConfig);
  }

  private createConfig(config: ModalConfig): OverlayConfig {
    const positionStrategy = this.overlay.position().global();

    if (config.centered || config.centered === undefined) {
      positionStrategy
        .centerHorizontally(
          coerceCssPixelValue(config.offset ? config.offset.x : 0)
        )
        .centerVertically(
          coerceCssPixelValue(config.offset ? config.offset.y : 0)
        );
    }

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop ?? true,
      backdropClass: 'ui-overlay-backdrop',
      panelClass: config.panelClass ?? 'ui-overlay-panel',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: positionStrategy,
      maxHeight: config.maxHeight ?? 'min(100%,100svh)',
      maxWidth: config.maxWidth ?? 'min(100%,100vw)',
      minWidth: config.minWidth ?? 'initial',
      height: config.height ?? 'auto',
      width: config.width ?? 'auto'
    });
  }
}
