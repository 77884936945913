import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Environment } from '@simlab/util-shared';
//TODO: deal with this thing somehow :(

@NgModule({
  imports: [EffectsModule.forRoot([])]
})
export class StoreConfigModule {
  static forRoot(
    environment: Environment
  ): ModuleWithProviders<StoreConfigModule> {
    return {
      ngModule: StoreConfigModule,
      providers: [
        ...(!environment.production
          ? StoreDevtoolsModule.instrument({ connectInZone: true }).providers ||
            []
          : [])
      ]
    };
  }
}
