import {
  animate,
  AnimationTriggerMetadata,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const designRadioExpansionPanelAnimations: {
  readonly bodyExpansion: AnimationTriggerMetadata;
} = {
  /** Animation that expands and collapses the panel content. */
  bodyExpansion: trigger('bodyExpansion', [
    state('collapsed, void', style({ height: '0px', visibility: 'hidden' })),
    state('expanded', style({ height: '*', visibility: 'visible' })),
    transition(
      'expanded <=> collapsed, void => collapsed',
      // NOTE: Keep this in sync with the Sass variable for the panel header animation.
      animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
    ),
  ]),
};
