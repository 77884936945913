import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'covertByte'
})
export class CovertByte implements PipeTransform {
  transform(bytes: number | string) {
    const value = Number(bytes);
    if (!+value) return '0 B';
    const decimals = 1;
    const unitStep = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const unitIndex = Math.floor(Math.log(value) / Math.log(unitStep));

    return `${parseFloat((value / Math.pow(unitStep, unitIndex)).toFixed(dm))} ${sizes[unitIndex]}`;
  }
}
