import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileLoaderCacheService } from '@simlab/simlab-facility-management/common';
import { MatterportIframeComponent } from './components/matterport-iframe/matterport-iframe.component';
import { ByPassSecurityPipe } from './pipes/bypass-security.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [MatterportIframeComponent],
  providers: [FileLoaderCacheService],
  declarations: [MatterportIframeComponent, ByPassSecurityPipe],
})
export class MatterportSdkBundleModule {}
