import { ICONS } from "@simlab/matterport";
import { PunchItemList, RFIListItem, RFIStatus } from "@simlab/procore/models";

export type PunchItemWithMarker = Pick<PunchItemList, 'id' | 'procoreId' | 'name' | 'workflowStatus' | 'marker' | 'showYellowBackground'>; 

export type PunchItemsWithMarkers = {
  responseGeneratedOn: Date | string,
  items: PunchItemWithMarker[]
}

export type RFIItemWithMarker = Pick<RFIListItem, 'procoreId' | 'id' | 'number' | 'status' | 'marker'>;

export type RFIWithMarkers = {
  responseGeneratedOn: Date | string;
  items: RFIItemWithMarker[];
}

export const rfiMatterportIcons: Record<RFIStatus, string> = {
  [RFIStatus.Closed]: ICONS['RFI_CLOSED'],
  [RFIStatus.Draft]: ICONS['RFI_DRAFT'],
  [RFIStatus.Open]: ICONS['RFI_DRAFT'],
}

export type AnnotationModules = 'notes' | 'punchList' | 'RFI';
