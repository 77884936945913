import { Dialog } from '@angular/cdk/dialog';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiFacadeService, EulaUrls } from '@simlab/data-access';
import { OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  firstValueFrom,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap
} from 'rxjs';
import {
  EulaDialogComponent,
  TUserConsents
} from '../components/eula-dialog/eula-dialog.component';

@Injectable()
export class CheckUserService {
  private readonly _apiFacadeService = inject(ApiFacadeService);
  private readonly _oidcSecurityService = inject(OidcSecurityService);
  private readonly _dialog = inject(Dialog);
  private readonly _router = inject(Router);
  private readonly _route = inject(ActivatedRoute);
  private readonly _validSource = new BehaviorSubject<boolean>(false);
  private readonly _validSource$ = this._validSource.asObservable();

  check$(state: boolean): Observable<boolean> {
    return this._validSource$.pipe(
      switchMap((hasEulaAccepted: boolean) =>
        iif(
          () => hasEulaAccepted,
          of(true),
          of(false).pipe(
            mergeMap(() =>
              iif(
                () => state,
                of(true).pipe(mergeMap(() => this._hasProfile$())),
                of(false)
              )
            )
          )
        )
      )
    );
  }

  private _hasProfile$(): Observable<boolean> {
    return this._apiFacadeService.userProfiles.doesUserProfileExist().pipe(
      mergeMap((hasProfile: boolean) =>
        iif(
          () => hasProfile,
          of(true).pipe(mergeMap(() => this._hasEula$())),
          of(false).pipe(
            mergeMap(() =>
              this._createProfile$().pipe(
                mergeMap((created: boolean) =>
                  iif(
                    () => created,
                    of(true).pipe(mergeMap(() => this._hasEula$(true))),
                    of(false)
                  )
                )
              )
            )
          )
        )
      ),
      catchError(() => of(false))
    );
  }

  private _newUser() {
    firstValueFrom(
      this._router.events.pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(async (e) => {
          await this._router.navigate(['/organizations'], {
            relativeTo: this._route,
            state: { newUser: true }
          });
        })
      )
    );
  }

  private _createProfile$(): Observable<boolean> {
    return this._oidcSecurityService.userData$.pipe(
      mergeMap((userData: UserDataResult) => {
        const user = userData.userData;
        return this._apiFacadeService.userProfiles
          .createUserProfile({ userName: user.name, userEmail: user.email })
          .pipe(map(() => true));
      }),
      catchError(() => of(false))
    );
  }

  private _hasEula$(newUser = false): Observable<boolean> {
    return this._apiFacadeService.userProfiles.hasUserAcceptedEula().pipe(
      mergeMap((hasEula: boolean) => {
        if (!hasEula) {
          return this._apiFacadeService.userProfiles.getEula$().pipe(
            mergeMap((value: EulaUrls) =>
              this._openModal$(value).pipe(
                mergeMap((res) =>
                  this._apiFacadeService.userProfiles
                    .acceptEula$({
                      eulaUrl: value.eulaUrl,
                      marketingEmailsPermission: res.marketingEmailsPermission,
                      processingPersonalDataPermission:
                        res.processingPersonalDataPermission
                    })
                    .pipe(map(() => true))
                )
              )
            )
          );
        }

        return of(true);
      }),

      tap((result: boolean) => {
        if (result && newUser) this._newUser();
        this._validSource.next(result);
      }),
      catchError(() => of(false))
    );
  }

  private _openModal$(data: EulaUrls): Observable<TUserConsents> {
    const dialogRef = this._dialog.open<
      TUserConsents,
      EulaUrls,
      EulaDialogComponent
    >(EulaDialogComponent, {
      disableClose: true,
      data
    });

    return dialogRef.closed.pipe(
      map((result) => {
        if (!result) {
          throw new Error(`Something went wrong during Eula's acceptance!`);
        }
        return result;
      }),
      take(1)
    );
  }
}
