import { inject, Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Params,
  QueryParamsHandling,
  Router,
} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { routerSelectors, RouterStoreParams } from '../+state/router.selectors';

@Injectable({
  providedIn: 'root',
})
export class RouterFacadeService {
      private readonly store = inject(Store);
  private readonly _router: Router = inject(Router);
  private readonly _route: ActivatedRoute = inject(ActivatedRoute);
  readonly getRouteNestedParams$: Observable<RouterStoreParams> =
    this.store.pipe(
      select(routerSelectors.getRouteNestedParams)
      // distinctUntilChanged(RouterHelpers.distinctUntilChangedComparator)
    );

  readonly getRoute$: Observable<string> = this.store.pipe(
    select(routerSelectors.getRoute)
    // distinctUntilChanged(RouterHelpers.distinctUntilChangedComparator)
  );

  readonly getNthRouterParam$ = (
    n: number
  ): Observable<ActivatedRouteSnapshot | null> =>
    this.store.pipe(select(routerSelectors.getNthNestedParam(n)));

  setQueryParams(
    navigation: string | null = null,
    queryParams: Params,
    queryParamsHandling?: QueryParamsHandling | null | undefined,
    activatedRoute?: ActivatedRoute
  ) {
    const navigationUrl = navigation ? [navigation] : [];
    this._router.navigate(navigationUrl, {
      relativeTo: activatedRoute || this._route,
      queryParams,
      queryParamsHandling,
    });
  }
}
