<div class="container-header">
  <ui-image-info class="logo" imageSrc="icon_logo_simlab" [maxWidth]="140">
  </ui-image-info>

  <h1 class="container-header--title">End-User License Agreement</h1>
</div>
<div class="container-body" [innerHTML]="eulaText()"></div>
<div class="container-action">
  <div class="container-action__checkbox-sections">
    <design-checkbox
      #eulaCheckBox
      (change)="eulaAccepted = eulaCheckBox.checked"
    >
      <b>
        I accept End User Licence Agreement
        <span class="container-action__checkbox-sections--required">
          * (Required)
        </span>
      </b>
    </design-checkbox>
    <design-checkbox
      #processingDataCheckBox
      (change)="processingData = processingDataCheckBox.checked"
    >
      I accept the processing of my Personal Data for
      <a [href]="eulaUrl.processingPersonalDataUrl" target="_blank">
        Marketing Purposes.</a
      >
    </design-checkbox>
    <design-checkbox
      #communicationByEmailCheckBox
      (change)="communicationByEmail = communicationByEmailCheckBox.checked"
    >
      I consent to
      <a [href]="eulaUrl.marketingEmailsUrl" target="_blank">
        Receive Marketing Communications via Email.</a
      >
    </design-checkbox>
  </div>

  <button
    design-flat-button
    designButtonColor="accent"
    (click)="handleConfirm()"
    [disabled]="eulaText() === null || !eulaAccepted"
  >
    Accept
  </button>
</div>
