import {
  AlwaysStencilFunc,
  BufferGeometry,
  Camera,
  Euler,
  Line,
  Material,
  MathUtils,
  Mesh,
  Object3D,
  Quaternion,
  Scene,
  Vector3,
} from 'three';
import { easeOutSine } from './helpers/math-utils';
import {
  disposeHierarchy,
  disposeNode,
  getElementPosition,
} from './helpers/three.helpres';
import {
  IAbstractConstructor,
  Inputs,
  Transformation,
} from './types/custom-component.type';

const MAX_GROWTH_CAMERA_DISTANCE = 15;

// eslint-disable-next-line @typescript-eslint/ban-types
export function mixinCustomComponent<T extends IAbstractConstructor<{}>>(
  base: T
) {
  abstract class CustomComponent extends base {
    inputs: Required<Inputs> = {
      id: '',
      visible: true,
      scale: new Vector3(1, 1, 1),
      renderOrder: 4,
      isCollider: true,
      position: new Vector3(0, 0, 0),
      rotation: new Quaternion(0, 0, 0, 1),
      normal: new Vector3(0, 1, 0),
      stemHeight: 0,
      opacity: 1,
      autoScale: true,
      userData: null,
      depthTest: true,
      transparent: false,
      lookAt: false,
      dollhouseView: true,
    };

    _localAutoScale: boolean | undefined = false;
    abstract _group: import('three').Group;
    _vector!: Vector3;
    _stem?: Line;
    _initRotation: Euler | undefined;
    _isBlueprint = false;

    onInit() {
      this.opacity = this.inputs.opacity;
      const { x, y, z } = this.inputs.position;
      this.position = new Vector3(x, y, z);
      const { x: rx, y: ry, z: rz, w: wz } = this.inputs.rotation;
      this.rotation = new Quaternion(rx, ry, rz, wz);
      this.scale = this.inputs.scale;
      this._localAutoScale = this.inputs.autoScale;
      this._group.name = this.inputs.id;
      this.renderOrder = this.inputs.renderOrder;
      this._group.layers.set(3);
      this._group.visible = this.inputs.visible;
    }
    drawStem() {
      if (!this._stem && this.inputs.stemHeight > 0) {
        this.createStemObject();
      }
      const { x, y, z } = this.inputs.position;
      const points = [this.position, new Vector3(x, y, z)];
      this._stem?.geometry.setFromPoints(points);
    }

    // abstract get lineBasicMaterial(): typeof import('three').LineBasicMaterial;
    // abstract get bufferGeometry(): typeof import('three').BufferGeometry;
    // abstract get line(): typeof import('three').Line;
    abstract lineBasicMaterial: typeof import('three').LineBasicMaterial;
    abstract bufferGeometry: typeof import('three').BufferGeometry;
    abstract line: typeof import('three').Line;
    // abstract get three(): typeof import('three');

    createStemObject(): void {
      if (!this._group.parent) return;
      const material = new this.lineBasicMaterial({ color: 'white' });
      material.stencilWrite = true;
      material.stencilFunc = AlwaysStencilFunc;
      material.depthWrite = false;
      const points = [new Vector3(0, 0, 0), new Vector3(0, 0, 0)];
      const geometry = new this.bufferGeometry().setFromPoints(points);
      this._stem = new this.line(geometry, material);
      this._stem.renderOrder = 999;
      this._group.parent?.add(this._stem);
    }

    set rotation(rotation: Quaternion) {
      if (this._group) {
        const { x, y, z } = new Euler().setFromQuaternion(rotation);
        this._group.rotation.set(x, y, z);
      }
    }

    get rotation() {
      return new Quaternion().setFromEuler(this._group.rotation);
    }
    set position(position: Vector3) {
      const normal = new Vector3(
        this.inputs.normal.x,
        this.inputs.normal.y,
        this.inputs.normal.z
      );
      const { x, y, z } = getElementPosition(
        position as Vector3,
        normal,
        this.inputs.stemHeight
      );
      const { x: gx, y: gy, z: gz } = this._group.position;
      this._initRotation = this._group.rotation.clone();
      if (x !== gx || y !== gy || z !== gz) {
        this._group.position.set(x, y, z);
        this.inputs.position = new Vector3(position.x, position.y, position.z);
        //TODO test
        this._lookAt(this.inputs.position);
        this.rotateYByPi();
        this.drawStem();
      }
    }

    get position() {
      const { x, y, z } = this._group.position;
      return new Vector3(x, y, z);
    }

    set normal(normal: Vector3) {
      this.inputs.normal = normal;
    }
    get normal() {
      return new Vector3(
        this.inputs.normal.x,
        this.inputs.normal.y,
        this.inputs.normal.z
      );
    }

    set lookAt(lookAt: boolean) {
      this.inputs.lookAt = lookAt;
    }
    transformation(transformation: Transformation) {
      const { x: nx, y: ny, z: nz } = transformation.normal;
      if (
        nx !== this.inputs.normal.x ||
        ny !== this.inputs.normal.y ||
        nz !== this.inputs.normal.z
      ) {
        this.inputs.normal = transformation.normal;
      }
      if (transformation.stemHeight !== this.inputs.stemHeight) {
        this.inputs.stemHeight = transformation.stemHeight;
      }
      const { x, y, z } = transformation.position;
      this.position = new Vector3(x, y, z);
    }

    set layer(layer: number) {
      this._group.layers.set(layer);
    }

    abstract get scene(): Scene;
    hide() {
      if (this._group.visible) {
        this._group.visible = false;
        if (this.inputs.stemHeight > 0 && this._stem) {
          this._stem.visible = false;
        }
      }
    }

    show() {
      if (!this._group.visible) {
        this._group.visible = true;
        if (this.inputs.stemHeight > 0 && this._stem) {
          this._stem.visible = true;
        }
      }
    }
    addChild(object: Object3D, blueprint = false) {
      Array.isArray(object)
        ? this._group.add(...object)
        : this._group.add(object);
      this.opacity = this.inputs.opacity;
      this._isBlueprint = blueprint;
    }

    getChild(index: number) {
      return this._group.children[index];
    }

    set renderOrder(renderOrder: number) {
      this._group.renderOrder = renderOrder;
    }

    set opacity(opacity: number) {
      this._group.children.forEach((childObj) => {
        const child =
          'isMesh' in childObj
            ? (childObj as Mesh<BufferGeometry, Material>)
            : false;
        if (!child) return;
        child.material.opacity = opacity;
        if (opacity < 1 && !this.inputs.depthTest) {
          child.material.depthTest = false;
          child.material.depthWrite = false;
          child.material.transparent = true;
        } else {
          child.material.depthTest = true;
          child.material.depthWrite = true;
          child.material.transparent = false;
        }
      });
    }

    abstract get camera(): Camera;
    abstract get cameraContainer(): Camera;

    rotateYByPi() {
      this._group.rotateY(Math.PI);
    }
    abstract _lookAt(vector: Vector3): void;

    set scale(scale: Vector3) {
      const { x, y, z } = scale;
      this._group.scale.set(x, y, z);
    }

    setScale() {
      const distance = this.cameraContainer.position
        .clone()
        .distanceTo(this._group.position);

      const clampedDistance = MathUtils.clamp(
        distance,
        0,
        MAX_GROWTH_CAMERA_DISTANCE
      );
      const percentageValueOfDistance =
        ((clampedDistance * 100) / MAX_GROWTH_CAMERA_DISTANCE) * 0.01;
      const lerped = MathUtils.lerp(0.06, 0.3, percentageValueOfDistance);
      const scale = easeOutSine(lerped);
      this._group.scale.set(scale, scale, 1);
    }

    animationFrame() {
      if (this.inputs.autoScale) {
        this.setScale();
      } else {
        const { x, y, z } = this.inputs.scale;
        this._group.scale.set(x, y, z);
      }
      if (!this._isBlueprint) {
        if (this.inputs.lookAt) {
          this._lookAt(this.cameraContainer.position);
          this._localAutoScale === undefined &&
            (this._localAutoScale = this.inputs.autoScale);
          this.inputs.autoScale = true;
        } else {
          this._lookAt(this.inputs.position);
          this.rotateYByPi();
          this.inputs.autoScale = this._localAutoScale || false;
          this._localAutoScale = undefined;
        }
      }
    }

    isVectorValid(vector: Vector3) {
      return (
        vector.x !== null &&
        vector.x !== undefined &&
        vector.y !== null &&
        vector.y !== undefined &&
        vector.z !== null &&
        vector.z !== undefined
      );
    }
    destroy() {
      if (this._stem) {
        (this._stem.material as Material).dispose();
        this._stem.geometry.dispose();
        this.scene.remove(this._stem);
      }
      this._group.children.forEach((childObj: Object3D) => {
        disposeHierarchy(childObj, disposeNode);
        this._group.remove(childObj);
      });
      this.scene.remove(this._group);
    }
  }
  return CustomComponent;
}
