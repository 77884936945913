import { TagNoteTypes } from './dto';
export type TagNoteKeys = keyof typeof TagNoteTypes;

export const ICONS: Record<TagNoteKeys, string> = {
  INFO: '../../assets/icons/icon_note_info_matterport.svg',
  INPROGRESS: '../../assets/icons/icon_note_in_progress_matterport.svg',
  PENDING: '../../assets/icons/icon_note_pending_matterport.svg',
  RESOLVED: '../../assets/icons/icon_note_resolved_matterport.svg',
  UNRESOLVED: '../../assets/icons/icon_note_unresolved_matterport.svg',
  A1: '../../assets/icons/icon_1A.svg',
  A2: '../../assets/icons/icon_2A.svg',
  B1: '../../assets/icons/icon_1B.svg',
  B2: '../../assets/icons/icon_2B.svg',
  C1: '../../assets/icons/icon_1C.svg',
  C2: '../../assets/icons/icon_2C.svg',
  PIN: '../../assets/icons/pin.svg',
  PUNCH_DRAFT: '../../assets/icons/icon_punch_draft_status.svg',
  PUNCH_CLOSED: '../../assets/icons/icon_punch_closed_status.svg',
  RFI_DRAFT: '../../assets/icons/icon_rfi_open_type.svg',
  RFI_OPEN: '../../assets/icons/icon_rfi_open_type.svg',
  RFI_CLOSED: '../../assets/icons/icon_rfi_closed_type.svg'
};
