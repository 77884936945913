import { OrganizationLevel } from '@simlab/data-access';
import { ProjectObjectCounts } from './project-object-counts';
import { SasToken } from './sas-token';
import { ICreationInfo } from './stages-base.interace';

export interface Project extends ICreationInfo {
  id: string;
  name: string;
  description: string;
  creatorId: string;
  lastEditorId: string;
  isOwner: boolean;
  markedAsFavourite: boolean; //NOTE: (david) misspelled
  projectThumbnailUrl: string;
  projectThumbnailUrlWithSas: SasToken;
  projectObjectsCounts: ProjectObjectCounts;
  organizationName: string;
  place?: ProjectAddress;
  isActive: boolean;
  procoreConnectionStatus: ProcoreConnectionStatusEnum;
  procoreInfo?: ProcoreProjectInfo;
}

export interface ProjectAddress {
  address: string;
  latitude: number;
  longitude: number;
}

export interface GenerateProjectReportPayload {
  projectId: string;
  stageDateFrom?: string;
  stageDateTo?: string;
  stagesIds?: Array<string>;
}

export interface ProjectPermissions {
  permissions: number;
  hasAdminRoleInProject: boolean;
  organizationPermissionsLevel: OrganizationLevel;
}

export type ProcoreConnectionStatusEnum =
  (typeof ProcoreConnectionStatusEnum)[keyof typeof ProcoreConnectionStatusEnum];
export const ProcoreConnectionStatusEnum = {
  NotConnected: 1,
  Connected: 2,
  Disconnected: 3,
  Disconnecting: 4, // local status (doesn't exists on BE)
  Reconnecting: 5 // local status (doesn't exists on BE)
} as const;

export interface ProcoreProjectInfo {
  companyId: number;
  projectId: number;
  companyName: string;
  projectName: string;
}
