import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AnotherOrganizationTeam } from '../../models/another-organization-team';
import { TeamCommand } from '../../models/command/team-command';
import { PaginationPage } from '../../models/organization-project';
import { OrganizationTeam } from '../../models/organization-team';
import { OrganizationTeamMember } from '../../models/organization-team-member';
import { OrganizationTeamProject } from '../../models/organization-team-project';
import { ProjectsSort } from '../../models/projects-sort';
import { TeamQuery } from '../../models/query/team-query';
import { TeamMember } from '../../models/team-member';

import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class TeamService implements TeamCommand, TeamQuery {
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);
  //QUERIES
  getPossibleTeamMembers$(payload: {
    teamId: string;
  }): Observable<TeamMember[]> {
    return this.httpClient.get<TeamMember[]>(
      `${this.url}/organizations-module/get-possible-team-members?teamId=${payload.teamId}`
    );
  }

  getSimpleOrganizationTeams$(payload: {
    organizationId: string;
  }): Observable<any> {
    throw new Error('Method not implemented.');
  }

  getOrganizationTeams$(payload: {
    organizationId: string;
    name?: string;
    sortBy?: ProjectsSort;
  }): Observable<PaginationPage<OrganizationTeam>> {
    const queryParameters: string[] = [];
    if (payload) {
      queryParameters.push(...[`organizationId=${payload.organizationId}`]);
      queryParameters.push(...this.sortBy(payload.sortBy));
      if (payload.name) {
        queryParameters.push(...[`TeamName=${payload.name}`]);
      }
    }
    return this.httpClient.get<PaginationPage<OrganizationTeam>>(
      `${
        this.url
      }/organizations-module/get-organization-teams?${queryParameters.join(
        '&'
      )}`
    );
  }
  getOrganizationTeam$(payload: {
    teamId: string;
  }): Observable<AnotherOrganizationTeam> {
    return this.httpClient.get<AnotherOrganizationTeam>(
      `${this.url}/organizations-module/get-organization-team?teamId=${payload.teamId}`
    );
  }

  getTeamName$(payload: { teamId: string }): Observable<string> {
    return this.httpClient
      .get<string>(
        `${this.url}/organizations-module/get-organization-team?teamId=${payload.teamId}`
      )
      .pipe(map((response: any) => response.teamName));
  }

  getTeamUsers$(payload: {
    teamId: string;
  }): Observable<OrganizationTeamMember[]> {
    return this.httpClient.get<OrganizationTeamMember[]>(
      `${this.url}/organizations-module/get-team-users?teamId=${payload.teamId}`
    );
  }
  getTeamProjects$(payload: {
    teamId: string;
  }): Observable<OrganizationTeamProject[]> {
    return this.httpClient.get<OrganizationTeamProject[]>(
      `${this.url}/organizations-module/get-team-projects?teamId=${payload.teamId}`
    );
  }

  //COMMANDS

  createTeam$(payload: {
    organizationId: string;
    teamTemplateId?: string | undefined;
    teamName: string;
  }): Observable<string> {
    return this.httpClient.post<string>(
      `${this.url}/organizations-module/create-team`,
      payload
    );
  }

  deleteTeam$(payload: { teamId: string }): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/organizations-module/delete-team?teamId=${payload.teamId}`
    );
  }

  changeTeamPermissions$(payload: {
    teamId: string;
    permissions: number;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/change-team-permissions`,
      payload
    );
  }

  changeTeamName$(payload: {
    teamId: string;
    teamName: string;
  }): Observable<any> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/change-team-name`,
      payload
    );
  }

  changeTeamDescription$(payload: {
    teamId: string;
    description: string;
  }): Observable<any> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/change-team-description`,
      payload
    );
  }

  addUsersToTeam$(payload: {
    teamId: string;
    usersIds: string[];
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/add-users-to-team`,
      payload
    );
  }

  removeUserFromTeam$(payload: {
    teamId: string;
    userId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/remove-user-from-team`,
      payload
    );
  }

  removeTeamFromProject$(payload: {
    projectId: string;
    teamId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/remove-team-from-project`,
      payload
    );
  }

  private sortBy(sortBy?: ProjectsSort): string[] {
    const buffer: string[] = [];

    if (sortBy?.type) {
      buffer.push(`SortColumn=${sortBy.type}`);
    }

    if (sortBy?.ascending) {
      buffer.push(`IsAscending=${sortBy.ascending}`);
    }

    return buffer;
  }
}
