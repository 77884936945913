/* eslint-disable @angular-eslint/no-host-metadata-property */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  Optional,
  QueryList,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  NgForm,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { ChipComponent } from '../chip/chip.component';

class ChipListBase {
  constructor(
    public _parentForm: NgForm,
    public _parentFormGroup: FormGroupDirective,
    public ngControl: NgControl
  ) {}
}
export class ChipListChange {
  constructor(public source: ChipListComponent, public value: any) {}
}

@Component({
  selector: 'ui-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  host: {
    '[attr.aria-orientation]': 'ariaOrientation',
    class: 'chip-list',
    '(focus)': 'focus()',
    '(blur)': '_blur()',
    '(keydown)': '_keydown($event)',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipListComponent
  extends ChipListBase
  implements ControlValueAccessor
{
  @ContentChildren(ChipComponent, {
    // We need to use `descendants: true`, because Ivy will no longer match
    // indirect descendants if it's left as false.
    descendants: true,
  })
  chips!: QueryList<ChipComponent>;

  /** Subject that emits when the component has been destroyed. */
  private readonly _destroyed: Subject<void> = new Subject<void>();
  private _label!: string;

  @Input()
  get multiple(): boolean {
    return this._multiple;
  }
  set multiple(value: BooleanInput) {
    this._multiple = coerceBooleanProperty(value);
    // this._syncChipsState();
  }
  private _multiple = false;

  @Input() set label(value: string) {
    this._label = value;
  }
  get label(): string {
    return this._label;
  }

  @Input()
  get value(): any {
    return this._value;
  }
  set value(value: any) {
    this.writeValue(value);
    this._value = value;
  }
  private _value: any;

  constructor(
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    @Optional() @Self() ngControl: NgControl
  ) {
    super(_parentForm, _parentFormGroup, ngControl);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  /** Function when touched */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onTouched = () => {};

  /** Function when changed */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _onChange: (value: any) => void = () => {};

  writeValue(value: any): void {
    if (this.chips) {
      // this._setSelectionByValue(value, false);
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }
}
