import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  Directive,
  inject,
  input,
  NgModule,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl, ReactiveFormsModule } from '@angular/forms';
import { DesignFormFieldControl } from '@simlab/design/form-field';
import { DpDatePickerModule } from 'ng2-date-picker';
import { derivedAsync } from 'ngxtension/derived-async';
import { EMPTY, map, startWith } from 'rxjs';
import { DateRangePickerBase } from './data-range-picker-base.directive';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { FormatDateSignalPipe } from '@simlab/design/format-date';

@Directive({
  selector: '[designStartDatepicker]',
  standalone: true,
})
export class DatePickerStartDirective {
  readonly control = inject(NgControl);
}

@Directive({
  selector: '[designEndDatepicker]',
  standalone: true,
})
export class DatePickerEndDirective {
  readonly control = inject(NgControl);
}

@Component({
  selector: 'design-date-range-picker',
  standalone: true,
  exportAs: 'designDateRangePicker',
  imports: [DpDatePickerModule, ReactiveFormsModule],
  templateUrl: './date-range-picker.component.html',
  styleUrl: './date-range-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'design-date-range-picker',
    role: 'group',
  },
  providers: [
    {
      provide: DesignFormFieldControl,
      useExisting: DateRangePickerComponent,
    },
    FormatDateSignalPipe,
  ],
})
export class DateRangePickerComponent extends DateRangePickerBase {
  private readonly _formatDate = inject(FormatDateSignalPipe);
  private readonly _inputRef =
    viewChild.required<HTMLInputElement>('designInputView');

  protected readonly startDatepickerValue = derivedAsync(
    () => {
      const startDatepicker = this.startDatepicker();

      return (
        startDatepicker.control.valueChanges?.pipe(
          startWith(startDatepicker.control.value),
          map((date) => date ?? ''),
        ) ?? EMPTY
      );
    },
    { initialValue: '' },
  );

  protected readonly endDatepickerValue = derivedAsync(
    () => {
      const endDatepicker = this.endDatepicker();

      return (
        endDatepicker.control.valueChanges?.pipe(
          startWith(endDatepicker.control.value),
          map((date) => date ?? ''),
        ) ?? EMPTY
      );
    },
    { initialValue: '' },
  );

  protected value = computed(() => {
    const [startDate, endDate] = [
      this.startDatepickerValue(),
      this.endDatepickerValue(),
    ];
    if (!startDate && !endDate) return '';

    return `${this._formatDate.transform(startDate)} - ${this._formatDate.transform(endDate)}`;
  });

  readonly placeholder = input<string>('');

  readonly startDatepicker = contentChild.required(DatePickerStartDirective);
  readonly endDatepicker = contentChild.required(DatePickerEndDirective);

  onContainerClick(): void {
    if (this._inputRef().focus) {
      this._inputRef().focus();
    }
  }
}

@NgModule({
  imports: [
    DateRangePickerComponent,
    DatePickerStartDirective,
    DatePickerEndDirective,
  ],
  exports: [
    DateRangePickerComponent,
    DatePickerStartDirective,
    DatePickerEndDirective,
  ],
})
export class DateRangePickerModule {}
