import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiHelperModule } from '../ui-helper/ui-helper.module';
import { FontComponent } from './components/font/font.component';
import { IconComponent } from './components/icon/icon.component';
import { IconCacheService } from './services/icon-cache.service';

@NgModule({
  declarations: [IconComponent, FontComponent],
  imports: [CommonModule, UiHelperModule],
  exports: [UiHelperModule, IconComponent, FontComponent],
  providers: [IconCacheService]
})
export class UiIconModule {}
