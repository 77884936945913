import {
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnDestroy,
} from '@angular/core';
import { Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { ButtonBase } from '../components/button-base.directive';
import { ButtonLoaderBase } from '../components/button-loader.component';

@Directive({
  selector: 'button[designFireOnClick], a[designFireOnClick]',
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['loading'],
  standalone: true,
})
export class FireOnClickDirective implements OnDestroy {
  private readonly hostElement = inject(ElementRef);
  private readonly buttonComponent = inject(ButtonBase, { self: true });
  private readonly _destroySource: Subject<void> = new Subject<void>();

  @HostBinding('class.design-loading-button')
  get designButtonLoading(): boolean {
    return this.buttonComponent.loading ?? false;
  }

  /**
   * Example:
   *
   * <button design-raised-button [designFireOnClick]="something$">
   * <design-button-loader [direction]="'end'"></design-button-loader>
   * Click me
   * <i design-font fontName="assets" fontSize="2rem" [direction]="'begin'"></i>
   * </button>
   */
  @Input()
  set designFireOnClick(stream$: Observable<unknown>) {
    this._fireOnClick$ = stream$;
  }

  private _fireOnClick$!: Observable<unknown>;

  @ContentChild(ButtonLoaderBase)
  set buttonLoaderRef(buttonLoaderBase: ButtonLoaderBase) {
    if (!buttonLoaderBase) {
      throw new Error(
        'design-button-loading is missing inside button element!',
      );
    }

    this._buttonLoaderBase = buttonLoaderBase;
    const hostElementHeight = (this.hostElement.nativeElement as HTMLElement)
      .clientHeight;
    this._buttonLoaderBase.setHeight(hostElementHeight);
  }
  private _buttonLoaderBase!: ButtonLoaderBase;

  @HostListener('click', ['$event.target']) onClick(): void {
    if (!this._fireOnClick$) {
      throw new Error('given stream is invalid!');
    }
    this.buttonComponent.loadingBegin();
    this._fireOnClick$
      .pipe(
        tap({
          next: () => this.buttonComponent.loadingEnd(),
          finalize: () => this.buttonComponent.loadingEnd(),
          //TODO: mby in the feature we will need to have @Output?
        }),
        takeUntil(this._destroySource),
        take(1),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }
}
