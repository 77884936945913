import { Injectable } from '@angular/core';
import { Camera } from '@simlab/matterport/assets/mpSdk/sdk';

@Injectable()
export class PositionCameraService {
  private _cameraPose: Camera.Pose | undefined;

  /**
   * Camera pose needing to transform by Matterport Library function before set
   */
  set cameraPose(value: Camera.Pose | undefined) {
    this._cameraPose = value;
  }
  get cameraPose(): Camera.Pose | undefined {
    return this._cameraPose;
  }
}
