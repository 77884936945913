export class ShowcaseHelper {
  /**
   * Function that is supposed to retrieve and return showcase id, it checks for ?m= or &m= containing ids of 11 elements (a-z)(A-Z)(0-9)
   * @param url showcase url
   * @returns showcase id
   */
  static findShowcaseId(url: string): string {
    if (!url) {
      throw Error(`Showcase url is invalid! - [${url}]`);
    }
    if (
      url.includes('/show/?m=') ||
      url.includes('&m=') ||
      url.includes('/models/')
    ) {
      const correctUrl = url.match(
        '(my.matterport)(.[vr])?(.([a-z]{1,3})/)(.+)(m=)?(.+)'
      );

      if (correctUrl === null) {
        throw Error(`Incorrect url - [${url}]`);
      }

      const matches = url.match(/([a-zA-Z0-9]{11,25})/g);

      if (matches === null) {
        throw Error(`Showcase id not found!`);
      }

      if (matches.length > 2) {
        throw Error(`Multiple showcase id's found!`);
      }
      return matches.pop() as string;
    }

    throw Error(`Showcase id not found!`);
  }
}
