<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@PERSONAL_SETTINGS">Personal settings</h2>
  </ng-container>

  <ng-container designDialogContent>
    <div class="container__context">
      <div class="container__context__left-menu">
        <button
          data-cy="personal-settings-language"
          class="button-settings"
          design-flat-button
          designButtonColor="primary"
          [designButtonSelected]="
            selectedPortal.component.name === languagePortal.component.name
          "
          (click)="selectedPortal = languagePortal"
        >
          <div class="label-settings" i18n="@@LANGUAGE">Language</div>
        </button>

        <button
          data-cy="personal-settings-appearance"
          class="button-settings"
          design-flat-button
          designButtonColor="primary"
          [designButtonSelected]="
            selectedPortal.component.name === appearancePortal.component.name
          "
          (click)="selectedPortal = appearancePortal"
        >
          <div class="label-settings" i18n="@@APPEARANCE">Appearance</div>
        </button>
        <button
          data-cy="personal-settings-units"
          class="button-settings"
          design-flat-button
          designButtonColor="primary"
          [designButtonSelected]="
            selectedPortal.component.name === unitsPortal.component.name
          "
          (click)="selectedPortal = unitsPortal"
        >
          <div class="label-settings" i18n="@@UNITS">Units</div>
        </button>
        <button
          data-cy="personal-settings-calendar-format"
          class="button-settings"
          design-flat-button
          designButtonColor="primary"
          [designButtonSelected]="
            selectedPortal.component.name === dateFormatPortal.component.name
          "
          (click)="selectedPortal = dateFormatPortal"
        >
          <div class="label-settings" i18n="@@CALENDAR_FORMAT">
            Calendar Format
          </div>
        </button>

        @if (devMode) {
          <button
            data-cy="personal-settings-integrations"
            class="button-settings"
            design-flat-button
            designButtonColor="primary"
            [designButtonSelected]="
              selectedPortal.component.name ===
              procoreConnectionPortal.component.name
            "
            (click)="selectedPortal = procoreConnectionPortal"
          >
            <div class="label-settings" i18n="@@INTEGRATIONS">Integrations</div>
          </button>
        }
      </div>

      <div class="container__context__portal">
        <ng-template
          #outlet="cdkPortalOutlet"
          [cdkPortalOutlet]="selectedPortal"
        ></ng-template>
      </div>
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      data-cy="personal-settings-ok-button"
      design-flat-button
      designButtonColor="accent"
      (click)="cancel()"
    >
      <div i18n="@@OK">Ok</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
