/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive } from '@angular/core';
import { designSuffixToken } from '../tokens/suffix.token';
import { AffixBase } from './affix-base.directive';

@Directive({
  selector: '[designSuffix], [designIconSuffix], [designTextSuffix]',
  standalone: true,
  host: {
    class: 'design-suffix',
  },
  providers: [{ provide: designSuffixToken, useExisting: DesignSuffix }],
})
export class DesignSuffix extends AffixBase {}
