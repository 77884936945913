<span class="design-panel-header-label" (click)="emitClick.next()">
  <ng-content select="[designLabel]"></ng-content>
</span>
<span class="design-expansion-container">
  <ng-container *ngIf="showArrow">
    <button design-icon-button (click)="openCloseHeader()">
      <i
        [class]="expanded ? 'rotate-270' : 'rotate-90'"
        designIcon="arrow_big"
      ></i>
    </button>
  </ng-container>
</span>
