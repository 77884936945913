import {
  IVector3,
  TMeasurement,
  TStageMeasurmentSimpleGroups
} from './common-models';

export type TDistanceBase = {
  title: string;
  creatorId: string;
  creatorName: string;
  createdAt: string;
  lastEditorName: string;
  modifiedAt: string;
  groupIds: string[];
  rootNoteId: string;
  color: string;
};

export type TDistanceSimple = {
  groupIds: string[];
  rootNoteId: string | undefined;
  rootNoteCreatorId: string | undefined;
} & TDistanceBase &
  Omit<TAddDistanceBase, 'data'>;

export type TDistance = {
  id: string;
  stageId: string | undefined;
  rootNoteCreatorId: string | undefined;
  data: IVector3[];
} & TDistanceBase;

export type TMeasurementGroups = {
  measurements: TMeasurement[];
} & TStageMeasurmentSimpleGroups;

export type TDistanceGroups = {
  items: TDistance[];
} & TStageMeasurmentSimpleGroups;

export type TAddDistanceBase = {
  title: string;
  color: string;
  data: IVector3[];
};

export type TAddDistanceStage = {
  stageId: string;
} & TAddDistanceBase;

export type TAddDistanceRootNote = {
  rootNoteId: string;
} & TAddDistanceBase;

export interface IAddMeasurementGroup {
  stageId: string;
  name: string;
}

export type TAddDistancesGroup = {
  groupId: string;
  distanceMeasurementsIds: string[];
};

export type TBaseUpdate = {
  id: string;
};

export type TAssignMeasurementToRootNote = {
  rootNote: string;
} & TBaseUpdate;

export type TUnassignMeasurementToRootNote = {} & TBaseUpdate;

export type TRemoveMeasurement = {} & TBaseUpdate;
export type TRemoveMeasurementGroup = {} & TBaseUpdate;

export type TRemoveMeasurementFromGroup = {
  groupId: string;
} & TBaseUpdate;
