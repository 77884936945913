export enum SortingType {
  Name = 1,
  CreatedAt = 2,
  EditedAt = 3,
  CreatorName = 4,
}

export enum SortingTypeOrganizationProject {
  Name = 1,
  CreatorName = 2,
  TeamsNumber = 3,
  UsersNumber = 4,
  CreatedAt = 5,
}

export enum SortingTypeOrganizationUsers {
  Name = 1,
  OrganizationRole = 2,
  TeamsNumber = 3,
  ProjectsNumber = 4,
  DateAdded = 5,
}

export enum SortingTypeOrganizationTeams {
  Name = 1,
  ProjectsNumber = 2,
  MembersNumber = 3,
}

export enum SortingTypeOrganizationRoles {
  Name = 1,
  MembersNumber = 2,
}
