import { InjectionToken } from '@angular/core';

export interface MatOptionParentComponent {
  disableRipple?: boolean;
  multiple?: boolean;
  inertGroups?: boolean;
}

export const MAT_OPTION_PARENT_COMPONENT =
  new InjectionToken<MatOptionParentComponent>('MAT_OPTION_PARENT_COMPONENT');
