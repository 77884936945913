import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { RootNoteCommand } from '../../models/command/root-notes-command';
import { DateRange } from '../../models/date-range';
import { CreateRootNote, Note, RootNotePosition } from '../../models/note';
import { RootNoteQuery } from '../../models/query/root-notes-query';

import { EmptyObject, SortModel } from '@simlab/design/sort-switch';
import { Quaternion } from '@simlab/matterport/api';
import { Author } from '../../models/author';
import { Media } from '../../models/media';
import { NoteCounts } from '../../models/note-counts';
import { NoteFilters } from '../../models/note-filters';
import { StageNotes } from '../../models/stage-notes';
import { IVector3 } from '../../models/vector3';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class RootNotesService implements RootNoteCommand, RootNoteQuery {
  private readonly url = inject<string>(API_URL);
  private readonly httpClient = inject(HttpClient);
  //COMMAND
  moveRootNoteToStage(payload: {
    rootNoteId: string;
    stageId: string;
    moveMarker: boolean;
  }): Observable<Note> {
    return this.httpClient.post<Note>(
      `${this.url}/construction-site-management-module/move-root-note-to-stage`,
      payload
    );
  }

  addRootNoteToStage(payload: {
    stageId?: string | undefined;
    id?: string | undefined;
    name: string;
    status: string;
    type: string;
    stakeholderId?: string | undefined;
    digitalNotes?: Media[] | undefined;
    description?: string | undefined;
  }): Observable<string> {
    return this.httpClient
      .post<string>(
        `${this.url}/construction-site-management-module/add-root-note-to-stage`,
        payload
      )
      .pipe(map((response: any) => response.commandResponseData));
  }

  addRootNotesToStage(payload: {
    stageId?: string;
    rootNotes: (CreateRootNote & RootNotePosition)[];
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/add-root-notes-to-stage`,
      payload
    );
  }

  editRootNoteName(payload: {
    rootNoteId: string;
    newName: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/edit-root-note-name`,
      payload
    );
  }

  editRootNoteDescription(payload: {
    rootNoteId: string;
    newDescription: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/edit-root-note-description`,
      payload
    );
  }

  changeRootNoteType(payload: {
    rootNoteId: string;
    newRootNoteType: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/change-root-note-type`,
      payload
    );
  }

  changeRootNoteStatus(payload: {
    rootNoteId: string;
    newRootNoteStatus: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/change-root-note-status`,
      payload
    );
  }

  placeMarkerInRootNote(payload: {
    rootNoteId: string;
    positionDto: IVector3;
    rotationDto: Quaternion;
    anchorPointNormalDto: IVector3;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/place-marker-in-root-note`,
      payload
    );
  }

  removeMarkerFromRootNote(payload: { rootNoteId: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/remove-marker-from-root-note`,
      payload
    );
  }

  removeRootNoteFromStage(payload: {
    rootNoteId: string;
    stageId: string;
  }): Observable<{
    rootNoteId: 'string';
    stageId: 'string';
    status: 'string';
    type: 'string';
  }> {
    return this.httpClient.post<{
      rootNoteId: 'string';
      stageId: 'string';
      status: 'string';
      type: 'string';
    }>(
      `${this.url}/construction-site-management-module/remove-root-note-from-stage`,
      payload
    );
  }

  changeRootNoteStakeholder(payload: {
    rootNoteId: string;
    stakeholderId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/change-root-note-stakeholder`,
      payload
    );
  }

  changeRootNoteNotificationEnabled(payload: {
    rootNoteId: string;
    areEnabled: boolean;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/change-root-note-notifications-enabled`,
      payload
    );
  }

  //QUERY
  getRootNote(payload: { id: string }): Observable<Note> {
    return this.httpClient
      .get<Note>(
        `${this.url}/construction-site-management-module/get-root-note?Id=${payload.id}`
      )
      .pipe(
        map((note: Note) => {
          if (note.description) {
            return {
              ...note,
              description: note.description.split('\u000b').join('\n')
            };
          }
          return note;
        })
      );
  }
  getRootNotesAuthors(payload: {
    projectId: string;
    apiVersion?: string | undefined;
  }): Observable<Author[]> {
    return this.httpClient.get<Author[]>(
      `${this.url}/construction-site-management-module/get-root-notes-authors?ProjectId=${payload.projectId}`
    );
  }

  getSimpleRootNotesForStage(payload: {
    stageId: string;
  }): Observable<Pick<Note, 'name' | 'id' | 'creatorId'>[]> {
    return this.httpClient.get<Pick<Note, 'name' | 'id' | 'creatorId'>[]>(
      `${this.url}/construction-site-management-module/get-simple-root-notes-for-stage?StageId=${payload.stageId}`
    );
  }

  getRootNotesForStage(payload: {
    stageId: string;
    page: { pageSize: number; pageNumber: number };
    rootNotesFilter?: NoteFilters | undefined;
    sortColumn?: string | undefined;
    isAscending?: boolean | undefined;
    apiVersion?: string | undefined;
  }): Observable<Note[]> {
    const queryParameters: string[] = [];
    if (payload.rootNotesFilter) {
      queryParameters.concat(this.filterBy(payload.rootNotesFilter));
    }
    if (payload.page) {
      if (payload.page.pageSize) {
        queryParameters.push(`Page.PageSize=${payload.page.pageSize}`);
      }
      queryParameters.push(`Page.PageNumber=${payload.page.pageNumber}`);
    }
    if (payload.isAscending) {
      queryParameters.push(`IsAscending=${payload.isAscending}`);
    }
    if (payload.sortColumn) {
      queryParameters.push(`SortColumn=${payload.sortColumn}`);
    }

    return this.httpClient
      .get<
        StageNotes<Note>
      >(`${this.url}/construction-site-management-module/get-root-notes-for-stage?stageId=${payload.stageId}&${queryParameters.join('&')}`)
      .pipe(
        map((response: StageNotes<Note>) => {
          return response.items as Note[];
        })
      );
  }

  getRootNotesWithMarkersForStages(payload: {
    stageId: string[] | null;
    rootNotesFilter?: NoteFilters | undefined;
    sortColumn?: string | undefined;
    isAscending?: boolean | undefined;
    apiVersion?: string | undefined;
  }): Observable<StageNotes<Note>> {
    let queryParameters: string[] = [];

    if (payload.rootNotesFilter) {
      queryParameters = this.filterBy(payload.rootNotesFilter);
    }

    if (payload.stageId) {
      payload.stageId.forEach((value: string) => {
        queryParameters.push(`StagesIds=${value}`);
      });
    }
    if (payload.isAscending) {
      queryParameters.push(`IsAscending=${payload.isAscending}`);
    }
    if (payload.sortColumn) {
      queryParameters.push(`SortColumn=${payload.sortColumn}`);
    }

    const headers = new HttpHeaders({
      'api-version': '2.0'
    });
    return this.httpClient.get<StageNotes<Note>>(
      `${
        this.url
      }/construction-site-management-module/get-root-notes-with-markers-for-stages?${queryParameters.join(
        '&'
      )}`,
      { headers }
    );
  }

  getRootNotesForStages(payload: {
    stageId: string[] | null;
    page?: { pageSize?: number; pageNumber: number };
    rootNotesFilter?: NoteFilters;
    sort?: SortModel | EmptyObject;
    apiVersion?: string | undefined;
  }): Observable<StageNotes<Note>> {
    let queryParameters: string[] = [];

    if (payload.rootNotesFilter) {
      queryParameters = this.filterBy(payload.rootNotesFilter);
    }
    if (payload.page) {
      if (payload.page.pageSize) {
        queryParameters.push(`Page.PageSize=${payload.page.pageSize}`);
      } else {
        queryParameters.push(`Page.PageSize=20`);
      }
      queryParameters.push(`Page.PageNumber=${payload.page.pageNumber}`);
    }
    if (payload.stageId) {
      payload.stageId.forEach((value: string) => {
        queryParameters.push(`StageIds=${value}`);
      });
    }
    if (payload.sort?.isAscending) {
      queryParameters.push(`IsAscending=${payload.sort.isAscending}`);
    }
    if (payload.sort?.sortColumn) {
      queryParameters.push(`SortColumn=${payload.sort.sortColumn}`);
    }

    const headers = new HttpHeaders({
      'api-version': '2.0'
    });
    return this.httpClient.get<StageNotes<Note>>(
      `${
        this.url
      }/construction-site-management-module/get-root-notes-for-stages?${queryParameters.join(
        '&'
      )}`,
      { headers }
    );
  }

  getRootNoteCountsForStage(payload: {
    stageId: string;
    apiVersion?: string | undefined;
  }): Observable<NoteCounts> {
    return this.httpClient.get<NoteCounts>(
      `${this.url}/construction-site-management-module/get-root-note-counts-for-stage?StageId=${payload.stageId}`
    );
  }

  private filterBy(rootNotesFilter: NoteFilters): string[] {
    const buffer: string[] = [];
    const base = 'RootNotesFilter';
    (Object.keys(rootNotesFilter) as Array<keyof NoteFilters>).forEach(
      (key: keyof NoteFilters) => {
        if (rootNotesFilter[key]) {
          if (Array.isArray(rootNotesFilter[key])) {
            (rootNotesFilter[key] as string[]).forEach((value: string) => {
              buffer.push(`${base}.${key}=${value}`);
            });
          } else if (typeof rootNotesFilter[key] === 'object') {
            if ((rootNotesFilter[key] as DateRange).from) {
              buffer.push(
                `${base}.${key}From=${(rootNotesFilter[key] as DateRange).from}`
              );
              buffer.push(
                `${base}.${key}To=${(rootNotesFilter[key] as DateRange).to}`
              );
            }
          } else {
            buffer.push(`${base}.${key}=${rootNotesFilter[key]}`);
          }
        }
      }
    );
    return buffer;
  }
}
