import { Update } from '@ngrx/entity';
import {
  createAction,
  createActionGroup,
  emptyProps,
  props
} from '@ngrx/store';
import { Organizations } from '@simlab/data-access';

export const init = createAction('[Organizations Page] Init');
export const selectOrganization = createAction(
  '[Organizations/Page] Select Organization',
  props<{ id: string | undefined }>()
);

export const selectOrganizationFailure = createAction(
  '[Organizations/Page] Select Organization Failure'
);

export const confirmTransferOrganizationSuccess = createAction(
  '[Organizations Local] Accept Transfer Organization Local Success'
);

export const OrganizationApiActions = createActionGroup({
  source: 'Organizations/API',
  events: {
    'Load Organizations Success': props<{ organizations: Organizations[] }>(),
    'Load Organizations Failure': props<{ error: any }>(),

    'Create organization': props<{ name: string }>(),
    'Create organization Success': props<{
      id: string;
      name: string;
      invitationPending: boolean;
    }>(),
    'Create organization Failure': props<{ error: any }>(),

    'Delete organization': props<{ organizationId: string }>(),
    'Delete organization Success': props<{
      organizationId: string;
      name: string;
    }>(),
    'Delete organization Failure': props<{ error: any }>(),

    'Leave organization': props<{ organizationId: string }>(),
    'Leave organization Success': props<{ organizationId: string }>(),
    'Leave organization Failure': props<{ error: any }>(),

    'Edit organization': props<{ organizationId: string; name: string }>(),
    'Edit organization Success': props<{
      update: Update<{ organizationId: string; name: string }>;
    }>(),
    'Edit organization Failure': props<{ error: any }>(),

    'Edit organization Thumbnail': props<{
      organizationId: string;
      thumbnail: File;
    }>(),

    'Edit organization Thumbnail Success': props<{
      organizationId: string;
      thumbnailUrl: string;
    }>(),

    'Edit organization Thumbnail Failure': props<{ error: any }>(),

    'Edit organization Description': props<{
      organizationId: string;
      description: string;
    }>(),
    'Edit organization Description Success': props<{
      update: Update<{ organizationId: string; description: string }>;
    }>(),
    'Edit organization Description Failure': props<{ error: any }>(),

    'Accept Invitation': props<{
      invitationId: string;
      organizationId: string;
    }>(),
    'Accept Invitation Success': props<{ organizationId: string }>(),
    'Accept Invitation Failure': props<{
      error: any;
      organizationId: string;
    }>(),

    'Reject Invitation': props<{
      id: string;
      organizationId: string;
    }>(),
    'Reject Invitation Success': props<{ id: string }>(),
    'Reject Invitation Failure': props<{
      error: any;
      organizationId: string;
    }>(),

    'Transfer Organization': props<{
      organizationId: string;
      newOwnerId: string;
    }>(),
    'Transfer Success': emptyProps(),
    'Transfer Failure': emptyProps(),

    'Reject Transfer Organization': props<{ organizationId: string }>(),
    'Reject Transfer Organization Success': emptyProps(),
    'Reject Transfer Organization Failure': emptyProps(),

    'Accept Transfer Organization': props<{ organizationId: string }>(),
    'Accept Transfer Organization Success': emptyProps(),
    'Accept Transfer Organization Failure': emptyProps(),

    'Cancel Transfer Organization': props<{ organizationId: string }>(),
    'Cancel Transfer Organization Success': emptyProps(),
    'Cancel Transfer Organization Failure': emptyProps()
  }
});
