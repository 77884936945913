<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@MY_LICENSES">My Licenses</h2>
  </ng-container>

  <ng-container designDialogContent>
    <ui-table
      [dataSource]="myLicensesDataSource"
      [displayedColumns]="displayedColumns"
      [loadingData]="(loadingData$ | async) === true"
    >
      <ng-container cdkColumnDef="column-0">
        <th class="ui-header-cell" cdk-header-cell *cdkHeaderCellDef>
          <div i18n="@@STARTED_AT">STARTED AT</div>
        </th>
        <td class="ui-cell" cdk-cell *cdkCellDef="let row">
          {{ row.startedAt | formatDate | async }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="column-1">
        <th class="ui-header-cell" cdk-header-cell *cdkHeaderCellDef>
          <div i18n="@@EXPIRE_DATE">EXPIRE DATE</div>
        </th>
        <td class="ui-cell" cdk-cell *cdkCellDef="let row">
          {{ row.expiresAt | formatDate | async }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="column-2">
        <th class="ui-header-cell" cdk-header-cell *cdkHeaderCellDef>
          <div class="text-uppercase" i18n="@@STATUS">status</div>
        </th>
        <td class="ui-cell" cdk-cell *cdkCellDef="let row">
          {{ LicenseStatus[row.activeStatus] }}
        </td>
      </ng-container>

      <ng-container cdkColumnDef="column-3">
        <th class="ui-header-cell" cdk-header-cell *cdkHeaderCellDef>
          <div i18n="@@ASSIGNED_BY">ASSIGNED BY</div>
        </th>
        <td class="ui-cell" cdk-cell *cdkCellDef="let row">
          {{ row.assignedByOrganization }}
          <span *ngIf="row.isOwner">(owner)</span>
        </td>
      </ng-container>

      <ng-container cdkColumnDef="column-4">
        <th class="ui-header-cell" cdk-header-cell *cdkHeaderCellDef>
          <div class="text-uppercase" i18n="@@PAYMENT">Payment</div>
        </th>
        <td class="ui-cell" cdk-cell *cdkCellDef="let row">
          <ng-container
            *ngIf="row.isSubscribed; else Unsubscribed"
            i18n="@@SUBSCRIBED"
          >
            Subscribed
          </ng-container>
          <ng-template #Unsubscribed i18n="@@UNSUBSCRIBED">
            Unsubscribed
          </ng-template>
        </td>
      </ng-container>
    </ui-table>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="cancel()"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
