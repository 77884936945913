import { createActionGroup, props } from '@ngrx/store';
import { OrganizationWithPermissions } from '@simlab/data-access';

export const OrganizationPrivilegesActions = createActionGroup({
  source: 'Organizations Privileges/API',
  events: {
    'Load Organization With Permissions Success': props<{
      organizationWithPermissions: OrganizationWithPermissions;
    }>(),
    'Load Organization With Permissions Failure': props<{ error: any }>(),
  },
});
