import {
  BufferGeometry,
  CanvasTexture,
  DoubleSide,
  Euler,
  FrontSide,
  Material,
  Mesh,
  NearestFilter,
  NearestMipmapNearestFilter,
  ReplaceStencilOp,
  Vector3,
  sRGBEncoding,
} from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import {
  ObjectConfig,
  generateClassInstance,
} from '../types/custom-component.type';
import { DeviceCanvasIconTexture } from './canvas.render.helper';
import { ThreeObjectBase } from './three-object.base';

export class PlaneConfiguration implements ObjectConfig<BlueprintComponent> {
  private _instance!: BlueprintComponent;
  constructor(
    readonly config: {
      texture: string;
      scale?: Vector3;
      position?: Vector3;
      visible?: boolean | undefined;
      renderOrder?: number | undefined;
      opacity?: number | undefined;
      isCollider?: boolean | undefined;
      transparent?: boolean | undefined;
    }
  ) {}
  init(threeContext: typeof import('three')) {
    this._instance = generateClassInstance<
      BlueprintComponent,
      PlaneConfiguration
    >(BlueprintComponent, threeContext, this);
  }
  get instance() {
    return this._instance;
  }
}
export class BlueprintComponent extends ThreeObjectBase {
  private _mesh!: Mesh;
  constructor(
    private readonly three: typeof import('three') & {
      SVGLoader: typeof SVGLoader;
    },
    private readonly configuration: PlaneConfiguration['config']
  ) {
    super();
    this.init(three, configuration);
  }
  init(
    threeContext: typeof import('three'),
    configuration: PlaneConfiguration['config']
  ): void {
    this._mesh = this.createObject(threeContext);
    this._texture = this.configuration.texture;
    this.rotation = new Euler(0, 0, 0);
    this._mesh.layers.set(3);

    configuration.position ?? ({ x: 0, y: 0, z: 0 } as Vector3);
  }

  private _getTexture(
    blueprintPath: PlaneConfiguration['config']['texture']
  ): Promise<THREE.Texture> {
    const image = new Image();
    return new Promise((resolve) => {
      if (image) {
        image.onload = () => {
          const canvasTextureIcon = new DeviceCanvasIconTexture(
            image,
            'rgba(255,255,255, 1)',
            4096,
            4096
          );
          const texture = new CanvasTexture(canvasTextureIcon.ctx.canvas);
          texture.minFilter = NearestFilter;
          texture.encoding = sRGBEncoding;
          resolve(texture);
        };
      }
      image.src = blueprintPath;
    });
  }
  private set _texture(blueprintPath: string) {
    (this._mesh.material as THREE.MeshBasicMaterial).dispose();
    const material = this._mesh.material as THREE.MeshBasicMaterial;
    this._getTexture(blueprintPath).then((texture: THREE.Texture) => {
      texture.minFilter = NearestFilter;
      texture.minFilter = NearestMipmapNearestFilter;
      material.map = texture;
      material.needsUpdate = true;
      material.side = DoubleSide;
      console.error('CHANGE MATERIAL DOUBLE SIDE');
      this.configuration.texture = blueprintPath;
    });
  }

  set texture(blueprintPath: string) {
    if (blueprintPath !== this.configuration.texture) {
      this._texture = blueprintPath;
    }
  }

  private createObject(threeContext: typeof import('three')): THREE.Mesh {
    const geometry = new threeContext.PlaneGeometry(1, 1);

    const canvasTextureIcon = new DeviceCanvasIconTexture(
      new Image(),
      'rgba(255,255,255, 1)',
      4096,
      4096
    );
    const texture = new CanvasTexture(canvasTextureIcon.ctx.canvas);
    texture.encoding = sRGBEncoding;
    const material = new threeContext.MeshBasicMaterial({
      map: texture,
      alphaTest: 0.2,
      polygonOffset: false,
      opacity: this.configuration.opacity ?? 1,
      transparent: this.configuration.transparent ?? false,
      side: FrontSide,
      toneMapped: false,
      polygonOffsetFactor: 0,
      polygonOffsetUnits: 0,
    });
    material.stencilWrite = true;
    material.stencilZPass = ReplaceStencilOp;
    return new threeContext.Mesh(geometry, material);
  }
  set position(position: Vector3) {
    throw new Error('Method not implemented.');
  }
  set scale(position: Vector3) {
    const { x, y, z } = position;
    this._mesh.scale.set(x, y, z);
  }
  set rotation(rotation: THREE.Euler) {
    const { x, y, z } = rotation;
    this._mesh.rotation.set(x, y, z);
  }
  show(): void {
    throw new Error('Method not implemented.');
  }
  hide(): void {
    throw new Error('Method not implemented.');
  }
  get object3D(): Mesh<BufferGeometry, Material | Material[]> {
    return this._mesh;
  }
}
