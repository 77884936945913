import { InvoiceAddress } from './invoice-address.interface';

export enum ChangeUserPaymentPlanStatus {
  Undefined = 0,
  Success = 1,
  Error = 2,
  CriticalError = 3,
  WaitingForPayment = 4,
  InvalidTokenError = 5,
  SuccessNoPayment = 6
}

export type ChangeUserPaymentPlanStatusKeys =
  keyof typeof ChangeUserPaymentPlanStatus;
export type ChangeUserPaymentPlanStatusValues =
  (typeof ChangeUserPaymentPlanStatus)[ChangeUserPaymentPlanStatusKeys];

export type FailurePaymentStatus = keyof {
  [K in keyof typeof ChangeUserPaymentPlanStatus as K extends `${string}`
    ? (typeof ChangeUserPaymentPlanStatus)[K] extends
        | ChangeUserPaymentPlanStatus.Success
        | ChangeUserPaymentPlanStatus.SuccessNoPayment
      ? never
      : K
    : never]: unknown;
};

export const PaymentPlanStatusRecords: Record<
  ChangeUserPaymentPlanStatus,
  ChangeUserPaymentPlanStatusKeys
> = {
  [ChangeUserPaymentPlanStatus.Undefined]: 'Undefined',
  [ChangeUserPaymentPlanStatus.Success]: 'Success',
  [ChangeUserPaymentPlanStatus.Error]: 'Error',
  [ChangeUserPaymentPlanStatus.CriticalError]: 'CriticalError',
  [ChangeUserPaymentPlanStatus.WaitingForPayment]: 'WaitingForPayment',
  [ChangeUserPaymentPlanStatus.InvalidTokenError]: 'InvalidTokenError',
  [ChangeUserPaymentPlanStatus.SuccessNoPayment]: 'SuccessNoPayment'
} as const;

export interface IChangeSubscriptionPlan {
  organizationId: string;
  planDefinitionId: string;
  promoCodesTexts: string[];
  address: InvoiceAddress;
  numberOfSeats?: number;
  numberOfLicenses?: number;
}

export interface ChangedSubscriptionPlan {
  status: ChangeUserPaymentPlanStatus;
  paymentId: string;
}

export interface PaymentStatus {
  status: PayUTransactionStatus;
  notificationEmail: string;
  nextPaymentDate: Date;
}

export enum PayUTransactionStatus {
  Pending = 0,
  Success = 1,
  Failure = 2
}
