import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { requestToHttpParamsMapper } from '@simlab/util/core';
import { Observable, map } from 'rxjs';
import { DigitalNotesCommand } from '../../models/command/digital-notes-command';
import { Media } from '../../models/media';
import { MediaType } from '../../models/media-type';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class DigitalNotesService implements DigitalNotesCommand {
  private readonly url = inject<string>(API_URL);
  private readonly httpClient = inject(HttpClient);
  //COMMAND
  addDigitalNoteToRootNote(payload: {
    rootNoteId: string;
    name: string;
    type: MediaType;
    extension: string;
    blobUrl: string;
    brushPainting?: string;
    measurementData?: string;
  }): Observable<Media> {
    const headers = new HttpHeaders({
      'api-version': '2.0'
    });
    return this.httpClient
      .post(
        `${this.url}/construction-site-management-module/add-digital-note-to-root-note`,
        payload,
        { headers }
      )
      .pipe(map((response: any) => response.commandResponseData));
  }

  editDigitalNoteFile(payload: {
    digitalNoteId: string;
    blobUrl: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/edit-digital-note-file`,
      payload
    );
  }

  removeDigitalNoteFromRootNote(payload: {
    digitalNoteId: string;
    rootNoteId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/construction-site-management-module/remove-digital-note-from-root-note`,
      payload
    );
  }

  addDocumentToRootNote(payload: {
    rootNoteId: string;
    documentId: string;
  }): Observable<Media> {
    return this.httpClient.post<Media>(
      `${this.url}/construction-site-management-module/add-document-to-root-note`,
      payload
    );
  }

  addProcoreDrawingDocumentToRootNote(payload: {
    rootNoteId: string;
    procoreDrawingId: number;
  }): Observable<Media> {
    return this.httpClient.post<Media>(
      `${this.url}/construction-site-management-module/add-procore-drawing-to-root-note`,
      payload
    );
  }

  addProcoreImageDocumentToRootNote(payload: {
    rootNoteId: string;
    procoreImageId: number;
  }): Observable<Media> {
    return this.httpClient.post<Media>(
      `${this.url}/construction-site-management-module/add-procore-image-to-root-note`,
      payload
    );
  }

  addProcoreDocumentToRootNote(payload: {
    rootNoteId: string;
    procoreFileId: number;
  }): Observable<Media> {
    return this.httpClient.post<Media>(
      `${this.url}/construction-site-management-module/add-procore-document-to-root-note`,
      payload
    );
  }

  removeProcoreDrawing(payload: {
    id: string;
    rootNoteId: string;
  }): Observable<void> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.delete<void>(
      `${this.url}/construction-site-management-module/remove-procore-drawing-from-root-note`,
      { params }
    );
  }

  removeProcoreDocument(payload: {
    id: string;
    rootNoteId: string;
  }): Observable<void> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.delete<void>(
      `${this.url}/construction-site-management-module/remove-procore-document-from-root-note`,
      { params }
    );
  }

  removeProcoreImage(payload: {
    id: string;
    rootNoteId: string;
  }): Observable<void> {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.delete<void>(
      `${this.url}/construction-site-management-module/remove-procore-image-from-root-note`,
      { params }
    );
  }
}
