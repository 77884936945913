import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { OrganizationCommand } from '../../models/command/organization-command';
import { OrganizationIdentifierInfo } from '../../models/organization-transfer.interface';
import {
  OrganizationResponse,
  OrganizationWithCreateProjectPermission
} from '../../models/organization-with-create-project-permission';
import { OrganizationWithPermissions } from '../../models/organization-with-permissions';
import { AccessType } from '../../models/private-license';
import { ProjectsSort } from '../../models/projects-sort';
import { OrganizationQuery } from '../../models/query/organization-query';
import { Role } from '../../models/role';
import { RoleMember } from '../../models/role-member';
import { SimpleRole } from '../../models/simple-role';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class OrganizationsService
  implements OrganizationCommand, OrganizationQuery
{
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);
  //COMMAND
  createOrganization$(payload: {
    name: string;
    description?: string | undefined;
  }): Observable<any> {
    return this.httpClient
      .post(`${this.url}/organizations-module/create-organization`, payload)
      .pipe(
        map((result: any) => ({
          id: result.commandResponseData,
          name: payload.name,
          invitationPending: false
        }))
      );
  }

  editOrganizationDescription$(payload: {
    organizationId: string;
    description: string;
  }): Observable<any> {
    return this.httpClient.post(
      `${this.url}/organizations-module/edit-organization-description`,
      payload
    );
  }

  editOrganizationName$(payload: {
    organizationId: string;
    name: string;
  }): Observable<any> {
    return this.httpClient.post(
      `${this.url}/organizations-module/edit-organization-name`,
      payload
    );
  }

  editOrganizationThumbnail$(payload: {
    organizationId: string;
    thumbnailUrl: string;
  }): Observable<string> {
    return this.httpClient.post(
      `${this.url}/organizations-module/edit-organization-thumbnail`,
      payload,
      { responseType: 'text' }
    );
  }

  deleteOrganization$(payload: {
    organizationId: string;
  }): Observable<OrganizationResponse> {
    return this.httpClient.delete<OrganizationResponse>(
      `${this.url}/organizations-module/delete-organization?organizationId=${payload.organizationId}`
    );
  }

  leaveOrganization$(payload: { organizationId: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/leave-organization`,
      payload
    );
  }

  checkUserAccessToOrganization$(payload: {
    organizationId: string;
  }): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/organizations-module/check-user-access-to-organization?OrganizationId=${payload.organizationId}`
    );
  }

  acceptProjectTransfer$(payload: {
    organizationId: string;
    projectId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/accept-project-transfer`,
      payload
    );
  }

  cancelProjectTransfer$(payload: {
    organizationId: string;
    projectId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/cancel-project-transfer`,
      payload
    );
  }

  rejectProjectTransfer$(payload: {
    organizationId: string;
    projectId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/reject-project-transfer`,
      payload
    );
  }

  changeUserOrganizationRole$(payload: {
    organizationId: string;
    userId: string;
    roleId: number;
    accessType: AccessType;
  }): Observable<any> {
    return this.httpClient.put(
      `${this.url}/organizations-module/change-user-organization-role?organizationId=${payload.organizationId}&userId=${payload.userId}&roleId=${payload.roleId}&accessType=${payload.accessType}`,
      payload
    );
  }

  transferProject$(payload: {
    projectId: string;
    organizationIdentifier: string;
  }): Observable<OrganizationIdentifierInfo> {
    return this.httpClient.post<OrganizationIdentifierInfo>(
      `${this.url}/organizations-module/transfer-project`,
      payload
    );
  }

  transferOrganization$(payload: {
    organizationId: string;
    newOwnerId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/transfer-organization`,
      payload
    );
  }

  rejectTransferOrganization$(payload: {
    organizationId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/reject-organization-transfer`,
      payload
    );
  }

  cancelTransferOrganization$(payload: {
    organizationId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/cancel-organization-transfer`,
      payload
    );
  }

  acceptTransferOrganization$(payload: {
    organizationId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/accept-organization-transfer`,
      payload
    );
  }

  //QUERY

  getOrganizationIdentifier$(payload: {
    organizationId: string;
  }): Observable<string> {
    return this.httpClient.get(
      `${this.url}/organizations-module/get-organization-identifier?organizationId=${payload.organizationId}`,
      { responseType: 'text' }
    );
  }

  getOrganizationDataByIdentifier$(payload: {
    organizationIdentifier: string;
  }): Observable<OrganizationIdentifierInfo> {
    return this.httpClient.get<OrganizationIdentifierInfo>(
      `${this.url}/organizations-module/get-organization-data-by-identifier?organizationIdentifier=${payload.organizationIdentifier}`
    );
  }

  getOrganizationRoleName$(payload: { roleId: number }): Observable<string> {
    return this.httpClient.get(
      `${this.url}/organizations-module/get-organization-role-name?roleId=${payload.roleId}`,
      { responseType: 'text' }
    );
  }

  checkOrganizationPermissions$(payload: {
    organizationId: string;
    permissions: number;
  }): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/organizations-module/check-organization-permissions?organizationId=${payload.organizationId}&permissions=${payload.permissions}`
    );
  }

  getOrganizationsWithCreateProjectPermissions$(): Observable<
    OrganizationWithCreateProjectPermission[]
  > {
    return this.httpClient.get<OrganizationWithCreateProjectPermission[]>(
      `${this.url}/organizations-module/get-organizations-with-create-project-permissions`
    );
  }

  getOrganizations$(): Observable<any> {
    return this.httpClient
      .get(`${this.url}/organizations-module/get-organizations`)
      .pipe(
        map((result: any) =>
          result.map((item: any) => ({
            id: item.organizationId,
            name: item.name,
            invitationPending: item.invitationPending,
            invitationRequiresOwnLicense: item.invitationRequiresOwnLicense,
            invitationId: item.invitationId,
            transfer: item.transfer
          }))
        )
      );
  }

  getOrganizationWithPermissions$(payload: {
    organizationId: string;
  }): Observable<OrganizationWithPermissions> {
    return this.httpClient.get<OrganizationWithPermissions>(
      `${this.url}/organizations-module/get-organization-with-permissions?organizationId=${payload.organizationId}`
    );
  }

  getSimpleOrganizationRoles$(
    organizationId: string
  ): Observable<SimpleRole[]> {
    return this.httpClient.get<SimpleRole[]>(
      `${this.url}/organizations-module/get-simple-organization-roles?organizationId=${organizationId}`
    );
  }

  getOrganizationRoles$(payload: {
    organizationId: string;
    name?: string;
    sortBy?: ProjectsSort;
  }): Observable<Role[]> {
    const queryParameters: string[] = [];
    if (payload) {
      queryParameters.push(...[`organizationId=${payload.organizationId}`]);
      queryParameters.push(...this.sortBy(payload.sortBy));
      if (payload.name) {
        queryParameters.push(...[`RoleName=${payload.name}`]);
      }
    }
    return this.httpClient.get<Role[]>(
      `${
        this.url
      }/organizations-module/get-organization-roles?${queryParameters.join(
        '&'
      )}`
    );
  }
  getOrganizationRoleMembers$(payload: {
    organizationId: string;
    roleId: number;
  }): Observable<RoleMember[]> {
    return this.httpClient.get<RoleMember[]>(
      `${this.url}/organizations-module/get-organization-role-members?organizationId=${payload.organizationId}&roleId=${payload.roleId}`
    );
  }

  getOrganizationRoleSettings$(payload: {
    organizationId: string;
    roleId: string;
  }): Observable<number> {
    return this.httpClient.get<number>(
      `${this.url}/organizations-module/get-organization-role-settings?organizationId=${payload.organizationId}&roleId=${payload.roleId}`
    );
  }

  private sortBy(sortBy?: ProjectsSort): string[] {
    const buffer: string[] = [];

    if (sortBy?.type) {
      buffer.push(`SortColumn=${sortBy.type}`);
    }

    if (sortBy?.ascending) {
      buffer.push(`IsAscending=${sortBy.ascending}`);
    }

    return buffer;
  }
}
