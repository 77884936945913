import { createAction, props } from '@ngrx/store';
import { Note, NoteFilters } from '@simlab/data-access';

export const init = createAction(
  '[ShowcaseNotes Page] Init',
  props<{
    payload: {
      stageId: string[] | null;
      rootNotesFilter?: NoteFilters | undefined;
    };
  }>()
);

export const clearStore = createAction('[ShowcaseNotes/API] Clear Store');

export const loadShowcaseNotesSuccess = createAction(
  '[ShowcaseNotes/API] Load ShowcaseNotes Success',
  props<{ showcaseNotes: Note[] }>()
);

export const loadShowcaseNotesFailure = createAction(
  '[ShowcaseNotes/API] Load ShowcaseNotes Failure',
  props<{ error: any }>()
);

export const selectNote = createAction(
  '[ShowcaseNotes Page] Set Selected Note Id',
  props<{ noteId: string }>()
);

export const selectNoteSuccess = createAction(
  '[ShowcaseNotes Page] Set Selected Note Success',
  props<{ note: Note }>()
);

export const selectNoteFailure = createAction(
  '[ShowcaseNotes Page] Set Selected Note Failure',
  props<{ error: any }>()
);

export const hideAll = createAction(
  '[ShowcaseNotes Page] Hide all Notes',
  props<{ hideAll: boolean }>()
);

export const hideAllSuccess = createAction(
  '[ShowcaseNotes Page] Hide all Notes Success',
  props<{ hideAll: boolean }>()
);

export const hideAllFailure = createAction(
  '[ShowcaseNotes Page] Hide all Notes Failure',
  props<{ error: any }>()
);

// export const addNote = createAction(
//   '[ShowcaseNotes Page] Add note',
//   props<{ note: Note }>()
// );

// export const removeNote = createAction(
//   '[ShowcaseNotes Page] Remove note',
//   props<{ note: Note }>()
// );

// export const updateNote = createAction(
//   '[ShowcaseNotes Page] Update note',
//   props<{ note: Note }>()
// );
