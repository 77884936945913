// @ts-nocheck
// disable type checking until typescript problem with mixin doeas not fix
// https://github.com/microsoft/TypeScript/issues/41347
import { mixinCustomComponent } from '@simlab/simlab-facility-management/scene-object';
import {
  BufferGeometry,
  Camera,
  Group,
  Line,
  LineBasicMaterial,
  Scene,
  Vector3,
} from 'three';
import { Dict } from '../models/dictionary';
import { TagComponentClickData } from '../models/matterport-tag-component.type';
import { ViewMode } from '../models/mattertags.interface';
import {
  ComponentInteractionType,
  SceneComponentBase,
} from './scene-component-base';

export const rootNoteType = 'mp.customComponentRenderer';
export function makeCustomComponentRenderer() {
  return new CustomMatteportComponent();
}

export const _MatterportComponentBase =
  mixinCustomComponent(SceneComponentBase);

export class CustomMatteportComponent extends _MatterportComponentBase {
  override get lineBasicMaterial(): typeof LineBasicMaterial {
    if (!this._context) throw new Error('Context is undefined');
    return this._context.three.LineBasicMaterial;
  }
  override get bufferGeometry(): typeof BufferGeometry {
    if (!this._context) throw new Error('Context is undefined');
    return this._context.three.BufferGeometry;
  }
  override get line(): typeof Line {
    if (!this._context) throw new Error('Context is undefined');
    return this._context.three.Line;
  }
  _viewMode: ViewMode | undefined;

  get three(): typeof import('three') {
    return this.context.three;
  }
  override get scene(): Scene {
    return this.context.scene;
  }
  override get camera(): Camera {
    return this.context.camera as THREE.Camera;
  }
  override _group: Group = new Group();

  override get cameraContainer(): Camera {
    return this.context.camera.parent as THREE.Camera;
  }

  override events: Dict<boolean> = {
    [ComponentInteractionType.CLICK]: true,
    [ComponentInteractionType.HOVER]: true,
  };
  override onInit(): void {
    this._group = new this.three.Group();
    super.onInit();
    this.outputs.objectRoot = this._group;
    if (this.inputs.isCollider) {
      this.outputs.collider = this._group;
    }
  }
  override onDestroy(): void {
    this._context = null;
    this.outputs.collider = null;
    this.outputs.objectRoot = null;
  }

  override onEvent(
    eventType: ComponentInteractionType,
    eventData: TagComponentClickData
  ) {
    this.notify(eventType, {
      ...eventData,
      ...{ userData: this.inputs.userData },
    });
  }

  override onInputsUpdated(oldInputs: typeof this.inputs) {
    if (oldInputs.opacity !== this.inputs.opacity) {
      this.opacity = this.inputs.opacity;
    }
    if (oldInputs.visible !== this.inputs.visible) {
      this._group.visible = this.inputs.visible;
    }
    if (oldInputs.scale !== this.inputs.scale) {
      this.scale = this.inputs.scale;
    }
    if (oldInputs.renderOrder !== this.inputs.renderOrder) {
      this.renderOrder = this.inputs.renderOrder;
    }
  }

  override onTick(delta: number) {
    this.animationFrame();
    if (this.inputs.dollhouseView) {
      if (this.inputs.lookAt) {
        this._lookAt(this.cameraContainer.position);
        this._localAutoScale === undefined &&
          (this._localAutoScale = this.inputs.autoScale);
        this.inputs.autoScale = true;
      } else {
        this._lookAt(this.inputs.position);
        this.rotateYByPi();
        this.inputs.autoScale = this._localAutoScale || false;
        this._localAutoScale = undefined;
      }
    }
  }

  override _lookAt(vector: Vector3 = this._vector): void {
    if (!this._group || !this.isVectorValid(vector)) {
      return;
    }
    try {
      if (this.viewMode && this.viewMode === ViewMode.FLOORPLAN) {
        this._group.lookAt(this.position.x, vector.y, this.position.z);
        this._group.rotateZ(this.camera.rotation.z);
      } else {
        this._group.lookAt(vector.x, vector.y, vector.z);
      }
      //TODO: (olek) check is need it
      this.outputs.objectRoot = this._group;
      this._vector = vector.clone();
    } catch (e) {
      console.error('[ERROR] PlaneRenderer, error: ', e);
    }
  }

  public get viewMode(): ViewMode | undefined {
    return this._viewMode;
  }
  public set viewMode(value: ViewMode | undefined) {
    this._viewMode = value;
  }
}
