import { NgModule } from '@angular/core';
import { DesignFormFieldError } from '../components/error.component';
import { DesignFormField } from '../components/form-field.component';
import { DesignFormFieldHint } from '../components/hint.directive';

@NgModule({
  imports: [DesignFormField, DesignFormFieldHint, DesignFormFieldError],
  exports: [DesignFormField, DesignFormFieldHint, DesignFormFieldError],
})
export class DesignFormFieldModule {}
