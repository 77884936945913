import { FormControl, FormGroup } from '@angular/forms';

export type TForm<T> = {
  [Property in keyof T]: FormControl<T[Property]>;
};

export type TFormExtended<T> = {
  [Property in keyof T]: T[Property] extends Record<string, unknown>
    ? FormGroup<TForm<T[Property]>>
    : FormControl<T[Property]>;
};
