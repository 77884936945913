<span class="chip__label">
  @if (label()) {
    {{ label() }}
  } @else {
    <ng-content></ng-content>
  }
</span>
@if (removable) {
<button type="button" class="chip__close-btn" design-icon-button>
  <i designIcon="cross" designSize="1.5rem" (click)="close()"></i>
</button>
}
