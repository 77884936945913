export type ErrorCodes =
  | 'UnknownException'
  | 'Unauthorized'
  | 'BrokenBusinessRule'
  | 'InsufficientPermissions'
  | 'EntityNotFound'
  | 'UserNameNotFound'
  | 'UserProfileNotFound'
  | 'UserAlreadyIsAPartOfOrganization'
  | 'UserDoesntHaveAccessToProject'
  | 'AtLeastOneUserIsNotAPartOfOrganization'
  | 'UserAlreadyHasAPendingInvitation'
  | 'InvitationIsNotPending'
  | 'InvitationCancelled'
  | 'CannotCheckSomeoneElseInvitation'
  | 'AtLeastOneTeamAlreadyInTheProject'
  | 'AtLeastOneUserAlreadyInTheProject'
  | 'CannotRaisePermissionLevelToOwner'
  | 'PermissionCannotBeBothAllowedAndForbidden'
  | 'InconsistentPermissions'
  | 'CannotFindBlobContainer'
  | 'CannotFindAzureBlobConnectionString'
  | 'OnlyPhotoNotesCanHaveRotationDefined'
  | 'CannotMoveRootNoteToStageToWhichItBelongs'
  | 'CannotMoveRootNoteToDifferentProject'
  | 'IncorrectAnchorPointNormalData'
  | 'IncorrectPositionData'
  | 'IncorrectRotationData'
  | 'OrganizationOwnerCannotLeaveOrganization'
  | 'ProjectAlreadyDeleted'
  | 'SynchronizerError'
  | 'ProjectAlreadySetActive'
  | 'ProjectAlreadySetIdle'
  | 'CannotFindPlanDefinition'
  | 'CannotCreateEnterprisePlan'
  | 'CannotUnsubscribeNonEnterprisePlan'
  | 'CannotCreateDemoPlan'
  | 'CannotCreateFreePlan'
  | 'NumberOfSeatsOnlyForSmallBusiness'
  | 'NumberOfLicensesOnlyForSmallBusiness'
  | 'NumberOfSeatsHasToBeSpecifiedForSmallBusiness'
  | 'NumberOfLicensesHasToBeSpecifiedForSmallBusiness'
  | 'CannotChangeToTheSamePlan'
  | 'SeatsLimitExceeded'
  | 'LicensesLimitExceeded'
  | 'LicensesNumberHasToBeAtLeastOne'
  | 'ActiveProjectsLimitReached'
  | 'StagesLimitReached'
  | 'RootNotesLimitReached'
  | 'RootNotesLimitWouldBeExceeded'
  | 'SeatsLimitReached'
  | 'CannotHaveViewers'
  | 'OwnedOrganizationsNumberReached'
  | 'CannotSetActiveAlreadyActivePlan'
  | 'WrongParameters'
  | 'ExpiredPlan'
  | 'NoPaymentMethod'
  | 'CannotAddSecondPaymentMethod'
  | 'NoInvoiceAddress'
  | 'IsCompanyMismatch'
  | 'PriceLowerThanZero'
  | 'NoExchangeRateForCurrency'
  | 'GetMatterportTokenError'
  | 'InvoiceDocumentAlreadyCreated'
  | 'CannotChangePlanWhenPaymentPending'
  | 'CannotFindSubscriptionForOrganization'
  | 'WrongRootNoteStatus'
  | 'DeserializationError'
  | 'OrganizationNotFound'
  | 'SubscriptionLimitsForProjectExceeded'
  | 'PromoCodeValidOnlyWithFirstPayment'
  | 'InvalidPromoCode'
  | 'PromoCodeUsedMultipleTimes'
  | 'OneResellerPromoCodePerUser'
  | 'CannotActivateResellerPromoCode'
  | 'NoPublicLinkToken'
  | 'InvalidPublicLinkToken'
  | 'NoTokenOrIncorrectToken'
  | 'IncorrectStakeholder'
  | 'DemoOnlyForFreePlan'
  | 'DemoPlanMaxLengthExceeded'
  | 'CannotTransferActiveProject'
  | 'ProjectTransferInProgress'
  | 'NoTransferPending'
  | 'UserOwnsAtLeastOneOrganization'
  | 'TransferAlreadyAccepted'
  | 'TransferAlreadyRejected'
  | 'TransferAlreadyCancelled'
  | 'TransferAlreadyExpired'
  | 'TooLowUserOrganizationRole'
  | 'UserIsAlreadyOrganizationOwner'
  | 'OrganizationTransferInProgress'
  | 'SameOrganization'
  | 'BlobDoesntExist'
  | 'TokenExpired'
  | 'ProjectNotPartOfOrganization'
  | 'VatNumberValidationFailed'
  | 'CannotLowerLicensesNumberModifyingPlan'
  | 'UserHasAlreadyLicenseInOrganization'
  | 'NotAssignedLicense'
  | 'UserDoesntHaveALicense'
  | 'UserDoesntHaveOrganziationLicense'
  | 'CannotUnassignOwnerLicense'
  | 'CannotUnsubscribeOwnerLicense'
  | 'LicenseNotReservedByInvitation'
  | 'PlanLicenseLimitReached'
  | 'LicenseAlreadySubscribed'
  | 'NotExpiredLicense'
  | 'CooperatorsWithLicensesLimitReached'
  | 'AtLeastOneSeatOrLicenseHasToBeAdded'
  | 'UserIsNotPartOfTheOrganization'
  | 'AlreadyAssignedLicense'
  | 'LicenseReservedForInvitation'
  | 'NonMatterportComponent'
  | 'ErrorFetchingMatterportSweeps'
  | 'CannotRemoveNotExpiredLicense'
  | 'CannotRemoveLicenseWhenDesactivationNotificationNotSent'
  | 'CannotSetOrganizationOwnerToViewer'
  | 'OrganizationOwnerCannotUseExternalLicense'
  | 'InactiveProject'
  | 'CannotSubscribeDeletedOrgLicense'
  | 'WrongExtension'
  | 'AreaMeasurementNotInStage'
  | 'AreaMeasurementGroupNotInStage'
  | 'StageMismatch'
  | 'AreaMeasurementIsNotAssignedToRootNote'
  | 'TeamIsNotPartOfProjectsOrganization'
  | 'CannotRemoveUserAddedWithTeam'
  | 'ProcoreRequestError'
  | 'NoProcoreToken'
  | 'PaypalRequestError'
  | 'ThreeDSVerificationNotPerformed'
  | 'NotRedirectedToThreeDSVerification'
  | 'ThreeDSVerificationFailedOrSkipped'
  | 'ProcoreForbidden';

export const ErrorCodesMessages: Record<ErrorCodes, string> = {
  UnknownException: '',
  Unauthorized: '',
  BrokenBusinessRule: '',
  InsufficientPermissions: '',
  EntityNotFound: '',
  UserNameNotFound: '',
  UserProfileNotFound: '',
  UserAlreadyIsAPartOfOrganization: '',
  UserDoesntHaveAccessToProject: '',
  AtLeastOneUserIsNotAPartOfOrganization: '',
  UserAlreadyHasAPendingInvitation: '',
  InvitationIsNotPending: '',
  InvitationCancelled: $localize`:@@ERROR_CODE_INVITATION_CANCELLED:Invitation cancelled`,
  CannotCheckSomeoneElseInvitation: '',
  AtLeastOneTeamAlreadyInTheProject: '',
  AtLeastOneUserAlreadyInTheProject: '',
  CannotRaisePermissionLevelToOwner: '',
  PermissionCannotBeBothAllowedAndForbidden: '',
  InconsistentPermissions: '',
  CannotFindBlobContainer: '',
  CannotFindAzureBlobConnectionString: '',
  OnlyPhotoNotesCanHaveRotationDefined: '',
  CannotMoveRootNoteToStageToWhichItBelongs: '',
  CannotMoveRootNoteToDifferentProject: '',
  IncorrectAnchorPointNormalData: '',
  IncorrectPositionData: '',
  IncorrectRotationData: '',
  OrganizationOwnerCannotLeaveOrganization: '',
  ProjectAlreadyDeleted: '',
  SynchronizerError: '',
  ProjectAlreadySetActive: '',
  ProjectAlreadySetIdle: '',
  CannotFindPlanDefinition: '',
  CannotCreateEnterprisePlan: '',
  CannotUnsubscribeNonEnterprisePlan: '',
  CannotCreateDemoPlan: '',
  CannotCreateFreePlan: '',
  NumberOfSeatsOnlyForSmallBusiness: '',
  NumberOfLicensesOnlyForSmallBusiness: '',
  NumberOfSeatsHasToBeSpecifiedForSmallBusiness: '',
  NumberOfLicensesHasToBeSpecifiedForSmallBusiness: '',
  CannotChangeToTheSamePlan: '',
  SeatsLimitExceeded: '',
  LicensesLimitExceeded: '',
  LicensesNumberHasToBeAtLeastOne: '',
  ActiveProjectsLimitReached: '',
  StagesLimitReached: '',
  RootNotesLimitReached: '',
  RootNotesLimitWouldBeExceeded: '',
  SeatsLimitReached: '',
  CannotHaveViewers: '',
  OwnedOrganizationsNumberReached: '',
  CannotSetActiveAlreadyActivePlan: '',
  WrongParameters: '',
  ExpiredPlan: '',
  NoPaymentMethod: '',
  CannotAddSecondPaymentMethod: '',
  NoInvoiceAddress: '',
  IsCompanyMismatch: '',
  PriceLowerThanZero: '',
  NoExchangeRateForCurrency: '',
  GetMatterportTokenError: '',
  InvoiceDocumentAlreadyCreated: '',
  CannotChangePlanWhenPaymentPending: '',
  CannotFindSubscriptionForOrganization: '',
  WrongRootNoteStatus: '',
  DeserializationError: '',
  OrganizationNotFound: '',
  SubscriptionLimitsForProjectExceeded: '',
  PromoCodeValidOnlyWithFirstPayment: '',
  InvalidPromoCode: '',
  PromoCodeUsedMultipleTimes: '',
  OneResellerPromoCodePerUser: '',
  CannotActivateResellerPromoCode: '',
  NoPublicLinkToken: '',
  InvalidPublicLinkToken: '',
  NoTokenOrIncorrectToken: '',
  IncorrectStakeholder: '',
  DemoOnlyForFreePlan: '',
  DemoPlanMaxLengthExceeded: '',
  CannotTransferActiveProject: '',
  ProjectTransferInProgress: '',
  NoTransferPending: '',
  UserOwnsAtLeastOneOrganization: '',
  TransferAlreadyAccepted: '',
  TransferAlreadyRejected: '',
  TransferAlreadyCancelled: '',
  TransferAlreadyExpired: '',
  TooLowUserOrganizationRole: '',
  UserIsAlreadyOrganizationOwner: '',
  OrganizationTransferInProgress: '',
  SameOrganization: '',
  BlobDoesntExist: '',
  TokenExpired: '',
  ProjectNotPartOfOrganization: '',
  VatNumberValidationFailed: '',
  CannotLowerLicensesNumberModifyingPlan: '',
  UserHasAlreadyLicenseInOrganization: '',
  NotAssignedLicense: '',
  UserDoesntHaveALicense: '',
  UserDoesntHaveOrganziationLicense: '',
  CannotUnassignOwnerLicense: '',
  CannotUnsubscribeOwnerLicense: '',
  LicenseNotReservedByInvitation: '',
  PlanLicenseLimitReached: '',
  LicenseAlreadySubscribed: '',
  NotExpiredLicense: '',
  CooperatorsWithLicensesLimitReached: '',
  AtLeastOneSeatOrLicenseHasToBeAdded: '',
  UserIsNotPartOfTheOrganization: '',
  AlreadyAssignedLicense: '',
  LicenseReservedForInvitation: '',
  NonMatterportComponent: '',
  ErrorFetchingMatterportSweeps: '',
  CannotRemoveNotExpiredLicense: '',
  CannotRemoveLicenseWhenDesactivationNotificationNotSent: '',
  CannotSetOrganizationOwnerToViewer: '',
  OrganizationOwnerCannotUseExternalLicense: '',
  InactiveProject: '',
  CannotSubscribeDeletedOrgLicense: '',
  WrongExtension: '',
  AreaMeasurementNotInStage: '',
  AreaMeasurementGroupNotInStage: '',
  StageMismatch: '',
  AreaMeasurementIsNotAssignedToRootNote: '',
  TeamIsNotPartOfProjectsOrganization: '',
  CannotRemoveUserAddedWithTeam: '',
  ProcoreRequestError: '',
  NoProcoreToken: '',
  PaypalRequestError: '',
  ThreeDSVerificationNotPerformed: '',
  NotRedirectedToThreeDSVerification: '',
  ThreeDSVerificationFailedOrSkipped: '',
  ProcoreForbidden: ''
} as const;
