import { coerceCssPixelValue } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { ICON_FONT_TYPE } from '../../models/sim-font.types';

/**
 * @deprecated To be removed in near future. Use designIcon instead.
 */
@Component({
  selector: 'i[ui-font]',
  exportAs: 'uiFont',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class FontComponent {
  private _currentValue?: ICON_FONT_TYPE;
  /**
   * @deprecated To be removed in near future. Use designIcon instead.
   */
  @Input()
  set fontName(value: ICON_FONT_TYPE) {
    if (this._currentValue) {
      this.renderer.removeClass(
        this.elementRef.nativeElement,
        `icon-${this._currentValue}`
      );
    }
    this.renderer.addClass(this.elementRef.nativeElement, `icon-${value}`);
    this._currentValue = value;
  }

  @Input()
  set fontSize(value: string) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'font-size',
      coerceCssPixelValue(value)
    );
  }

  @Input()
  set fontColor(value: string) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'color', value);
  }

  @Input()
  set fontRotateBy(value: number) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'display', 'block');
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'transform',
      `rotate(${value}deg)`
    );
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {}
}
