/* eslint-disable @nx/enforce-module-boundaries */
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Environment, ENVIRONMENT_CONFIG } from '@simlab/util-shared';
import { Observable } from 'rxjs';
import {
  ChangedSubscriptionPlan,
  IChangeSubscriptionPlan,
  PaymentStatus
} from '../../models/change-user-payment-plan';
import { CountryISO } from '../../models/country-code';
import { CurrencyCode } from '../../models/currency-code';
import { InvoiceAddress } from '../../models/invoice-address.interface';
import { InvoiceInterface } from '../../models/invoice.interface';
import { OrganizationLimits } from '../../models/organization-limits';
import { OrganizationPlan } from '../../models/organization-plan';
import { PayUCardRegistrationStatusCodeResponse } from '../../models/payU-card-registration-status-sode';
import { Plan } from '../../models/plan.interface';
import { RegisterCardToken } from '../../models/register-card-token.response';
import { RemoveCardStatusResponse } from '../../models/remove-card.status';
import {
  CodesValidation,
  PromoCodes,
  SubscriptionPlanChangeInformation,
  SubscriptionPlanChangeInformationPayload,
  SubscriptionPrompt
} from '../../models/subscription-plan-change-information';
import { API_URL } from '../../tokens/api-url.token';
import { CardCommand } from './command/card-command';
import { InvoiceCommand } from './command/invoices-command';
import { PayPalCommand } from './command/paypal-command';
import { SubscriptionPlansCommand } from './command/subscription-plans-command';
import { CardQuery } from './query/card-query';
import { InvoiceQuery } from './query/invoices-query';
import { SubscriptionPlansQuery } from './query/subscription-plans-query';

@Injectable()
export class ApiSubscriptionService
  implements
    SubscriptionPlansQuery,
    SubscriptionPlansCommand,
    InvoiceCommand,
    InvoiceQuery,
    CardQuery,
    CardCommand,
    PayPalCommand
{
  private readonly environment = inject<Environment>(ENVIRONMENT_CONFIG);
  private readonly url = inject<string>(API_URL);
  private readonly httpClient = inject(HttpClient);

  getCurrencyCodeForCountry(payload: {
    country: string;
  }): Observable<CurrencyCode> {
    return this.httpClient.get<CurrencyCode>(
      `${this.url}/subscriptions-module/get-currency-code-for-country?country=${payload.country}`
    );
  }

  getInvoiceAddress(payload: {
    organizationId: string;
  }): Observable<InvoiceAddress> {
    return this.httpClient.get<InvoiceAddress>(
      `${this.url}/subscriptions-module/get-invoice-address?organizationId=${payload.organizationId}`
    );
  }

  getInvoices(payload: {
    organizationId: string;
    year: number;
  }): Observable<InvoiceInterface[]> {
    return this.httpClient.get<InvoiceInterface[]>(
      `${this.url}/subscriptions-module/get-invoices?OrganizationId=${payload.organizationId}&Year=${payload.year}`
    );
  }

  getInvoicesYears(payload: { organizationId: string }): Observable<number[]> {
    return this.httpClient.get<number[]>(
      `${this.url}/subscriptions-module/get-invoices-years?organizationId=${payload.organizationId}`
    );
  }

  getInvoiceUrl(payload: { id: string }): Observable<{ url: string }> {
    return this.httpClient.get<{ url: string }>(
      `${this.url}/subscriptions-module/get-invoice-url?id=${payload.id}`
    );
  }

  getCardRegistrationStatus(payload: {
    registrationId: string;
  }): Observable<PayUCardRegistrationStatusCodeResponse> {
    return this.httpClient.get<PayUCardRegistrationStatusCodeResponse>(
      `${this.url}/subscriptions-module/get-card-registration-status?Id=${payload.registrationId}`
    );
  }

  getPlanDefinitions(payload: { country: string }): Observable<Plan[]> {
    return this.httpClient.get<Plan[]>(
      `${this.url}/subscriptions-module/get-plan-definitions?Country=${payload.country}`
    );
  }

  getAllCountries(): Observable<CountryISO[]> {
    return this.httpClient.get<CountryISO[]>(
      `${this.url}/subscriptions-module/get-all-countries`
    );
  }

  getSubscriptionPrompts(payload: {
    organizationId: string;
  }): Observable<SubscriptionPrompt> {
    return this.httpClient.get<SubscriptionPrompt>(
      `${this.url}/subscriptions-module/get-subscription-prompts?organizationId=${payload.organizationId}`
    );
  }

  getOrganizationSubscriptionPlan(payload: {
    organizationId: string;
  }): Observable<OrganizationPlan> {
    return this.httpClient.get<OrganizationPlan>(
      `${this.url}/subscriptions-module/get-organization-subscription-plan?organizationId=${payload.organizationId}`
    );
  }

  getSubscriptionPlanChangeInformation(
    payload: SubscriptionPlanChangeInformationPayload
  ): Observable<SubscriptionPlanChangeInformation> {
    const queryParameters: string[] = [];
    queryParameters.push(...[`OrganizationId=${payload.organizationId}`]);
    queryParameters.push(...[`PlanDefinitionId=${payload.planDefinitionId}`]);
    queryParameters.push(...[`Country=${payload.country}`]);
    payload.promoCodes.forEach((promoCode: string) =>
      queryParameters.push(...[`PromoCodesTexts=${promoCode}`])
    );

    queryParameters.push(...[`IsVatPayer=${payload.isVatPayer}`]);
    if (payload.numberOfSeats !== undefined) {
      queryParameters.push(...[`NumberOfSeats=${payload.numberOfSeats}`]);
    }
    if (payload.numberOfLicenses !== undefined) {
      queryParameters.push(...[`NumberOfLicenses=${payload.numberOfLicenses}`]);
    }
    return this.httpClient.get<SubscriptionPlanChangeInformation>(
      `${
        this.url
      }/subscriptions-module/get-subscription-plan-change-information?${queryParameters.join(
        '&'
      )}`
    );
  }

  getPayPalIdToken(payload: { organizationId: string }): Observable<string> {
    return this.httpClient.get(
      `${this.url}/subscriptions-module/get-paypal-id-token?organizationId=${payload.organizationId}`,
      { responseType: 'text' }
    );
  }

  createPaypalSetupToken(payload: {
    organizationId: string;
  }): Observable<{ registrationId: string; setupToken: string }> {
    return this.httpClient.post<{ registrationId: string; setupToken: string }>(
      `${this.url}/subscriptions-module/create-paypal-setup-token`,
      payload
    );
  }
  createPaypalPaymentToken(payload: {
    organizationId: string;
    registrationId: string;
    setupToken: string;
  }): Observable<{ registrationId: string; setupToken: string }> {
    return this.httpClient.post<{ registrationId: string; setupToken: string }>(
      `${this.url}/subscriptions-module/create-paypal-payment-token`,
      payload
    );
  }

  removePaymentMethod(payload: { organizationId: string }): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/subscriptions-module/remove-payment-method?organizationId=${payload.organizationId}`
    );
  }

  changeSubscriptionPlan(
    payload: IChangeSubscriptionPlan
  ): Observable<ChangedSubscriptionPlan> {
    return this.httpClient.post<ChangedSubscriptionPlan>(
      `${this.url}/subscriptions-module/change-subscription-plan`,
      payload
    );
  }

  getPaymentStatus(payload: { paymentId: string }): Observable<PaymentStatus> {
    return this.httpClient.get<PaymentStatus>(
      `${this.url}/subscriptions-module/get-payment-status?paymentId=${payload.paymentId}`
    );
  }

  updateInvoiceAddress(payload: InvoiceAddress): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/subscriptions-module/update-invoice-address`,
      payload
    );
  }

  registerCard(payload: {
    cardToken: string;
    mask: string;
    organizationId: string;
    country: string;
  }): Observable<RegisterCardToken> {
    const baseAddress = document.getElementsByTagName('base')[0].href;
    const base = baseAddress.substring(0, baseAddress.length - 1);
    return this.httpClient.post<RegisterCardToken>(
      `${this.url}/subscriptions-module/register-card`,
      {
        ...payload,
        continueUrl: `${base}${this.environment.configuration.payU.redirect}`
      }
    );
  }

  removeCard(payload: {
    organizationId: string;
  }): Observable<RemoveCardStatusResponse> {
    return this.httpClient.post<RemoveCardStatusResponse>(
      `${this.url}/subscriptions-module/remove-card`,
      payload
    );
  }

  getOrganizationLimits$(payload: {
    organizationId: string;
  }): Observable<OrganizationLimits> {
    return this.httpClient.get<OrganizationLimits>(
      `${this.url}/subscriptions-module/get-organization-limits?organizationId=${payload.organizationId}`
    );
  }

  getRemainingActiveProjectLimitsForOrganization$(payload: {
    organizationId: string;
  }): Observable<number> {
    return this.httpClient.get<number>(
      `${this.url}/organizations-module/get-remaining-active-projects-limit-for-organization?organizationId=${payload.organizationId}`
    );
  }

  getRemainingStageLimitsForProject$(payload: {
    projectId: string;
  }): Observable<number> {
    return this.httpClient.get<number>(
      `${this.url}/construction-site-management-module/get-remaining-stages-limit-for-project?projectId=${payload.projectId}`
    );
  }

  getRemainingRootNoteLimitsForStage$(payload: {
    projectId: string;
  }): Observable<number> {
    return this.httpClient.get<number>(
      `${this.url}/construction-site-management-module/get-remaining-root-notes-limit-for-project?projectId=${payload.projectId}`
    );
  }

  getOrganizationIdByProjectId$(payload: {
    projectId: string;
  }): Observable<string> {
    return this.httpClient.get<string>(
      `${this.url}/organizations-module/get-project-organization-id?projectId=${payload.projectId}`
    );
  }

  getRemainingLimitOfOrganization$(): Observable<number> {
    return this.httpClient.get<number>(
      `${this.url}/organizations-module/get-remaining-organizations-limit`
    );
  }

  getRemainingSeatsLimitsForOrganization$(payload: {
    organizationId: string;
  }): Observable<{ remainingSeatsLimit: number; canInviteViewers: boolean }> {
    return this.httpClient.get<{
      remainingSeatsLimit: number;
      canInviteViewers: boolean;
    }>(
      `${this.url}/organizations-module/get-remaining-seats-limit-for-organization?organizationId=${payload.organizationId}`
    );
  }

  getOrganizationPromoCodes(payload: {
    organizationId: string;
  }): Observable<PromoCodes[]> {
    return this.httpClient.get<PromoCodes[]>(
      `${this.url}/subscriptions-module/get-organization-promocodes?organizationId=${payload.organizationId}`
    );
  }

  activatePromoCode(payload: {
    organizationId: string;
    promoCodeText: string;
  }): Observable<CodesValidation> {
    return this.httpClient.post<CodesValidation>(
      `${this.url}/subscriptions-module/activate-promocode`,
      payload
    );
  }
}
