import {
  Inputs,
  addColliderToGroup,
  mixinCustomComponent,
  removeColliderFromGroup,
} from '@simlab/simlab-facility-management/scene-object';
import {
  BufferGeometry,
  Camera,
  Group,
  Line,
  LineBasicMaterial,
  Scene,
  Vector3,
} from 'three';

const _TagComponentBase = mixinCustomComponent(class {});

export class TagComponent extends _TagComponentBase {
  override scene: Scene;
  override camera: Camera;
  override cameraContainer: Camera;
  override _group: Group = new Group();
  override lineBasicMaterial: typeof LineBasicMaterial = LineBasicMaterial;
  override bufferGeometry: typeof BufferGeometry = BufferGeometry;
  override line: typeof Line = Line;
  onClick?: (
    event: { type: 'onClick' } & { target: Group },
    component: this
  ) => void;
  constructor(
    private readonly _camera: Camera,
    private readonly _scene: Scene,
    private readonly _input: Inputs
  ) {
    super();
    this.camera = _camera;
    this.cameraContainer = _camera;
    this.scene = _scene;

    this.inputs = {
      ...this.inputs,
      ..._input,
    };
    this.onInit();
  }
  override onInit(): void {
    super.onInit();
    this._group.addEventListener('onClick', this._onClick.bind(this));
    this.collision = this.inputs.isCollider;
    this._scene.add(this._group);
    this.animate();
  }
  protected animate() {
    requestAnimationFrame(() => this.animate());
    this.animationFrame();
  }

  private _onClick(event: { type: 'onClick' } & { target: Group }) {
    this.onClick && this.onClick(event, this);
  }

  set collision(collision: boolean) {
    Object.defineProperty(this._group, 'collision', {
      value: collision,
      writable: true,
    });
    this._group.traverse((object) => {
      object.layers.set(collision ? 3 : 31);
    });

    collision
      ? addColliderToGroup(this._group)
      : removeColliderFromGroup(this._group);
  }

  override _lookAt(vector: Vector3): void {
    this._group.lookAt(this.camera.position);
  }
  override destroy(): void {
    this._group.removeEventListener('onClick', this._onClick);
    super.destroy();
  }
}
