import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IconSizes } from '../../models/icon-sizes';
import { ICON_TYPE } from '../../models/icon-type';
import { IconCacheService } from '../../services/icon-cache.service';

@Component({
  selector: `i[ui-icon]`,
  exportAs: 'uiIcon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnDestroy {
  private readonly destroy = new Subject<boolean>();

  @Input()
  set defaultColor(value: BooleanInput) {
    const state = coerceBooleanProperty(value);
    if (state) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        'force-default-color'
      );
    }
  }

  @Input()
  set customSize(value: string) {
    const wrapper = (this.elementRef.nativeElement as Element).firstChild;
    if (wrapper) {
      this.renderer.setStyle(wrapper, 'width', value);
      this.renderer.setStyle(wrapper, 'height', value);
    }
  }
  @Input()
  set size(value: IconSizes) {
    const wrapper = (this.elementRef.nativeElement as Element).firstChild;
    if (wrapper) {
      this.renderer.addClass(wrapper, `ui-${value}`);
    }
  }

  @Input()
  set name(value: ICON_TYPE) {
    const wrapper = (this.elementRef.nativeElement as Element).firstChild;
    if (wrapper && this.iconCacheService && value) {
      this.iconCacheService
        .getSvg(value)
        .pipe(takeUntil(this.destroy))
        .subscribe((svg: string) => {
          (wrapper as Element).innerHTML = svg;
        });
    }
  }

  constructor(
    @Optional()
    private readonly iconCacheService: IconCacheService,
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer2
  ) {
    const element = this.renderer.createElement('span');
    this.renderer.addClass(element, 'ui-icon-wrapper');
    this.renderer.appendChild(this.elementRef.nativeElement, element);
    this.renderer.addClass(this.elementRef.nativeElement, 'icon');
  }

  ngOnDestroy(): void {
    this.destroy.next(false);
    this.destroy.complete();
  }
}
