import { DOCUMENT, DatePipe } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withRouterConfig
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import {
  UserPreferenceFacade,
  componentsReducer,
  noteReducer,
  organizationPrivilegesReducer,
  organizationsReducer,
  projectsReducer,
  showcaseNotesReducer,
  stagesReducer,
  userPreferencesReducer
} from '@simlab/data-store';
import { ToastOverlayService, ToastService } from '@simlab/design/toast';

import { DATA_PICKER_FORMAT_CONFIG } from '@simlab/design/datepicker';

import { ScrollDispatcher } from '@angular/cdk/scrolling';
import { FORMAT_DATE_CONFIG } from '@simlab/design/format-date';
import { StagesScrollDispatcher } from '@simlab/design/layout';
import { API_GOOGLE, API_GOOGLE_URL, loadGoogleScript } from '@simlab/ui/map';
import {
  DEV_MODE,
  ENVIRONMENT_CONFIG,
  Environment,
  ToastEventService,
  UNITY_PLATFORM_URL
} from '@simlab/util-shared';
import { InsightsModule, UtilCoreModule } from '@simlab/util/core';
import { environment } from '../environments/environment';
import {
  loadFormatData,
  loadFormatDataToDataPicker
} from './app-configuration-function';
import { appRoutes } from './app.routes';
import { CheckForUpdateService } from './check-for-update.service';

export const appConfig: ApplicationConfig = {
  providers: [
    ToastEventService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => {
    //     return () => {

    //       const userLanguage = localStorage.getItem(
    //         STAGES_USER_LANGUAGE
    //       ) as SupportedLanguage;
    //       if (userLanguage !== null) {
    //         const locationPath = location.pathname.split('/')[1];
    //         if (locationPath !== userLanguage) {
    //           location.href =
    //             location.origin +
    //             location.pathname
    //               .split('/')
    //               .map((element, index) => {
    //                 if (index === 1) {
    //                   return userLanguage;
    //                 }
    //                 return element;
    //               })
    //               .join('/');
    //         }
    //       }
    //     };
    //   },
    //   multi: true,
    // },
    provideRouter(
      appRoutes(environment),
      withComponentInputBinding(),
      withRouterConfig({
        paramsInheritanceStrategy: 'always'
      })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    importProvidersFrom(
      UtilCoreModule.forRoot(environment),
      InsightsModule.forRoot()
    ),

    provideStore(
      {
        notes: noteReducer,
        stages: stagesReducer,
        router: routerReducer,
        projects: projectsReducer,
        components: componentsReducer,
        organizations: organizationsReducer,
        showcaseNotes: showcaseNotesReducer,
        userPreference: userPreferencesReducer,
        privileges: organizationPrivilegesReducer
      },
      {
        metaReducers: [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true
        }
      }
    ),

    provideStoreDevtools({ connectInZone: true }),
    provideRouterStore(),

    SwUpdate,
    DatePipe,
    ToastService,
    ToastOverlayService,
    CheckForUpdateService,
    {
      provide: FORMAT_DATE_CONFIG,
      deps: [UserPreferenceFacade],
      useFactory: loadFormatData
    },
    {
      provide: UNITY_PLATFORM_URL,
      useFactory: () => {
        const userAgent = window.navigator.userAgent;
        const isMac = userAgent.indexOf('Mac') !== -1;
        const isWindows = userAgent.indexOf('Win') !== -1;
        const isIOS =
          /iPad|iPhone|iPod/.test(userAgent) ||
          (isMac && 'ontouchend' in document);

        if (isMac && !isIOS) {
          return environment.configuration.unity.macBook;
        } else if (isWindows) {
          return environment.configuration.unity.windows;
        }
        return undefined;
      }
    },
    {
      provide: DEV_MODE,
      useValue: !environment.production && !environment.staging
    },
    {
      provide: ScrollDispatcher,
      useClass: StagesScrollDispatcher
    },
    {
      provide: DATA_PICKER_FORMAT_CONFIG,
      deps: [UserPreferenceFacade],
      useFactory: loadFormatDataToDataPicker
    },
    {
      provide: ENVIRONMENT_CONFIG,
      useValue: environment
    },
    {
      provide: API_GOOGLE_URL,
      useFactory: (environment: Environment) =>
        environment.configuration.googleMaps.apiKey,
      deps: [ENVIRONMENT_CONFIG]
    },
    {
      provide: API_GOOGLE,
      deps: [DOCUMENT, API_GOOGLE_URL],
      useFactory: loadGoogleScript
    }
  ]
};
