import {
  SortingTypeOrganizationRoles,
  SortingTypeOrganizationTeams,
  SortingTypeOrganizationUsers,
  SortingType,
  SortingTypeOrganizationProject,
} from './sorting-type';

export interface ProjectsSort {
  type:
    | SortingType
    | SortingTypeOrganizationProject
    | SortingTypeOrganizationUsers
    | SortingTypeOrganizationTeams
    | SortingTypeOrganizationRoles;
  ascending: boolean;
}
