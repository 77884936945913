import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiIconModule } from '../ui-icon/ui-icon.module';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import { ChipComponent } from './components/chip/chip.component';

@NgModule({
  declarations: [ChipListComponent, ChipComponent],
  imports: [CommonModule, UiIconModule, DesignIcon, DesignIconButton],
  exports: [UiIconModule, ChipListComponent, ChipComponent]
})
export class UiChipsModule {}
