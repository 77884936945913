import { Action, createReducer, on } from '@ngrx/store';
import { IErrorResponse, UserPreferences } from '@simlab/data-access';
import * as UserPreferenceActions from './user-preference.actions';
export const USER_PREFERENCE_FEATURE_KEY = 'userPreference';

export interface State {
  userPreferences: UserPreferences | undefined;
  userLoggedToProcore: boolean | undefined;
  loaded: boolean; // has the Stages list been loaded
  error?: IErrorResponse | null; // last known error (if any)
}

export interface UserPReferencePartialState {
  readonly [USER_PREFERENCE_FEATURE_KEY]: State;
}

export const initialState: State = {
  userPreferences: undefined,
  loaded: false,
  userLoggedToProcore: undefined
};

export const userPreferencesReducer = createReducer(
  initialState,
  on(UserPreferenceActions.init, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(UserPreferenceActions.initSuccess, (state, { userPreferences }) => {
    return {
      ...state,
      userPreferences,
      loaded: true
    };
  }),

  on(UserPreferenceActions.updateUserPreferences, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(
    UserPreferenceActions.updateUserPreferencesSuccess,
    (state, { userPreferences }) => {
      return {
        ...state,
        userPreferences,
        loaded: true
      };
    }
  ),
  on(UserPreferenceActions.updateUserPreferencesFailure, (state, { error }) => {
    return {
      ...state,
      loaded: false,
      loading: false,
      error: error
    };
  }),
  on(UserPreferenceActions.userLoggedToProcore, (state) => {
    return {
      ...state,
      loaded: false,
      loading: true
    };
  }),
  on(
    UserPreferenceActions.userLoggedToProcoreSuccess,
    (state, { userLoggedToProcore }) => {
      return {
        ...state,
        loaded: true,
        loading: false,
        userLoggedToProcore
      };
    }
  ),
  on(UserPreferenceActions.updateUserPreferencesFailure, (state, { error }) => {
    return {
      ...state,
      loaded: false,
      loading: false,
      userLoggedToProcore: false
    };
  })
);

export function reducer(state: State | undefined, action: Action) {
  return userPreferencesReducer(state, action);
}
