import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignFlatButton, DesignStrokedButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { ConfirmationModalRef } from '../../models/confirmation-modal-ref';
import { MODAL_DATA } from '../../tokens/modal-data.token';

@Component({
  selector: 'ui-exit-compare-confirmation-dialog',
  templateUrl: './exit-compare-confirmation-dialog.component.html',
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DesignCommonModule,
    DesignDialogWrapperModule,
    DesignFlatButton,
    DesignStrokedButton,
  ],
})
export class ExitCompareConfirmationDialogComponent {
  constructor(
    @Inject(MODAL_DATA)
    public readonly modalData: {
      text: string;
      confirmButton?: string;
      title: string;
    },
    private readonly modalRef: ConfirmationModalRef<unknown>
  ) {}

  confirm(): void {
    this.modalRef.emit({ state: true });
    this.modalRef.close();
  }

  cancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
