/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive } from '@angular/core';
import { designPrefixToken } from '../tokens/prefix.token';
import { AffixBase } from './affix-base.directive';

@Directive({
  selector: '[designPrefix], [designIconPrefix], [designTextPrefix]',
  standalone: true,
  host: {
    class: 'design-prefix',
  },
  providers: [{ provide: designPrefixToken, useExisting: DesignPrefix }],
})
export class DesignPrefix extends AffixBase {}
