import { InjectionToken } from '@angular/core';
import { Euler, PerspectiveCamera, Vector3 } from 'three';

export const SIMLAB_CAMERA = new InjectionToken<SimlabCamera>('Simlab camera');
export class SimlabCamera {
  private readonly _camera: PerspectiveCamera;
  constructor(
    private _fov = 60,
    private _aspect = 1,
    private _near = 0.01,
    private _far = 1000
  ) {
    this._camera = new PerspectiveCamera(_fov, _aspect, _near, _far);
    this._camera.layers.enableAll();
  }
  get camera(): PerspectiveCamera {
    return this._camera;
  }

  set position(position: Vector3) {
    this._camera.position.copy(position);
  }

  get position() {
    return this._camera.position;
  }

  set rotation(rotation: Euler) {
    this._camera.rotation.copy(rotation);
  }
  get rotation() {
    return this._camera.rotation;
  }

  set aspect(aspect: number) {
    this.camera.aspect = aspect;
    this.camera.updateProjectionMatrix();
  }
  lookAt(position: Vector3) {
    this._camera.lookAt(position);
  }
}
