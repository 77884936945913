import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  ViewEncapsulation
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterModule, RouterOutlet } from '@angular/router';
import { DesignStrokedButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { DocumentsInstanceDataToken } from '@simlab/documents/data-access';
import { UiDividerModule } from '@simlab/ui/divider';

import { UiTabsModule } from '@simlab/design/tabs';
import { canOpenProcoreTab } from './documents.routes';

@Component({
  selector: 'feature-projects-documents-page',
  standalone: true,
  imports: [
    RouterModule,
    UiTabsModule,
    UiDividerModule,
    DesignStrokedButton,
    DesignIcon,
    RouterOutlet
  ],
  templateUrl: './documents-page.component.html',
  styleUrls: ['./documents-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'feature-projects-documents-page'
  },
  providers: [
    {
      provide: DocumentsInstanceDataToken,
      useExisting: DocumentsPageComponent
    }
  ]
})
export class DocumentsPageComponent {
  readonly projectConnectedWithProcore = toSignal(
    canOpenProcoreTab(inject(ActivatedRoute).snapshot)
  );
  readonly projectId = input.required<string>();
}
