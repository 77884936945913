export interface OrganizationTeam {
  teamId: string;
  teamName: string;
  teamDescription: string;
  projectsCount: number;
  tagsCount: number;
  usersCount: number;
  ownerId: string;
  ownerName: string;
}
