import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class TitleService {
  private readonly _titleSource: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  private readonly _title$ = this._titleSource.asObservable();

  get title$(): Observable<string> {
    return this._title$;
  }

  setTitle(value: string): void {
    this._titleSource.next(value);
  }
}
