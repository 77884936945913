import { inject, Injectable } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, select, Store } from '@ngrx/store';
import { ProjectPermission } from '@simlab/data-access';
import { map, Observable } from 'rxjs';
import { ProjectPrivilegesActions } from './privileges.actions';
import * as PrivilegesSelectors from './privileges.selectors';

@Injectable()
export class ProjectPrivilegesFacade {
  private readonly action$ = inject(ActionsSubject);
  private readonly _store: Store = inject(Store);
  readonly permissionLoaded$: Observable<boolean> = this.action$
    .pipe(
      ofType(
        ProjectPrivilegesActions.loadProjectPermissionsSuccess,
        ProjectPrivilegesActions.loadProjectPermissionsFailure
      )
    )
    .pipe(
      map((e) => {
        return e.type === '[Privileges/API] Load Project Permissions Success';
      })
    );

  readonly hasPrivilegesTo$ = (
    permission: ProjectPermission
  ): Observable<boolean> =>
    this._store.pipe(select(PrivilegesSelectors.getPermission(permission)));

  readonly addEditDeleteOwnNotes$: Observable<boolean> = this._store.pipe(
    select(
      PrivilegesSelectors.getPermission(ProjectPermission.AddEditDeleteOwnNotes)
    )
  );

  readonly deleteNote$: Observable<boolean> = this._store.pipe(
    select(PrivilegesSelectors.getPermission(ProjectPermission.DeleteNote))
  );

  readonly renameNote$: Observable<boolean> = this._store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        ProjectPermission.RenameNoteAndChangeDescription
      )
    )
  );

  readonly addReplaceMarker$: Observable<boolean> = this._store.pipe(
    select(
      PrivilegesSelectors.getPermission(ProjectPermission.AddReplaceMarker)
    )
  );

  readonly changeNoteStatus$: Observable<boolean> = this._store.pipe(
    select(
      PrivilegesSelectors.getPermission(ProjectPermission.ChangeNoteStatus)
    )
  );

  readonly changeStakeholder$: Observable<boolean> = this._store.pipe(
    select(
      PrivilegesSelectors.getPermission(ProjectPermission.ChangeStakeholder)
    )
  );

  readonly addEditDeleteOwnElements$: Observable<boolean> = this._store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        ProjectPermission.AddEditDeleteOwnElements
      )
    )
  );

  readonly AddEditDeleteComponentsInOwnProjects$: Observable<boolean> =
    this._store.pipe(
      select(
        PrivilegesSelectors.getPermission(
          ProjectPermission.AddEditDeleteComponentsInOwnProjects
        )
      )
    );

  readonly editDeleteOtherUserElements$: Observable<boolean> = this._store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        ProjectPermission.EditDeleteOtherUserElements
      )
    )
  );

  readonly addEditDeleteStagesInOwnProjects$: Observable<boolean> =
    this._store.pipe(
      select(
        PrivilegesSelectors.getPermission(
          ProjectPermission.AddEditDeleteStagesInOwnProjects
        )
      )
    );

  readonly addEditDeleteComponentsInOtherUsersProjects$: Observable<boolean> =
    this._store.pipe(
      select(
        PrivilegesSelectors.getPermission(
          ProjectPermission.AddEditDeleteComponentsInOtherUsersProjects
        )
      )
    );

  readonly AddEditDeleteStagesInOtherUsersProjects$: Observable<boolean> =
    this._store.pipe(
      select(
        PrivilegesSelectors.getPermission(
          ProjectPermission.AddEditDeleteStagesInOtherUsersProjects
        )
      )
    );

  readonly canAccessSynchronizationPanel$: Observable<boolean> =
    this._store.pipe(
      select(
        PrivilegesSelectors.getPermission(
          ProjectPermission.CanAccessSynchronizationPanel
        )
      )
    );

  readonly addEditDeleteOwnComments$: Observable<boolean> = this._store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        ProjectPermission.AddEditDeleteOwnComments
      )
    )
  );

  readonly editDeleteOtherUserComments$: Observable<boolean> = this._store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        ProjectPermission.EditDeleteOtherUserComments
      )
    )
  );

  loadPrivileges(projectId: string): void {
    this._store.dispatch(
      ProjectPrivilegesActions.loadProjectPermissions({ projectId })
    );
  }

  clearStore() {
    this._store.dispatch(ProjectPrivilegesActions.clearStore());
  }

  readonly hasAdminRoleInProject$: Observable<boolean> = this._store.pipe(
    select(PrivilegesSelectors.hasAdminRoleInProject)
  );

  readonly hasViewerRoleInProject$: Observable<boolean> = this._store.pipe(
    select(PrivilegesSelectors.hasViewerRoleInProject)
  );
}
