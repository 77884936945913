import {
  BlueprintComponent,
  ChildConfiguration,
  ClickEventEmitter,
  ComponentConfiguration,
  ICustomComponent,
} from '@simlab/simlab-facility-management/scene-object';
import { TransformConverter } from '@simlab/transform';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import * as THREE from 'three';
import { Camera, Scene } from 'three';
import { movePositionByOffset } from '../common/move-by-offset';
import { TagComponent } from '../components/tag-component';
/**
 
 * @param camera - (Camera): The camera object used for rendering the scene.
 * @param scene - (Scene): The scene object where the tag components will be
 * @param offset -  (TransformConverter): An instance of the TransformConverter class used for calculating position offsets.
 * @returns - (ICustomComponent<TagComponent>): An object with methods for managing tag components.
 * @description returns factory that  creates and manages tag components in a 3D scene. It takes a camera, a scene, and an instance of the TransformConverter class as inputs. The function returns an object with methods for adding, updating, and deleting tag components, as well as managing the selected tag component.
  *@example
  *import { tagComponentFactory } from './tagComponentFactory';
  *
  *const camera = new PerspectiveCamera();
  *const scene = new Scene();
  *const offset = new TransformConverter();
  *
  *const tagComponentManager = tagComponentFactory(camera, scene, offset);
  *
  *tagComponentManager.addComponent$({
  *  id: 'tag1',
  *  position: { x: 0, y: 0, z: 0 },
  *  normal: { x: 0, y: 1, z: 0 },
  *  objects: [],
  *}).subscribe((component) => {
  *  console.log('Tag component added:', component);
  *});
  *
  *tagComponentManager.updateNote({
  *  id: 'tag1',
  *  position: { x: 1, y: 0, z: 0 },
  *  normal: { x: 0, y: 1, z: 0 },
  *  stemHeight: 1,
  *});
  *
  *tagComponentManager.deleteNote('tag1');
 */
export class TagComponentFactory implements ICustomComponent<TagComponent> {
  private readonly _components: Map<string, TagComponent> = new Map();
  private readonly _componentClicked: Subject<ClickEventEmitter> =
    new Subject<ClickEventEmitter>();
  private readonly _selectedTag: BehaviorSubject<string | undefined> =
    new BehaviorSubject<string | undefined>(undefined);
  readonly selectedNote$ = this._selectedTag.asObservable();
  readonly componentClicked$ = this._componentClicked.asObservable();

  constructor(
    private readonly _camera: Camera,
    private readonly _scene: Scene,
    private readonly _offset: TransformConverter
  ) {}

  private _addCollider = (tagComponent: TagComponent) => {
    tagComponent.onClick = (event, component) => {
      const id = component.inputs.id;
      this._selectedTag.next(id);
      this._componentClicked.next({ id, userData: component._group.userData });
    };
  };
  private _canAddComponent = (id: string) => {
    if (this._components.get(id))
      throw Error(`Tag with id ${id} already exist`);
  };
  private _getComponent = (id: string): TagComponent => {
    const component = this._components.get(id);
    if (!component) throw Error(`Tag with id ${id} does not exist in scene`);
    return component;
  };
  set selectedNote(noteId: string | undefined) {
    if (noteId) this._getComponent(noteId);
    this._selectedTag.next(noteId);
  }
  get selectedNote(): string | undefined {
    return this._selectedTag.getValue();
  }

  addComponent$ = (
    component: ComponentConfiguration
  ): Observable<TagComponent> => {
    this._canAddComponent(component.id);
    const _tagComponent = new TagComponent(
      this._camera,
      this._scene,
      component
    );
    component.objects.forEach((child: ChildConfiguration) => {
      child.init(THREE);
      _tagComponent.addChild(
        child.instance.object3D,
        child.instance instanceof BlueprintComponent
      );
    });
    this._components.set(component.id, _tagComponent);
    this._addCollider(_tagComponent);
    return of(_tagComponent);
  };
  addComponentWithOffset$ = (
    component: ComponentConfiguration
  ): Observable<TagComponent> => {
    const movedComponent = movePositionByOffset(component, this._offset);
    return this.addComponent$({
      ...component,
      ...movedComponent,
    });
  };
  updateNote$ = (
    component: Pick<
      ComponentConfiguration,
      'position' | 'normal' | 'id' | 'stemHeight'
    >
  ): Observable<void> => {
    return of(this.updateNote(component));
  };
  updateNote = (
    component: Pick<
      ComponentConfiguration,
      'position' | 'normal' | 'id' | 'stemHeight'
    >
  ): void => {
    const _component = this._getComponent(component.id);
    _component.inputs.normal = component.normal;
    _component.inputs.stemHeight = component.stemHeight;
    _component.position = component.position;
  };
  updatePositionWithOffset = (
    component: Pick<
      ComponentConfiguration,
      'position' | 'normal' | 'id' | 'stemHeight' | 'rotation' | 'scale'
    >
  ): void => {
    this.updateNote({
      ...component,
      ...movePositionByOffset(component, this._offset),
    });
  };
  updatePositionWithOffset$ = (
    component: Pick<
      ComponentConfiguration,
      'position' | 'normal' | 'id' | 'stemHeight' | 'rotation' | 'scale'
    >
  ): Observable<void> => {
    return of(this.updatePositionWithOffset(component));
  };
  deleteNote = (noteId: string): string => {
    const component = this._getComponent(noteId);
    if (this.selectedNote === noteId) this.selectedNote = undefined;
    component.destroy();
    this._components.delete(noteId);
    return noteId;
  };
  deleteNote$ = (noteId: string): Observable<string> => {
    return of(this.deleteNote(noteId));
  };
  clearAllNotes = (): void => {
    this._components.forEach((_: TagComponent, id: string) => {
      this.deleteNote(id);
    });
  };

  clearAllNotes$ = (): Observable<void> => {
    return of(this.clearAllNotes());
  };
}
