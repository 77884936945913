import { InjectionToken, Signal } from '@angular/core';
import { Observable } from 'rxjs';

export type FormatDateConfig = {
  observable: Observable<string>;
  signal: Signal<string>;
};

export const FORMAT_DATE_CONFIG = new InjectionToken<FormatDateConfig>(
  'FORMAT_DATE_CONFIG'
);
