import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { ComponentRef } from '@angular/core';
import { ToastContainerBase } from '../toast-container-base.directive';

export class OverlayRef {
  constructor(private _portalHost: DomPortalOutlet) {}

  attach(
    portal: ComponentPortal<ToastContainerBase>
  ): ComponentRef<ToastContainerBase> {
    return this._portalHost.attach(portal);
  }

  detach() {
    return this._portalHost.detach();
  }
}
