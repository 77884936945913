import { KeyValue } from '@angular/common';
import { DateRange, IChipItem } from '@simlab/data-access';
import { StageFilterBase } from '../services/stage-filters.service';
import { FilterForm } from './filter-form';

export class FiltersChipsBase extends FilterForm {
  constructor(
    private readonly _form: StageFilterBase,
    private readonly authorsEmail?: string[],
    private readonly stakeholderEmail?: string[]
  ) {
    super(_form);
  }

  private _removeStatus = (el: string) => {
    const index = this._date.rootNoteStatuses.indexOf(el);
    const data = [...this._date.rootNoteStatuses];
    data.splice(index, 1);
    return data;
  };
  private _removeType = (el: string) => {
    const index = this._date.rootNoteTypes.indexOf(el);
    const data = [...this._date.rootNoteTypes];
    data.splice(index, 1);
    return data;
  };

  private _removeAuthor = (el: string) => {
    const index = this.authorsEmail?.indexOf(el);
    const data = [...this._date.rootNoteAuthors];
    if (index !== undefined) {
      data.splice(index, 1);
      this.authorsEmail?.splice(index, 1);
    }
    return data;
  };

  private _removeStakeholder = (el: string) => {
    const index = this.stakeholderEmail?.indexOf(el);
    const data = [...this._date.stakeholders];
    if (index !== undefined) {
      data.splice(index, 1);
      this.stakeholderEmail?.splice(index, 1);
    }
    return data;
  };

  toFormValue(chip: KeyValue<string, string>): {
    [name in keyof StageFilterBase]?: any;
  } {
    const form: {
      [name in keyof StageFilterBase]?: any;
    } = JSON.parse(JSON.stringify(this._date));
    switch (chip.key) {
      case 'Created': {
        (form.rootNoteCreated as DateRange).from = '';
        (form.rootNoteCreated as DateRange).to = '';
        break;
      }
      case 'In Progress': {
        form.rootNoteStatuses = this._removeStatus('InProgress');
        break;
      }
      case 'Information': {
        form.rootNoteTypes = this._removeType('Information');
        break;
      }
      case 'Modified': {
        (form.rootNoteModified as DateRange).from = '';
        (form.rootNoteModified as DateRange).to = '';
        break;
      }
      case 'Pending': {
        form.rootNoteStatuses = this._removeStatus('Pending');

        break;
      }
      case 'Resolved': {
        form.rootNoteStatuses = this._removeStatus('Resolved');

        break;
      }
      case 'Stage': {
        (form.rootNoteStageDate as DateRange).from = '';
        (form.rootNoteStageDate as DateRange).to = '';
        break;
      }
      case 'Unresolved': {
        form.rootNoteStatuses = this._removeStatus('Unresolved');

        break;
      }
      case 'Author': {
        form.rootNoteAuthors = this._removeAuthor(chip.value);
        break;
      }
      case 'Stakeholder': {
        form.stakeholders = this._removeStakeholder(chip.value);
        break;
      }
      case 'Own Note': {
        form.onlyMyNotes = '';
        break;
      }
    }
    if (!form.rootNoteStatuses.length && form.rootNoteTypes.includes('Issue')) {
      form.rootNoteTypes = this._removeType('Issue');
    }
    return form;
  }

  mapToChips(): IChipItem[] {
    const chips: IChipItem[] = [];

    if (this.info) {
      chips.push({ key: 'Information', value: '' });
    }
    if (this.pending) {
      chips.push({ key: 'Pending', value: '' });
    }
    if (this.inProgress) {
      chips.push({ key: 'In Progress', value: '' });
    }
    if (this.resolved) {
      chips.push({ key: 'Resolved', value: '' });
    }
    if (this.unresolved) {
      chips.push({ key: 'Unresolved', value: '' });
    }
    if (this.fromNoteCreation && this.toNoteCreation) {
      chips.push({
        key: 'Created',
        value: `${this.fromNoteCreation} - ${this.toNoteCreation}`
      });
    }
    if (this.fromNoteModified && this.toNoteModified) {
      chips.push({
        key: 'Modified',
        value: `${this.fromNoteModified} - ${this.toNoteModified}`
      });
    }
    if (this.fromRange && this.toRange) {
      chips.push({
        key: 'Stage',
        value: `${this.fromRange} - ${this.toRange}`
      });
    }
    if (this.rootNoteAuthors) {
      this.authorsEmail?.forEach((author) => {
        chips.push({ key: 'Author', value: `${author}` });
      });
    }
    if (this.stakeholders) {
      this.stakeholderEmail?.forEach((author) => {
        chips.push({ key: 'Stakeholder', value: `${author}` });
      });
    }
    if (this.onlyMyNotes) {
      chips.push({ key: 'Own Note', value: '' });
    }

    return chips;
  }
}
