import { DateRange } from './date-range';

export interface NoteFilters {
  rootNoteTypes: string[];
  rootNoteStatuses: string[];
  rootNoteCreated: DateRange;
  rootNoteModified: DateRange;
  rootNoteStageDate: DateRange;
  onlyMyNotes: boolean;
  rootNoteAuthors: string[];
  stakeholders: string[];
  byNoteAuthor: boolean;
  selectedStakeholders: boolean;
  name?: string;
}

export const NoteSort = {
  Title: 1,
  Status: 2,
  LastModified: 3
};

export type NoteSort = (typeof NoteSort)[keyof typeof NoteSort];
