/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-selector */
/* eslint-disable @angular-eslint/directive-class-suffix */
import {
  AfterContentInit,
  Directive,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { designRadioAccordionBaseToken } from '../../tokens/radio-accordion.token';
import { DesignAccordion } from '../accordion.directive';
import { ExpansionPanelHeader } from '../expansion-panel-header.directive';
import { DesignRadioExpansionPanel } from './radio-expansion-panel.component';

export type DesignRadioAccordionInput = string | number | null;

export class DesignRadioAccordionChange {
  constructor(
    /** The radio button that emits the change event. */
    public source: DesignRadioExpansionPanel,
    /** The value of the radio button. */
    public value: any
  ) {}
}

/**
 * Provider Expression that allows mat-radio-group to register as a ControlValueAccessor. This
 * allows it to support [(ngModel)] and ngControl.
 * @docs-private
 */

export const designRadioAccordionControlValueAccessorProvider: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DesignRadioAccordion),
  multi: true,
};

@Directive({
  selector: 'design-radio-accordion',
  exportAs: 'designRadioAccordion',
  standalone: true,
  providers: [
    designRadioAccordionControlValueAccessorProvider,
    {
      provide: designRadioAccordionBaseToken,
      useExisting: DesignRadioAccordion,
    },
  ],
  host: {
    class: 'design-base-accordion design-radio-accordion',
    role: 'radiogroup',
  },
})
export class DesignRadioAccordion
  extends DesignAccordion
  implements ControlValueAccessor, AfterContentInit
{
  @Output()
  readonly byChange: EventEmitter<DesignRadioAccordionChange> = new EventEmitter<DesignRadioAccordionChange>();

  private _value: DesignRadioAccordionInput = null;

  @Input()
  get value(): DesignRadioAccordionInput {
    return this._value;
  }
  set value(newValue: DesignRadioAccordionInput) {
    if (this._value !== newValue) {
      // Set this before proceeding to ensure no circular loop occurs with selection.
      this._value = newValue;

      this._updateSelectedFromValue();
      this._checkAndExpandSelected();
    }
  }

  private _selected: DesignRadioExpansionPanel | null = null;

  @Input()
  get selected() {
    return this._selected;
  }
  set selected(selected: DesignRadioExpansionPanel | null) {
    this._selected = selected;
    this.value = selected ? selected.value : null;
    this._checkAndExpandSelected();
  }

  /** Whether the `value` has been set to its initial value. */
  private _isInitialized = false;

  override ngAfterContentInit(): void {
    super.ngAfterContentInit();

    this._isInitialized = true;
    this._updateSelectedFromValue();
    this._checkAndExpandSelected();
  }

  /**
   * onTouch function registered via registerOnChange (ControlValueAccessor).
   * @docs-private
   */
  _onChange: (value: any) => void = () => {};

  /**
   * onTouch function registered via registerOnTouch (ControlValueAccessor).
   * @docs-private
   */
  _onTouched: () => any = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  private _checkAndExpandSelected() {
    if (this._selected && !this._selected.expanded) {
      this._selected.expanded = true;
    }
  }

  /** Updates the `selected` radio button from the internal _value state. */
  private _updateSelectedFromValue(): void {
    const isAlreadySelected =
      this._selected !== null && this._selected.value === this._value;
    if (this._allHeaderDescendants && !isAlreadySelected) {
      this._selected = null;
      const panelHeader = this._allHeaderDescendants.find(
        (header: ExpansionPanelHeader) => header._panel.value === this.value
      );
      this._selected = panelHeader?._panel ?? null;
    }
  }
}
