import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { API_URL } from '@simlab/data-access';
import { ProjectsFacade, StagesFacade } from '@simlab/data-store';
import { RouterStoreParams } from '@simlab/util-shared';
import { defer, EMPTY, map, Observable } from 'rxjs';

@Injectable()
export class ProcoreDownloadResourceGetterService {
  private readonly _stagesFacade = inject(StagesFacade);
  private readonly _url = inject<string>(API_URL);
  private readonly _httpClient = inject(HttpClient);

  private readonly _projectId$: Observable<string> = defer(() =>
    this._stagesFacade.getRouteNestedParams$.pipe(
      map((params: RouterStoreParams) => params.params['projectId'])
    )
  );

  private readonly _projectId = toSignal(this._projectId$, {
    requireSync: true
  });

  private readonly _selectedProject = toSignal(
    inject(ProjectsFacade).selectProjectById$(this._projectId())
  );

  getResourceDownloadUrl$(resourceUrl: string) {
    const procoreCompanyId = this._selectedProject()?.procoreInfo?.companyId;
    if (!procoreCompanyId) return EMPTY;

    return this._httpClient.get(
      `${this._url}/procore-api/get-url-location?ProcoreCompanyId=${procoreCompanyId}&Url=${encodeURIComponent(resourceUrl)}`,
      { responseType: 'text' }
    );
  }
}
