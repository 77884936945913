import { InjectionToken } from '@angular/core';
import { Environment } from '../models/environment.interface';

export const ENVIRONMENT_CONFIG = new InjectionToken<Environment>(
  'ENVIRONMENT_CONFIG'
);

export const DEV_MODE = new InjectionToken<boolean>('DEV_MODE');

export const UNITY_PLATFORM_URL = new InjectionToken<string | undefined>(
  'UNITY_PLATFORM'
);
