<div class="form-field-label-wrapper" *ngIf="hasLabel">
  <ng-content select="label"></ng-content>
  <span
    *ngIf="control.required"
    aria-hidden="true"
    class="form-field-label-marker"
  ></span>
</div>

<div
  class="form-field-wrapper"
  [class.form-field-wrapper--disable-padding]="disablePadding"
  [class.form-field-wrapper--disable-hover]="disableHover"
  (click)="control.onContainerClick($event)"
>
  <div *ngIf="hasIconPrefix">
    <ng-content select="[designPrefix], [designIconPrefix]"></ng-content>
  </div>
  <div *ngIf="hasTextPrefix">
    <ng-content select="[designTextPrefix]"></ng-content>
  </div>
  <!-- <div class="input-wrapper">
    <ng-content select="[designInput]"></ng-content>
  </div> -->
  <div class="control-wrapper">
    <ng-content select="[designInput], design-date-range-picker"></ng-content>
    <ng-content
      select="design-chip-input-panel, [designChipInputPanel]"
    ></ng-content>
  </div>
  <div *ngIf="hasIconSuffix" class="suffix-wrapper">
    <ng-content select="[designSuffix], [designIconSuffix]"></ng-content>
  </div>
  <div *ngIf="hasTextSuffix">
    <ng-content select="[designTextSuffix]"></ng-content>
  </div>
</div>

<div class="form-field-subscript-wrapper" [ngSwitch]="getDisplayedMessages()">
  <div class="form-field-hint-wrapper" *ngSwitchCase="'hint'">
    <ng-content select="design-hint, [designHint]"></ng-content>
  </div>
  <div class="form-field-error-wrapper" *ngSwitchCase="'error'">
    <ng-content select="design-error, [designError]"></ng-content>
  </div>
</div>
