import { createAction, props } from '@ngrx/store';
import {
  Media,
  MediaType,
  Note,
  NoteBase,
  NoteFilters,
  Page
} from '@simlab/data-access';
import { State } from './notes.reducer';

export const init = createAction(
  '[Notes Page] Init',
  props<{
    payload: {
      stageId: string[] | null;
      page: { pageSize?: number; pageNumber: number };
      rootNotesFilter?: NoteFilters;
    };
    noteId?: string;
  }>()
);

export const clearStore = createAction('[Notes Page] Clear Store');

export const selectedNote = createAction(
  '[Notes Page] Set Selected Note',
  props<{ note: Note }>()
);

export const selectedNoteId = createAction(
  '[Notes Page] Set Selected Note Id',
  props<{ noteId: string | undefined }>()
);

export const selectedNoteLocally = createAction(
  '[Notes Page] Set Selected Note Locally',
  props<{ note: Note }>()
);

export const deleteNoteLocally = createAction(
  '[Notes/API] Delete Note Locally'
);

export const deleteNote = createAction(
  '[Notes/API] Delete Note',
  props<{ rootNoteId: string; stageId: string }>()
);

export const deleteNoteSuccess = createAction(
  '[Notes/API] Delete Note Success',
  props<{ noteId: string; stageId: string }>()
);

export const selectedNoteIdAfterDeleteNote = createAction(
  '[Notes/API] Set Selected Note Id after delete note',
  props<{ noteId: string }>()
);

export const deleteNoteFailure = createAction(
  '[Notes/API] Delete Note Failure',
  props<{ error: any }>()
);

export const loadNoteSuccess = createAction(
  '[Notes/API] Load Note Success',
  props<{ note: Note[] }>()
);

export const metadata = createAction(
  '[Notes/API] Load Note Metadata Success',
  props<{ metadata: Page }>()
);

export const loadNoteFailure = createAction(
  '[Notes/API] Load Note Failure',
  props<{ error: any }>()
);

export const loadNoteListFailure = createAction(
  '[Notes/API] Load Note List Failure'
);

export const addDigitalNote = createAction(
  '[Notes Side Panel] Add Digital Note To Root',
  props<{ payload: Media; mediaType: MediaType }>()
);

export const addDigitalNoteByID = createAction(
  '[Notes Side Panel] Add Digital Note To Root By ID',
  props<{
    payload: Media;
    mediaType: MediaType | (string & {});
    nodeId: string;
  }>()
);

export const removeEmptyDigitalNote = createAction(
  '[Notes Side Panel] Remove Empty Digital Note To Root',
  props<{ mediaType: MediaType | (string & {}) }>()
);

export const removeDigitalNote = createAction(
  '[Notes Side Panel] Remove Digital Note From Root',
  props<{ id: string; mediaType: MediaType }>()
);

export const removeMarker = createAction(
  '[Matterport] Remove Markert From Root  Note ',
  props<{ noteId: string }>()
);

export const changeNoteStakeholder = createAction(
  '[Notes Side Panel] Change Note Stakeholder',
  props<{ stakeholderId: string }>()
);

export const changeNoteStatus = createAction(
  '[Notes Side Panel] Change Note Status',
  props<{ status: string }>()
);

export const changeNoteStatusSuccess = createAction(
  '[Notes Side Panel] Change Note Status Success',
  props<{ note: Note }>()
);

export const changeNoteDescription = createAction(
  '[Notes Side Panel] Change Note Description',
  props<{ nodeDescription: string }>()
);

export const changeNoteName = createAction(
  '[Notes Side Panel] Change Note Name',
  props<{ name: string }>()
);

export const updateNote = createAction(
  '[Notes Side Panel] Update Note',
  props<{ note: Note }>()
);

export const changeNoteType = createAction(
  '[Notes Side Panel] Change Note Type',
  props<{ nodeType: string }>()
);

export const selectFirst = createAction('[Notes Side Panel] Select First Note');

export const removeNote = createAction(
  '[Notes Side Panel] Remove Note From Root',
  props<{
    response: {
      rootNoteId: string;
      stageId: string;
      status: string;
      type: string;
    };
  }>()
);

export const addNote = createAction(
  '[Notes List] Add Note',
  props<{ note: Note }>()
);
export const addSimpleNote = createAction(
  '[Notes List] Add Simple Note',
  props<{ note: Omit<NoteBase, 'id'> & Pick<Note, 'stageId'> }>()
);
export const addSimpleNoteSuccess = createAction(
  '[Notes List] Add Simple Note Success ',
  props<{ note: Note }>()
);
export const addSimpleNoteFailure = createAction(
  '[Notes List] Add Simple Note Failure',
  props<{ error: any }>()
);

export const addMedia = createAction(
  '[Notes/API] Add Media',
  props<{
    projectId: string;
    noteId: string;
    file: File;
    mediaType: MediaType;
    fileType?: string;
  }>()
);

export const addMediaSuccess = createAction(
  '[Notes/API] Add Media Success',
  props<{ media: Media; mediaType: MediaType }>()
);

export const addMediaFailure = createAction(
  '[Notes/API] Add Media Failure',
  props<{ error: any }>()
);

export const updateMedia = createAction(
  '[Notes/API] Update Media',
  props<{ id: string; mediaType: MediaType; file: Media }>()
);

export const addNotes = createAction(
  '[Mattertag import] Add Notes',
  props<{ notes: Note[]; stageId: string }>()
);

export const addNotesSuccess = createAction(
  '[Mattertag import] Add Notes Success'
);
export const addNotesFailure = createAction(
  '[Mattertag import] Add Notes Failure',
  props<{ error: any }>()
);

export const updateNoteNotificationSuccess = createAction(
  '[Notes/API] Update Note Notification Success',
  props<{ rootNoteId: string; areEnabled: boolean }>()
);

export const updateSelectedNoteNotificationSuccess = createAction(
  '[Notes/API] Update Selected Note Notification Success',
  props<{ rootNoteId: string; areEnabled: boolean }>()
);

export const updateNoteNotification = createAction(
  '[Notes/API] Update Note Notification',
  props<{ rootNoteId: string; areEnabled: boolean }>()
);

export const updateNoteNotificationFailure = createAction(
  '[Notes/API] Update Note Notification Failure',
  props<{ error: any }>()
);

export const updateSort = createAction(
  '[Notes List] Update sort state',
  props<{ sort: State['sort'] }>()
);
