import {
  BufferGeometry,
  Camera,
  CanvasTexture,
  Clock,
  Color,
  Float32BufferAttribute,
  Group,
  Line,
  LineBasicMaterial,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Scene,
  ShapeUtils,
  Sprite,
  SpriteMaterial,
} from 'three';

import { mixinMeasurementComponent } from './measurement-mesh-base';
import { TMeasurementMeshInputs } from '../types/line-measurement.interface';
import { Units } from '@simlab/simlab-facility-management/sub-features/measurement';
import { Transform, TransformConverter } from '@simlab/transform';

const _areaMeasurementComponentBase = mixinMeasurementComponent(class {});

export class MeasurementMeshComponent extends _areaMeasurementComponentBase {
  override transform: TransformConverter;
  private _clock = new Clock();
  override get camera(): Camera {
    return this._camera;
  }
  override get scene(): Scene {
    return this._scene;
  }
  override get cameraContainer(): Camera {
    throw new Error('Method not implemented.');
  }
  override get Group(): typeof Group {
    return Group;
  }
  override get SpriteMaterial(): typeof SpriteMaterial {
    return SpriteMaterial;
  }
  override get Color(): typeof Color {
    return Color;
  }
  override get MeshBasicMaterial(): typeof MeshBasicMaterial {
    return MeshBasicMaterial;
  }
  override get LineBasicMaterial(): typeof LineBasicMaterial {
    return LineBasicMaterial;
  }
  override get CanvasTexture(): typeof CanvasTexture {
    return CanvasTexture;
  }
  override get ShapeUtils(): typeof ShapeUtils {
    return ShapeUtils;
  }
  override get Float32BufferAttribute(): typeof Float32BufferAttribute {
    return Float32BufferAttribute;
  }
  override get PlaneGeometry(): typeof PlaneGeometry {
    return PlaneGeometry;
  }
  override get Mesh(): typeof Mesh {
    return Mesh;
  }
  override get BufferGeometry(): typeof BufferGeometry {
    return BufferGeometry;
  }
  override get Sprite(): typeof Sprite {
    return Sprite;
  }
  override get Line(): typeof Line {
    return Line;
  }
  override set collider(colision: boolean) {
    throw new Error('Method not implemented.');
  }
  constructor(
    private readonly _camera: Camera,
    private readonly _scene: Scene,
    private readonly _transform: TransformConverter,
        private readonly _input: TMeasurementMeshInputs
  ) {
    super();
    this.transform = _transform
    this.inputs = {
      ...this.inputs,
      ..._input,
    };
    this.onInit();
    this.animate();

    this.scene.add(this._group);
  }
  protected animate() {
    requestAnimationFrame(() => this.animate());
    // this.clockNow;
    this.animationFrame(this._clock.getDelta() * 1000);
  }
  override destroy(): void {
    this._group.traverse((obj) => {
      this.scene.remove(obj);
    });
    this.scene.remove(this._group);
  }
  setColor(color: string) {
    this.color = color;
    this._setLineMaterial();
    this._setPointMaterial();
    // this._setAreaMeshMaterial();
  }
  setSurfaceSizeUnit(unit: Units) {
    this.inputs.unit = unit;
    if (!this.sizeMesh) return;
    this._setMeasurementTexture();
  }
}
