import { InjectionToken } from '@angular/core';
import { HamburgerService } from '../public-api';

export const HAMBURGER_TOKEN = new InjectionToken<HamburgerService>(
  'HAMBURGER_TOKEN'
);

export function hamburgerServiceFactory(): HamburgerService {
  return new HamburgerService();
}
