interface LicenseBase {
  startedAt: Date;
  expiresAt: Date;
  activeStatus: LicenseStatus;
  isSubscribed: boolean;
}

export interface UserLicense extends LicenseBase {
  assignedByOrganization: string;
  isOwner: boolean;
}

export interface OrganizationLicense extends LicenseBase {
  licenseId: string;
  isOwnerLicense: boolean;
  assignedUserEmail: string;
  assignedUserName: string;
  userId: string;
}

export enum LicenseStatus {
  Active = 1,
  Expired = 2,
  InvitationPending = 3,
  InvitationRejected = 4
}

export const LicenseStatusConst: Record<number, string> = {
  [LicenseStatus.Active]: $localize`:@@GEN_ACTIVE: Active `,
  [LicenseStatus.Expired]: $localize`:@@GEN_EXPIRED: Expired `,
  [LicenseStatus.InvitationPending]: $localize`:@@USER_INVITATION_PENDING: Invitation pending `,
  [LicenseStatus.InvitationRejected]: $localize`:@@USER_INVITATION_REJECTED: Invitation rejected `
};

export enum AccessType {
  Viewer = 1,
  Seat = 2,
  ExternalLicense = 3,
  OrganizationLicense = 4
}
export const ACCESS_TYPE: Record<AccessType, string> = {
  [AccessType.Viewer]: $localize`:@@VIEWER:Viewer`,
  [AccessType.Seat]: $localize`:@@SEAT:Seat`,
  [AccessType.ExternalLicense]: $localize`:@@PERSONAL_LICENSE:Personal license`,
  [AccessType.OrganizationLicense]: $localize`:@@ASSIGNED_LICENSE:Assigned license`
};
