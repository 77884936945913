import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Theme, TimelineMode } from '@simlab/data-access';
import { State, USER_PREFERENCE_FEATURE_KEY } from './user-preference.reducer';

const getUserPreferenceState = createFeatureSelector<State>(
  USER_PREFERENCE_FEATURE_KEY
);

export const getUserPreference = createSelector(
  getUserPreferenceState,
  ({ userPreferences }) => userPreferences
);

export const getUserPreferenceLoaded = createSelector(
  getUserPreferenceState,
  ({ userPreferences, userLoggedToProcore }) =>
    userPreferences !== undefined && userLoggedToProcore !== undefined
);

export const getUserPreferenceDateFormat = createSelector(
  getUserPreferenceState,
  ({ userPreferences }) => userPreferences?.dateFormat || 'dd/MM/yyyy'
);

export const getUserPreferenceLanguage = createSelector(
  getUserPreferenceState,
  ({ userPreferences }) => userPreferences?.language || 'en'
);

export const getThemeMode = createSelector(
  getUserPreferenceState,
  ({ userPreferences }) =>
    userPreferences?.theme ? userPreferences.theme : Theme.Light
);

export const userConnectedToProcore = createSelector(
  getUserPreferenceState,
  ({ userLoggedToProcore }) => {
    return userLoggedToProcore;
  }
);

export const getTimelineMode = createSelector(
  getUserPreferenceState,
  ({ userPreferences }) => {
    return userPreferences ? userPreferences.timeline : TimelineMode.Expanded;
  }
);
