import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  InputSignal,
  output
} from '@angular/core';
import { DesignButtonModule, DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiIconModule } from '@simlab/ui/icon';
import { UiProgressBarComponent } from '@simlab/ui/progress-bar';

export type TEventWorkStatus =
  | 'COMPLETED'
  | 'WORKING'
  | 'COMPLETED_WITH_FAILURES';

export type TEventElementItem = {
  title: string;
  subTitle?: string;
  currentElement: number;
  countOfElements: number;
  status: TEventItemStatus;
  cancel: (() => void) | undefined;
};

export type TEventItemStatus = {
  workStatus: TEventWorkStatus;
  failed: string[];
  showErrorDetails: boolean;
  errorDetails?: (failed: string[]) => void;
};

export type EventElementWithoutProgressParams = {
  title: InputSignal<string>;
  status: InputSignal<TEventWorkStatus>;
};

@Component({
  selector: 'event-ui-element',
  standalone: true,
  imports: [
    UiProgressBarComponent,
    DesignIconButton,
    DesignButtonModule,
    DesignIcon,
    UiIconModule
  ],
  templateUrl: './ui-event-element.component.html',
  styleUrl: './ui-event-element.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiEventElementComponent {
  readonly title = input.required<string>();
  readonly subTitle = input<string>();
  readonly currentElement = input.required<number>();
  readonly countOfElements = input.required<number>();
  readonly progress = computed(() => {
    const currentElement = this.currentElement();
    const countOfElements = this.countOfElements();
    const step = 100 / countOfElements;

    return step * currentElement;
  });
  readonly status = input.required<TEventItemStatus>();
  readonly cancel = output<void>();
}
