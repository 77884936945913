export interface CodesValidation {
  errorMessage: string;
  status: ActivatePromoCodedStatus;
}

export enum ActivatePromoCodedStatus {
  Accepted,
  Rejected
}

export interface PromoCodesInfo {
  promoCodes: PromoCodes[];
  newCodesValidations: CodesValidation[];
}
export interface SubscriptionPlanChangeInformation extends PromoCodesInfo {
  calculatedPrice: number;
  isVatApplicable: boolean;
  vatRate: number;
  priceBeforeDiscount: number;
  numberOfLicenses?: number;
}

export interface PromoCodes {
  discountAmountNet: number;
  discountInPercent: number;
  canBeRemoved: boolean;
  promoCodeApplicationOrder: number;
  validTo: string;
  code: string;
}

export interface SubscriptionPrompt {
  prompts: Prompt[];
}

export interface Prompt {
  priorityLevel: SubscriptionPromptPriorityLevel;
  displayType: SubscriptionPromptDisplayType;
  messageType: SubscriptionPromptMessageType;
  attempt?: number;
}

export enum SubscriptionPromptDisplayType {
  Unknown,
  Success,
  Info,
  Warning,
  Error
}

export enum SubscriptionPromptMessageType {
  Unknown,
  NoActiveCardNoFreePlan,
  RecurringPaymentFailure
}

export enum SubscriptionPromptPriorityLevel {
  None,
  Low,
  Medium,
  High
}

export interface SubscriptionPlanChangeInformationPayload {
  organizationId: string;
  planDefinitionId: string;
  country: string;
  isVatPayer: boolean;
  promoCodes: string[];
  numberOfSeats?: number;
  numberOfLicenses?: number;
}
