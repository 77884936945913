/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { FocusKeyManager } from '@angular/cdk/a11y';
import { CdkAccordion } from '@angular/cdk/accordion';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterContentInit,
  ContentChildren,
  Directive,
  Input,
  OnDestroy,
  QueryList,
} from '@angular/core';
import { startWith, tap } from 'rxjs';
import { DesignAccordionBase } from '../modules/accordion-base.interface';
import { designRadioAccordionBaseToken } from '../tokens/radio-accordion.token';
import { ExpansionPanelHeader } from './expansion-panel-header.directive';

@Directive({
  selector: '[designAccordion]',
  standalone: true,
  providers: [
    {
      provide: designRadioAccordionBaseToken,
      useExisting: DesignAccordion,
    },
  ],
  host: {
    class: 'design-base-accordion design-accordion',
  },
})
export class DesignAccordion
  extends CdkAccordion
  implements DesignAccordionBase, AfterContentInit, OnDestroy
{
  @Input()
  get hasSpacing(): boolean {
    return this._hasSpacing;
  }
  set hasSpacing(value: BooleanInput) {
    this._hasSpacing = coerceBooleanProperty(value);
  }
  protected _hasSpacing = false;

  private _keyManager!: FocusKeyManager<ExpansionPanelHeader>;

  /** Headers belonging to this accordion. */
  private _ownHeaders = new QueryList<ExpansionPanelHeader>();
  /** All headers inside the accordion. Includes headers inside nested accordions. */
  @ContentChildren(ExpansionPanelHeader, { descendants: true })
  protected readonly _allHeaderDescendants!: QueryList<ExpansionPanelHeader>;

  ngAfterContentInit(): void {
    this._allHeaderDescendants.changes
      .pipe(
        startWith(this._allHeaderDescendants),
        tap((headers: QueryList<ExpansionPanelHeader>) => {
          this._ownHeaders.reset(
            headers.filter(
              (header: ExpansionPanelHeader) => header._panel.accordion === this
            )
          );
          this._ownHeaders.notifyOnChanges();
        })
      )
      .subscribe();

    this._keyManager = new FocusKeyManager(this._ownHeaders)
      .withWrap()
      .withHomeAndEnd();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._ownHeaders.destroy();
  }

  /** Handles keyboard events coming in from the panel headers. */
  _handleHeaderKeydown(event: KeyboardEvent) {
    this._keyManager.onKeydown(event);
  }

  _handleHeaderFocus(header: ExpansionPanelHeader) {
    this._keyManager.updateActiveItem(header);
  }
}
