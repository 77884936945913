/* eslint-disable @angular-eslint/directive-selector */
import { Directive, forwardRef, Provider } from '@angular/core';
import { CheckboxRequiredValidator, NG_VALIDATORS } from '@angular/forms';

export const DESIGN_CHECKBOX_REQUIRED_VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DesignCheckboxRequiredValidator),
  multi: true,
};

@Directive({
  selector: `design-checkbox[required][formControlName],
             design-checkbox[required][formControl], design-checkbox[required][ngModel]`,
  providers: [DESIGN_CHECKBOX_REQUIRED_VALIDATOR],
  standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class DesignCheckboxRequiredValidator extends CheckboxRequiredValidator {}
