import { CurrencyCode } from './currency-code';
import { InvoiceAddress } from './invoice-address.interface';

export interface MethodPayment {
  cardMask: string | undefined;
  paymentProviderType: PaymentProviderType | undefined;
  paymentMethodOwnerEmail: string | undefined;
  paymentMethodOwnerName: string | undefined;
}

export interface OrganizationPlan extends MethodPayment {
  name: string;
  variant: PlanVariantType;
  paymentForNumberOfDays: number;
  activeProjectsLimit: number;
  stagesLimit: number;
  seatsLimit: number;
  licensesNumber: number;
  unsubscribedLicensesNumber: number;
  rootNotesLimit: number;
  canInviteViewers: boolean;
  advancedFeaturesAccess: boolean;
  advertisements: boolean;
  currencyCode: CurrencyCode;
  supportedAreaInM2Limit: number;
  isForCompany: boolean;
  price: number;
  newPlanName: string;
  newPlanIsForCompany: boolean;
  newPlanStartDate: Date;
  newPlanPrice: number;
  newPlanSeatsLimit: number;
  nextPaymentDate: Date;
  hasUserEverPaid: boolean;
  isAlphaParticipant: boolean;
  invoiceAddress: InvoiceAddress;
}

export enum PaymentProviderType {
  PayUCard,
  PayPal,
  PayPalCard
}

export enum PlanVariant {
  Free,
  Demo,
  PrivateUse,
  SmallBusiness,
  Enterprise
}

export type PlanVariantType = keyof typeof PlanVariant;
