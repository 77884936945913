import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProjectPermissions } from '@simlab/data-access';

export const ProjectPrivilegesActions = createActionGroup({
  source: 'Privileges/API',
  events: {
    'Clear Store': emptyProps(),
    'Load Project Permissions': props<{ projectId: string }>(),
    'Load Project Permissions Success': props<{
      projectPermissions: ProjectPermissions;
    }>(),
    'Load Project Permissions Failure': props<{ error: any }>(),
  },
});
