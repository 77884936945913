import { Injectable, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  FormatDate,
  IErrorResponse,
  SupportedLanguage,
  Theme,
  TimelineMode,
  UserPreferences
} from '@simlab/data-access';
import { Observable, filter, map } from 'rxjs';
import * as UserPreferenceAction from './user-preference.actions';
import * as UserPreferenceSelector from './user-preference.selectors';

@Injectable()
export class UserPreferenceFacade {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);

  readonly userConnectedToProcore$ = this.store.pipe(
    select(UserPreferenceSelector.userConnectedToProcore),
    filter((value) => value !== undefined)
  );
  readonly userConnectedToProcore = toSignal(this.userConnectedToProcore$);
  readonly userPreferencesUpdatedSuccessfully$: Observable<
    IErrorResponse | undefined
  > = this.actions$.pipe(
    ofType(
      UserPreferenceAction.updateUserPreferencesSuccess,
      UserPreferenceAction.updateUserPreferencesFailure
    ),
    map((action) => {
      if (
        action.type === '[UserPreference/API] Update User Preferences Success'
      )
        return undefined;
      return action.error;
    })
  );

  readonly getUserPreference$: Observable<UserPreferences | undefined> =
    this.store.pipe(select(UserPreferenceSelector.getUserPreference));

  readonly loaded$: Observable<boolean> = this.store.pipe(
    select(UserPreferenceSelector.getUserPreferenceLoaded)
  );

  readonly getThemeMode$: Observable<Theme> = this.store.pipe(
    select(UserPreferenceSelector.getThemeMode)
  );

  readonly getTimelineMode$: Observable<TimelineMode> = this.store.pipe(
    select(UserPreferenceSelector.getTimelineMode)
  );

  readonly getDateFormat$: Observable<FormatDate> = this.store.pipe(
    select(UserPreferenceSelector.getUserPreferenceDateFormat)
  );

  readonly getDateFormat = toSignal(this.getDateFormat$, {
    initialValue: 'MM/dd/yyyy'
  });

  readonly getLanguage$: Observable<SupportedLanguage> = this.store.pipe(
    select(UserPreferenceSelector.getUserPreferenceLanguage)
  );

  initStore() {
    this.store.dispatch(UserPreferenceAction.init());
    this.store.dispatch(UserPreferenceAction.userLoggedToProcore());
  }

  updateUserPreferences(userPreferences: UserPreferences) {
    this.store.dispatch(
      UserPreferenceAction.updateUserPreferences({ userPreferences })
    );
  }

  updateUserProcoreConnectionStatus(isConnected: boolean) {
    this.store.dispatch(
      UserPreferenceAction.userLoggedToProcoreSuccess({
        userLoggedToProcore: isConnected
      })
    );
  }
}
