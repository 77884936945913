/* eslint-disable @typescript-eslint/no-empty-function */
import { Observable, Subject, first, takeUntil } from 'rxjs';

import {

  IMeasurementTool,
  MeasurementMode,
  TMeasurementMeshChange,
  Units,
} from '@simlab/simlab-facility-management/sub-features/measurement';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import {
  FunctionResponse,
  IUnityInputs,
  IUnityResponse,
} from './matterport-access-base';
import {  TMeasurementMesh } from '@simlab/simlab-facility-management/sub-features/line-measurement';
import { LineMeasurementComponent } from '../services/line-measurement-tool.service';

export class UnityLineTool
  implements
    IUnityInputs<IMeasurementTool<LineMeasurementComponent,TMeasurementMesh>>,
    IUnityResponse<IMeasurementTool<LineMeasurementComponent,TMeasurementMesh>>
{
  readonly destroyer = new Subject<void>();
  readonly selectedMeasurement$: Observable<string | undefined> =
    this.measurementTool.selectedMeasurement$;
  readonly selectedMeasurementMode$: Observable<MeasurementMode> =
    this.measurementTool.selectedMeasurementMode$;
  readonly selectedMeasurementChange$: Observable<TMeasurementMeshChange<TMeasurementMesh> | undefined> =
    this.measurementTool.selectedMeasurementChange$;
  constructor(readonly measurementTool: IMeasurementTool<LineMeasurementComponent,TMeasurementMesh>) {
    this.selectedMeasurement$
      .pipe(takeUntil(this.destroyer))
      .subscribe((data) => this.onSelectedMeasurement$({ status: 'Success', data }));
    this.selectedMeasurementMode$
      .pipe(takeUntil(this.destroyer))
      .subscribe((data) =>
        this.onSelectedMeasurementMode$({ status: 'Success', data })
      );
    this.selectedMeasurementChange$
      .pipe(takeUntil(this.destroyer))
      .subscribe((data) =>
        this.onSelectedMeasurementChange$({ status: 'Success', data })
      );
  }
  transformationEvent$!: Observable<
    'change' | 'mouseDown' | 'mouseUp' | 'objectChange' | undefined
  >;
  get selectedComponent(): LineMeasurementComponent | undefined {
    throw new Error('Method not implemented.');
  }
  get transformControls(): TransformControls | undefined {
    throw new Error('Method not implemented.');
  }
  get hiddenMeasurementsOmit(): string[] | undefined {
    throw new Error('Method not implemented.');
  }
  get listenersEnabled(): boolean {
    throw new Error('Method not implemented.');
  }
  get count(): number {
    throw new Error('Method not implemented.');
  }
  dispose!: () => void;
  onTransformationEvent$!: (
    args: FunctionResponse<
      'change' | 'mouseDown' | 'mouseUp' | 'objectChange' | undefined
    >
  ) => void;
  onSelectedComponent!: (args: FunctionResponse<any>) => void;
  onTransformControls!: (args: FunctionResponse<any>) => void;
  onHiddenMeasurementsOmit!: (args: FunctionResponse<any>) => void;
  onListenersEnabled!: (args: FunctionResponse<any>) => void;
  onCount!: (args: FunctionResponse<any>) => void;
  onDispose!: (args: FunctionResponse<void>) => void;

  isTouchDevice = () => false;

  addMeasurements = (areas: TMeasurementMesh[]) => {
    try {
      const areaIds: string[] = [];
      areas.forEach((area) => areaIds.push(this.measurementTool.addMeasurement(area)));
      this.onAddMeasurements({ status: 'Success', data: areaIds });
    } catch (error) {
      this.onAddMeasurements({ status: 'Failure', error });
    }
  };
  addMeasurement = (area: TMeasurementMesh) => {
    try {
      const areaId = this.measurementTool.addMeasurement(area);
      this.onAddMeasurement({ status: 'Success', data: areaId });
    } catch (error) {
      this.onAddMeasurement({ status: 'Failure', error });
    }
  };
  createMeasurement = () => {
    try {
      const areaId = this.measurementTool.createMeasurement();
      this.onCreateMeasurement({ status: 'Success', data: areaId });
    } catch (error) {
      this.onCreateMeasurement({ status: 'Failure', error });
    }
  };
  cancelMeasurementCreation = () => {
    try {
      this.measurementTool.cancelMeasurementCreation();
      this.onCancelMeasurementCreation({ status: 'Success' });
    } catch (error) {
      this.onCancelMeasurementCreation({ status: 'Failure', error });
    }
  };
  deleteMeasurement = (areaId: string) => {
    try {
      this.measurementTool.deleteMeasurement(areaId);
      this.onDeleteMeasurement({ status: 'Success' });
    } catch (error) {
      this.onDeleteMeasurement({ status: 'Failure', error });
    }
  };
  deleteSelectedMeasurement = () => {
    try {
      this.measurementTool.deleteSelectedMeasurement();
      this.onDeleteSelectedMeasurement({ status: 'Success' });
    } catch (error) {
      this.onDeleteSelectedMeasurement({ status: 'Failure', error });
    }
  };
  editSelectedMeasurement = () => {
    try {
      this.measurementTool.editSelectedMeasurement();
      this.onEditSelectedMeasurement({ status: 'Success' });
    } catch (error) {
      this.onEditSelectedMeasurement({ status: 'Failure', error });
    }
  };
  updateMeasurementColor = (id: string, color: string | undefined) => {
    try {
      this.measurementTool.updateMeasurementColor(id, color);
      this.onUpdateMeasurementColor({ status: 'Success' });
    } catch (error) {
      this.onUpdateMeasurementColor({ status: 'Failure', error });
    }
  };
  updateSelectedMeasurementColor = (color: string | undefined) => {
    try {
      this.measurementTool.updateSelectedMeasurementColor(color);
      this.onUpdateSelectedMeasurementColor({ status: 'Success' });
    } catch (error) {
      this.onUpdateSelectedMeasurementColor({ status: 'Failure', error });
    }
  };
  addListener = (
    listenerActionType: 'ADD_POINT' | 'REMOVE_POINT' | 'CREATION_POINT'
  ) => {
    try {
      this.measurementTool
        .addListener(listenerActionType)
        .pipe(first())
        .subscribe((e) => {
          this.onAddListener({ status: 'Success' });
          this.measurementTool.removeListener();
        });
    } catch (error) {
      this.onAddListener({ status: 'Failure', error });
    }
  };
  removeListener = () => {
    try {
      this.measurementTool.removeListener();
      this.onRemoveListener({ status: 'Success' });
    } catch (error) {
      this.onRemoveListener({ status: 'Failure', error });
    }
  };
  hideMeasurements = (omit?: string[] | undefined) => {
    try {
      this.measurementTool.hideMeasurements(omit);
      this.onHideMeasurements({ status: 'Success' });
    } catch (error) {
      this.onHideMeasurements({ status: 'Failure', error });
    }
  };
  showMeasurements = (omit?: string[] | undefined) => {
    try {
      this.measurementTool.showMeasurements(omit);
      this.onShowMeasurements({ status: 'Success' });
    } catch (error) {
      this.onShowMeasurements({ status: 'Failure', error });
    }
  };
  deleteAllMeasurements = () => {
    try {
      this.measurementTool.deleteAllMeasurements();
      this.onDeleteAllMeasurements({ status: 'Success' });
    } catch (error) {
      this.onDeleteAllMeasurements({ status: 'Failure', error });
    }
  };
  set sizeUnit(unit: Units) {
    this.measurementTool.sizeUnit = unit;
    this.onSizeUnit({ status: 'Success' });
  }
  set selectedMeasurementMode(mode: MeasurementMode) {
    this.measurementTool.selectedMeasurementMode = mode;
    this.onSelectedMeasurementMode({ status: 'Success' });
  }
  get selectedMeasurementMode(): MeasurementMode {
    return this.measurementTool.selectedMeasurementMode;
  }
  set selectedMeasurement(areaId: string | undefined) {
    this.measurementTool.selectedMeasurement = areaId;
    this.onSelectedMeasurement({ status: 'Success' });
  }
  get selectedMeasurement(): string | undefined {
    return this.measurementTool.selectedMeasurement;
  }
  undo = () => {
    this.measurementTool.undo();
    this.onUndo({ status: 'Success' });
  };
  redo = () => {
    this.measurementTool.redo();
    this.onRedo({ status: 'Success' });
  };
  finish = () => {
    try {
      this.measurementTool.finish();
      this.onFinish({ status: 'Success' });
    } catch (error) {
      this.onFinish({ status: 'Failure', error });
    }
  };
  get canRedo(): boolean {
    return this.measurementTool.canRedo;
  }
  get canUndo(): boolean {
    return this.measurementTool.canUndo;
  }
  set segmentsVisibility(visible: boolean) {
    this.measurementTool.segmentsVisibility = visible;
  }

  onSelectedMeasurement$ = (args: FunctionResponse<string | undefined>) => {};
  onSelectedMeasurementMode$ = (args: FunctionResponse<MeasurementMode>) => {};
  onSelectedMeasurementChange$ = (
    args: FunctionResponse<TMeasurementMeshChange<TMeasurementMesh >| undefined>
  ) => {};
  onAddMeasurement = (args: FunctionResponse<string>) => {};
  onAddMeasurements = (args: FunctionResponse<string[]>) => {};

  onCreateMeasurement = (args: FunctionResponse<string>) => {};
  onCancelMeasurementCreation = (args: FunctionResponse<void>) => {};
  onDeleteMeasurement = (args: FunctionResponse<void>) => {};
  onDeleteSelectedMeasurement = (args: FunctionResponse<void>) => {};
  onEditSelectedMeasurement = (args: FunctionResponse<void>) => {};
  onUpdateMeasurementColor = (args: FunctionResponse<void>) => {};
  onUpdateSelectedMeasurementColor = (args: FunctionResponse<void>) => {};
  onAddListener = (
    args: FunctionResponse<Observable<string | undefined>>
  ) => {};
  onRemoveListener = (args: FunctionResponse<void>) => {};
  onHideMeasurements = (args: FunctionResponse<void>) => {};
  onShowMeasurements = (args: FunctionResponse<void>) => {};
  onDeleteAllMeasurements = (args: FunctionResponse<void>) => {};
  onSizeUnit = (args: FunctionResponse<any>) => {};
  onSelectedMeasurementMode = (args: FunctionResponse<any>) => {};
  onSelectedMeasurement = (args: FunctionResponse<any>) => {};
  onUndo = (args: FunctionResponse<void>) => {};
  onRedo = (args: FunctionResponse<void>) => {};
  onFinish = (args: FunctionResponse<void>) => {};
  onCanRedo = (args: FunctionResponse<any>) => {};
  onCanUndo = (args: FunctionResponse<any>) => {};
  onSegmentsVisibility = (args: FunctionResponse<any>) => {};
  onIsTouchDevice = (args: FunctionResponse<boolean>) => {};
}
