import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter
} from '@angular/core';
import { UserPreferences } from '@simlab/data-access';
import { UiButtonModule } from '@simlab/ui/button';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiIconModule } from '@simlab/ui/icon';
import { PersonalSettingBase } from '../../personal-settings.component';

@Component({
  selector: 'simlab-matterport-account',
  templateUrl: './matterport-account.component.html',
  styleUrls: ['./matterport-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UiDividerModule,
    UiButtonModule,
    UiIconModule,
    PortalModule
  ]
})
export class MatterportAccountComponent implements PersonalSettingBase {
  userPreferencesChange!: EventEmitter<UserPreferences>;
  userPreferences!: UserPreferences;
}
