import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { API_URL } from '../../tokens/api-url.token';

export enum ModelAccess {
  Public = 'access.public',
  Private = 'access.private',
  Protected = 'access.protected',
  Invalid = 'access.invalid',
}

@Injectable()
export class MatterportApiService {
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);

  getMatterportModelAccess(scanId: string): Observable<ModelAccess> {
    return this.httpClient
      .get(
        `${this.url}/construction-site-management-module/get-matterport-model-access?scanId=${scanId}`,
        {
          responseType: 'text',
        }
      )
      .pipe(map((response: string) => <ModelAccess>response));
  }
}
