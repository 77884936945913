export interface OrganizationProjectUserBase {
  userId: string;
  userName: string;
  userEmail: string;
}
export interface OrganizationProjectUser extends OrganizationProjectUserBase {
  userProjectTeamsCount: number;
  directInvitation: boolean;
  lastSeen: string;
}
