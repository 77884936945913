export type TChipLabel =
  | 'Information'
  | 'Pending'
  | 'In Progress'
  | 'Resolved'
  | 'Unresolved'
  | 'Created'
  | 'Modified'
  | 'Stage'
  | 'Author'
  | 'Own Note'
  | 'Stakeholder';

export const CHIP_CONST = {
  Information: 'Information',
  Pending: 'Pending',
  'In Progress': 'In Progress',
  Resolved: 'Resolved',
  Unresolved: 'Unresolved',
  Created: 'Created',
  Modified: 'Modified',
  Stage: 'Stage',
  Author: 'Author',
  'Own Note': 'Own Note',
  Stakeholder: 'Stakeholder'
} satisfies Record<TChipLabel, string>;

export const CHIP_LABEL = {
  Information: $localize`:@@IN_PROGRESS:In Progress`,
  Pending: $localize`:@@PENDING:Pending`,
  Resolved: $localize`:@@RESOLVED:Resolved`,
  Unresolved: $localize`:@@UNRESOLVED:Unresolved`,
  Created: $localize`:@@CREATED:Created`,
  Modified: $localize`:@@MODIFIED:Modified`,
  Stage: $localize`:@@STAGE:Stage`,
  Author: $localize`:@@AUTHOR:Author`,
  'Own Note': $localize`:@@OWN_NOTE:Own Note`,
  Stakeholder: $localize`:@@STAKEHOLDER:Stakeholder`,
  'In Progress': $localize`:@@IN_PROGRESS:In Progress`
} satisfies Record<TChipLabel, string>;

export interface IChipItem {
  key: TChipLabel;
  value: string;
}
