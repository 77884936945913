import { CurrencyCode } from './currency-code';
import { PlanVariantType } from './organization-plan';

export interface Plan {
  planDefinitionId: string;
  variant: PlanVariantType;
  activeProjectsLimit: number;
  stagesLimit: number;
  rootNotesLimit: number;
  seatsLimit: number;
  licensesLimit: number;
  canInviteViewers: boolean;
  advancedFeaturesAccess: boolean;
  supportedAreaInM2Limit: number;
  advertisements: boolean;
  paymentForNumberOfDays: number;
  prizePerSeatGross: number | null;
  prizePerLicenseGross: number;
  isForCompany: boolean;
  currencyCode: CurrencyCode;
  vatRate: number;
  isVatApplicable: boolean;
  description: string;
  name: string;
  headerName: string;
  image?: string;
}
