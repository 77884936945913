<div [className]="'fab-button-menu__' + direction()">
  <button
    class="main-button"
    design-fab-button
    [disabled]="disabled()"
    designButtonColor="primary"
    (click)="isActive.set(!isActive())"
  >
    <i [designIcon]="designIconName()"></i>
  </button>

  <div
    class="content-wrapper"
    [@animation]="{
      value: isActive(),
      params: { transform: animationDirection() },
    }"
  >
    @if (isActive() && !disabled()) {
      <ng-content></ng-content>
    }
  </div>
</div>
