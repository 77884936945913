<div class="carousel-container" *ngIf="collection[currentIndex]">
  <ng-container *ngIf="type === 'photo'; else video">
    <ng-container
      *ngIf="collection[currentIndex].url$ | async; let url; else: normal"
    >
      <img
        #photoElement
        [src]="url"
        (loaded)="imageLoaded = true"
        [draggable]="false"
      />
    </ng-container>

    <ng-template #normal>
      <img
        #photoElement
        [src]="collection[currentIndex].privateUrl"
        (loaded)="imageLoaded = true"
        [draggable]="false"
      />
    </ng-template>
  </ng-container>
  <ng-template #video>
    <video
      class="carousel-container__video"
      controls="controls"
      preload="metadata"
      [src]="collection[currentIndex].url$ | async"
    ></video>
  </ng-template>

  <ng-container *ngIf="!imageLoaded && type === 'photo'">
    <ui-progress-spinner
      class="carousel-container--spinner"
      uiColor="background"
    ></ui-progress-spinner>
  </ng-container>

  <button
    design-fab-button
    designButtonColor="secondary"
    class="carousel-container--button-left"
    [disabled]="hasPrevious === false"
    (click)="previousElement()"
  >
    <i designIcon="arrow_big" designSize="2rem" [designRotateBy]="180"></i>
  </button>

  <button
    design-fab-button
    designButtonColor="secondary"
    class="carousel-container--button-right"
    [disabled]="hasNext === false"
    (click)="nextElement()"
  >
    <i designIcon="arrow_big" designSize="2rem"></i>
  </button>
</div>
