import { Observable } from 'rxjs';
import {
  TAreaMeasurement,
  TAreaMeasurementGroup
} from '../area-measurement.interface';

export interface AreaQuery {
  getStageMeasurementGroups: (
    stageId: string
  ) => Observable<TAreaMeasurementGroup[]>;
  getStageMeasurements: (stageId: string) => Observable<TAreaMeasurement[]>;
}
