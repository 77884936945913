import { Directive, ElementRef, inject } from '@angular/core';
import {
  CanColor,
  CanDisable,
  CanLoad,
  mixinColor,
  mixinDisabled,
  mixinLoaded,
} from '@simlab/design/internal';

const _ButtonBase = mixinDisabled(
  mixinLoaded(
    mixinColor(
      class {
        public _elementRef = inject(ElementRef)
      }
    )
  )
);

@Directive()
export abstract class ButtonBase
  extends _ButtonBase
  implements CanDisable, CanLoad, CanColor
{}
