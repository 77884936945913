import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { StageComponent } from '@simlab/data-access';
import * as ComponentsActions from './components.actions';

export const COMPONENTS_FEATURE_KEY = 'components';

export interface State extends EntityState<StageComponent> {
  selectedId?: string; // which Components record has been selected
  loaded: boolean; // has the Components list been loaded
  error?: string | null; // last known error (if any)
}

export interface ComponentsPartialState {
  readonly [COMPONENTS_FEATURE_KEY]: State;
}

export const componentsAdapter: EntityAdapter<StageComponent> =
  createEntityAdapter<StageComponent>({
    sortComparer: (item0: StageComponent, item1: StageComponent) => {
      const time0 = new Date(item0.createdAt).getTime();
      const time1 = new Date(item1.createdAt).getTime();

      if (time0 > time1) return -1;
      if (time0 < time1) return 1;

      return 0;
    },
  });

export const initialState: State = componentsAdapter.getInitialState({
  // set initial required properties
  loaded: false,
});

export const componentsReducer = createReducer(
  initialState,
  on(ComponentsActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),

  on(ComponentsActions.clearStore, (state) => {
    return {
      ...componentsAdapter.removeAll(state),
      loaded: false,
      selectedId: undefined,
    };
  }),

  on(ComponentsActions.initSetComponent, (state) => {
    return {
      ...state,
      selectedId: '',
    };
  }),

  on(ComponentsActions.initSetComponentSuccess, (state, action) => {
    return {
      ...state,
      selectedId: action.component?.id ?? '',
    };
  }),

  on(ComponentsActions.setSelectedComponentById, (state, action) => {
    return {
      ...state,
      selectedId: action.componentId,
    };
  }),

  on(ComponentsActions.acceptSynchronizationSuccess, (state, { component }) => {
    return componentsAdapter.upsertOne(component, state);
  }),

  on(ComponentsActions.deleteLastKnowPosition, (state) => {
    return {
      ...state,
      lastKnowPosition: undefined,
    };
  }),
  on(ComponentsActions.loadComponentsSuccess, (state, { components }) =>
    componentsAdapter.setAll(components, { ...state, loaded: true })
  ),
  on(ComponentsActions.changeComponent, (state, { component }) =>
    componentsAdapter.setAll([component], { ...state, loaded: true })
  ),

  on(ComponentsActions.updateComponent, (state, { newName, componentId }) => {
    const updatedComp: Update<StageComponent> = {
      id: componentId,
      changes: {
        name: newName,
      },
    };
    return componentsAdapter.updateOne(updatedComp, { ...state, loaded: true });
  }),
  on(ComponentsActions.loadComponentsFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(
    ComponentsActions.setSynchronizingAcceptedSuccess,
    (state, { componentId, status }) => {
      const updatedComp: Update<StageComponent> = {
        id: componentId,
        changes: {
          isSynchronizingAccepted: status,
        },
      };
      return componentsAdapter.updateOne(updatedComp, {
        ...state,
      });
    }
  ),
  on(
    ComponentsActions.deleteSynchronizationSuccess,
    (state, { componentId }) => {
      const updatedComp: Update<StageComponent> = {
        id: componentId,
        changes: {
          isSynchronizingAccepted: false,
        },
      };
      return componentsAdapter.updateOne(updatedComp, {
        ...state,
      });
    }
  ),
  on(ComponentsActions.addComponentSuccess, (state, { component }) => {
    return componentsAdapter.addOne(component, state);
  }),

  on(
    ComponentsActions.editComponentNameSuccess,
    (state, { componentId, newName }) => {
      const updatedComp: Update<StageComponent> = {
        id: componentId,
        changes: {
          name: newName,
        },
      };
      return componentsAdapter.updateOne(updatedComp, state);
    }
  ),

  on(ComponentsActions.removeComponentSuccess, (state, { componentId }) => {
    return componentsAdapter.removeOne(componentId, state);
  }),

  on(
    ComponentsActions.checkSweepOnComponentSuccess,
    (state, { componentId, sweeps }) => {
      const updatedComp: Update<StageComponent> = {
        id: componentId,
        changes: {
          sweepsUpdating: true,
          sweeps,
        },
      };
      return componentsAdapter.updateOne(updatedComp, {
        ...state,
      });
    }
  ),

  on(
    ComponentsActions.sweepOnComponentUpdatedSuccess,
    (state, { componentId }) => {
      const updatedComp: Update<StageComponent> = {
        id: componentId,
        changes: {
          sweepsUpdating: true,
        },
      };
      return componentsAdapter.updateOne(updatedComp, {
        ...state,
      });
    }
  ),
  on(ComponentsActions.componentVisibilityChange, (state, action) => {
    const updatedComponent: Update<StageComponent> = {
      id: action.id,
      changes: {
        visible: action.visible,
      },
    };
    return componentsAdapter.updateOne(updatedComponent, {
      ...state,
    });
  })
);

export function reducer(state: State | undefined, action: Action) {
  return componentsReducer(state, action);
}
