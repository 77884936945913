import { HttpContextToken, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatterportApiConfig } from '../models/matterport-api-config';
export const BYPASS_LOG = new HttpContextToken(() => false);

@Injectable()
export class AuthorizationTokenInterceptor implements HttpInterceptor {
  constructor(private readonly matterportApiConfig: MatterportApiConfig) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.context.get(BYPASS_LOG) === true) {
      return next.handle(request);
    }
    const token = sessionStorage.getItem('matterport-token');
    const req = request.clone({
      setHeaders: {
        'x-matterport-application-key': this.matterportApiConfig.applicationKey,
      },
    });
    if (token) {
      req.headers.append('Authorization', `Bearer ${token}`);
    }

    return next.handle(req);
  }
}
