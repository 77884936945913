import { createFeature, createReducer, on } from '@ngrx/store';
import {
  EnumFlagHelper,
  OrganizationLevel,
  ProjectPermission
} from '@simlab/data-access';
import { ProjectPrivilegesActions } from './privileges.actions';

export const PRIVILEGES_FEATURE_KEY = 'projectPrivileges';
const BIT_VALUES = EnumFlagHelper.enumToBitValues(ProjectPermission);

export interface State {
  permissions: Record<string, boolean>;
  hasAdminRoleInProject: boolean;
  organizationPermissionsLevel: OrganizationLevel;
  isLoading: boolean;
  error: string | null;
}

const initialState: State = {
  permissions: EnumFlagHelper.bitValuesToSelected(BIT_VALUES, 0),
  hasAdminRoleInProject: false,
  organizationPermissionsLevel: OrganizationLevel.Basic,
  isLoading: false,
  error: null
};

const privilegesFeature = createFeature({
  name: PRIVILEGES_FEATURE_KEY,
  reducer: createReducer(
    initialState,
    on(
      ProjectPrivilegesActions.loadProjectPermissionsSuccess,
      (state, { projectPermissions }) => ({
        ...state,
        permissions: EnumFlagHelper.bitValuesToSelected(
          BIT_VALUES,
          projectPermissions.permissions
        ),
        hasAdminRoleInProject: projectPermissions.hasAdminRoleInProject,
        organizationPermissionsLevel:
          projectPermissions.organizationPermissionsLevel,
        isLoading: true
      })
    ),
    on(ProjectPrivilegesActions.clearStore, () => ({
      permissions: EnumFlagHelper.bitValuesToSelected(BIT_VALUES, 0),
      hasAdminRoleInProject: false,
      organizationPermissionsLevel: OrganizationLevel.Viewer,
      isLoading: false,
      error: null
    })),
    on(
      ProjectPrivilegesActions.loadProjectPermissionsFailure,
      (state, { error }) => ({
        ...state,
        error,
        isLoading: true
      })
    )
  )
});

export const {
  name: privilegesFeatureKey,
  reducer: projectPrivilegesReducer,
  selectError,
  selectIsLoading,
  selectPermissions,
  selectProjectPrivilegesState
} = privilegesFeature;
