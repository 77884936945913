import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { API_URL } from '@simlab/data-access';
import {
  AddUserToken,
  AssignProject,
  BallInCourtUser,
  Company,
  ProcorePermission,
  Project,
  PunchItemsGetPayload,
  PunchItemsResponse,
  RFIGetPayload,
  RFIListResponse,
  UnAssignProject,
  UserToken
} from '@simlab/procore/models';
import { requestToHttpParamsMapper } from '@simlab/util/core';
import { catchError, mergeMap, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiProcoreService {
  public readonly url = inject<string>(API_URL);
  public readonly httpClient = inject(HttpClient);

  hasUserProcoreToken(): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/users-module/has-user-procore-token`
    );
  }

  getUserCompanyPermissions(
    procoreCompanyId: string
  ): Observable<ProcorePermission[]> {
    return this.httpClient.get<ProcorePermission[]>(
      `${this.url}/procore-api/get-user-company-permissions?procoreCompanyId=${procoreCompanyId}`
    );
  }

  getCompanies$(): Observable<Company[]> {
    return this.httpClient.get<Company[]>(
      `${this.url}/procore-api/get-companies`
    );
  }

  getCompanyProjects$(procoreCompanyId: number): Observable<Project[]> {
    return this.httpClient.get<Project[]>(
      `${this.url}/procore-api/get-company-projects?procoreCompanyId=${procoreCompanyId}`
    );
  }

  assignProcoreProject$(body: AssignProject): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/assign-procore-project`,
      body
    );
  }

  unAssignProcoreProject$(body: UnAssignProject): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/unassign-procore-project`,
      body
    );
  }

  getProcoreUserToken(): Observable<UserToken> {
    return this.httpClient.get<UserToken>(
      `${this.url}/users-module/get-procore-user-token`
    );
  }

  getProcoreAuthorizationCodeUrl(returnUrl: string): Observable<string> {
    return this.httpClient.get(
      `${this.url}/users-module/get-procore-authorization-code-url?returnUrl=${returnUrl}`,
      { responseType: 'text' }
    );
  }

  addProcoreUserToken(body: AddUserToken): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/users-module/add-procore-user-token`,
      body
    );
  }

  removeProcoreUserToken(): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/users-module/remove-procore-user-token`
    );
  }

  reassignProcoreItems(projectId: string, includeManuallyAssigned: boolean) {
    return this.httpClient
      .post(`${this.url}/procore-module/reassign-procore-items-to-stages`, {
        projectId,
        includeManuallyAssigned
      })
      .pipe(mergeMap(() => this.importProcoreData(projectId)));
  }

  importProcoreData(projectId: string) {
    return this.httpClient.post(
      `${this.url}/procore-module/import-procore-data`,
      { projectId }
    );
  }

  getRfiList(payload: RFIGetPayload) {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<RFIListResponse>(
      `${this.url}/procore-module/get-rfis`,
      {
        params
      }
    );
  }

  getPunchItemsList(payload: PunchItemsGetPayload) {
    const params = requestToHttpParamsMapper(payload);

    return this.httpClient.get<PunchItemsResponse>(
      `${this.url}/procore-module/get-punch-items`,
      {
        params
      }
    );
  }

  getRfiItemBallInCourtUsers(projectId: string): Observable<BallInCourtUser[]> {
    return this.httpClient
      .get<
        BallInCourtUser[]
      >(`${this.url}/procore-module/get-rfi-ball-in-court-users?projectId=${projectId}`)
      .pipe(
        catchError((e) => {
          console.error(e);
          return [];
        })
      );
  }

  getPunchItemBallInCourtUsers(
    projectId: string
  ): Observable<BallInCourtUser[]> {
    return this.httpClient.get<BallInCourtUser[]>(
      `${this.url}/procore-module/get-punch-item-ball-in-court-users?projectId=${projectId}`
    );
  }

  isAnythingToImportFromProcore(projectId: string): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.url}/procore-module/is-anything-to-import?projectId=${projectId}`
    );
  }
}
