import { CommonModule } from '@angular/common';
import { Component, Output, inject } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  TForm,
  Theme,
  TimelineMode,
  UserPreferences
} from '@simlab/data-access';
import { DesignRadioAccordionModule } from '@simlab/design/accordion';
import { Observable, map } from 'rxjs';
import { PersonalSettingBase } from '../../personal-settings.component';
import { UserPreferencesToken } from '../units/units.component';
import { TimelineModeComponent } from './timeline-mode/timeline-mode.component';

interface IAppearance {
  theme: Theme;
  timelineMode: TimelineMode;
}

export type AppearanceForm = TForm<IAppearance>;

@Component({
  selector: 'simlab-appearance',
  standalone: true,
  imports: [
    CommonModule,
    DesignRadioAccordionModule,
    FormsModule,
    ReactiveFormsModule,
    TimelineModeComponent
  ],
  template: `
    <ng-container [formGroup]="formGroup">
      <label i18n="@@CHOOSE_THEME"> Choose theme. </label>
      <design-radio-accordion hasSpacing formControlName="theme">
        <design-radio-expansion-panel
          id="drep-dark"
          [value]="theme.Dark"
          #dark
          data-cy="apperance-dark-mode"
        >
          <design-radio-expansion-panel-header color="secondary">
            <label designLabel i18n="@@DARK_MODE">Dark mode</label>
          </design-radio-expansion-panel-header>
        </design-radio-expansion-panel>

        <design-radio-expansion-panel
          data-cy="apperance-light-mode"
          id="drep-light"
          [value]="theme.Light"
          #light
        >
          <design-radio-expansion-panel-header color="secondary">
            <label designLabel i18n="@@LIGHT_MODE">Light mode</label>
          </design-radio-expansion-panel-header>
        </design-radio-expansion-panel>
      </design-radio-accordion>

      <simlab-timeline-mode [formGroup]="formGroup"></simlab-timeline-mode>
    </ng-container>
  `,
  styles: [
    `
      .simlab-appearance {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }
    `
  ],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    class: 'simlab-appearance'
  }
})
export class AppearanceComponent implements PersonalSettingBase {
  private _userPreferences: UserPreferences = inject(UserPreferencesToken);
  get userPreferences() {
    return this._userPreferences;
  }
  readonly theme = Theme;
  readonly formGroup: FormGroup<AppearanceForm> = new FormGroup<AppearanceForm>(
    {
      theme: new FormControl<Theme>(Theme.Light, { nonNullable: true }),
      timelineMode: new FormControl<TimelineMode>(TimelineMode.Condensed, {
        nonNullable: true
      })
    }
  );

  constructor() {
    this.formGroup.patchValue({
      theme: this._userPreferences.theme,
      timelineMode: this._userPreferences.timeline
    });
  }
  @Output() readonly userPreferencesChange: Observable<UserPreferences> =
    this.formGroup.valueChanges.pipe(
      map((value) => {
        if (!value) return this.userPreferences;
        return {
          ...this.userPreferences,
          theme: value.theme,
          timeline: value.timelineMode
        } as UserPreferences;
      })
    );
}
