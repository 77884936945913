import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { AfterViewInit, Directive, inject, OnDestroy } from '@angular/core';
import { HamburgerService } from '@simlab/util/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { SidenavComponent } from './sidenav/sidenav.component';

export abstract class HamburgerServiceBase {
  abstract get state(): boolean;
  abstract toggle(): void;
  abstract get state$(): Observable<boolean>;
  abstract open(): void;
  abstract close(): void;
}
@Directive({})
export abstract class DefaultSidenavBehavior
  implements AfterViewInit, OnDestroy {
  private readonly _hamburger = inject(HamburgerService);
  private readonly _breakpoints = inject(BreakpointObserver);
  abstract sidenav: SidenavComponent;
  protected readonly _destroySource: Subject<void> = new Subject<void>();
  get hamburgerState$(): Observable<boolean> {
    return this._hamburger.state$;
  }

  ngAfterViewInit(): void {
    this._sizeObserver();
  }

  ngOnDestroy(): void {
    this._destroySource.next();
    this._destroySource.complete();
  }

  private _sizeObserver() {
    this._breakpoints
      .observe([Breakpoints.Handset, Breakpoints.XSmall, Breakpoints.Small])
      .pipe(takeUntil(this._destroySource))
      .subscribe((breakpoint: BreakpointState) => {
        if (breakpoint.matches) {
          this.sidenav.mode = 'over';
        } else {
          this.sidenav.mode = 'side';
          this._hamburger.open();
        }
      });
  }
}
