<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel i18n="@@WARNING">Warning</h2>
  </ng-container>

  <ng-container designDialogContent>
    <div class="main-text" i18n="@@MODALS_OPEN_NEW_TAB_INFORMATION">
      You need open content new tab
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="close()"
      data-cy="crop-image-dialog-cancel"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="accent"
      (click)="open()"
      data-cy="crop-image-dialog-confirm"
    >
      <div i18n="@@CONFIRM">Confirm</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
