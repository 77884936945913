import { DataSource } from '@angular/cdk/collections';
import { ApiFacadeService, UserLicense } from '@simlab/data-access';
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  map,
  of,
} from 'rxjs';

export class MyLicensesDataSource implements DataSource<UserLicense> {
  private readonly _userLicensesSource = new BehaviorSubject<UserLicense[]>([]);
  private readonly _loadingSource = new BehaviorSubject<boolean>(false);

  readonly loading$: Observable<boolean> = this._loadingSource.asObservable();

  constructor(private readonly apiFacadeService: ApiFacadeService) {}

  connect(): Observable<readonly UserLicense[]> {
    return this._userLicensesSource.asObservable();
  }

  disconnect(): void {
    this._userLicensesSource.complete();
    this._loadingSource.complete();
  }

  getUserLicense(): Observable<readonly UserLicense[]> {
    this._loadingSource.next(true);

    return this.apiFacadeService.privateLicense.getUserLicenses().pipe(
      map((userLicense: UserLicense[]) => {
        this._userLicensesSource.next(userLicense);
        return userLicense;
      }),
      catchError(() => of([])),
      finalize(() => this._loadingSource.next(false))
    );
  }
}
