/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Optional,
  ViewEncapsulation
} from '@angular/core';
import { _OptionBase } from '../../directives/options-base.directive';
import {
  MAT_OPTION_PARENT_COMPONENT,
  MatOptionParentComponent
} from '../../models/options-parent';

@Component({
  selector: 'ui-option',
  exportAs: 'uiOption',
  host: {
    role: 'option',
    '[attr.tabindex]': '_getTabIndex()',
    '[class.selected]': 'selected',
    '[class.option-multiple]': 'multiple',
    '[class.active]': 'active',
    '[id]': 'id',
    '[attr.aria-selected]': '_getAriaSelected()',
    '[attr.aria-disabled]': 'disabled.toString()',
    '[class.option-disabled]': 'disabled',
    '(click)': '_selectViaInteraction()',
    '(keydown)': '_handleKeydown($event)',
    class: 'ui-option focus-indicator'
  },
  styleUrls: ['option.component.scss'],
  templateUrl: 'option.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionComponent extends _OptionBase {
  constructor(
    element: ElementRef<HTMLElement>,
    changeDetectorRef: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_OPTION_PARENT_COMPONENT)
    parent: MatOptionParentComponent
  ) {
    super(element, changeDetectorRef, parent);
  }
}
