import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Note } from '@simlab/data-access';
import {
  SHOWCASENOTES_FEATURE_KEY,
  State,
  showcaseNotesAdapter,
} from './showcase-notes.reducer';

// Lookup the 'ShowcaseNotes' feature state managed by NgRx
export const getShowcaseNotesState = createFeatureSelector<State>(
  SHOWCASENOTES_FEATURE_KEY
);

const { selectAll, selectEntities } = showcaseNotesAdapter.getSelectors();

export const getShowcaseNotesLoaded = createSelector(
  getShowcaseNotesState,
  (state: State) => state.loaded
);

export const getShowcaseNotesError = createSelector(
  getShowcaseNotesState,
  (state: State) => state.error
);

export const getAllShowcaseNotes = createSelector(
  getShowcaseNotesState,
  (state: State) => selectAll(state)
);

export const getShowcaseNotesEntities = createSelector(
  getShowcaseNotesState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getShowcaseNotesState,
  (state: State) => state.selectedId
);

export const getSelected = createSelector(
  getShowcaseNotesState,
  (state: State) => (state.selectedNote as Note) ?? ({} as Note)
);

export const getNotesHidden = createSelector(
  getShowcaseNotesState,
  (state: State) => state.hideAll
);
