/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
import { CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { TemplatePortal } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  Subject,
  distinctUntilChanged,
  filter,
  startWith,
  take,
  tap,
} from 'rxjs';
import { designRadioExpansionPanelAnimations } from '../animations/radio-expansion-panel.animation';
import { DesignAccordionBase } from '../modules/accordion-base.interface';
import { designRadioAccordionBaseToken } from '../tokens/radio-accordion.token';
import { designRadioExpansionPanelBaseToken } from '../tokens/radio-expansion-panel.token';
import { DesignRadioExpansionPanelContent } from './radio-expansion-panel/radio-expansion-panel-content.directive';

import { AnimationEvent } from '@angular/animations';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';

export type DesignExpansionPanelState = 'expanded' | 'collapsed';

@Component({
  selector: 'design-expansion-panel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [designRadioExpansionPanelAnimations.bodyExpansion],
  providers: [
    //NOTE: (dawid) Provide DesignRadioAccordion as undefined to prevent nested expansion panels from registering to the same accordion.
    {
      provide: designRadioAccordionBaseToken,
      useExisting: DesignExpansionPanel,
    },
    {
      provide: designRadioExpansionPanelBaseToken,
      useExisting: DesignExpansionPanel,
    },
  ],
  host: {
    class: 'design-radio-expansion-panel',
    '[attr.aria-expanded]': 'expanded',
    '[class.design-radio-expansion-panel--expanded]': 'expanded',
    '[class.design-radio-expansion-panel--spacing]': 'hasSpacing',
  },
})
export class DesignExpansionPanel
  extends CdkAccordionItem
  implements AfterContentInit, OnChanges, OnDestroy
{
  @Input() value: any = undefined;
  private _alwaysExpanded = false;
  @Input() set alwaysExpanded(value: BooleanInput) {
    this._alwaysExpanded = coerceBooleanProperty(value);
  }

  private _viewContainerRef = inject(ViewContainerRef);
  /** Portal holding the user's content. */
  _portal!: TemplatePortal;

  /** Content that will be rendered lazily. */
  @ContentChild(DesignRadioExpansionPanelContent)
  _lazyContent!: DesignRadioExpansionPanelContent;

  get emptyContent(): boolean {
    return this._lazyContent !== undefined;
  }

  /** Optionally defined accordion the expansion panel belongs to. */
  override accordion: DesignAccordionBase;

  /** Stream that emits for changes in `@Input` properties. */
  readonly _inputChanges = new Subject<SimpleChanges>();

  /** An event emitted after the body's expansion animation happens. */
  @Output() readonly afterExpand = new EventEmitter<void>();

  /** An event emitted after the body's collapse animation happens. */
  @Output() readonly afterCollapse = new EventEmitter<void>();

  /** Stream of body animation done events. */
  readonly _bodyAnimationDone = new Subject<AnimationEvent>();
  readonly bodyAnimationDone$ = this._bodyAnimationDone.asObservable();

  /** Determines whether the expansion panel should have spacing between it and its siblings. */
  get hasSpacing(): boolean {
    if (this.accordion) {
      return this.expanded && this.accordion.hasSpacing;
    }

    return false;
  }

  constructor(
    @Optional()
    @SkipSelf()
    @Inject(designRadioAccordionBaseToken)
    accordion: DesignAccordionBase,
    _uniqueSelectionDispatcher: UniqueSelectionDispatcher,
  ) {
    const _changeDetectorRef = inject(ChangeDetectorRef);

    super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
    this.accordion = accordion;

    // We need a Subject with distinctUntilChanged, because the `done` event
    // fires twice on some browsers. See https://github.com/angular/angular/issues/24084
    this._bodyAnimationDone
      .pipe(
        distinctUntilChanged(
          (previous: AnimationEvent, next: AnimationEvent) => {
            return (
              previous.fromState === next.fromState &&
              previous.toState === next.toState
            );
          },
        ),
        tap((event: AnimationEvent) => {
          if (event.fromState !== 'void') {
            if (event.toState === 'expanded') {
              this.afterExpand.emit();
            } else if (event.toState === 'collapsed') {
              this.afterCollapse.emit();
            }
          }
        }),
      )
      .subscribe();
  }

  ngAfterContentInit(): void {
    //TODO: ???1
    if (this._lazyContent /* && this._lazyContent._expansionPanel === this*/) {
      // Render the content as soon as the panel becomes open.
      this.opened
        .pipe(
          startWith(null),
          filter(
            () => (this.expanded || this._alwaysExpanded) && !this._portal,
          ),
          tap(() => {
            this._portal = new TemplatePortal(
              this._lazyContent._template,
              this._viewContainerRef,
            );
          }),
          take(1),
        )
        .subscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._inputChanges.next(changes);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this._inputChanges.complete();
  }

  /** Gets the expanded state string. */
  _getExpandedState(): DesignExpansionPanelState {
    if (this._alwaysExpanded) {
      return 'expanded';
    }
    return this.expanded ? 'expanded' : 'collapsed';
  }
}
