import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class NoteSidePanelService {
  private readonly _stateSource = new BehaviorSubject<boolean>(false);
  private readonly _state$ = this._stateSource.asObservable();

  get state(): boolean {
    return this._stateSource.value;
  }

  get state$(): Observable<boolean> {
    return this._state$;
  }

  toggle(): void {
    this._stateSource.next(!this._stateSource.value);
  }

  open(): void {
    this._stateSource.next(true);
  }
  close(): void {
    this._stateSource.next(false);
  }
}
