import { ElementRef } from '@angular/core';
import { DesignColorInput } from '../models/primitives';
import { AbstractConstructor, Constructor } from './constructor';

/** @docs-private */
export interface CanColor {
  color: DesignColorInput | undefined;
}

type CanColorColor = Constructor<CanColor> & AbstractConstructor<CanColor>;
/** @docs-private */
export interface HasElementRef {
  _elementRef: ElementRef;
}
/** Mixin to augment a directive with a `loaded` property. */
export function mixinColor<T extends AbstractConstructor<HasElementRef>>(
  base: T,
  defaultColor?: DesignColorInput
): CanColorColor & T;
export function mixinColor<T extends Constructor<HasElementRef>>(
  base: T,
  defaultColor?: DesignColorInput
): CanColorColor & T {
  return class extends base {
    private _color: DesignColorInput | undefined = undefined;
    defaultColor = defaultColor;
    get color(): DesignColorInput | undefined {
      return this._color;
    }
    set color(value: DesignColorInput | undefined) {
      const colorPalette = value || this.defaultColor;

      if (colorPalette !== this._color) {
        if (this._color) {
          this._elementRef.nativeElement.classList.remove(
            `design-color--${this._color}`
          );
        }
        if (colorPalette) {
          this._elementRef.nativeElement.classList.add(
            `design-color--${colorPalette}`
          );
        }

        this._color = colorPalette;
      }
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
