import { ModuleWithProviders, NgModule } from '@angular/core';
import { TitleService } from './services/title.service';

@NgModule({})
export class UiTitleModule {
  static forRoot(): ModuleWithProviders<UiTitleModule> {
    return {
      ngModule: UiTitleModule,
      providers: [TitleService],
    };
  }
}
