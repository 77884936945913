import { HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  IAddMeasurementToStage,
  IAssignMeasurementToRootNote,
  IUnassignMeasurementFromRootNote,
  IUpdateMeasurementColor,
  IUpdateMeasurementTitle,
  TAddMeasurementGroup,
  TAddMeasurementGroups,
  TAddMeasurementsToGroup,
  TAddRootNote,
  TMeasurementGroupBase,
  TUpdateColor,
  TUpdateData
} from '../../models/src/common-models';
import {
  TMeasurementGroups,
  TRemoveMeasurementFromGroup
} from '../../models/src/distance.type';

@Injectable()
export abstract class MeasurementFacade<T, G> {
  protected readonly _store = inject(Store);
  protected readonly _actions$ = inject(Actions);
  abstract init(stageId: string): void;
  abstract readonly allMeasurement$: Observable<T[]>;
  abstract readonly allMeasurementsHidden$: Observable<boolean>;
  abstract readonly selectedMeasurement$: Observable<T | undefined>;
  abstract readonly updateColorSuccess$: Observable<TUpdateColor>;
  abstract readonly updateDataSuccess$: Observable<any>;
  abstract readonly deleteSuccess$: Observable<string>;
  abstract readonly addSuccess$: Observable<unknown>;
  abstract readonly showMeasurementsSegments$: Observable<boolean>;
  abstract readonly addMeasurementGroupSuccess$: Observable<string>;
  abstract addMeasurementsToGroup$(
    body: TAddMeasurementsToGroup
  ): Observable<string>;

  abstract updateMeasurementData(payload: TUpdateData): void;
  abstract updateMeasurementTitle(payload: IUpdateMeasurementTitle): void;
  abstract updateMeasurementColor(payload: IUpdateMeasurementColor): void;
  abstract addMeasurementToGroups$(
    payload: TAddMeasurementGroups
  ): Observable<void>;
  abstract addMeasurement(body: IAddMeasurementToStage): void;
  abstract addMeasurementToRootNote(body: TAddRootNote): void;
  abstract unassignMeasurementFromNote(
    body: IUnassignMeasurementFromRootNote
  ): void;
  abstract selectedMeasurement(id: string | undefined): void;
  abstract hideAllMeasurements(value: boolean): void;
  abstract getMeasurementsGroup$(
    stageId: string
  ): Observable<TMeasurementGroups[]>;
  abstract addMeasurementGroup(value: TAddMeasurementGroup): void;
  abstract assignMeasurementToNote(value: IAssignMeasurementToRootNote): void;
  abstract exportMeasurements(
    resultFileName: string,
    ids: string[]
  ): Observable<HttpResponse<Blob>>;
  abstract deleteMeasurement(id: string): void;
  abstract deleteMeasurementGroup(id: string): Observable<void>;
  abstract renameMeasurementGroup(
    body: TMeasurementGroupBase
  ): Observable<void>;
  abstract removeMeasurementFromGroup$(
    payload: TRemoveMeasurementFromGroup
  ): Observable<void>;
}
