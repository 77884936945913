/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Directive,
  DoCheck,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges, inject
} from '@angular/core';
import {
  FormGroupDirective,
  NgControl,
  NgForm,
  Validators,
} from '@angular/forms';
import { DesignFormFieldControl } from '@simlab/design/form-field';
import {
  CanDisable,
  CanUpdateErrorState,
  ErrorStateMatcher,
  mixinErrorState,
} from '@simlab/design/internal';
import { Subject } from 'rxjs';

declare const ngDevMode: boolean;
let nextUniqueId = 0;

const _InputBase = mixinErrorState(
  class {
    /**
     * Emits whenever the component state changes and should cause the parent
     * form field to update. Implemented as part of `DesignFormFieldControl`.
     * @docs-private
     */
    readonly stateChanges = new Subject<void>();
    public _defaultErrorStateMatcher = inject(ErrorStateMatcher);
    constructor(
      public _parentForm: NgForm,
      public _parentFormGroup: FormGroupDirective,
      /**
       * Form control bound to the component.
       * Implemented as part of `DesignFormFieldControl`.
       * @docs-private
       */
      public ngControl: NgControl
    ) {}
  }
);
@Directive()
export abstract class InputBase
  extends _InputBase
  implements
    DesignFormFieldControl<any>,
    CanUpdateErrorState,
    DoCheck,
    OnChanges,
    OnDestroy,
    CanDisable
{
  protected _elementRef = inject<ElementRef<HTMLInputElement>>(ElementRef<HTMLInputElement>);
  protected _previousNativeValue: any;

  protected _uid = `design-input-${nextUniqueId++}`;

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  @Input()
  get id(): string {
    return this._id;
  }
  set id(value: string) {
    this._id = value || this._uid;
  }

  protected _id!: string;

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  focused = false;

  /**
   * Implemented as part of DesignFormFieldControl.
   * @docs-private
   */
  override readonly stateChanges: Subject<void> = new Subject<void>();

  /**
   * Implemented as part of DesignFormFieldControl.
   * @docs-private
   */
  @Input()
  get value(): string {
    return this._elementRef.nativeElement.value;
  }
  set value(value: any) {
    if (value !== this.value) {
      this._elementRef.nativeElement = value;
      this.stateChanges.next();
    }
  }

  /**
   * Implemented as part of DesignFormFieldControl.
   * @docs-private
   */
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  get disabled(): boolean {
    return this._disabled;
  }
  protected _disabled = false;

  /**
   * Implemented as part of DesignFormFieldControl.
   * @docs-private
   */
  @Input()
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }
  protected _required: boolean | undefined;

  /** An object used to control when error messages are shown. */
  @Input()
  override errorStateMatcher!: ErrorStateMatcher;

  @Input()
  placeholder = '';

  constructor(
    @Optional()
    _parentForm: NgForm,
    @Optional()
    _parentFormGroup: FormGroupDirective,
    @Optional()
    @Self()
    ngControl: NgControl,
  ) {
    super(_parentForm, _parentFormGroup, ngControl);
    this._previousNativeValue = this.value;
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.stateChanges.next();
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      // We need to re-evaluate this on every change detection cycle, because there are some
      // error triggers that we can't subscribe to (e.g. parent form submissions). This means
      // that whatever logic is in here has to be super lean or we risk destroying the performance.
      this.updateErrorState();
    }

    // We need to dirty-check the native element's value, because there are some cases where
    // we won't be notified when it changes (e.g. the consumer isn't using forms or they're
    // updating the value using `emitEvent: false`).
    this._dirtyCheckNativeValue();
  }

  ngOnDestroy(): void {
    this.stateChanges.next();
  }

  /** Does some manual dirty checking on the native input `value` property. */
  protected _dirtyCheckNativeValue() {
    const newValue = this._elementRef.nativeElement.value;

    if (this._previousNativeValue !== newValue) {
      this._previousNativeValue = newValue;
      this.stateChanges.next();
    }
  }

  /** Callback for the cases where the focused state of the input changes. */
  focusChanged(isFocused: boolean): void {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

  /**
   * Implemented as part of MatFormFieldControl.
   * @docs-private
   */
  onContainerClick(): void {
    // Do not re-focus the input element if the element is already focused.
    if (!this.focused) {
      this.focus();
    }
  }

  onInputNOOP(): void {
    // This is a noop function and is used to let Angular know whenever the value changes.
    // Angular will run a new change detection each time the `input` event has been dispatched.
    // It's necessary that Angular recognizes the value change, because when floatingLabel
    // is set to false and Angular forms aren't used, the placeholder won't recognize the
    // value changes and will not disappear.
    // Listening to the input event wouldn't be necessary when the input is using the
    // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
  }

  /** Focuses the input. */
  focus(options?: FocusOptions): void {
    this._elementRef.nativeElement.focus(options);
  }
}
