<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i
        ui-font
        fontName="warning-filled"
        fontSize="8rem"
        fontColor="#CE2838"
      ></i>
      <h2 designLabel>{{ data.title }}</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    <div class="content">
      {{ data.text }}
    </div>
  </ng-container>

  <ng-container designDialogFooter>
    <button design-stroked-button (click)="cancel()">Cancel</button>
    <button design-flat-button designButtonColor="error" (click)="confirm()">
      {{ data.buttonText ? data.buttonText : 'Confirm' }}
    </button>
  </ng-container>
</design-dialog-wrapper>
