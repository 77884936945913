import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  contentChild,
  EventEmitter,
  Host,
  input,
  OnDestroy,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { DesignIconButton } from '@simlab/design/button';
import { DesignCheckbox } from '@simlab/design/checkbox';
import { DesignIcon } from '@simlab/design/icon';
import { CanColor } from '@simlab/design/internal';
import { tap } from 'rxjs';
import { ExpansionPanelHeader } from '../expansion-panel-header.directive';
import { DesignRadioExpansionPanel } from '../radio-expansion-panel/radio-expansion-panel.component';

@Component({
  selector: 'design-checkbox-expansion-panel-header',
  standalone: true,
  imports: [DesignIcon, DesignIconButton, DesignCheckbox],
  templateUrl: './checkbox-expansion-panel-header.component.html',
  styleUrls: ['./checkbox-expansion-panel-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-checkbox-expansion-panel-header design-focus-indicator',
    role: 'button',
    '[attr.aria-expanded]': '_isExpanded()',
    '[class.design-checkbox-expansion-panel-header--expanded]': '_isExpanded()',
    '[class.design-color--primary]': 'color === "primary"',
    '[class.design-color--secondary]': 'color === "secondary"',
    '[class.design-color--error]': 'color === "error"',
  },
  inputs: ['color'],
  providers: [
    {
      provide: ExpansionPanelHeader,
      useExisting: CheckboxExpansionPanelHeaderComponent,
    },
  ],
})
export class CheckboxExpansionPanelHeaderComponent
  extends ExpansionPanelHeader
  implements FocusableOption, AfterViewInit, OnDestroy, CanColor
{
  private readonly _designCheckbox = contentChild.required(DesignCheckbox);

  readonly displayToggleButton = input<boolean>(false);

  get checkBox() {
    return this._designCheckbox();
  }

  constructor(
    @Host()
    public panel: DesignRadioExpansionPanel,
  ) {
    super(panel);
    this.expanded = this.panel.expanded;
  }

  @Output() emitClick = new EventEmitter<void>();
  expanded = false;

  override _toggle(): void {
    if (!this.disabled) {
      if (this.panel.expanded) {
        this.panel.close();
      } else {
        this.panel.open();
      }
    }
    this.expanded = this.panel.expanded;
    this._changeDetectorRef.detectChanges();
  }

  openCloseHeader(): void {
    this._toggle();
  }

  openCloseHeaderCheckBox(): void {
    this._toggle();
  }

  ngAfterViewInit(): void {
    this._focusMonitor
      .monitor(this._elementRef)
      .pipe(
        tap((origin: FocusOrigin) => {
          if (origin && this.panel.accordion) {
            this.panel.accordion._handleHeaderFocus(this);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._parentChangeSubscription.unsubscribe();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }
}
