import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterModule,
  Routes
} from '@angular/router';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  CURRENT_PROJECT_ID,
  ProjectsFacade,
  UserPreferenceFacade
} from '@simlab/data-store';
import { DocumentsPageComponent } from '@simlab/feature/documents';

import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject
} from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import {
  ComponentsFacade,
  NotesFacade,
  ProjectPrivilegesEffects,
  ProjectPrivilegesFacade,
  ShowcaseNotesFacade,
  StagesFacade,
  privilegesFeatureKey,
  projectPrivilegesReducer
} from '@simlab/data-store';
import {
  AreaFacade,
  DistanceFacade,
  provideAreaMeasurment,
  provideDistanceMeasurment
} from '@simlab/feature-measurement/state';
import { ProcoreAuthorizationService } from '@simlab/procore/services';
import { API_URL_NEW } from '@simlab/shared/common/tokens';
import { Environment } from '@simlab/util-shared';
import { CheckUserGuard, UserPreferencesResolver } from '@simlab/util/core';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';
import { iif, of, switchMap, tap } from 'rxjs';
import { environment as globalEnvironment } from '../environments/environment';
import { CallbackComponent } from './components/callback/callback.component';
import { ErrorComponent } from './components/error/error.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SocketLayoutComponent } from './components/socket-layout/socket-layout.component';
import { QueryGuard } from './guards/query.guard';

export const permissionToProjectGuard = (route: ActivatedRouteSnapshot) => {
  if (route.params['projectId']) {
    const projectPrivilegesFacade = inject(ProjectPrivilegesFacade);
    const router: Router = inject(Router);
    projectPrivilegesFacade.loadPrivileges(route.params['projectId']);
    return projectPrivilegesFacade.permissionLoaded$.pipe(
      tap((loaded) => {
        if (!loaded) {
          router.navigate([`projects/list`]);
        }
      })
    );
  }
  return of(true);
};

@Component({
  template: ` <router-outlet></router-outlet>`,
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterModule],
  providers: [
    {
      provide: CURRENT_PROJECT_ID,
      useFactory: () => inject(ActivatedRoute).snapshot.params['projectId']
    }
  ]
})
export class ClearStateWrapperComponent implements OnDestroy {
  private readonly _notesFacade = inject(NotesFacade);
  private readonly _stagesFacade = inject(StagesFacade);
  private readonly _areaMeasurementFacade = inject(AreaFacade);
  private readonly _componentsFacade = inject(ComponentsFacade);
  private readonly _showcaseNotesFacade = inject(ShowcaseNotesFacade);
  private readonly _distanceMeasurementFacade = inject(DistanceFacade);
  private readonly _privilegesFacade = inject(ProjectPrivilegesFacade);
  ngOnDestroy(): void {
    this._notesFacade.clearStore();
    this._stagesFacade.clearStore();
    this._privilegesFacade.clearStore();
    this._componentsFacade.clearStore();
    this._showcaseNotesFacade.clearStore();
    this._areaMeasurementFacade.clearStore();
    this._distanceMeasurementFacade.clearStore();
  }
}

const playgroundRoute: Route = {
  path: 'playground',
  loadChildren: () =>
    import('./components/playground/playground.component').then(
      (m) => m.PlaygroundModule
    )
};

export const appRoutes = (environment: Environment): Routes => [
  {
    path: '',
    canActivate: [AutoLoginAllRoutesGuard, CheckUserGuard],
    resolve: [UserPreferencesResolver],
    component: SocketLayoutComponent,
    children: [
      {
        path: 'projects',
        loadChildren: () =>
          import('@simlab/feature/projects').then(
            (m) => m.FeatureProjectsModule
          )
      },
      {
        path: 'project/:projectId',
        component: ClearStateWrapperComponent,
        canActivateChild: [
          () => {
            const facade = inject(ProjectsFacade);
            return facade.loaded$.pipe(
              switchMap((loaded: boolean) =>
                iif(
                  () => loaded,
                  of(true),
                  of(facade.initStore({})).pipe(
                    switchMap(() => facade.projectsLoaded$)
                  )
                )
              )
            );
          },
          () => {
            const userPrefFacade = inject(UserPreferenceFacade);
            return userPrefFacade.loaded$.pipe(
              switchMap((loaded: boolean) => {
                return iif(
                  () => loaded,
                  of(true),
                  of(userPrefFacade.initStore()).pipe(
                    switchMap(() => userPrefFacade.userConnectedToProcore$)
                  )
                );
              })
            );
          }
        ],

        children: [
          {
            path: 'stages',
            loadChildren: () =>
              import('@simlab/pages/project').then((m) =>
                m.stagesRoutes(environment)
              )
          },
          {
            path: 'documents',
            component: DocumentsPageComponent,
            loadChildren: () =>
              import('@simlab/feature/documents').then((m) => m.documentRoutes)
          }
        ],
        canActivate: [permissionToProjectGuard],

        providers: [
          {
            provide: API_URL_NEW,
            useValue: environment.configuration.api.url
          },
          provideState(privilegesFeatureKey, projectPrivilegesReducer),
          provideEffects(ProjectPrivilegesEffects),
          ProcoreAuthorizationService,
          NotesFacade,
          StagesFacade,
          ShowcaseNotesFacade,
          ProjectPrivilegesFacade,
          provideAreaMeasurment(),
          provideDistanceMeasurment()
        ]
      },
      {
        path: 'invitation',
        loadChildren: () =>
          import('@simlab/feature/invitation').then(
            (m) => m.FeatureInvitationModule
          ),
        canLoad: [QueryGuard.canLoadWithParams(['t', 'n'])]
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import('@simlab/feature/organizations').then((m) => {
            return m.organizationRoutes(environment);
          })
      },
      { path: '', redirectTo: '/projects/list', pathMatch: 'full' }
    ]
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'public/:token',
    loadChildren: () =>
      import('@simlab/feature/public').then((m) => m.FeaturePublicModule)
  },
  {
    path: 'payment-summary',
    loadComponent: () =>
      import('@simlab/feature/organizations').then(
        (m) => m.PaymentSummaryComponent
      )
  },
  {
    path: 'home',
    redirectTo: '/projects/list',
    pathMatch: 'full'
  },
  {
    path: 'unity',
    loadChildren: () =>
      import('@simlab/feature/unity').then((m) => m.unityRoutes)
  },
  {
    path: 'callbackMatterport',
    loadChildren: () =>
      import('./components/callback/matterport-api-callback.component').then(
        (m) => m.MatterportApiCallbackModule
      )
  },
  {
    path: 'callbackProcore',
    loadComponent: () =>
      import('./components/callback/procore-callback.component')
  },
  globalEnvironment.production ? {} : playgroundRoute,
  { path: '403', component: ForbiddenComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'unauthorized', redirectTo: '403', pathMatch: 'full' },
  { path: '**', redirectTo: '404', pathMatch: 'full' }
];
