/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive } from '@angular/core';
import { DesignFormFieldControl } from '@simlab/design/form-field';
import { InputBase } from './input-base.directive';

@Directive({
  selector: 'input[designInput]',
  exportAs: 'designInput',
  standalone: true,
  host: {
    class: 'design-base-input design-input',
    '[id]': 'id',
    // Native input properties that are overwritten by Angular inputs need to be synced with
    // the native input element. Otherwise property bindings for those don't work.
    '[attr.id]': 'id',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '[attr.aria-required]': 'required',
    '(focus)': 'focusChanged(true)',
    '(blur)': 'focusChanged(false)',
    '(input)': 'onInputNOOP()',
  },
  providers: [{ provide: DesignFormFieldControl, useExisting: DesignInput }],
})
export class DesignInput extends InputBase {}
