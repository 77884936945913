/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive } from '@angular/core';
import { designFormFieldHintToken } from '../tokens/hint.token';

@Directive({
  selector: 'design-hint, [designHint]',
  standalone: true,
  providers: [
    { provide: designFormFieldHintToken, useExisting: DesignFormFieldHint },
  ],
})
export class DesignFormFieldHint {}
