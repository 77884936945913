import { IVector3 } from './vector3';

export interface AcceptSynchronization {
  referenceComponent: string;
  referenceStage: string;
  referencePoint1: IVector3;
  referencePoint2: IVector3;
  referencePoint3: IVector3;
  targetComponent: string;
  targetStage: string;
  targetPoint1: IVector3;
  targetPoint2: IVector3;
  targetPoint3: IVector3;
}
