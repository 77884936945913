import { GroupComponent } from './components/group/group.component';
import { TabComponent } from './components/tabs/tab.component';
import { TabLabelDirective } from './directives/tab-label.directive';
import { TabAlign } from './models/tab-align';
import { UiTabsModule } from './ui-tabs.module';

export {
  GroupComponent,
  TabAlign,
  TabComponent,
  TabLabelDirective,
  UiTabsModule,
};
