import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  OrganizationInfo,
  OrganizationLevel,
  OrganizationPermission
} from '@simlab/data-access';
import { filter, map, Observable } from 'rxjs';
import * as PrivilegesSelectors from './privileges.selectors';

@Injectable()
export class OrganizationPrivilegesFacade {
  private readonly store = inject(Store);
  //organizzation

  readonly hasLimitedAccess$: Observable<boolean> = this.store.pipe(
    select(PrivilegesSelectors.getRoleId),
    map(
      (roleId: OrganizationLevel | undefined) =>
        roleId !== undefined &&
        (roleId === OrganizationLevel.Viewer ||
          roleId === OrganizationLevel.Basic)
    )
  );

  readonly isOwner$: Observable<boolean> = this.store.pipe(
    select(PrivilegesSelectors.getRoleId),
    map((roleId: number | undefined) => (roleId ? roleId === 3 : false))
  );

  readonly isAdmin$: Observable<boolean> = this.store.pipe(
    select(PrivilegesSelectors.getRoleId),
    map((roleId: number | undefined) => (roleId ? roleId === 2 : false))
  );

  readonly viewOrganizationInfo$: Observable<OrganizationInfo> =
    this.store.pipe(select(PrivilegesSelectors.getOrganizationInfo));

  readonly viewOrganization$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(OrganizationPermission.ViewOrganization)
    )
  );
  readonly editOrganizationProfile$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.EditOrganizationProfile
      )
    )
  );
  readonly deleteOrganization$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.DeleteOrganization
      )
    )
  );
  readonly transferOrganization$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.TransferOrganization
      )
    )
  );

  // organization roles
  readonly viewOrganizationRoles$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.ViewOrganizationRoles
      )
    )
  );

  // users
  readonly viewUsers$: Observable<boolean> = this.store.pipe(
    select(PrivilegesSelectors.getPermission(OrganizationPermission.ViewUsers))
  );
  readonly inviteNewUsers$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(OrganizationPermission.InviteNewUsers)
    )
  );
  readonly changeUserOrganizationRole$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.ChangeUserOrganizationRole
      )
    )
  );
  readonly deleteUsers$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(OrganizationPermission.DeleteUsers)
    )
  );
  readonly addRemoveTagsToUser$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.AddRemoveTagsToUser
      )
    )
  );

  // projects
  readonly viewProjects$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(OrganizationPermission.ViewProjects)
    )
  );
  readonly createDeleteProjects$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.CreateDeleteProjects
      )
    )
  );

  readonly addDeleteTeamsInProjects$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.AddDeleteTeamsInProjects
      )
    )
  );
  readonly manageIndividualUserPermissionsInProject$: Observable<boolean> =
    this.store.pipe(
      select(
        PrivilegesSelectors.getPermission(
          OrganizationPermission.ManageIndividualUserPermissionsInProject
        )
      )
    );
  readonly archiveProject$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.ChangeProjectArchivizationStatus
      )
    )
  );
  readonly transferProject$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(OrganizationPermission.TransferProject)
    )
  );

  // teams
  readonly viewTeams$: Observable<boolean> = this.store.pipe(
    select(PrivilegesSelectors.getPermission(OrganizationPermission.ViewTeams))
  );
  readonly createManageDeleteOwnTeams$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.CreateManageDeleteOwnTeams
      )
    )
  );
  readonly manageTeamsPrivileges$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.ManageDeleteTeams
      )
    )
  );

  // tags
  readonly viewTags$: Observable<boolean> = this.store.pipe(
    select(PrivilegesSelectors.getPermission(OrganizationPermission.ViewTags))
  );
  readonly addRemoveOwnTags$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(OrganizationPermission.AddRemoveOwnTags)
    )
  );
  readonly removeOthersTags$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(OrganizationPermission.RemoveOthersTags)
    )
  );

  readonly viewLicenseManager$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(OrganizationPermission.ManageLicenses)
    )
  );

  // subscription plan
  readonly viewSubscriptionPlans$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.ViewSubscriptionPlans
      )
    )
  );
  readonly changeSubscriptionPlan$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.ChangeSubscriptionPlan
      )
    )
  );
  readonly managePayMethod$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(OrganizationPermission.ManagePayMethod)
    )
  );
  readonly viewDownloadInvoices$: Observable<boolean> = this.store.pipe(
    select(
      PrivilegesSelectors.getPermission(
        OrganizationPermission.ViewDownloadInvoices
      )
    )
  );

  readonly loaded$: Observable<boolean> = this.store.pipe(
    select(PrivilegesSelectors.getLoaded)
  );

  readonly organizationId$: Observable<string> = this.store.pipe(
    select(PrivilegesSelectors.getOrganizationId),
    filter((organizationId: string | undefined) => !!organizationId),
    map((organizationId: string | undefined) => organizationId as string)
  );
}
