/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { Directive } from '@angular/core';
import { IconBase } from './icon-base.directive';

@Directive({
  selector: 'i[designIcon]',
  exportAs: 'designIcon',
  standalone: true,
  host: {
    class: 'design-icon',
  },
  inputs: ['name: designIcon', 'rotateBy: designRotateBy', 'size: designSize'],
})
export class DesignIcon extends IconBase {}
