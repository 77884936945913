<div
  cdk-overlay-origin
  [attr.aria-owns]="panelOpen ? id + '-panel' : null"
  class="select-trigger"
  (click)="toggle()"
  #origin="cdkOverlayOrigin"
  #trigger
>
  <div class="select-value" [ngSwitch]="empty" [attr.id]="_valueId">
    <i
      *ngIf="iconName"
      class="icon-select"
      ui-font
      [fontName]="iconName"
      fontSize="1.4rem"
    ></i>
    <span class="select-placeholder select-min-line" *ngSwitchCase="true">{{
      placeholder
    }}</span>

    <span class="select-value-text" *ngSwitchCase="false">
      <span *ngIf="!rectangle && !showPlaceholder" class="select-min-line">{{
        triggerValue
      }}</span>
      <span
        class="select-placeholder select-min-line"
        *ngIf="rectangle || showPlaceholder"
        >{{ placeholder }}</span
      >

      <span *ngIf="multiple" class="selection-length"
        >{{ triggerValueArray.length }}

        <button
          design-icon-button
          type="button"
          (click)="$event.stopPropagation(); removeAllSelectedFilters()"
        >
          <i designIcon="cross" designSize="23px"></i>
        </button>
      </span>
    </span>
  </div>

  <ng-content *ngIf="!panelOpen" select="i[ui-font]"></ng-content>
  <ng-content *ngIf="!panelOpen" select="i[ui-icon]"></ng-content>
  <ng-content *ngIf="!panelOpen" select="i[designIcon]"></ng-content>
  <div class="select-arrow-wrapper">
    <i designIcon="arrow_right" designSize="20px" [designRotateBy]="90"></i>
  </div>
</div>

<ui-chip-list class="chip-list-context" *ngIf="rectangle">
  <ng-container *ngFor="let chip of triggerValueArray">
    <ui-chip
      [removable]="true"
      [label]="chip"
      (eClose)="removeSelectedFilter(chip)"
    ></ui-chip>
  </ng-container>
</ui-chip-list>

<ng-template
  cdk-connected-overlay
  cdkConnectedOverlayLockPosition
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayPanelClass]="_overlayPanelClass"
  [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayMinWidth]="_triggerRect?.width || 0"
  (backdropClick)="close()"
  (attach)="_onAttached()"
  (detach)="close()"
>
  <div class="select-panel-wrap">
    <div
      #panel
      role="listbox"
      tabindex="-1"
      class="select-panel select-value-text"
      [attr.id]="id + '-panel'"
      [attr.aria-label]="ariaLabel || null"
      [attr.aria-labelledby]="_getPanelAriaLabelledby()"
      [style.transformOrigin]="_transformOrigin"
      [style.font-size.px]="_triggerFontSize"
      (keydown)="_handleKeydown($event)"
    >
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
