import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ApiFacadeService,
  OrganizationWithPermissions,
} from '@simlab/data-access';
import { catchError, map, of, switchMap } from 'rxjs';
import * as OrganizationActions from '../organizations/organizations.actions';
import { OrganizationPrivilegesActions } from './privileges.actions';

@Injectable()
export class OrganizationPrivilegesEffects {
      private readonly actions$ = inject(Actions);
      private readonly router = inject(Router);
      private readonly apiFacadeService = inject(ApiFacadeService);
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.selectOrganization),
      switchMap((action: { id: string | undefined }) => {
        if (action.id !== undefined) {
          return this.apiFacadeService.organizations
            .getOrganizationWithPermissions$({
              organizationId: action.id,
            })
            .pipe(
              map((organizationWithPermissions: OrganizationWithPermissions) =>
                OrganizationPrivilegesActions.loadOrganizationWithPermissionsSuccess(
                  {
                    organizationWithPermissions,
                  }
                )
              ),
              catchError((error) =>
                of(
                  OrganizationPrivilegesActions.loadOrganizationWithPermissionsFailure(
                    {
                      error,
                    }
                  )
                )
              )
            );
        }
        return of(OrganizationActions.selectOrganizationFailure());
      })
    )
  );
}
