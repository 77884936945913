import { HttpParams } from '@angular/common/http';

export function requestToHttpParamsMapper<TRequest extends Record<string, any>>(
  request: TRequest,
  parentKey: string | null = null,
  params: HttpParams = new HttpParams()
): HttpParams {
  Object.entries(request).forEach(([key, value]) => {
    if (value == null) return;

    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (isObject(value)) {
      params = requestToHttpParamsMapper(value, newKey, params);
    } else if (Array.isArray(value)) {
      value.forEach((item) => {
        params = params.append(`${newKey}`, item as any);
      });
    } else {
      params = params.append(newKey, value as any);
    }
  });

  return params;
}

function isObject(value: unknown): value is Record<string, unknown> {
  return (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    !isDate(value) &&
    value !== null
  );
}

function isDate(value: unknown): boolean {
  return value instanceof Date && !isNaN(value.getTime());
}
