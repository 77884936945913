import { ConfirmWithIconModalComponent } from './components-new/confirm-with-icon-modal/confirm-with-icon-modal.component';
import { InfoModalDialogComponent } from './components-new/info-modal-dialog/info-modal-dialog.component';
import { SimpleAcceptModalDialogComponent } from './components-new/simple-accept-modal-dialog/simple-accept-modal-dialog.component';
import { WarningConfirmationModalComponent } from './components-new/warning-confirmation-modal/warning-confirmation-modal.component';
import { WarningCustomizeModalComponent } from './components-new/warning-customize-modal/warning-customize-modal.component';
import { WarningModalComponent } from './components-new/warning-modal/warning-modal.component';

import { DeleteConfirmationDialogComponent } from './components/delete-confirmation-dialog/delete-confirmation-dialog.component';
import { DeleteWithConfirmNameDialogComponent } from './components/delete-with-confirm-name-confirmation-dialog/delete-with-confirm-name-dialog.component';
import { ExitCompareConfirmationDialogComponent } from './components/exit-compare-confirmation-dialog/exit-compare-confirmation-dialog.component';
import { NotSavedChangesDialogComponent } from './components/not-saved-changes-dialog/not-saved-changes-dialog.component';
import { ConfirmationModalRef } from './models/confirmation-modal-ref';
import { DialogResponse } from './models/dialog-response';
import { ModalRef } from './models/modal-ref';
import { ModalService } from './services/modal.service';
import { MODAL_DATA } from './tokens/modal-data.token';
import { UiModalModule } from './ui-modal.module';
export {
  ConfirmWithIconModalComponent,
  ConfirmationModalRef,
  DeleteConfirmationDialogComponent,
  DeleteWithConfirmNameDialogComponent,
  DialogResponse,
  ExitCompareConfirmationDialogComponent,
  InfoModalDialogComponent,
  MODAL_DATA,
  ModalRef,
  ModalService,
  NotSavedChangesDialogComponent,
  SimpleAcceptModalDialogComponent,
  UiModalModule,
  WarningConfirmationModalComponent,
  WarningCustomizeModalComponent,
  WarningModalComponent,
};
