import { Dialog } from '@angular/cdk/dialog';
import { Platform } from '@angular/cdk/platform';
import { inject, Injectable } from '@angular/core';
import { map, of, switchMap } from 'rxjs';
import { OpenNewTabSafariComponent } from '../components/open-new-tab-safari/open-new-tab-safari.component';

@Injectable()
export class OpenNewTabService {
  private readonly _platform = inject(Platform);
  private readonly _dialog = inject(Dialog);

  openUrlInNewTabWithSafariSupport(url: string) {
    return of(true).pipe(
      switchMap(() => {
        if (this._platform.SAFARI) {
          const dialogRef = this._dialog.open<
            boolean,
            string,
            OpenNewTabSafariComponent
          >(OpenNewTabSafariComponent, {
            width: 'min(90%, 400px)'
          });

          return dialogRef.closed.pipe(
            map((e) => {
              if (e) {
                window.open(url, '_black');
              }
            })
          );
        } else {
          return of(window.open(url, '_black'));
        }
      })
    );
  }
}
