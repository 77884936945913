import { A11yModule, CdkTrapFocus } from '@angular/cdk/a11y';
import { NgModule } from '@angular/core';
import { DesignDialogContent } from '../components/dialog-content.directive';
import { DesignDialogFooter } from '../components/dialog-footer.directive';
import { DesignDialogHeader } from '../components/dialog-header.directive';
import { DesignDialogWrapper } from '../components/dialog-wrapper.component';
@NgModule({
  imports: [
    DesignDialogWrapper,
    DesignDialogHeader,
    DesignDialogFooter,
    DesignDialogContent,
    A11yModule,
  ],
  exports: [
    DesignDialogWrapper,
    DesignDialogHeader,
    DesignDialogFooter,
    DesignDialogContent,
    CdkTrapFocus,
  ],
})
export class DesignDialogWrapperModule {}
