import { Color } from 'three';
import { ComponentTextDefinition } from '../types/custom-component.type';

export function contrastColor(color: Color): '#000000' | '#FFFFFF' {
  const luma = (0.299 * color.r + 0.587 * color.g + 0.114 * color.b) / 255;

  // Return black for bright colors, white for dark colors
  return luma > 0.5 ? '#000000' : '#FFFFFF';
}

export function contrastColors(color: Color): '#000000' | '#FFFFFF' {
  const luma = (0.299 * color.r + 0.587 * color.g + 0.114 * color.b) / 1;

  // Return black for bright colors, white for dark colors
  return luma > 0.5 ? '#000000' : '#FFFFFF';
}
export function roundRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  width: number,
  height: number,
  radius: any,
  fill: boolean,
  stroke: boolean
) {
  if (typeof stroke === 'undefined') {
    stroke = true;
  }
  if (typeof radius === 'undefined') {
    radius = 5;
  }
  if (typeof radius === 'number') {
    radius = { tl: radius, tr: radius, br: radius, bl: radius };
  } else {
    const defaultRadius: Record<string, number> = {
      tl: 0,
      tr: 0,
      br: 0,
      bl: 0,
    };
    for (const side in defaultRadius) {
      radius[side] = radius[side] || defaultRadius[side];
    }
  }
  ctx.beginPath();
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  if (fill) {
    ctx.fill();
  }
  if (stroke) {
    ctx.stroke();
  }
}

export class DeviceCanvasTextTexture {
  ctx!: CanvasRenderingContext2D;
  image: HTMLImageElement = new Image();
  htmlElement;
  text = '';
  rectFillStyleColor = 'rgba(26,30,31,0.75)';
  textFillStyleColor = '#FFFFFF';

  constructor(
    private componentText: ComponentTextDefinition,
    private textLength = 1,
    private width = 50 * textLength,
    private height = 128
  ) {
    this.htmlElement = document.createElement('canvas');
    const ctx = this.htmlElement.getContext('2d');
    if (!ctx) return;
    this.ctx = ctx;
    this.ctx.canvas.width = this.width;
    this.ctx.canvas.height = this.height;
    this.ctx.fillStyle = '#FFF';
    this.text = componentText.text || '';
    if (componentText.backgroundColor) {
      const linear = componentText.backgroundColor.convertSRGBToLinear();

      const color = linear.getStyle();
      if (color) this.rectFillStyleColor = color;
      this.textFillStyleColor = contrastColors(linear);
    }
    this.draw(this.ctx);
  }

  draw(ctx: CanvasRenderingContext2D) {
    const radius = 20;

    ctx.clearRect(0, 0, this.width, this.height);

    ctx.lineWidth = 5;
    ctx.strokeStyle = this.textFillStyleColor;
    ctx.fillStyle = this.rectFillStyleColor;

    roundRect(ctx, 0, 0, this.width, this.height, radius, true, true);
    ctx.fillStyle = this.textFillStyleColor;

    ctx.font = 'bold  70px Arial';
    ctx.textAlign = 'left';
    this.text = this.text || '';
    ctx.fillText(
      this.text,
      this.width - 45 * this.textLength,
      this.height - 35
    );
  }
}
export class DeviceCanvasIconTexture {
  ctx!: CanvasRenderingContext2D;

  constructor(
    private image = new Image(),
    private color: any,
    private width = 256,
    private height = 256
  ) {
    const htmlElement = document.createElement('canvas');
    const ctx = htmlElement.getContext('2d');
    if (!ctx) return;
    this.ctx = ctx;
    this.ctx.canvas.width = this.width;
    this.ctx.canvas.height = this.height;
    this.ctx.fillStyle = '#FFF';

    this.draw(this.ctx);
  }

  draw(ctx: CanvasRenderingContext2D) {
    const radius = 35;

    ctx.clearRect(0, 0, this.width, this.height);

    ctx.lineWidth = 5;
    ctx.strokeStyle = 'rgba(255, 255, 255)';
    ctx.fillStyle = this.color || 'rgba(54,54,55, .4)';
    roundRect(ctx, 0, 0, this.width, this.height, radius, true, true);

    try {
      ctx.drawImage(this.image, 0, 0, this.width, this.height);
    } catch (e) {
      console.error('[ERROR] mp-device3d, error: ', e);
    }
  }
}

export class CircleCanvasTexture {
  ctx!: CanvasRenderingContext2D;

  constructor(
    private stroke: string,
    private background: string,
    private width = 256,
    private height = 256,
    private image?: HTMLImageElement
  ) {
    const htmlElement = document.createElement('canvas');
    const ctx = htmlElement.getContext('2d');
    if (!ctx) return;
    this.ctx = ctx;
    this.ctx.canvas.width = this.width;
    this.ctx.canvas.height = this.height;

    this.draw(this.ctx);
  }

  draw(ctx: CanvasRenderingContext2D) {
    ctx.lineWidth = this.width * 0.1;
    ctx.strokeStyle = this.stroke;
    ctx.fillStyle = this.background;
    ctx.beginPath();
    ctx.arc(
      this.width * 0.5,
      this.height * 0.5,
      this.height * 0.5 - this.width * 0.2,
      0,
      2 * Math.PI
    );

    ctx.fill();
    ctx.stroke();
    this.image && ctx.drawImage(this.image, 0, 0, this.width, this.height);
  }
}
export function parseObjectToRgbaString(
  setRgbwColor_Prop: Color
): string | undefined {
  if (!setRgbwColor_Prop) return;
  const rChannel = Number(setRgbwColor_Prop.r);
  const gChannel = Number(setRgbwColor_Prop.g);
  const bChannel = Number(setRgbwColor_Prop.b);
  return `rgb(${rChannel},${gChannel},${bChannel})`;
}

export function parseColorVectorToRgbObject(
  colorVector: Uint8ClampedArray | number[]
): RGBVectorInterface {
  return {
    r: colorVector[0],
    g: colorVector[1],
    b: colorVector[2],
  };
}
export interface RGBVectorInterface {
  r: number;
  g: number;
  b: number;
  w?: number;
}
