import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonBase, ButtonComponent } from '../button/button.component';

@Component({
  selector: `a[ui-button], a[ui-raised-button], a[ui-stroked-button], a[ui-flat-button], a[ui-icon-button], a[ui-fab-button], a[ui-mini-fab-button]`,
  exportAs: 'uiButton, uiAnchor',
  templateUrl: '../button/button.component.html',
  styleUrls: ['../button/button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: ButtonBase, useExisting: AnchorComponent }],
})
export class AnchorComponent extends ButtonComponent {
  @Input() tabIndex!: number;

  @HostBinding('attr.tabindex')
  get attrTabindex() {
    return this.disabled ? -1 : this.tabIndex || 0;
  }
}
