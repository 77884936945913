import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ApiFacadeService,
  ModelAccess,
  StageComponent,
} from '@simlab/data-access';
import { MatterportApiFacadeService, ModelSweep } from '@simlab/matterport/api';
import { ShowcaseHelper } from '@simlab/util-shared';
import {
  catchError,
  exhaustMap,
  filter,
  first,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { setSynchronizedMatterport } from '../stages/stages.actions';
import { StagesFacade } from '../stages/stages.facade';
import * as ComponentsActions from './components.actions';
import { ComponentsFacade } from './components.facade';

@Injectable()
export class ComponentsEffects {
      private readonly actions$ = inject(Actions);
      private readonly matterport = inject(MatterportApiFacadeService);
      private readonly apiFacadeService = inject(ApiFacadeService);
      private readonly componentsFacade = inject(ComponentsFacade);
      private readonly stagesFacade = inject(StagesFacade);
  init$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ComponentsActions.init),
        switchMap((action) =>
          this.apiFacadeService.components
            .getComponentsForProject({
              types: ['Blueprint', 'Matterport'],
              projectId: action.projectId,
            })
            .pipe(
              map((components: StageComponent[]) =>
                components.map((component: StageComponent) => {
                  if (!component.extension) return component;
                  return { ...component, visible: false };
                })
              ),
              switchMap((components: StageComponent[]) => {
                this.componentsFacade.loadComponentsSuccess(components);
                return this.stagesFacade.selectedId$.pipe(
                  map((stageId: string) =>
                    this.componentsFacade.initSetComponent(stageId)
                  ),
                  first()
                );
              }),
              catchError((error) =>
                of(ComponentsActions.loadComponentsFailure({ error }))
              )
            )
        )
      ),
    { dispatch: false }
  );

  initSetComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.initSetComponent),
      switchMap((action) =>
        this.componentsFacade.allComponents$.pipe(
          filter((components: StageComponent[]) => components !== undefined),
          take(1),
          map((components: StageComponent[]) => {
            const allComponentsForStage = components?.filter(
              (component: StageComponent) =>
                component.stageId === action.stageId && !component.extension
            );
            const componentToSelect = allComponentsForStage.find(
              (comp: StageComponent) => comp.isSynchronizingAccepted
            );

            return ComponentsActions.initSetComponentSuccess({
              component: componentToSelect ?? allComponentsForStage[0],
            });
          })
        )
      )
    )
  );

  initSetComponentSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.initSetComponentSuccess),
      map((data) => {
        if (data.component !== undefined) {
          return ComponentsActions.checkSweepOnComponent({
            component: data.component,
          });
        }
        return ComponentsActions.checkSweepOnComponentFailure();
      })
    )
  );

  setComponentId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.checkSweepOnComponent),
      switchMap((action) => {
        const scanId = ShowcaseHelper.findShowcaseId(
          action.component.componentUrl
        );
        return this.apiFacadeService.matterport
          .getMatterportModelAccess(scanId)
          .pipe(
            switchMap((modelAccess: ModelAccess) => {
              if (
                modelAccess !== ModelAccess.Public &&
                this.matterport.checkTokenExist()
              ) {
                return this.apiFacadeService.tokens
                  .getMatterportUserToken()
                  .pipe(
                    switchMap((token) =>
                      this.apiFacadeService.components.updateComponentMatterportSweeps$(
                        {
                          componentId: action.component.id,
                          token: token.accessToken,
                        }
                      )
                    )
                  );
              } else if (modelAccess === ModelAccess.Public) {
                return this.apiFacadeService.components.updateComponentMatterportSweeps$(
                  {
                    componentId: action.component.id,
                  }
                );
              }
              return of(undefined);
            }),
            switchMap((modelSweep: ModelSweep[] | undefined) => {
              if (modelSweep !== undefined) {
                return of(
                  ComponentsActions.checkSweepOnComponentSuccess({
                    componentId: action.component.id,
                    sweeps: modelSweep,
                  }),
                  ComponentsActions.sweepOnComponentUpdatedSuccess({
                    componentId: action.component.id,
                  })
                );
              }
              return of();
            })
          );
      })
    )
  );

  synchronization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.setSynchronizingAccepted),
      switchMap((action) =>
        iif(
          () => action.status,
          this.apiFacadeService.components
            .markAsSynchronized({
              componentId: action.componentId,
            })
            .pipe(
              mergeMap(() =>
                this.componentsFacade.hasComponent$(action.componentId).pipe(
                  take(1),
                  mergeMap((hasComponent) =>
                    iif(
                      () => hasComponent,
                      of(undefined).pipe(
                        tap(() => {
                          this.componentsFacade.setSelectedComponentById(
                            action.componentId
                          );
                        })
                      ),
                      this.apiFacadeService.components.getComponentForStage({
                        stageId: action.stageId,
                        componentId: action.componentId,
                      })
                    )
                  ),
                  tap((response) => {
                    if (response)
                      this.componentsFacade.addComponentSuccess(response);
                  })
                )
              )
            ),
          this.apiFacadeService.components.markAsDesynchronized({
            componentId: action.componentId,
          })
        ).pipe(map(() => action))
      ),

      map((action) => {
        return ComponentsActions.setSynchronizingAcceptedSuccess({
          stageId: action.stageId,
          componentId: action.componentId,
          status: action.status,
        });
      }),

      catchError(() => of(ComponentsActions.setSynchronizingAcceptedFailure()))
    )
  );

  deleteSynchronization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.deleteSynchronization),
      switchMap((action) =>
        this.apiFacadeService.components
          .markAsDesynchronized({
            componentId: action.componentId,
          })
          .pipe(map(() => action))
      ),
      map((action) =>
        ComponentsActions.deleteSynchronizationSuccess({
          stageId: action.stageId,
          componentId: action.componentId,
        })
      ),
      catchError(() => of(ComponentsActions.deleteSynchronizationFailure()))
    )
  );

  setSynchronizingAcceptedSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.setSynchronizingAcceptedSuccess),
      map((action) => setSynchronizedMatterport({ stageId: action.stageId }))
    )
  );

  addComponentToStage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.addComponentToStage),
      exhaustMap((action) =>
        this.apiFacadeService.components
          .addComponentToStage(action.component)
          .pipe(
            switchMap((componentId) =>
              this.apiFacadeService.components.getComponentForStage({
                stageId: action.component.stageId,
                componentId,
              })
            )
          )
      ),
      map((component: StageComponent) => {
        this.stagesFacade.hasComponent(component.stageId);
        return ComponentsActions.addComponentSuccess({ component });
      }),
      catchError(() => of(ComponentsActions.addComponentFailure()))
    )
  );

  removeComponent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.removeComponent),
      exhaustMap((action) =>
        this.apiFacadeService.components
          .removeComponentFromStage({
            stageId: action.stageId,
            componentId: action.componentId,
          })
          .pipe(
            map(() =>
              ComponentsActions.removeComponentSuccess({
                componentId: action.componentId,
              })
            )
          )
      ),
      catchError((e) => of(ComponentsActions.removeComponentFailure()))
    )
  );

  editComponentName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.editComponentName),
      exhaustMap((action) =>
        this.apiFacadeService.components
          .editComponentName({
            newName: action.newName,
            componentId: action.componentId,
          })
          .pipe(
            map(() =>
              ComponentsActions.editComponentNameSuccess({
                newName: action.newName,
                componentId: action.componentId,
              })
            )
          )
      ),
      catchError((e) => of(ComponentsActions.editComponentNameFailure()))
    )
  );

  acceptSynchronization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.acceptSynchronization),
      exhaustMap((action) =>
        this.apiFacadeService.components.synchronizeComponent(action.payload)
      ),
      map((response) =>
        ComponentsActions.acceptSynchronizationSuccess({ component: response })
      ),
      catchError((e) => of(ComponentsActions.acceptSynchronizationFailure()))
    )
  );

  acceptSynchronizationSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ComponentsActions.acceptSynchronizationSuccess),
      mergeMap((response) =>
        of(
          ComponentsActions.setSelectedComponentById({
            componentId: response.component.id,
          }),

          setSynchronizedMatterport({ stageId: response.component.stageId })
        )
      )
    )
  );
}
