import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProjectPermission } from '@simlab/data-access';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PRIVILEGES_FEATURE_KEY, State } from './privileges.reducer';

export const getPrivilegesState = createFeatureSelector<State>(
  PRIVILEGES_FEATURE_KEY
);

export const getPermission = (permission: ProjectPermission) =>
  createSelector(
    getPrivilegesState,
    (state: State) => state.permissions[permission]
  );

export const hasAdminRoleInProject = createSelector(
  getPrivilegesState,
  (state: State) => state.hasAdminRoleInProject
);

export const getLoaded = createSelector(
  getPrivilegesState,
  (state: State) => state.isLoading
);
