import { FocusableOption, FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';

let _uniqueIdCounter = 0;
@Component({
  selector: '[design-menu-item]',
  exportAs: 'uiMenuItem',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[id]': 'id',
  },
  standalone: true,
})
export class MenuItemComponent
  implements FocusableOption, OnDestroy, AfterViewInit
{
  readonly _focused: Subject<MenuItemComponent> =
    new Subject<MenuItemComponent>();
  readonly _hovered: Subject<MenuItemComponent> =
    new Subject<MenuItemComponent>();

  /** The unique ID of the option. */
  @Input() id = `sim-menu-item-${_uniqueIdCounter++}`;

  @HostBinding('class')
  elementClass = 'design-menu-item';

  @Input() disabled = false;
  @HostBinding('attr.disabled')
  get attrDisabled(): boolean | null {
    return this.disabled || null;
  }

  @HostBinding('attr.aria-disabled')
  get attrAriaDisabled(): string {
    return this.disabled.toString();
  }

  @HostListener('click', ['$event'])
  handleClick(event: MouseEvent): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('mouseenter', ['$event'])
  handleEnter(event: MouseEvent) {
    this._hovered.next(this);
  }

  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly focusMonitor: FocusMonitor,
  ) {}

  ngAfterViewInit(): void {
    if (this.focusMonitor) {
      // Start monitoring the element so it gets the appropriate focused classes. We want
      // to show the focus style for menu items only when the focus was not caused by a
      // mouse or touch interaction.
      this.focusMonitor.monitor(this.elementRef, false);
    }
  }

  ngOnDestroy(): void {
    if (this.focusMonitor) {
      this.focusMonitor.stopMonitoring(this.elementRef);
    }

    this._focused.complete();
    this._hovered.complete();
  }

  focus(origin?: FocusOrigin, options?: FocusOptions): void {
    if (this.focusMonitor && origin) {
      this.focusMonitor.focusVia(this._getHostElement(), origin, options);
    }

    this._focused.next(this);
  }

  getLabel(): string {
    return '';
  }

  private _getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }
}
