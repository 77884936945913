import { NoteFilters } from '@simlab/data-access';

export class FilterForm {
  info: boolean;
  pending: boolean;
  inProgress: boolean;
  resolved: boolean;
  unresolved: boolean;
  fromNoteCreation: string;
  toNoteCreation: string;
  fromNoteModified: string;
  toNoteModified: string;
  fromRange: string;
  toRange: string;
  onlyMyNotes: boolean;
  rootNoteAuthors: string[];
  stakeholders: string[];
  byNoteAuthor: boolean;
  selectedStakeholders: boolean;

  constructor(readonly _date: NoteFilters) {
    this.info = this._includesString(_date?.rootNoteTypes, 'Information');
    this.pending = this._includesString(_date?.rootNoteStatuses, 'Pending');
    this.inProgress = this._includesString(
      _date?.rootNoteStatuses,
      'InProgress'
    );
    this.resolved = this._includesString(_date?.rootNoteStatuses, 'Resolved');
    this.unresolved = this._includesString(
      _date?.rootNoteStatuses,
      'Unresolved'
    );
    this.fromNoteCreation = this._toDateString(_date?.rootNoteCreated?.from);
    this.toNoteCreation = this._toDateString(_date?.rootNoteCreated?.to);
    this.fromNoteModified = this._toDateString(_date?.rootNoteModified?.from);
    this.toNoteModified = this._toDateString(_date?.rootNoteModified?.to);
    this.fromRange = this._toDateString(_date?.rootNoteStageDate?.from);
    this.toRange = this._toDateString(_date?.rootNoteStageDate?.to);
    ('');
    this.onlyMyNotes = _date?.onlyMyNotes;
    this.rootNoteAuthors = _date?.rootNoteAuthors;
    this.stakeholders = _date?.stakeholders;
    this.byNoteAuthor = _date?.byNoteAuthor;
    this.selectedStakeholders = _date?.selectedStakeholders;
  }

  private _toDateString = (dateJSON: string) =>
    dateJSON ? dateJSON.slice(0, 10) : '';

  private _includesString = (strArr: string[] | null, element: string) =>
    strArr?.includes(element) ?? false;
}
