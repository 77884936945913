import { InjectionToken } from '@angular/core';
import {
  DesignRadioExpansionPanel,
  DesignRadioExpansionPanelBase,
} from '../components/radio-expansion-panel/radio-expansion-panel.component';

export const designRadioExpansionPanelToken =
  new InjectionToken<DesignRadioExpansionPanel>(
    'designRadioExpansionPanelToken'
  );

/**
 * Token used to provide a `DesignRadioExpansionPanel` to `DesignRadioExpansionPanelContent`.
 * Used to avoid circular imports between `DesignRadioExpansionPanel` and `DesignRadioExpansionPanelContent`.
 */
export const designRadioExpansionPanelBaseToken =
  new InjectionToken<DesignRadioExpansionPanelBase>(
    'designRadioExpansionPanelToken'
  );
