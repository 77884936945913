import { Injectable, inject } from '@angular/core';
import { ofType } from '@ngrx/effects';
import { ActionsSubject, Store, select } from '@ngrx/store';
import { map } from 'rxjs';
import {
  OrganizationApiActions,
  confirmTransferOrganizationSuccess,
  init,
  selectOrganization
} from './organizations.actions';
import * as OrganizationsSelectors from './organizations.selectors';

@Injectable()
export class OrganizationsFacade {
  private readonly _store = inject(Store);
  private readonly _action$ = inject(ActionsSubject);
  readonly selectedId$ = this._store.pipe(
    select(OrganizationsSelectors.getSelectedId)
  );
  readonly selectedFirstId$ = this._store.pipe(
    select(OrganizationsSelectors.getFirstOrganizationsEntities)
  );

  readonly initStoreLoaded$ = this._action$.pipe(
    ofType(OrganizationApiActions.loadOrganizationsSuccess)
  );

  readonly organizationUploadingImage$ = this._store.pipe(
    select(OrganizationsSelectors.getOrganizationUploadingImage)
  );

  readonly loaded$ = this._store.pipe(
    select(OrganizationsSelectors.getOrganizationsLoaded)
  );

  readonly getInfoAboutTransfer$ = this._store.pipe(
    select(OrganizationsSelectors.getInfoAboutTransfer)
  );

  readonly acceptTransferOrganizationSuccess$ = this._action$.pipe(
    ofType(OrganizationApiActions.acceptTransferOrganizationSuccess)
  );

  readonly allOrganizations$ = this._store.pipe(
    select(OrganizationsSelectors.getAllOrganizations)
  );

  readonly createOrganizationSuccess$ = this._action$.pipe(
    ofType(OrganizationApiActions.createOrganizationSuccess)
  );
  readonly acceptInvitationStatus$ = this._action$.pipe(
    ofType(
      OrganizationApiActions.acceptInvitationSuccess,
      OrganizationApiActions.acceptInvitationFailure
    )
  );

  readonly invitationSuccesOrFailuredStatus$ = this._action$.pipe(
    ofType(
      OrganizationApiActions.acceptInvitationSuccess,
      OrganizationApiActions.rejectInvitationSuccess,
      OrganizationApiActions.acceptInvitationFailure,
      OrganizationApiActions.rejectInvitationFailure
    ),
    map((e) => {
      if (
        e.type === '[Organizations/API] Accept Invitation Success' ||
        e.type === '[Organizations/API] Reject Invitation Success'
      ) {
        return { status: true, errorCode: undefined };
      }
      return { status: false, errorCode: e.error.error.errorCode };
    })
  );

  readonly organizationById$ = (id: string) =>
    this._store.pipe(select(OrganizationsSelectors.getOrganizationById(id)));

  initStore(): void {
    this._store.dispatch(init());
  }

  createOrganization(organizationName: string): void {
    this._store.dispatch(
      OrganizationApiActions.createOrganization({ name: organizationName })
    );
  }

  deleteOrganization(organizationId: string): void {
    this._store.dispatch(
      OrganizationApiActions.deleteOrganization({
        organizationId: organizationId
      })
    );
  }

  confirmTransferOrganizationSuccess() {
    this._store.dispatch(confirmTransferOrganizationSuccess());
  }
  leaveOrganization(organizationId: string): void {
    this._store.dispatch(
      OrganizationApiActions.leaveOrganization({
        organizationId: organizationId
      })
    );
  }

  selectOrganization(id: string): void {
    this._store.dispatch(selectOrganization({ id }));
  }

  rejectInvitation(id: string, organizationId: string): void {
    this._store.dispatch(
      OrganizationApiActions.rejectInvitation({ id, organizationId })
    );
  }

  acceptInvitation(invitationId: string, organizationId: string): void {
    this._store.dispatch(
      OrganizationApiActions.acceptInvitation({ invitationId, organizationId })
    );
  }

  editName(organizationId: string, name: string): void {
    this._store.dispatch(
      OrganizationApiActions.editOrganization({ organizationId, name })
    );
  }

  editDescription(organizationId: string, description: string): void {
    this._store.dispatch(
      OrganizationApiActions.editOrganizationDescription({
        organizationId,
        description
      })
    );
  }

  editThumbnail(organizationId: string, thumbnail: File): void {
    this._store.dispatch(
      OrganizationApiActions.editOrganizationThumbnail({
        organizationId,
        thumbnail
      })
    );
  }

  transferOrganization(organizationId: string, newOwnerId: string) {
    this._store.dispatch(
      OrganizationApiActions.transferOrganization({
        organizationId,
        newOwnerId
      })
    );
  }

  rejectTransferOrganization(organizationId: string) {
    this._store.dispatch(
      OrganizationApiActions.rejectTransferOrganization({ organizationId })
    );
  }

  cancelTransferOrganization(organizationId: string) {
    this._store.dispatch(
      OrganizationApiActions.cancelTransferOrganization({ organizationId })
    );
  }

  acceptTransferOrganization(organizationId: string) {
    this._store.dispatch(
      OrganizationApiActions.acceptTransferOrganization({ organizationId })
    );
  }
}
