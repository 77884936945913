import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  IAddAreaMeasurementToGroups,
  IAddMeasurementToStage,
  IErrorResponse,
  IUnassignAreaMeasurementFromRootNote,
  IUpdateAreaMeasurementColor,
  IUpdateAreaMeasurementData,
  IUpdateAreaMeasurementTitle,
  TAddAreaToRootNote,
  TAddMeasurementGroup,
  TAreaMeasurement,
  TAssignToRootNote
} from '@simlab/feature-measurement/models';

export const AreaActions = createActionGroup({
  source: 'AreaMeasurement/API',
  events: {
    'Load Stage Measurements': props<{ stageId: string }>(),
    'Load Stage Measurements Success': props<{
      measurements: TAreaMeasurement[];
    }>(),
    'Load Stage Measurements Failure': props<{ error: IErrorResponse }>(),

    Init: props<{ stageId: string }>(),
    'Load Measurement Success': props<{
      areaMeasurement: TAreaMeasurement[];
    }>(),
    'Load Measurement Failure': props<{ error: IErrorResponse }>(),
    'Clear Store': emptyProps(),
    'Update Measurement Data': props<IUpdateAreaMeasurementData>(),
    'Update Measurement Title': props<IUpdateAreaMeasurementTitle>(),
    'Update Measurement Color': props<IUpdateAreaMeasurementColor>(),
    'Add Measurement To Group': props<IAddAreaMeasurementToGroups>(),
    'Delete Measurement': props<{ id: string }>(),
    'Update Measurement Data Success': props<IUpdateAreaMeasurementData>(),
    'Update Measurement Title Success': props<IUpdateAreaMeasurementTitle>(),
    'Update Measurement Color Success': props<IUpdateAreaMeasurementColor>(),
    'Add Measurement To Group Success': props<IAddAreaMeasurementToGroups>(),
    'Attach Measurement To Note': props<TAssignToRootNote>(),
    'Attach Measurement To Note Success': props<TAssignToRootNote>(),
    'Delete Measurement Success': props<{ id: string }>(),
    'Update Measurement Data Failure': props<{ error: IErrorResponse }>(),
    'Update Measurement Title Failure': props<{ error: IErrorResponse }>(),
    'Update Measurement Color Failure': props<{ error: IErrorResponse }>(),
    'Add Measurement To Group Failure': props<{ error: IErrorResponse }>(),
    'Attach Measurement To Note Failure': props<{ error: IErrorResponse }>(),
    'Delete Measurement Failure': props<{ error: IErrorResponse }>(),
    'Unassign Measurement From Note':
      props<IUnassignAreaMeasurementFromRootNote>(),
    'Assigned Note Has Been Deleted':
      props<IUnassignAreaMeasurementFromRootNote>(),
    'Add Measurement': props<{ payload: IAddMeasurementToStage }>(),
    'Unassign Measurement From Note Success':
      props<IUnassignAreaMeasurementFromRootNote>(),
    'Add Measurement Success': props<TAreaMeasurement>(),
    'Unassign Measurement From Note Failure': props<{
      error: IErrorResponse;
    }>(),
    'Add Measurement Failure': props<{ error: IErrorResponse }>(),
    'Select Measurement': props<{ id: string | undefined }>(),
    'Select Measurement Success': props<{ id: string | undefined }>(),
    'Select Measurement Failure': props<{ error: IErrorResponse }>(),
    'Add Measurement To Root Note': props<{
      payload: TAddAreaToRootNote;
    }>(),
    'Add Measurement To Root Note Success': props<TAreaMeasurement>(),
    'Add Measurement To Root Note Failure': props<{ error: IErrorResponse }>(),
    'Hide All Measurements': props<{ hideAll: boolean }>(),
    'Show Measurements Segments': props<{ visibility: boolean }>(),
    'Toggle Measurement Segments Visibility': emptyProps(),

    'Add Measurement Group': props<{ body: TAddMeasurementGroup }>(),
    'Add Measurement Group Success': props<{ value: string }>(),
    'Add Measurement Group Failure': props<{ error: IErrorResponse }>()
  }
});
