<div class="header" [class.header-tap-space]="spaceAroundTab()">
  <div class="header--tabs">
    <div
      *ngFor="let tab of allTabs; let idx = index"
      [ngClass]="{ selected: selectedIndex === idx }"
      (click)="changeStep(idx)"
      class="tab"
    >
      <ng-template [ngIf]="tab.templateLabel()">
        <ng-template [cdkPortalOutlet]="tab.templateLabel()"></ng-template>
      </ng-template>
      <ng-template [ngIf]="!tab.templateLabel()">
        {{ tab.label() }}</ng-template
      >
      <ng-container *ngIf="tab.count() > 0">
        <div class="info-badge">
          <span>{{ tab.count() }}</span>
        </div>
      </ng-container>
    </div>
  </div>

  @if (headerEnd()) {
    <div class="wrapper-end-element">
      <ng-container *ngTemplateOutlet="headerEnd() ?? null"></ng-container>
    </div>
  }
</div>
<div class="divider"></div>
<div class="body-wrapper">
  <design-tab-body
    *ngFor="let tab of allTabs; let i = index"
    [content]="tab.content!"
    [ngClass]="{ active: selectedIndex === i }"
  >
  </design-tab-body>
</div>
<div class="footer">
  <ng-container *ngTemplateOutlet="footer() ?? null"></ng-container>
</div>
