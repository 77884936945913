import { inject, Injectable, signal } from '@angular/core';
import { PunchItemsListStore, RFIListStore } from '@simlab/procore/services';
import { EMPTY } from 'rxjs';

@Injectable()
export class AnnotationsFacade {
  private readonly _punchItemsListStore = inject(PunchItemsListStore, {
    optional: true
  });
  private readonly _rfiListStore = inject(RFIListStore, { optional: true });

  readonly punchItemActiveMarkers =
    this._punchItemsListStore?.activateMarkers ?? signal([]);
  readonly rfiItemActiveMarkers =
    this._rfiListStore?.activateMarkers ?? signal([]);

  readonly getSelectedPunchItem$ =
    this._punchItemsListStore?.getItemSelected$ ?? (() => EMPTY);
  readonly getSelectedRfiItem$ =
    this._rfiListStore?.getItemSelected$ ?? (() => EMPTY);

  setSelectedPunchItemById(annotationId: string) {
    if (this._punchItemsListStore === null) return;
    this._punchItemsListStore.setSelectedById(annotationId);
  }

  setSelectedRfiItemById(annotationId: string) {
    if (this._rfiListStore === null) return;
    this._rfiListStore.setSelectedById(annotationId);
  }

  deleteRfiItemFromStore(itemId: string) {
    this._rfiListStore?.deleteItemFromStore(itemId);
  }

  deletePunchItemFromStore(itemId: string) {
    this._punchItemsListStore?.deleteItemFromStore(itemId);
  }
}
