import { Injectable, inject } from '@angular/core';
import { UrlTree } from '@angular/router';
import {
  AuthenticatedResult,
  OidcSecurityService
} from 'angular-auth-oidc-client';
import { mergeMap, Observable } from 'rxjs';
import { CheckUserService } from '../services/check-user.service';

@Injectable()
export class CheckUserGuard {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly checkUserService = inject(CheckUserService);
  private readonly _checkUser$ = this.oidcSecurityService.isAuthenticated$.pipe(
    mergeMap((result: AuthenticatedResult) =>
      this.checkUserService.check$(result.isAuthenticated)
    )
  );

  canLoad(): Observable<boolean | UrlTree> {
    return this._checkUser$;
  }

  canActivate(): Observable<boolean | UrlTree> {
    return this._checkUser$;
  }

  canActivateChild(): Observable<boolean | UrlTree> {
    return this._checkUser$;
  }
}
