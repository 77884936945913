/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-class-suffix */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { ButtonBase } from './button-base.directive';

@Component({
  selector: 'button[design-icon-button], a[design-icon-button]',
  exportAs: 'designAnchor',
  standalone: true,
  imports: [CommonModule],

  template: '<ng-content></ng-content>',
  styleUrls: ['./icon-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-base-button design-icon-button',
    '[class.design-color--primary]': 'color === "primary"',
    '[class.design-color--secondary]': 'color === "secondary"',
    '[class.design-color--error]': 'color === "error"',
  },
  inputs: [
    'disabled',
    'disabled: designButtonDisabled',
    'selected: designButtonSelected',
    'loaded: designButtonLoaded',
    'color: designButtonColor',
  ],
})
export class DesignIconButton extends ButtonBase { }