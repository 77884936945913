import { InjectionToken } from '@angular/core';
import { Camera, Scene, WebGLRenderer } from 'three';

export const SIMLAB_RENDERER = new InjectionToken<SimlabRenderer>(
  'Simlab renderer'
);

export class SimlabRenderer {
  private readonly _renderer: WebGLRenderer;
  constructor(
    canvas: HTMLCanvasElement | undefined,
    antialias: boolean = true
  ) {
    this._renderer = new WebGLRenderer({
      canvas,
      antialias,
    });
  }
  public get renderer(): WebGLRenderer {
    return this._renderer;
  }
  setPixelRation(pixelRatio: number) {
    this._renderer.setPixelRatio(pixelRatio);
  }
  setSize(width: number, height: number) {
    this._renderer.setSize(width, height);
  }
  render(scene: Scene, camera: Camera) {
    this._renderer.render(scene, camera);
  }
  get domElement() {
    return this._renderer.domElement;
  }
}
