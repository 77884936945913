import { DateRange } from './date-range';

export interface ProjectsFilterBase {
  sortByFavourites?: boolean;
  owner?: boolean;
  archived?: boolean;
  organizationsId?: string[];
  projectAuthors?: string[];
  projectName?: string;
  modified?: DateRange;
  createdFrom?: string;
  createdTo?: string;
}
