import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { API_URL_NEW } from '@simlab/shared/common/tokens';

@Injectable({
  providedIn: 'root'
})
export class ApiBase {
  public readonly url = inject<string>(API_URL_NEW);
  public readonly httpClient = inject(HttpClient);
  get<R>(path: string, params?: {}) {
    return this.httpClient.get<R>(
      `${this.url}/construction-site-management-module/${path}`,
      params
    );
  }

  post<T, R>(path: string, body: T) {
    return this.httpClient.post<R>(
      `${this.url}/construction-site-management-module/${path}`,
      body
    );
  }

  put<T, R>(path: string, body: T) {
    return this.httpClient.put<R>(
      `${this.url}/construction-site-management-module/${path}`,
      body
    );
  }

  delete<R = void>(path: string) {
    return this.httpClient.delete<R>(
      `${this.url}/construction-site-management-module/${path}`
    );
  }
}
