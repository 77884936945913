import { createAction, props } from '@ngrx/store';
import {
  AcceptSynchronization,
  CreateComponent,
  StageComponent,
} from '@simlab/data-access';
import { ModelSweep } from '@simlab/matterport/api';

// export const init = createAction(
//   '[Components Page] Init',
//   props<{ stageId: string }>()
// );
export const init = createAction(
  '[Components Page] Init',
  props<{ projectId: string }>()
);

export const clearStore = createAction('[Components Page] Clear Store');

export const initSetComponent = createAction(
  '[Components Page] Init set component',
  props<{ stageId: string }>()
);

export const initSetComponentSuccess = createAction(
  '[Components Page] Init set component Success',
  props<{ component: StageComponent | undefined }>()
);

export const deleteLastKnowPosition = createAction(
  '[Components Delete] Last know position'
);

export const loadComponentsSuccess = createAction(
  '[Components/API] Load Components Success',
  props<{ components: StageComponent[] }>()
);

export const addComponentToStage = createAction(
  '[Components/API] Add Component to Stage',
  props<{ component: CreateComponent }>()
);

export const addComponentToStageSuccess = createAction(
  '[Components/API] Add Component to Stage Success'
);

export const addComponentFailure = createAction(
  '[Components/API] Add Component Failure'
);

export const addComponentSuccess = createAction(
  '[Components/API] Add Component Success',
  props<{ component: StageComponent }>()
);

export const changeComponent = createAction(
  '[Synchronization API] Change Component',
  props<{ component: StageComponent }>()
);

export const loadComponentsFailure = createAction(
  '[Components/API] Load Components Failure',
  props<{ error: any }>()
);

export const removeComponent = createAction(
  '[Components/API] Remove Component',
  props<{ componentId: string; stageId: string }>()
);

export const removeComponentSuccess = createAction(
  '[Components/API] Remove Component Success',
  props<{ componentId: string }>()
);

export const removeComponentFailure = createAction(
  '[Components/API] Remove Component Failure'
);

export const editComponentName = createAction(
  '[Components/API] Edit Component Name',
  props<{ componentId: string; newName: string }>()
);

export const editComponentNameSuccess = createAction(
  '[Components/API] Edit Component Name Success',
  props<{ componentId: string; newName: string }>()
);

export const editComponentNameFailure = createAction(
  '[Components/API] Edit Component Name Failure'
);

export const updateComponent = createAction(
  '[Components List] Update Component Name',
  props<{ newName: string; componentId: string }>()
);

export const acceptSynchronization = createAction(
  '[Components/API] Accept Synchronization',
  props<{ payload: AcceptSynchronization }>()
);

export const acceptSynchronizationSuccess = createAction(
  '[Components/API] Accept Synchronization Success',
  props<{ component: StageComponent }>()
);

export const acceptSynchronizationFailure = createAction(
  '[Components/API] Accept Synchronization Failure'
);

export const removeAllComponentFromState = createAction(
  '[Components List] Remove All Component'
);

export const removeComponentByIdFromState = createAction(
  '[Components List] Remove Component by id',
  props<{ componentId: string }>()
);

export const setSynchronizingAccepted = createAction(
  '[Components List] Set Synchronizing Accepted',
  props<{ stageId: string; componentId: string; status: boolean }>()
);
export const setSynchronizingAcceptedSuccess = createAction(
  '[Components List] Set Synchronizing Accepted Success',
  props<{ stageId: string; componentId: string; status: boolean }>()
);
export const setSynchronizingAcceptedFailure = createAction(
  '[Components List] Set Synchronizing Accepted Failure'
);

export const deleteSynchronization = createAction(
  '[Components List] Delete Synchronizing',
  props<{ stageId: string; componentId: string }>()
);
export const deleteSynchronizationSuccess = createAction(
  '[Components List] Delete Synchronizing Success',
  props<{ stageId: string; componentId: string }>()
);
export const deleteSynchronizationFailure = createAction(
  '[Components List] Delete Synchronizing Failure'
);

export const setSelectedComponentById = createAction(
  '[Components] Set components',
  props<{ componentId: string | undefined }>()
);

export const checkSweepOnComponent = createAction(
  '[Components] Set components Sweep',
  props<{ component: StageComponent }>()
);

export const checkSweepOnComponentSuccess = createAction(
  '[Components] Set components Sweep Success',
  props<{ componentId: string; sweeps: ModelSweep[] }>()
);

export const checkSweepOnComponentFailure = createAction(
  '[Components] Set components Sweep Failure'
);

export const sweepOnComponentUpdatedSuccess = createAction(
  '[Components] Set components Sweep Updated Success',
  props<{ componentId: string }>()
);
export const componentVisibilityChange = createAction(
  '[Components] Set component visibility',
  props<{ id: string; visible: boolean }>()
);
export const componentVisibilityChangeSuccess = createAction(
  '[Components] Set Component Visibility Success',
  props<{ id: string; visible: boolean }>()
);
export const componentVisibilityChangeFailure = createAction(
  '[Components] Set Component Visibility Failure'
);
