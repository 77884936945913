import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  IErrorResponse,
  IVector3,
  TDistance
} from '@simlab/feature-measurement/models';
import { DistanceApiActions } from './distance.actions';

export const DISTANCE_MEASUREMENT_FEATURE_KEY = 'distanceMeasurement';

export interface DistanceState extends EntityState<TDistance> {
  selectedId?: string | number; // which meserment record has been selected
  loaded: boolean; // has the distanceMeasurement list been loaded
  loading: boolean; // has the distanceMeasurement list been loaded
  hideAll: boolean;
  segmentsVisible: boolean;
  error?: IErrorResponse | null; // last known error (if any)
}

export interface AreaMeasurementPartialState {
  readonly [DISTANCE_MEASUREMENT_FEATURE_KEY]: DistanceState;
}

export const distanceAdapter: EntityAdapter<TDistance> =
  createEntityAdapter<TDistance>();

export const initialAreaMeasurementState: DistanceState =
  distanceAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
    hideAll: false,
    segmentsVisible: false
  });

const reducer = createReducer(
  initialAreaMeasurementState,
  on(DistanceApiActions.loadStageMeasurements, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(DistanceApiActions.clearStore, (state) => ({
    ...distanceAdapter.removeAll(state),
    loaded: false,
    loading: false,
    selectedId: undefined,
    segmentsVisible: false,
    hideAll: false,
    error: null
  })),
  on(DistanceApiActions.loadStageMeasurementsSuccess, (state, { result }) =>
    distanceAdapter.setAll(result, {
      ...state,
      loaded: true,
      loading: false
    })
  ),
  on(DistanceApiActions.loadStageMeasurementsFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(DistanceApiActions.addMeasurement, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),

  on(DistanceApiActions.addMeasurementSuccess, (state, measurement) => {
    return distanceAdapter.addOne(measurement, {
      ...state,
      loaded: true,
      loading: false
    });
  }),

  on(DistanceApiActions.addMeasurementFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(DistanceApiActions.addMeasurementToRootNote, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),

  on(
    DistanceApiActions.addMeasurementToRootNoteSuccess,
    (state, measurement) => {
      return distanceAdapter.addOne(measurement, {
        ...state,
        loaded: true,
        loading: false
      });
    }
  ),
  on(
    DistanceApiActions.addMeasurementToRootNoteFailure,
    (state, { error }) => ({
      ...state,
      loaded: false,
      loading: false,
      error
    })
  ),

  on(DistanceApiActions.assignMeasurementsToRootNote, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(
    DistanceApiActions.assignMeasurementsToRootNoteSuccess,
    (state, { id, rootNoteId }) => {
      const update: Update<TDistance> = {
        id,
        changes: {
          rootNoteId
        }
      };
      return distanceAdapter.updateOne(update, {
        ...state,
        loaded: true,
        loading: false
      });
    }
  ),
  on(
    DistanceApiActions.assignMeasurementsToRootNoteFailure,
    (state, { error }) => ({
      ...state,
      loaded: false,
      loading: false,
      error
    })
  ),
  on(DistanceApiActions.unAssignMeasurementsFromRootNote, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(
    DistanceApiActions.unAssignMeasurementsFromRootNoteSuccess,
    (state, { id }) => {
      const update: Update<TDistance> = {
        id,
        changes: {
          rootNoteId: undefined
        }
      };
      return distanceAdapter.updateOne(update, {
        ...state,
        loaded: true,
        loading: false
      });
    }
  ),
  on(
    DistanceApiActions.unAssignMeasurementsFromRootNoteFailure,
    (state, { error }) => ({
      ...state,
      loaded: false,
      loading: false,
      error
    })
  ),
  on(DistanceApiActions.deleteMeasurement, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(DistanceApiActions.deleteMeasurementSuccess, (state, { id }) => {
    return distanceAdapter.removeOne(id, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(DistanceApiActions.deleteMeasurementFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(DistanceApiActions.updateMeasurementColor, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(
    DistanceApiActions.updateMeasurementColorSuccess,
    (state, { id, color }) => {
      const updateData: Update<TDistance> = {
        id,
        changes: {
          color
        }
      };
      return distanceAdapter.updateOne(updateData, {
        ...state,
        loaded: true,
        loading: false
      });
    }
  ),
  on(DistanceApiActions.updateMeasurementColorFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(DistanceApiActions.updateMeasurementTitle, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(
    DistanceApiActions.updateMeasurementTitleSuccess,
    (state, { id, title }) => {
      const updateData: Update<TDistance> = {
        id,
        changes: {
          title
        }
      };
      return distanceAdapter.updateOne(updateData, {
        ...state,
        loaded: true,
        loading: false
      });
    }
  ),
  on(DistanceApiActions.updateMeasurementTitleFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(DistanceApiActions.updateMeasurementData, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(DistanceApiActions.hideAllMeasurements, (state, { hideAll }) => ({
    ...state,
    hideAll
  })),
  on(DistanceApiActions.showMeasurementsSegments, (state, { visibility }) => ({
    ...state,
    segmentsVisible: visibility
  })),
  on(DistanceApiActions.toggleMeasurementSegmentsVisibility, (state) => {
    return {
      ...state,
      segmentsVisible: !state.segmentsVisible
    };
  }),
  on(DistanceApiActions.updateMeasurementDataSuccess, (state, { id, data }) => {
    const updateData: Update<TDistance> = {
      id,
      changes: {
        data: data as IVector3[]
      }
    };
    return distanceAdapter.updateOne(updateData, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  // on(DistanceApiActions.assignedNoteHasBeenDeleted, (state, action) => {
  //   const updateArea: Update<TDistance> = {
  //     id: action.id,
  //     changes: {
  //       rootNoteId: undefined
  //     }
  //   };
  //   return distanceAdapter.updateOne(updateArea, { ...state });
  // }),
  on(DistanceApiActions.updateMeasurementDataFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(DistanceApiActions.selectMeasurement, (state, { id }) => {
    return {
      ...state,
      selectedId: id,
      loaded: false,
      loading: true,
      error: null
    };
  }),
  on(DistanceApiActions.selectMeasurementSuccess, (state, { id }) => ({
    ...state,
    selectedId: id,
    segmentsVisible: false,
    loaded: true,
    loading: false
  })),
  on(DistanceApiActions.selectMeasurementFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  }))
);

export function distanceMeasurementReducer(
  state: DistanceState | undefined,
  action: Action
) {
  return reducer(state, action);
}
