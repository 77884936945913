export function createFormDataImage(image: string, projectId: string) {
  const formData = new FormData();
  formData.append('mimeType', 'image/jpeg');
  formData.append('extension', 'jpeg');
  formData.append('contextId', projectId);
  formData.append(
    'base64Image',
    image
      .replace('data:image/jpeg;base64,', '')
      .replace('data:image/jpg;base64,', '')
  );
  return formData;
}

export enum ContextType {
  Project,
  Organization
}

export function prepareFormData(
  file: File,
  contextId: string,
  contextType: ContextType
): FormData {
  const formData = new FormData();
  const nameSplit = file.name.split('.');
  formData.append('File', file);
  formData.append('extension', nameSplit[nameSplit.length - 1]);
  formData.append('contextId', contextId);
  formData.append('contextType', ContextType[contextType]);
  return formData;
}
