import { Subject, filter, fromEvent, race, takeUntil, tap } from 'rxjs';
import { MathUtils, PerspectiveCamera, Spherical, Vector3 } from 'three';
import { CameraPose } from '../types/camera';
import { SimlabCameraControl } from './fp-camera-controls';

export class PanoramaControls implements SimlabCameraControl {
  private readonly _cameraPose = new Subject<CameraPose>();
  private readonly _destroy = new Subject<void>();
  private _onPointerDownMouseX = 0;
  private _onPointerDownMouseY = 0;
  private _onPointerDownLon = 0;
  private _onPointerDownLat = 0;
  private _lon = 0;
  private _lat = 0;
  private _enabled = true;
  private _phi = 0;
  private _theta = 0;
  private _enabledRotation = true;
  readonly cameraPoseChange$ = this._cameraPose.asObservable();
  needsUpdate = true;

  public get enabled() {
    return this._enabled;
  }
  public set enabled(value) {
    this._enabled = value;
  }
  private readonly _onMouseout = fromEvent<PointerEvent>(
    this._domElement,
    'mouseout'
  );
  private readonly _onPointerUp = fromEvent<PointerEvent>(
    this._domElement,
    'pointerup'
  ).pipe(filter((event) => event.isPrimary === true));

  constructor(
    private readonly _camera: PerspectiveCamera,
    private readonly _domElement: HTMLElement | Document
  ) {
    this._init();
  }
  update(delta: number): SimlabCameraControl {
    throw new Error('Method not implemented.');
  }
  set enableRotation(enabled: boolean) {
    this._enabledRotation = enabled;
  }
  get enableRotation(): boolean {
    return this._enabledRotation;
  }
  set enableMovement(enabled: boolean) {
    throw new Error('Method not implemented.');
  }
  get enableMovement(): boolean {
    throw new Error('Method not implemented.');
  }
  set enabledAll(enabled: boolean) {
    throw new Error('Method not implemented.');
  }
  get enabledAll(): boolean {
    throw new Error('Method not implemented.');
  }

  private _init() {
    this._onPointerDownObserver();
    this._onMouseWheelObserver();
    this._animate();
  }
  private _onPointerDownObserver() {
    fromEvent<PointerEvent>(this._domElement, 'pointerdown')
      .pipe(
        filter((event) => event.isPrimary === true && this._enabledRotation),
        tap((event) => {
          this._onPointerDownMouseX = event.clientX;
          this._onPointerDownMouseY = event.clientY;
          this._onPointerDownLon = this._lon;
          this._onPointerDownLat = this._lat;
          this._onPointerMoveObserver();
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  }
  private _onMouseWheelObserver() {
    fromEvent<WheelEvent>(this._domElement, 'wheel', { passive: true })
      .pipe(
        tap((event) => {
          const fov = this._camera.fov + event.deltaY * 0.05;
          this._camera.fov = MathUtils.clamp(fov, 10, 100);
          this._camera.updateProjectionMatrix();
          this.needsUpdate = true;
        }),
        takeUntil(this._destroy)
      )
      .subscribe();
  }

  private _onPointerMoveObserver() {
    fromEvent<PointerEvent>(this._domElement, 'pointermove')
      .pipe(
        filter((event) => event.isPrimary === true),
        tap((event) => {
          this._lon =
            (this._onPointerDownMouseX - event.clientX) * 0.05 +
            this._onPointerDownLon;
          this._lat =
            (event.clientY - this._onPointerDownMouseY) * 0.05 +
            this._onPointerDownLat;
          this._lat = Math.max(-85, Math.min(85, this._lat));
          this._phi = MathUtils.degToRad(90 - this._lat);
          this._theta = MathUtils.degToRad(this._lon);
          this.needsUpdate = true;
        }),
        takeUntil(race(this._destroy, this._onPointerUp, this._onMouseout))
      )
      .subscribe();
  }
  private _animate() {
    requestAnimationFrame(() => this._animate());
    this._update();
  }

  reset() {
    this._lat = 0;
    this._lon = 0;
  }
  recalculate() {
    const lookAtVector = new Vector3(0, 0, -1);
    lookAtVector.applyQuaternion(this._camera.quaternion);

    const sphere = new Spherical(500).setFromVector3(
      new Vector3(lookAtVector.z, lookAtVector.y, lookAtVector.x)
    );
    this._phi = sphere.phi;
    this._theta = sphere.theta;
    this._lat = -MathUtils.radToDeg(this._phi - 0.5 * Math.PI);
    this._lon = MathUtils.radToDeg(this._theta);
    this.needsUpdate = true;
  }

  private _update() {
    if (!this._enabled || (!this._lat && !this._lon) || !this.needsUpdate)
      return;
    const { x, y, z } = new Vector3().setFromSphericalCoords(
      500,
      this._phi,
      this._theta
    );
    this._camera.lookAt(z, y, x);
    this._cameraPose.next({
      position: this._camera.position.clone(),
      quaternion: this._camera.quaternion.clone(),
      rotation: this._camera.rotation.clone(),
    rotationAngles: new Vector3(),
      
    });
    this.needsUpdate = false;
  }

  destroy() {
    this._destroy.next();
  }
}
