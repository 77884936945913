//NOTE: as number[] - unsure if this should be a number[]
export class EnumFlagHelper {
  static enumToBitValues<T>(enumObject: T): number[] {
    return Object.keys(enumObject as number[])
      .map(Number)
      .filter(Boolean);
  }

  static bitValuesToSelected<T>(
    bitValues: number[],
    enumValue: T
  ): Record<number, boolean> {
    return bitValues
      .map((bit: number) => {
        return {
          [bit]: (bit & (enumValue as any)) === bit,
        };
      })
      .reduce(
        (
          previous: Record<number, boolean>,
          current: Record<number, boolean>
        ) => ({ ...previous, ...current }),
        {}
      );
  }

  static toggleBit(source: number, bitIndex: number): number {
    return (source |= 1 << bitIndex);
  }

  static clearBit(source: number, bitIndex: number): number {
    return (source &= ~(1 << bitIndex));
  }
}
