import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update,
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Note } from '@simlab/data-access';
import * as NotesActions from '../notes/notes.actions';
import * as ShowcaseNotesActions from './showcase-notes.actions';

export const SHOWCASENOTES_FEATURE_KEY = 'showcaseNotes';

export interface State extends EntityState<Note> {
  selectedNote?: Note;
  selectedId?: string | number; // which ShowcaseNotes record has been selected
  loaded: boolean; // has the ShowcaseNotes list been loaded
  error?: string | null; // last known error (if any)
  hideAll: boolean;
}

export interface ShowcaseNotesPartialState {
  readonly [SHOWCASENOTES_FEATURE_KEY]: State;
}

export const showcaseNotesAdapter: EntityAdapter<Note> =
  createEntityAdapter<Note>();

export const initialState: State = showcaseNotesAdapter.getInitialState({
  // set initial required properties
  loaded: false,
  hideAll: false,
});

export const showcaseNotesReducer = createReducer(
  initialState,
  on(ShowcaseNotesActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(ShowcaseNotesActions.clearStore, (state) => ({
    ...showcaseNotesAdapter.removeAll(state),
    selectedNote: undefined,
    selectedId: undefined,
    hideAll: false,
    loaded: false,
    error: null,
  })),
  on(
    ShowcaseNotesActions.loadShowcaseNotesSuccess,
    (state, { showcaseNotes }) =>
      showcaseNotesAdapter.setAll(showcaseNotes, { ...state, loaded: true })
  ),
  on(ShowcaseNotesActions.loadShowcaseNotesFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(ShowcaseNotesActions.hideAll, (state) => ({
    ...state,
  })),
  on(ShowcaseNotesActions.hideAllSuccess, (state, { hideAll }) => ({
    ...state,
    hideAll,
  })),
  on(ShowcaseNotesActions.hideAllFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(ShowcaseNotesActions.selectNoteSuccess, (state, { note }) => ({
    ...state,
    selectedNote: note,
  })),
  on(NotesActions.removeNote, (state, { response }) =>
    showcaseNotesAdapter.removeOne(response.rootNoteId, state)
  ),
  on(NotesActions.addSimpleNoteSuccess, (state, { note }) =>
    showcaseNotesAdapter.addOne(note, state)
  ),
  on(NotesActions.deleteNoteSuccess, (state, { noteId }) =>
    showcaseNotesAdapter.removeOne(noteId, state)
  ),
  on(NotesActions.updateNote, (state, { note }) => {
    const updatedNote: Update<Note> = {
      changes: note,
      id: note.id,
    };
    return showcaseNotesAdapter.updateOne(updatedNote, state);
  })
);

export function reducer(state: State | undefined, action: Action) {
  return showcaseNotesReducer(state, action);
}
