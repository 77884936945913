import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { TeamTemplateCommand } from '../../models/command/team-template-command';
import { PaginationPage } from '../../models/organization-project';
import { OrganizationTeamTemplate } from '../../models/organization-team-template';
import { ProjectsSort } from '../../models/projects-sort';
import { TeamTemplateQuery } from '../../models/query/team-template-query';
import { SimpleTeamTemplate } from '../../models/simple-team-template';
import { TeamTemplate } from '../../models/team-template';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class TeamTemplateService
  implements TeamTemplateCommand, TeamTemplateQuery
{
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);
  //QUERIES
  getTeamTemplate$(payload: {
    teamTemplateId: string;
  }): Observable<TeamTemplate> {
    return this.httpClient.get<TeamTemplate>(
      `${this.url}/organizations-module/get-team-template?teamTemplateId=${payload.teamTemplateId}`
    );
  }

  getSimpleOrganizationTeamTemplates$(payload: {
    organizationId: string;
  }): Observable<SimpleTeamTemplate[]> {
    return this.httpClient.get<SimpleTeamTemplate[]>(
      `${this.url}/organizations-module/get-simple-organization-team-templates?organizationId=${payload.organizationId}`
    );
  }
  getOrganizationTeamTemplates$(payload: {
    organizationId: string;
    teamTemplateName?: string;
    sortBy?: ProjectsSort;
  }): Observable<PaginationPage<OrganizationTeamTemplate>> {
    const queryParameters: string[] = [];
    if (payload) {
      queryParameters.push(...[`organizationId=${payload.organizationId}`]);
      queryParameters.push(...this.sortBy(payload.sortBy));
      if (payload.teamTemplateName) {
        queryParameters.push(
          ...[`TeamTemplateName=${payload.teamTemplateName}`]
        );
      }
    }
    return this.httpClient.get<PaginationPage<OrganizationTeamTemplate>>(
      `${
        this.url
      }/organizations-module/get-organization-team-templates?${queryParameters.join(
        '&'
      )}`
    );
  }

  //COMMANDS
  editTeamTemplatePermission$(payload: {
    teamTemplateId: string;
    permissions: number;
  }): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/organizations-module/edit-team-template-permissions`,
      payload
    );
  }

  saveTeamAsTemplate$(payload: {
    teamId: string;
    name: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/save-team-as-template`,
      payload
    );
  }

  createTeamTemplate$(payload: {
    organizationId: string;
    name: string;
  }): Observable<string> {
    return this.httpClient.post<string>(
      `${this.url}/organizations-module/create-team-template`,
      payload
    );
  }

  deleteTeamTemplate$(payload: { teamTemplateId: string }): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/organizations-module/delete-team-template?teamTemplateId=${payload.teamTemplateId}`
    );
  }
  editTeamTemplate$(payload: {
    teamTemplateId: string;
    teamTemplateName: string;
    description: string;
    permissions: number;
  }): Observable<any> {
    throw new Error('Method not implemented.');
  }

  changeTeamTemplateName$(payload: {
    teamTemplateId: string;
    name: string;
  }): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/organizations-module/change-team-template-name`,
      payload
    );
  }
  changeTeamTemplateDescription$(payload: {
    teamTemplateId: string;
    description: any;
  }): Observable<void> {
    return this.httpClient.put<void>(
      `${this.url}/organizations-module/change-team-template-description`,
      payload
    );
  }

  private sortBy(sortBy?: ProjectsSort): string[] {
    const buffer: string[] = [];

    if (sortBy?.type) {
      buffer.push(`SortColumn=${sortBy.type}`);
    }

    if (sortBy?.ascending) {
      buffer.push(`IsAscending=${sortBy.ascending}`);
    }

    return buffer;
  }
}
