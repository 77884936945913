import { Injectable, OnDestroy, inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateRange, IChipItem, NoteFilters } from '@simlab/data-access';
import {
  NotesFacade,
  ShowcaseNotesFacade,
  StagesFacade,
  UserPreferenceFacade
} from '@simlab/data-store';
import { BehaviorSubject, Observable, Subject, iif, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  shareReplay,
  startWith,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { FiltersChipsBase } from '../models/filter-chip-base';
import { RouterStoreParams } from '../public-api';

/**
 * @desc../models/filter-chip-base
 * Implements interface {@link Filters}
 *  which is used in store and for project request
 */
export class StageFilterBase implements NoteFilters {
  constructor(
    public rootNoteTypes: string[] = [],
    public rootNoteStatuses: string[] = [],
    public rootNoteCreated: DateRange = { from: '', to: '' },
    public rootNoteModified: DateRange = { from: '', to: '' },
    public rootNoteStageDate: DateRange = { from: '', to: '' },
    public onlyMyNotes = false,
    public rootNoteAuthors: string[] = [],
    public name = '',
    public stakeholders: string[] = [],
    public byNoteAuthor = false,
    public selectedStakeholders = false
  ) {}
}

const FILTER_CHANGE_DEBOUNCE = 300;
@Injectable()
export class StageFiltersService implements OnDestroy {
  private readonly notesFacade = inject(NotesFacade);
  private readonly stagesFacade = inject(StagesFacade);
  private readonly showcaseNotesFacade = inject(ShowcaseNotesFacade);
  private readonly userPreferenceFacade = inject(UserPreferenceFacade);

  private readonly _initialFormValues: { [name: string]: unknown } = {};
  private readonly _filterForm: UntypedFormGroup = this._initFilterForm(
    new StageFilterBase()
  );

  private readonly _filterByName$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  private readonly _selectedPage$: BehaviorSubject<{
    pageSize?: number;
    pageNumber: number;
  }> = new BehaviorSubject<{ pageSize?: number; pageNumber: number }>({
    pageNumber: 1
  });
  private readonly _destroy$: Subject<void> = new Subject<void>();
  private _authorsEmail: string[] = [];
  private _formatDate: 'MM/dd/yyyy' | 'dd/MM/yyyy' = 'dd/MM/yyyy';
  get authorsEmail(): string[] {
    return this._authorsEmail;
  }
  set authorsEmail(value: string[]) {
    this._authorsEmail = value;
  }

  private _stakeholderEmail: string[] = [];
  get stakeholderEmail(): string[] {
    return this._stakeholderEmail;
  }
  set stakeholderEmail(value: string[]) {
    this._stakeholderEmail = value;
  }

  constructor() {
    this._selectedStageObserver$();
    this._filterChangesObserver$();
    this._formatDateUpdated$();
  }

  ngOnDestroy(): void {
    this._filterByName$.next('');
    this._filterByName$.complete();
    this._destroy$.next();
    this._destroy$.complete();
  }
  /**
   * @description
   * Reset filters value to initial state
   * @returns
   */
  public reset(): void {
    this._filterForm.reset();
    this._filterForm.patchValue(this._initialFormValues);
  }

  /**
   * @description
   * Initialize filter form group and create controls
   * Then return reference
   * @returns
   */
  public _initFilterForm(model: StageFilterBase): UntypedFormGroup {
    const form = new UntypedFormGroup({});
    (Object.keys(model) as Array<keyof NoteFilters>).forEach(
      (prop: keyof NoteFilters) => {
        this._initialFormValues[prop] = model[prop];
        form.addControl(prop, new UntypedFormControl(model[prop]));
      }
    );
    return form;
  }

  /**
   * @description
   * Return reference to filter form
   * @returns
   */
  public get filterForm(): UntypedFormGroup {
    return this._filterForm;
  }

  /**
   * @description
   * Emits any filter value change
   * @returns
   */
  public get filterChanges$(): Observable<StageFilterBase> {
    return this._filterForm.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(FILTER_CHANGE_DEBOUNCE)
    );
  }

  private _formatDateUpdated$(): void {
    this.userPreferenceFacade.getDateFormat$
      .pipe(takeUntil(this._destroy$))
      .subscribe((formaDate) => {
        if (formaDate) {
          this._formatDate = formaDate;
        }
      });
  }

  /**
   * @description
   * Observe on filter value chagnge
   *
   */
  private _filterChangesObserver$(): void {
    this._filterForm.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(FILTER_CHANGE_DEBOUNCE),
        takeUntil(this._destroy$)
      )
      .subscribe(() => this.applyFilters());
  }

  /**
   * @description
   * Return filter form value
   * @returns
   */
  public get selectedValues(): StageFilterBase {
    return this._filterForm.value;
  }

  /**
   * Set next value in _selectedPage$
   */
  public set selectedPage(pageNumber: number) {
    const selectedPage = this._selectedPage$.getValue();
    this._selectedPage$.next({ ...selectedPage, ...{ pageNumber } });
  }

  pageChange(page: number, name?: string | number) {
    if ((this._filterForm.value as NoteFilters).rootNoteStageDate.from) {
      this.applyFilters(page);
    } else {
      this.stagesFacade.selectedId$
        .pipe(take(1))
        .subscribe((stageId: string) => {
          this._getStoreData([stageId], page, name?.toString());
        });
    }
  }

  searchByName(name: string | number) {
    this._filterByName$.next(name.toString());
    if ((this._filterForm.value as NoteFilters).rootNoteStageDate.from) {
      this.applyFilters();
    } else {
      this.stagesFacade.selectedId$
        .pipe(take(1))
        .subscribe((stageId: string) => {
          if (stageId) this._getStoreData([stageId], 1, name.toString());
        });
    }
  }

  /**
   * Observe when slected stage is changed
   * if it is not selected any other range of stages
   * then get notes from api
   */
  private _selectedStageObserver$() {
    const observerBody$ = (nodeId?: string) =>
      this.stagesFacade.selectedId$.pipe(
        filter((selectedStage: string | undefined) => !!selectedStage), //NOTE: (olek) if it is selected stage
        filter(
          () => !(this._filterForm.value as NoteFilters).rootNoteStageDate.from //NOTE: (olek) if it is !!NOT!! selected range of stages
        ),
        map((selectedStage: string | undefined) => selectedStage as string),
        tap(() => (this.selectedPage = 1)), // reset page position
        debounceTime(FILTER_CHANGE_DEBOUNCE), //NOTE: (olek) prevent from fast changes
        takeUntil(this._destroy$),
        tap((selectedStage: string) => {
          this._getStoreData(
            [selectedStage],
            this._selectedPage$.getValue().pageNumber,
            undefined,
            nodeId
          );
        })
      );

    this.stagesFacade.getRouteNestedParams$
      .pipe(
        take(1),
        mergeMap((routerParams: RouterStoreParams) => {
          return observerBody$(routerParams.queryParams['noteId']);
        })
      )
      .subscribe();

    //TODO: do we need this? :D
    // observerBody$().pipe(skip(1)).subscribe();
  }

  /**
   * Gets ids of all available stages and send request form new filtered data
   */
  public applyFilters(page?: number) {
    this.stagesFacade.allStagesIds$
      .pipe(
        mergeMap((allStages: string[] | number[]) =>
          iif(
            () =>
              (this._filterForm.value as NoteFilters).rootNoteStageDate.from
                .length > 0,
            of(allStages),
            this.stagesFacade.selectedId$
          )
        ),
        take(1)
      )
      .subscribe((allStagesIds: string | string[] | number[]) => {
        const nextPage = page
          ? page
          : this._selectedPage$.getValue().pageNumber;
        this._selectedPage$.next({
          ...this._selectedPage$.getValue(),
          pageNumber: 1
        });
        this._getStoreData(
          typeof allStagesIds === 'string'
            ? [allStagesIds]
            : (allStagesIds as string[]),
          nextPage,
          this._filterByName$.getValue()
        );
      });
  }

  private updateDate(selectedFilters: StageFilterBase) {
    if (this._formatDate === 'dd/MM/yyyy') {
      return {
        ...selectedFilters,
        rootNoteCreated: this.updatedDates(selectedFilters.rootNoteCreated),
        rootNoteModified: this.updatedDates(selectedFilters.rootNoteModified),
        rootNoteStageDate: this.updatedDates(selectedFilters.rootNoteStageDate)
      } as StageFilterBase;
    }
    return { ...selectedFilters } as StageFilterBase;
  }

  private updatedDates(value: DateRange) {
    return {
      to: this.changeDatePosition(value.to),
      from: this.changeDatePosition(value.from)
    };
  }

  private changeDatePosition(value: string) {
    if (value === '') return '';

    const spl = value.split('/');
    return `${spl[1] + '/' + spl[0] + '/' + spl[2]}`;
  }

  /**
   * Merge selected filters + selectedPage + selectedStage
   * and get data from api
   */
  private _getStoreData(
    forIds: string[] | null,
    page: number,
    name = '',
    noteId?: string
  ) {
    const selectedFilters: StageFilterBase = this.updateDate({
      ...this._filterForm.value,
      name
    });

    const selectedPage: { pageSize?: number; pageNumber: number } = {
      pageNumber: page
    };
    const notesPayload: {
      stageId: string[] | null;
      page: { pageSize?: number; pageNumber: number };
      rootNotesFilter?: NoteFilters;
    } = {
      stageId: forIds,
      rootNotesFilter: selectedFilters as NoteFilters,
      page: selectedPage
    };

    //TODO: select note from query params
    this.notesFacade.initStore(notesPayload, noteId);
    this.showcaseNotesFacade.initStore({
      stageId: notesPayload.stageId,
      rootNotesFilter: notesPayload.rootNotesFilter
    });
  }

  get filterAsChips$(): Observable<any> {
    return this.filterChanges$.pipe(
      map((filters: StageFilterBase) => {
        return {
          test: filters.rootNoteTypes
        };
      })
    );
  }

  get filerChipItems$(): Observable<IChipItem[]> {
    return this.filterChanges$.pipe(
      startWith(this.filterForm.value as StageFilterBase),
      debounceTime(300),
      map((form: StageFilterBase) => {
        return new FiltersChipsBase(
          form,
          this.authorsEmail,
          this.stakeholderEmail
        );
      }),
      map((chipBase: FiltersChipsBase) => {
        return chipBase.mapToChips();
      }),
      shareReplay(1)
    );
  }
}
