import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { AbstractConstructor, Constructor } from './constructor';

/** @docs-private */
export interface CanLoad {
  loaded: boolean;
}

type CanLoadCtor = Constructor<CanLoad> & AbstractConstructor<CanLoad>;

/** Mixin to augment a directive with a `loaded` property. */
export function mixinLoaded<T extends AbstractConstructor<{}>>(
  base: T
): CanLoadCtor & T;
export function mixinLoaded<T extends Constructor<{}>>(
  base: T
): CanLoadCtor & T {
  return class extends base {
    private _loaded = false;

    get loaded(): boolean {
      return this._loaded;
    }
    set loaded(value: BooleanInput) {
      this._loaded = coerceBooleanProperty(value);
    }

    constructor(...args: any[]) {
      super(...args);
    }
  };
}
