import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { UiCheckboxModule } from '../ui-checkbox/ui-checkbox.module';
import { UiChipsModule } from '../ui-chips/ui-chips.module';
import { UiIconModule } from '../ui-icon/ui-icon.module';
import { OptionComponent } from './components/option/option.component';
import {
  MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
  SelectComponent
} from './components/select/select.component';

@NgModule({
  declarations: [SelectComponent, OptionComponent],
  imports: [
    CommonModule,
    OverlayModule,
    ScrollingModule,
    FormsModule,
    ReactiveFormsModule,
    UiCheckboxModule,
    UiIconModule,
    UiChipsModule,
    DesignIcon,
    DesignIconButton
  ],
  exports: [OverlayModule, ScrollingModule, SelectComponent, OptionComponent],
  providers: [MAT_SELECT_SCROLL_STRATEGY_PROVIDER]
})
export class UiSelectModule {}
