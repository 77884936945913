import { OrganizationLevel } from './organization-level';
import { SasToken } from './sas-token';

export interface OrganizationWithPermissions {
  organizationId: string;
  name: string;
  description: string;
  ownerId: string;
  ownerName: string;
  ownerEmail: string;
  creationDate: string;
  permissions: number;
  roleId: OrganizationLevel;
  thumbnailUrl: SasToken;
}
