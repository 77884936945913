export enum RemoveCardStatus {
  Undefined = 0,
  Success = 1,
  Error = 2,
  CriticalError = 3,
}

export interface RemoveCardStatusResponse {
  status: RemoveCardStatus;
}
