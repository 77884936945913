import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ThemeType } from '../models/theme-type';
import { THEME_OPTIONS } from '../tokens/options.token';

@Injectable()
export class ThemeService {
  private readonly _renderer: Renderer2;
  private readonly _themeKey = 'Stages.Theme';
  private readonly _themeChangeBSSource = new BehaviorSubject<ThemeType | null>(
    (localStorage.getItem(this._themeKey) as ThemeType) || 'light'
  );
  private readonly _themeChangeOSource$ = this._themeChangeBSSource
    .asObservable()
    .pipe(
      filter((value: ThemeType | null) => value != null),
      map((value: ThemeType | null) => value as ThemeType)
    );

  get update$(): Observable<ThemeType> {
    return this._themeChangeOSource$;
  }

  get current(): ThemeType {
    return this._themeChangeBSSource.value as ThemeType;
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(THEME_OPTIONS) private readonly themeType: ThemeType,
    private readonly rendererFactory: RendererFactory2
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);

    if (this.current === null) {
      this.save(themeType);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public init(): void {
    this._renderer.setAttribute(this.document.body, 'class', this.current);
  }

  public set(theme: ThemeType): void {
    if (this.current === theme) {
      return;
    }

    this._renderer.setAttribute(this.document.body, 'class', theme);
    this.save(theme);
  }

  private save(theme: ThemeType): void {
    try {
      localStorage.setItem(this._themeKey, theme);
      this._themeChangeBSSource.next(theme);
    } catch (error) {
      console.error(error);
    }
  }
}
