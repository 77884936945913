import { UiMapComponent } from './components/ui-map.component';
import { API_GOOGLE, API_GOOGLE_URL, loadGoogleScript } from './tokens';
import { UiMapModule } from './ui-map.module';

export {
  UiMapModule,
  API_GOOGLE,
  API_GOOGLE_URL,
  loadGoogleScript,
  UiMapComponent,
};
