import { OutputEmitterRef } from '@angular/core';
import { ICreationInfo, TTriangle, TVerticle } from './common-models';

export type TAreaMeasurementData = {
  vertices: TVerticle[];
  triangles: TTriangle[];
  surfaceSize: number;
};

export type TAreaMeasurementBase = {
  id: string;
  title: string;
  color: string;
  data: TAreaMeasurementData;
};
export type TAreaMeasurementSimple = {
  groupIds: string[];
  rootNoteId: string | undefined;
  rootNoteCreatorId: string | undefined;
} & ICreationInfo &
  Omit<TAreaMeasurementBase, 'data'>;
export type TAreaMeasurement = {
  groupIds: string[];
  measurements: any[];
  rootNoteId: string | undefined;
} & ICreationInfo &
  TAreaMeasurementSimple &
  TAreaMeasurementBase;
export type TAreaMeasurementGroupBase = {
  id: string;
  name: string;
};
export type TAreaMeasurementGroup = {
  items: TAreaMeasurement[];
} & ICreationInfo &
  TAreaMeasurementGroupBase;

export interface IAddAreaMeasurementToRootNote
  extends Omit<TAreaMeasurementBase, 'id'> {
  rootNoteId: string;
}

export interface IAddAreaMeasurementToStage
  extends Omit<TAreaMeasurementBase, 'id'> {
  stageId: string;
}

export interface IAddAreaMeasurementToGroups {
  id: string;
  groupsIds: string[];
}
export interface IAddAreaMeasurementsToGroup {
  groupId: string;
  areaMeasurementsIds: string[];
}

export interface IAssignAreaMeasurementToRootNote {
  id: string;
  rootNoteId?: string;
  name?: string;
}
export interface IUnassignAreaMeasurementFromRootNote {
  id: string;
}

export type IUpdateAreaMeasurementTitle = Pick<
  TAreaMeasurementBase,
  'title' | 'id'
>;
export type IUpdateAreaMeasurementColor = Pick<
  TAreaMeasurementBase,
  'color' | 'id'
>;
export type IUpdateAreaMeasurementData = Pick<
  TAreaMeasurementBase,
  'data' | 'id'
>;

export interface TRemoveAreaMeasurementFromGroup {
  id: string;
  groupId: string;
}
export interface IRemoveAreaMeasurementElement {
  id: string;
}

export type TAddAreaMeasurementTo = 'note' | 'stage';
export interface _IAreaMeasurementAction {
  readonly deleteAreaMeasurement: IRemoveAreaMeasurementElement;
  readonly assignToNote: IAssignAreaMeasurementToRootNote;
  readonly unassignFromNote: IUnassignAreaMeasurementFromRootNote;
  readonly addMeasurementToGroups: IAddAreaMeasurementToGroups;
  readonly changeColor: IUpdateAreaMeasurementColor;
  readonly changeTitle: IUpdateAreaMeasurementTitle;
  readonly edit: IUpdateAreaMeasurementData;
  readonly add: TAddAreaMeasurementTo | void;
  readonly openRelatedNote: string;
  readonly goToSelected: void;
}
export interface _IAreaMeasurementsGroupAction {
  readonly changeName: void;
  readonly createGroup: void;
  readonly deleteAreaMeasurementGroup: void;
  readonly addMeasurementsToGroup: void;
  readonly removeMeasurementFromGroup: {
    id: string;
    groupId: string;
  };
  readonly goToMeasurementList: string;
}

export type IAreaMeasurementAction<T> = {
  [prop in keyof T]: (payload: T[prop]) => void;
};
export type IAreaMeasurementComponent<T> = {
  [prop in keyof T]: OutputEmitterRef<T[prop]>;
};
