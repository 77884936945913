import { Injectable } from '@angular/core';
declare global {
  interface Window {
    mozIndexedDB?: IDBFactory;
    webkitIndexedDB?: IDBFactory;
    msIndexedDB?: IDBFactory;
  }
}
@Injectable()
export class StorageService {
  private _indexedDB =
    window.indexedDB ||
    window.mozIndexedDB ||
    window.webkitIndexedDB ||
    window.msIndexedDB;
  readonly name = 'simlab-facility-management';
  readonly version = 1;

  private _database!: IDBDatabase;

  private _databaseExist() {
    if (this._indexedDB === undefined) {
      console.warn('Storage: IndexedDB not available.');
      throw Error('Storage: IndexedDB not available.');
    }
  }
  init = (): Promise<boolean> => {
    this._databaseExist();
    const request: IDBOpenDBRequest = this._indexedDB.open(
      this.name,
      this.version
    );
    return new Promise((resolve, reject) => {
      request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = request.result;

        if (db.objectStoreNames.contains('states') === false) {
          db.createObjectStore('states');
        }
      };

      request.onsuccess = (event) => {
        this._database = request.result;
        resolve(true);
      };

      request.onerror = (event) => {
        console.error('IndexedDB', event);
        reject(false);
      };
    });
  };

  get = <T>(): Promise<T> => {
    this._databaseExist();

    return new Promise((resolve, reject) => {
      if (this._database === undefined) reject();
      const transaction = this._database.transaction(['states'], 'readwrite');
      const objectStore = transaction.objectStore('states');
      const request = objectStore.get(0);
      request.onsuccess = (event) => {
        resolve(request.result);
      };
    });
  };

  set = (data: any): Promise<void> => {
    this._databaseExist();

    return new Promise((resolve, reject) => {
      const start = performance.now();
      if (this._database === undefined) {
        console.warn('Database not initialized');
        reject();
      }
      const transaction = this._database.transaction(['states'], 'readwrite');
      const objectStore = transaction.objectStore('states');
      const request = objectStore.put(data, 0);
      request.onsuccess = () => {
        console.log(
          // eslint-disable-next-line no-useless-escape
          '[' + /\d\d\:\d\d\:\d\d/.exec(new Date().toString())?.[0] + ']',
          'Saved state to IndexedDB. ' +
            (performance.now() - start).toFixed(2) +
            'ms'
        );
        resolve();
      };
    });
  };

  clear = (): Promise<void> => {
    this._databaseExist();
    return new Promise((resolve, reject) => {
      if (this._database === undefined) reject();
      const transaction = this._database.transaction(['states'], 'readwrite');
      const objectStore = transaction.objectStore('states');
      const request = objectStore.clear();
      request.onsuccess = () => {
        console.log(
          // eslint-disable-next-line no-useless-escape
          '[' + /\d\d\:\d\d\:\d\d/.exec(new Date().toString())?.[0] + ']',
          'Cleared IndexedDB.'
        );
        resolve();
      };
    });
  };
}
