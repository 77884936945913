import { Observable } from 'rxjs';
import {
  TAddAreaToRootNote,
  TAddAreaToStage,
  TUpdateArea
} from '../area-date.type';

export interface AreaCommand {
  addMeasurement: (body: TAddAreaToStage) => Observable<string>;
  addMeasurementToRootNote: (body: TAddAreaToRootNote) => Observable<string>;
  updateMeasurementData: (body: TUpdateArea) => Observable<void>;
}
