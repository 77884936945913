export const BUTTON_HOST_ATTRIBUTES = [
  'ui-button',
  'ui-raised-button',
  'ui-stroked-button',
  'ui-flat-button',
  'ui-icon-button',
  'ui-fab-button',
  'ui-mini-fab-button',
];


export type BadgeSize = 'small' | 'medium' | 'large';