import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export class DesignInputValidators {
  static WITHOUT_SPECIAL_CHARACTERS = new RegExp(
    /[\/\\\?%\*\:\|\"<>&\u0080-\uFFFF]/,
  );

  static WITHOUT_DOT_START_END_TEXT = new RegExp(/^\..*|.*\.$/);
  static WITHOUT_SPACE_START_END_TEXT = new RegExp(/^\s.*|.*\s$/);

  /**
   * Validates date based on pattern
   * @param control
   * @returns
   */
  static checkPattern(
    pattern: RegExp,
    validatorField: { [key: string]: boolean },
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === '' && !control.hasValidator(Validators.required))
        return null;
      const result = pattern.test(control.value);
      return result ? validatorField : null;
    };
  }
}
