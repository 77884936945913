import { StageComponent } from '@simlab/data-access';
import { TransformConverter } from '@simlab/transform';
import { Vector3 } from 'three';

export abstract class ComponentHelper {
  static findClosestId(dst: Vector3, comps: StageComponent[]) {
    let closestComponent!: StageComponent;
    let lowestDistance: number | undefined;

    comps.forEach((component, index) => {
      if (component.sweeps?.length) {
        const transformConverter = new TransformConverter(component.offset);
        component.sweeps.forEach((sweep) => {
          let sweepPosition = sweep.position;

          sweepPosition = transformConverter.to3dPosition(
            new Vector3(sweepPosition.x, sweepPosition.y, sweepPosition.z)
          );

          const distance = dst.distanceTo(
            new Vector3(sweepPosition.x, sweepPosition.y, sweepPosition.z)
          );
          if (!lowestDistance || distance < lowestDistance) {
            closestComponent = comps[index];
            lowestDistance = distance;
          }
        });
      }
    });

    return closestComponent;
  }
}
