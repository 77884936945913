import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DesignFlatButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { ConfirmationModalRef } from '../../models/confirmation-modal-ref';
import { MODAL_DATA } from '../../tokens/modal-data.token';

@Component({
  selector: 'ui-info-modal-dialog',
  templateUrl: './info-modal-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    DesignCommonModule,
    DesignDialogWrapperModule,
    DesignFlatButton,
  ],
})
export class InfoModalDialogComponent {
  constructor(
    @Inject(MODAL_DATA) readonly data: { mainText: string; content: string },
    private readonly modalRef: ConfirmationModalRef<unknown>,
  ) {}
  confirm(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }
}
