import { DataSource } from '@angular/cdk/collections';
import {
  CdkColumnDef,
  CdkHeaderRowDef,
  CdkNoDataRow,
  CdkRowDef,
  CdkTable
} from '@angular/cdk/table';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  Input,
  QueryList,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'ui-table',
  templateUrl: './ui-table.component.html',
  styleUrls: ['./ui-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class UiTableComponent<T> implements AfterContentInit {
  @Input() dataSource!: DataSource<T>;
  @Input() displayedColumns!: string[];
  @Input() loadingData = false;

  @ContentChildren(CdkHeaderRowDef) headerRowDefs!: QueryList<CdkHeaderRowDef>;
  @ContentChildren(CdkRowDef) rowDefs!: QueryList<CdkRowDef<T>>;
  @ContentChildren(CdkColumnDef) columnDefs!: QueryList<CdkColumnDef>;
  @ContentChild(CdkNoDataRow) noDataRow!: CdkNoDataRow;
  @ViewChild(CdkTable, { static: true }) table!: CdkTable<T>;

  ngAfterContentInit(): void {
    this.columnDefs.forEach((columnDef) => this.table.addColumnDef(columnDef));
    this.rowDefs.forEach((rowDef) => this.table.addRowDef(rowDef));
    this.headerRowDefs.forEach((headerRowDef) =>
      this.table.addHeaderRowDef(headerRowDef)
    );
    this.table.setNoDataRow(this.noDataRow);
  }
}
