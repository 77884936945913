import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UserCommand } from '../../models/command/user-command';
import { PaginationPage } from '../../models/organization-project';
import { ProjectsSort } from '../../models/projects-sort';
import { UserQuery } from '../../models/query/user-query';
import { User } from '../../models/user';
import { UserProject } from '../../models/user-project';
import { UserTeam } from '../../models/user-team';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class UserService implements UserQuery, UserCommand {
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);
  //COMMAND
  removeUserFromOrganization$(payload: {
    organizationId: string;
    userId: string;
  }): Observable<any> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/remove-user-from-organization`,
      payload
    );
  }

  removeUserFromProject$(payload: {
    projectId: string;
    userId: string;
  }): Observable<any> {
    return this.httpClient.post<void>(
      `${this.url}/organizations-module/remove-user-from-project`,
      payload
    );
  }

  //QUERY
  getUsers$(payload: {
    organizationId: string;
    userName?: string;
    sortBy?: ProjectsSort;
  }): Observable<PaginationPage<User>> {
    const queryParameters: string[] = [];
    if (payload) {
      queryParameters.push(...[`organizationId=${payload.organizationId}`]);
      queryParameters.push(...this.sortBy(payload.sortBy));
      if (payload.userName) {
        queryParameters.push(...[`UserNameOrEmail=${payload.userName}`]);
      }
    }
    return this.httpClient.get<PaginationPage<User>>(
      `${this.url}/organizations-module/get-users?${queryParameters.join('&')}`
    );
  }

  getUser$(payload: {
    organizationId: string;
    userId: string;
  }): Observable<User> {
    return this.httpClient.get<User>(
      `${this.url}/organizations-module/get-user?organizationId=${payload.organizationId}&userId=${payload.userId}`
    );
  }

  getUserProjectsInOrganization$(payload: {
    organizationId: string;
    userId: string;
  }): Observable<UserProject[]> {
    return this.httpClient.get<UserProject[]>(
      `${this.url}/organizations-module/get-user-projects-in-organization?organizationId=${payload.organizationId}&userId=${payload.userId}`
    );
  }
  getUserTeamsInOrganization$(payload: {
    organizationId: string;
    userId: string;
  }): Observable<UserTeam[]> {
    return this.httpClient.get<UserTeam[]>(
      `${this.url}/organizations-module/get-user-teams-in-organization?organizationId=${payload.organizationId}&userId=${payload.userId}`
    );
  }

  private sortBy(sortBy?: ProjectsSort): string[] {
    const buffer: string[] = [];

    if (sortBy?.type) {
      buffer.push(`SortColumn=${sortBy.type}`);
    }

    if (sortBy?.ascending) {
      buffer.push(`IsAscending=${sortBy.ascending}`);
    }

    return buffer;
  }
}
