import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@simlab/design/toast';
import { ENVIRONMENT_CONFIG } from '@simlab/util-shared';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, catchError, map, switchMap, tap, throwError } from 'rxjs';

@Injectable()
export class TokenInjectorInterceptor implements HttpInterceptor {
      private readonly oidcSecurityService = inject(OidcSecurityService);
      private readonly route = inject(ActivatedRoute);
  private _toastService = inject(ToastService);
  environment = inject(ENVIRONMENT_CONFIG);

  private _handleAuthError(err: HttpErrorResponse): Observable<never> {
    if (err.status === 403) {
      this._toastService.open(err.error.errorMessage, 'Error', false);
    }
    return throwError(() => err);
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.includes(this.environment.configuration.api.url))
      return next
        .handle(request)
        .pipe(
          catchError((error: HttpErrorResponse) => this._handleAuthError(error))
        );
    if (window.location.href.split('/')[4] === 'public') {
      return this.route.params.pipe(
        tap(() => {
          const publicToken = window.location.href.split('/')[5];
          const indexOf = publicToken.indexOf('?stageId');
          request = request.clone({
            headers: request.headers.set(
              'Authorization',
              `Bearer ${
                indexOf === -1 ? publicToken : publicToken.slice(0, indexOf)
              }`
            ),
          });
        }),

        switchMap(() =>
          next
            .handle(request)
            .pipe(
              catchError((error: HttpErrorResponse) =>
                this._handleAuthError(error)
              )
            )
        )
      );
    }
    if (request.url !== 'https://my.matterport.com/api/mp/models/graph') {
      return this.oidcSecurityService.getAccessToken().pipe(
        map((accessToken: string) => {
          request = request.clone({
            headers: request.headers.set(
              'Authorization',
              `Bearer ${accessToken}`
            ),
          });
          return request;
        }),
        switchMap((request: HttpRequest<unknown>) =>
          next
            .handle(request)
            .pipe(
              catchError((error: HttpErrorResponse) =>
                this._handleAuthError(error)
              )
            )
        )
      );
    }

    return next
      .handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => this._handleAuthError(error))
      );
  }
}
