import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ApiFacadeService,
  STAGES_USER_LANGUAGE,
  Theme,
  ThemeRecord,
  UserPreferences
} from '@simlab/data-access';
import { ToastService } from '@simlab/design/toast';
import { ThemeService } from '@simlab/ui/theme';
import { catchError, filter, map, of, switchMap, take } from 'rxjs';
import * as UserPreferenceActions from './user-preference.actions';
import { UserPreferenceFacade } from './user-preference.facade';

@Injectable()
export class UserPreferenceEffects {
  private readonly actions$ = inject(Actions);
  private readonly toastService = inject(ToastService);
  private readonly apiFacadeService = inject(ApiFacadeService);
  private readonly userPreferenceFacade = inject(UserPreferenceFacade);
  private readonly themeService = inject(ThemeService);
  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.init),
      take(1),
      switchMap(() => this.userPreferenceFacade.loaded$),
      filter((loaded) => !loaded),
      switchMap(() =>
        this.apiFacadeService.userPreferences.getUserPreferences()
      ),
      map((userPreferences: UserPreferences) => {
        const savedLanguage = localStorage.getItem(STAGES_USER_LANGUAGE);

        if (
          (!savedLanguage &&
            location.pathname.split('/')[1] !== userPreferences.language) ||
          (!!savedLanguage && savedLanguage !== userPreferences.language)
        ) {
          localStorage.setItem(STAGES_USER_LANGUAGE, userPreferences.language);
          location.href =
            location.origin +
            location.pathname
              .split('/')
              .map((element, index) => {
                if (index === 1) {
                  return userPreferences.language;
                } else if (index === 0) return element;

                //Because in this place it have /callback, and after replace language callback cannot be added to work well
                return '';
              })
              .join('/');
        }

        this.themeService.set(ThemeRecord[userPreferences.theme as Theme]);
        return UserPreferenceActions.initSuccess({ userPreferences });
      }),
      catchError((error) => of(UserPreferenceActions.initFailure()))
    )
  );

  userLoggedToProcore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.userLoggedToProcore),
      switchMap(() =>
        this.apiFacadeService.userPreferences.hasUserProcoreToken().pipe(
          map((userLoggedToProcore) =>
            UserPreferenceActions.userLoggedToProcoreSuccess({
              userLoggedToProcore
            })
          ),
          catchError((error) => {
            return of(
              UserPreferenceActions.userLoggedToProcoreFailure({ error })
            );
          })
        )
      )
    )
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.updateUserPreferences),
      switchMap(({ userPreferences }) =>
        this.apiFacadeService.userPreferences
          .updateUserPreferences(userPreferences)
          .pipe(
            map(() => {
              this.themeService.set(
                ThemeRecord[userPreferences.theme as Theme]
              );
              return UserPreferenceActions.updateUserPreferencesSuccess({
                userPreferences
              });
            }),
            catchError((error) =>
              of(UserPreferenceActions.updateUserPreferencesFailure({ error }))
            )
          )
      )
    )
  );
}
