import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { ChangeDetectorRef, ElementRef, SimpleChanges, inject } from '@angular/core';
import { mixinColor } from '@simlab/design/internal';
import { Subscription, filter, merge, tap } from 'rxjs';
import { DesignExpansionPanel } from './expansion-panel.component';

const _ExpansionPanelHeaderBase = mixinColor(
  class {
    readonly _elementRef = inject(ElementRef);
  }
);

export class ExpansionPanelHeader extends _ExpansionPanelHeaderBase {
  protected _parentChangeSubscription = Subscription.EMPTY;
  readonly elementRef = inject(ElementRef);
  protected readonly _focusMonitor = inject(FocusMonitor);
  protected readonly  _changeDetectorRef = inject(ChangeDetectorRef);
  /**
   * Whether the associated panel is disabled. Implemented as a part of `FocusableOption/ListKeyManagerOption`.
   * @docs-private
   */
  get disabled(): boolean {
    return this._panel.disabled;
  }
  constructor(
    public _panel: DesignExpansionPanel,
  ) {
    super();
    // Since the toggle state depends on an @Input on the panel, we
    // need to subscribe and trigger change detection manually.
    this._parentChangeSubscription = merge(
      this._panel.opened,
      this._panel.closed,
      this._panel._inputChanges.pipe(
        filter((changes: SimpleChanges) => {
          return !!changes['disabled'];
        })
      )
    )
      .pipe(tap(() => this._changeDetectorRef.markForCheck()))
      .subscribe();
  }

  /**
   * Focuses the panel header. Implemented as a part of `FocusableOption`.
   * @param origin Origin of the action that triggered the focus.
   * @docs-private
   */
  focus(origin?: FocusOrigin | undefined, options?: FocusOptions): void {
    if (origin) {
      this._focusMonitor.focusVia(this._elementRef, origin, options);
    } else {
      this._elementRef.nativeElement.focus(options);
    }
  }

  /** Gets whether the panel is expanded. */
  _isExpanded(): boolean {
    return this._panel.expanded;
  }

  /** Toggles the expanded state of the panel. */
  _toggle(): void {
    if (!this.disabled) {
      this._panel.toggle();
    }
  }
}
