import { Observable, defer } from 'rxjs';
import { PerspectiveCamera } from 'three';

import { InjectionToken } from '@angular/core';
import { FirstPersonControls } from '@simlab/three/FirstPersonControls';
import { CameraPose } from '../types/camera';
import { PanoramaControls } from './panorama-camera-controls';
export type TCameraControlsKeysOptions = {
  Forward: string;
  Back: string;
  Left: string;
  Right: string;
  speed: number;
};

const DEFAULT_CAMERA_CONTROLS_KEY: TCameraControlsKeysOptions = {
  Forward: 'ArrowUp',
  Back: 'ArrowDown',
  Left: 'ArrowLeft',
  Right: 'ArrowRight',
  speed: 25,
};
export const SIMLAB_CAMERA_CONTROL = new InjectionToken<SimlabCameraControl>(
  'Simlab camera control'
);

export type SimlabCameraControl = {
  cameraPoseChange$: Observable<CameraPose>;
  destroy: () => void;
  set enableRotation(enabled: boolean);

  get enableRotation(): boolean;
  set enableMovement(enabled: boolean);
  get enableMovement(): boolean;

  set enabledAll(enabled: boolean);
  get enabledAll(): boolean;
  reset: () => void;
  recalculate: () => void;
  update(delta: number): SimlabCameraControl;
};

export class SimlabCameraControls
  extends FirstPersonControls
  implements SimlabCameraControl
{
  protected _rotationControls = new PanoramaControls(
    this._camera,
    this.domElement
  );
  readonly cameraPoseChange$ = defer(
    () => this._rotationControls.cameraPoseChange$
  );
  constructor(readonly _camera: PerspectiveCamera, domElement: HTMLElement) {
    super(_camera, domElement);
    this.removePointerEvents();
  }

  set enableRotation(enabled: boolean) {
    this._rotationControls.enabled = enabled;
  }

  get enableRotation(): boolean {
    return this._rotationControls.enabled;
  }
  set enableMovement(enabled: boolean) {
    this.enabled = enabled;
  }
  get enableMovement(): boolean {
    return this.enabled;
  }

  set enabledAll(enabled: boolean) {
    this.enabled = enabled;
    this._rotationControls.enabled = enabled;
  }
  get enabledAll(): boolean {
    return this.enabled && this._rotationControls.enabled;
  }
  reset() {
    this._rotationControls.reset();
  }
  recalculate() {
    this._rotationControls.recalculate();
  }
  destroy() {
    this._rotationControls.destroy();
  }
}

// export function cameraControlsKeyEvents(
//   domElement: HTMLElement | Window,
//   camera: Camera,
//   delta: Clock,
//   controls: OrbitControls,
//   options: TCameraControlsKeysOptions = DEFAULT_CAMERA_CONTROLS_KEY
// ) {
//   fromEvent<KeyboardEvent>(domElement, 'keydown')
//     .pipe(
//       tap((event: KeyboardEvent) => {
//         const cameraForward = camera.getWorldDirection(new Vector3());
//         const upVec = camera.up;
//         switch (event.key) {
//           case options.Forward: {
//             const pos = camera.position
//               .clone()
//               .addScaledVector(cameraForward, delta.getDelta() * options.speed);
//             console.log(pos, camera.position);

//             camera.position.copy(pos);
//             break;
//           }
//           case options.Back: {
//             camera.position.copy(
//               camera.position.addScaledVector(
//                 cameraForward,
//                 -delta.getDelta() * options.speed
//               )
//             );
//             break;
//           }
//           case options.Left: {
//             break;
//           }
//           case options.Right: {
//             break;
//           }
//         }
//         controls.update();
//       })
//     )
//     .subscribe();
// }
