import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Note } from '@simlab/data-access';
import { NOTE_FEATURE_KEY, noteAdapter, State } from './notes.reducer';
// Lookup the 'Note' feature state managed by NgRx
export const getNoteState = createFeatureSelector<State>(NOTE_FEATURE_KEY);

const { selectAll, selectEntities } = noteAdapter.getSelectors();

export const getNoteRightPanelLoaded = createSelector(
  getNoteState,
  (state: State) => state.rightPanelLoaded
);

export const getNoteListLoaded = createSelector(
  getNoteState,
  (state: State) => state.listLoaded
);

export const getNoteError = createSelector(
  getNoteState,
  (state: State) => state.error
);

export const getNoteSort = createSelector(
  getNoteState,
  (state: State) => state.sort
);

export const getAllNote = createSelector(getNoteState, (state: State) =>
  selectAll(state)
);

export const getNoteEntities = createSelector(getNoteState, (state: State) =>
  selectEntities(state)
);

export const getSelectedState = createSelector(getNoteState, (state) => {
  return state.selectedNote ?? null;
});

export const metadata = createSelector(getNoteState, (state) => {
  return state.metadata ?? null;
});

export const filters = createSelector(getNoteState, (state) => {
  return state.filters ?? null;
});

export const sort = createSelector(getNoteState, (state) => state.sort);

export const getAllPhotos = createSelector(
  getSelectedState,
  (note: Note | null) => {
    return note?.photoNotes ?? null;
  }
);

export const getAllAudios = createSelector(
  getSelectedState,
  (note: Note | null) => {
    return note?.audioNotes ?? [];
  }
);

export const getAllVideos = createSelector(
  getSelectedState,
  (note: Note | null) => {
    return note?.videoNotes ?? [];
  }
);

export const getAllDocs = createSelector(
  getSelectedState,
  (note: Note | null) => {
    return note?.documentNotes ?? [];
  }
);

export const getSelectedId = createSelector(getNoteState, (state: State) => {
  return state.selectedId as string;
});

export const getSelected = createSelector(
  getNoteState,
  (state: State) => (state.selectedNote as Note) ?? ({} as Note)
);

export const counts = createSelector(getSelectedState, (note: Note | null) => {
  return {
    audio: note?.audioNotes?.length ?? 0,
    doc: note?.documentNotes?.length ?? 0,
    photo: note?.photoNotes?.length ?? 0,
    video: note?.videoNotes?.length ?? 0
  };
});
