import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import {
  ProcorePunchItemStatusesKeys,
  ProcoreRFIStatusesKeys
} from '@simlab/procore/models';

export type ProcoreFiltersState = {
  filter: {
    punchItemStatuses: ProcorePunchItemStatusesKeys[];
    punchItemBallInCourt: string[];
    punchItemDueDate: string | undefined;
    rfiStatuses: ProcoreRFIStatusesKeys[];
    rfiDueDate: string | undefined;
    rfiBallInCourt: string[];
  };
};

const initialState: ProcoreFiltersState = {
  filter: {
    punchItemStatuses: [],
    punchItemBallInCourt: [],
    punchItemDueDate: undefined,
    rfiStatuses: [],
    rfiDueDate: undefined,
    rfiBallInCourt: []
  }
};

export const ProcoreFiltersStore = signalStore(
  withState(initialState),
  withMethods((store) => ({
    resetFilters(): void {
      patchState(store, () => ({
        ...initialState
      }));
    },
    setFilters(newFilter: Partial<ProcoreFiltersState['filter']>): void {
      patchState(store, ({ filter }) => ({
        filter: { ...filter, ...newFilter }
      }));
    }
  }))
);
