import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const ToastAnimation = trigger('toastAnimation', [
  state('inactive', style({ opacity: 0, transform: 'translateX(450px)' })),
  state('active', style({ opacity: 1 })),
  state('removed', style({ height: '0px', 'min-height': '0px', opacity: 0 })),
  transition('inactive => active', animate('{{easeTime}}ms  {{easing}}')),
  transition('active => removed', animate('{{easeTime}}ms {{easing}}')),
]);
