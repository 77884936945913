<span class="prefix-suffix-wrapper" *ngIf="prefix; else padding">
  <ng-content *ngTemplateOutlet="prefix ?? null"> </ng-content> </span
><input
  #input
  [type]="type"
  [disabled]="disabled"
  [required]="required"
  [value]="value"
  id="{{ id }},"
  placeholder="{{ placeholder }}"
  readonly="{{ readonly || null }}"
  (focus)="focusChanged(true)"
  (focusout)="onTouched()"
  (blur)="focusChanged(false)"
  (input)="emitChangeEvent($event)"
  class="disable-default"
  [class.cursor-readonly]="readonly"
  [class.notEditable]="notEditable"
/>
<button *ngIf="deleteButton && value" ui-icon-button class="clean-button">
  <i ui-font fontName="cross" fontSize="2rem" (click)="cleanValue()"></i>
</button>
<span class="prefix-suffix-wrapper" *ngIf="suffix; else padding">
  <ng-content *ngTemplateOutlet="suffix ?? null"> </ng-content
></span>
<ng-template #padding>
  <span style="width: 0.5rem"></span>
</ng-template>
