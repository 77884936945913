import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';

@Injectable()
export class IconCacheService {
  constructor(private readonly httpClient: HttpClient) {}

  private readonly _cached: Map<string, string> = new Map<string, string>();

  public getSvg(name: string): Observable<string> {
    if (this._cached.has(name)) {
      return of(`${this._cached.get(name)}`);
    }

    return this.fetch(name).pipe(
      tap((result: string) => {
        this._cached.set(name, result);
      })
    );
  }

  private fetch(name: string): Observable<string> {
    const headers = new HttpHeaders();
    headers.set('Accept', 'image/svg+xml');

    return this.httpClient.get(`assets/ui/icons/${name}.svg`, {
      headers,
      responseType: 'text'
    });
  }
}
