import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiHelperModule } from '../ui-helper/ui-helper.module';
import { UiPaginatorModule } from '../ui-paginator/ui-paginator.module';
import { UiProgressSpinnerModule } from '../ui-progress-spinner/ui-progress-spinner.module';
import { UiTableComponent } from './components/ui-table/ui-table.component';

@NgModule({
  declarations: [UiTableComponent],
  imports: [
    CommonModule,
    CdkTableModule,
    UiProgressSpinnerModule,
    UiHelperModule
  ],
  exports: [UiTableComponent, UiPaginatorModule, UiHelperModule]
})
export class UiTableModule {}
