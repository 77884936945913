import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { Directive, ElementRef, Renderer2, inject } from '@angular/core';
import {
  DesignSymbol,
  DesignSymbolAsUnion,
  DesignSymbolInput,
} from '@simlab/design/internal';

declare const ngDevMode: boolean;

const _IconBase = class {};

@Directive()
export abstract class IconBase extends _IconBase {
      private _elementRef = inject(ElementRef);
      private readonly _renderer = inject(Renderer2);

  set name(value: DesignSymbolInput) {
    if (value !== this._fontSymbol) {
      if (this._fontSymbol) {
        this._elementRef.nativeElement.classList.toggle(
          `design-symbol-${this._fontSymbol.toLocaleLowerCase()}`
        );
      }

      if (value) {
        const symbol = DesignSymbol[value as DesignSymbolAsUnion];
        this._elementRef.nativeElement.classList.toggle(
          `design-symbol-${symbol || value.toLocaleLowerCase()}`
        );
      }
    }

    this._fontSymbol = value as DesignSymbol;
  }

  set rotateBy(value: number) {
    this._renderer.setStyle(this._elementRef.nativeElement, 'display', 'block');
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'transform',
      `rotate(${value}deg)`
    );
  }

  set size(value: string) {
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      'font-size',
      coerceCssPixelValue(value)
    );
  }

  _fontSymbol!: DesignSymbol;

  constructor(
) {
    super();
  }
}
