import { SasToken } from './sas-token';

export interface DigitalNote {
  id: string;
  addedAt: string;
  addedBy: string;
  modifiedAt: string;
  lastEditedBy: string;
  name: string;
  type: string;
  digitalNoteUrl: string;
  digitalNoteUrlWithSas: SasToken;
}
