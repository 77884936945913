import { Directive, ElementRef, inject } from '@angular/core';

@Directive()
export abstract class AffixBase {
  protected _isText = false;

  get isText(): boolean {
    return this._isText;
  }

  constructor() {
        const elementRef = inject(ElementRef);
    const element = <Element>elementRef.nativeElement;
    this._isText =
      element.hasAttribute('designTextPrefix') ||
      element.hasAttribute('designTextSuffix');
  }
}
