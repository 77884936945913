<div class="top-section">
  <button
    class="top-section__return-button"
    design-stroked-button
    routerLink="../stages"
  >
    <i designIcon="arrow_back" designSize="20px"></i>
    <ng-container i18n="@@BACK_TO_PROJECT">Back to project</ng-container>
  </button>

  <h2 class="top-section__title">Project documents</h2>
</div>

<design-tab-group class="ui-tab-group">
  <design-tab>
    <ng-template designTabLabel>
      <a [routerLink]="['./stages']" routerLinkActive="router-link-active">
        <h1>Stages</h1></a
      >
    </ng-template>
    <!-- <feature-projects-stages-documents /> -->
  </design-tab>
  @if (projectConnectedWithProcore()) {
    <design-tab>
      <ng-template designTabLabel>
        <a [routerLink]="['./procore']" routerLinkActive="router-link-active">
          <h1>Procore</h1></a
        >
      </ng-template>
      <!-- <feature-projects-procore-documents /> -->
    </design-tab>
  }
</design-tab-group>
<div class="root-document">
  <router-outlet></router-outlet>
</div>
