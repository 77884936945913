import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { ApiDistance } from '../../../data-access/distance-api.service';
import { DistanceMeasurementEffects } from './distance.effects';
import { DistanceFacade } from './distance.facade';
import {
  DISTANCE_MEASUREMENT_FEATURE_KEY,
  distanceMeasurementReducer
} from './distance.reducer';

export function provideDistanceMeasurment(): EnvironmentProviders[] {
  return [
    provideState(DISTANCE_MEASUREMENT_FEATURE_KEY, distanceMeasurementReducer),
    provideEffects(DistanceMeasurementEffects),
    makeEnvironmentProviders([ApiDistance, DistanceFacade])
  ];
}
