import { HttpClient } from '@angular/common/http';
import { Renderer2 } from '@angular/core';

import { FileLoaderCacheService } from '@simlab/simlab-facility-management/common';
import {
  ComponentConfiguration,
  ICustomComponent,
} from '@simlab/simlab-facility-management/scene-object';
import {
  TagComponent,
  TagComponentFactory,
} from '@simlab/simlab-facility-management/sub-features/tag';
import { TransformConverter } from '@simlab/transform';
import { Camera, Scene } from 'three';
import { _BlueprintBase } from '../components/blueprint_base';
import { IBlueprint } from '../types/blueprint.interface';

export class BlueprintFactory implements IBlueprint<TagComponent> {
  private readonly componentLoader: ICustomComponent<TagComponent> =
    new TagComponentFactory(this._camera, this._scene, this._offset);

  private readonly _blueprint = new _BlueprintBase<
    TagComponent,
    ICustomComponent<TagComponent>
  >(
    this.componentLoader,
    this._httpClient,
    this._renderer,
    this._fileLoaderCacheService
  );

  constructor(
    private readonly _camera: Camera,
    private readonly _scene: Scene,
    private readonly _offset: TransformConverter,
    private readonly _httpClient: HttpClient,
    private readonly _renderer: Renderer2,
    private readonly _fileLoaderCacheService: FileLoaderCacheService
  ) {}
  addBlueprint = (
    component: ComponentConfiguration
  ): Promise<TagComponent | undefined> => {
    return this._blueprint.addBlueprint(component);
  };
  deleteBlueprint = (blueprintId: string): string => {
    return this._blueprint.deleteBlueprint(blueprintId);
  };
}
