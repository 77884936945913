import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiButtonModule } from '../ui-button/ui-button.module';
import { UiFormFieldModule } from '../ui-form-field/ui-form-field.module';
import { UiIconModule } from '../ui-icon/ui-icon.module';
import { UiInputModule } from '../ui-input/ui-input.module';
import { UiMapComponent } from './components/ui-map.component';

@NgModule({ declarations: [UiMapComponent],
    exports: [UiMapComponent], imports: [CommonModule,
        UiButtonModule,
        UiIconModule,
        UiFormFieldModule,
        UiInputModule,
        ReactiveFormsModule], providers: [provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())] })
export class UiMapModule {}
