import {
  Box3,
  Group,
  Intersection,
  Matrix4,
  Ray,
  Raycaster,
  Vector3,
} from 'three';

export const addColliderToGroup = (group: Group) => {
  group.raycast = (raycaster: Raycaster, intersects: Intersection<any>[]) => {
    const _inverseMatrix = new Matrix4();
    const _ray = new Ray();
    const _intersectionPoint = new Vector3();
    const _intersectionPointWorld = new Vector3();
    const matrixWorld = group.matrixWorld;

    _inverseMatrix.invert();
    _ray.copy(raycaster.ray).applyMatrix4(_inverseMatrix);

    const boundingBox = new Box3().setFromObject(group);

    const intersect = _ray.intersectBox(boundingBox, _intersectionPoint);
    if (intersect === null) return;

    _intersectionPointWorld.copy(_intersectionPoint);
    _intersectionPointWorld.applyMatrix4(matrixWorld);

    const distance = raycaster.ray.origin.distanceTo(_intersectionPointWorld);
    if (distance < raycaster.near || distance > raycaster.far) return;
    intersects.push({
      distance: distance,
      point: _intersectionPointWorld.clone(),
      object: group,
    });
  };
};

export const removeColliderFromGroup = (group: Group) => {
  group.raycast = (raycaster: Raycaster, intersects: Intersection<any>[]) =>
    undefined;
};
