import { Injectable, inject } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import {
  AcceptSynchronization,
  CreateComponent,
  StageComponent,
} from '@simlab/data-access';
import { RouterSelectors, RouterStoreParams } from '@simlab/util-shared';
import { Observable, Subject, map } from 'rxjs';
import * as ComponentsActions from './components.actions';
import * as ComponentsSelectors from './components.selectors';

@Injectable()
export class ComponentsFacade {
      private readonly store = inject(Store);
      private readonly actions$ = inject(Actions);
  readonly blueprintLoaded$: Subject<string> = new Subject<string>();

  readonly addComponentSuccess$ = this.actions$.pipe(
    ofType(ComponentsActions.addComponentSuccess)
  );

  readonly addComponentFailure$ = this.actions$.pipe(
    ofType(ComponentsActions.addComponentFailure)
  );

  readonly removeComponentSuccess$ = this.actions$.pipe(
    ofType(ComponentsActions.removeComponentSuccess)
  );

  readonly removeComponentFailure$ = this.actions$.pipe(
    ofType(ComponentsActions.removeComponentFailure)
  );

  /**
   * Combine pieces of state using createSelector,
   * and expose them as observables through the facade.
   */
  readonly loaded$: Observable<boolean> = this.store.pipe(
    select(ComponentsSelectors.getComponentsLoaded)
  );

  readonly selectedComponent$: Observable<StageComponent | undefined> =
    this.store.pipe(select(ComponentsSelectors.getSelectedComponent));

  readonly getRouteNestedParams$: Observable<RouterStoreParams> =
    this.store.pipe(select(RouterSelectors.getRouteNestedParams));
  readonly allComponents$ = this.store.pipe(
    select(ComponentsSelectors.getAllComponents)
  );
  readonly selectedComponentId$ = this.store.pipe(
    select(ComponentsSelectors.getSelected)
  );

  readonly acceptSynchronizationSuccess$ = this.actions$.pipe(
    ofType(ComponentsActions.acceptSynchronizationSuccess),
    map((data) => data.component)
  );

  readonly getComponentsById$ = (componentId: string) =>
    this.store.pipe(select(ComponentsSelectors.componentById(componentId)));

  readonly matterportComponentsForStage$ = (stageId: string) =>
    this.store.pipe(
      select(ComponentsSelectors.getMatterportComponentsInStage(stageId))
    );

  readonly blueprintComponentsForStage$ = (
    stageId: string,
    outOfSync = false
  ) =>
    this.store.pipe(
      select(
        ComponentsSelectors.getBlueprintComponentsInStage(stageId, outOfSync)
      )
    );

  readonly hasComponent$ = (componentId: string): Observable<boolean> =>
    this.store.pipe(select(ComponentsSelectors.hasComponent(componentId)));

  initStore(projectId: string): void {
    this.store.dispatch(ComponentsActions.init({ projectId }));
  }

  clearStore() {
    this.store.dispatch(ComponentsActions.clearStore());
  }

  initSetComponent(stageId: string): void {
    this.store.dispatch(ComponentsActions.initSetComponent({ stageId }));
  }

  removeAllComponent(): void {
    this.store.dispatch(ComponentsActions.removeAllComponentFromState());
  }

  removeComponentById(componentId: string): void {
    this.store.dispatch(
      ComponentsActions.removeComponentByIdFromState({ componentId })
    );
  }

  removeComponent(stageId: string, componentId: string): void {
    this.store.dispatch(
      ComponentsActions.removeComponent({ componentId, stageId })
    );
  }

  editComponentName(componentId: string, newName: string): void {
    this.store.dispatch(
      ComponentsActions.editComponentName({ componentId, newName })
    );
  }

  loadComponentsSuccess(components: StageComponent[]): void {
    this.store.dispatch(
      ComponentsActions.loadComponentsSuccess({ components })
    );
  }

  addComponentToStage(component: CreateComponent): void {
    this.store.dispatch(ComponentsActions.addComponentToStage({ component }));
  }

  addComponentSuccess(component: StageComponent): void {
    this.store.dispatch(ComponentsActions.addComponentSuccess({ component }));
  }

  changeComponent(component: StageComponent): void {
    this.store.dispatch(ComponentsActions.changeComponent({ component }));
  }

  updateComponent(newName: string, componentId: string): void {
    this.store.dispatch(
      ComponentsActions.updateComponent({ newName, componentId })
    );
  }

  synchronizeComponent(payload: AcceptSynchronization) {
    this.store.dispatch(ComponentsActions.acceptSynchronization({ payload }));
  }

  setSynchronizingAccepted(
    stageId: string,
    componentId: string,
    status: boolean
  ): void {
    this.store.dispatch(
      ComponentsActions.setSynchronizingAccepted({
        stageId,
        componentId,
        status,
      })
    );
  }

  deleteSynchronization(stageId: string, componentId: string): void {
    this.store.dispatch(
      ComponentsActions.deleteSynchronization({
        stageId,
        componentId,
      })
    );
  }

  deleteLastKnowPosition() {
    this.store.dispatch(ComponentsActions.deleteLastKnowPosition());
  }

  setSelectedComponentById(componentId: string | undefined): void {
    this.store.dispatch(
      ComponentsActions.setSelectedComponentById({ componentId })
    );
  }

  checkTheSweepOnComponent(component: StageComponent): void {
    this.store.dispatch(
      ComponentsActions.checkSweepOnComponent({
        component: component,
      })
    );
  }

  componentVisibilityChange(payload: { id: string; visible: boolean }) {
    this.store.dispatch(ComponentsActions.componentVisibilityChange(payload));
  }
}
