import { AbstractControl, ValidatorFn } from '@angular/forms';

export class DateValidators {
  static dateLessThan(
    dateField1: string,
    dateField2: string,
    validatorField: { [key: string]: boolean }
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const date1 = control.get(dateField1)?.value;
      const date2 = control.get(dateField2)?.value;

      const parsedDate1 = formatDate(date1);
      const parsedDate2 = formatDate(date2);
      if (date1 !== null && date2 !== null && parsedDate1 > parsedDate2) {
        return validatorField;
      }
      return null;
    };
  }
}

function formatDate(dateString: string) {
  const day = dateString.substring(0, 2);
  const month = dateString.substring(3, 5);
  const year = dateString.substring(6, dateString.length);
  return new Date(`${month}-${day}-${year}`);
}
