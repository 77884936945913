import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  inject
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import {
  ApiFacadeService,
  UserLicense,
  UserPreferences
} from '@simlab/data-access';
import { UserPreferenceFacade } from '@simlab/data-store';
import { DesignFlatButton, DesignIconButton } from '@simlab/design/button';
import { DesignCommonModule } from '@simlab/design/common';
import { DesignIcon } from '@simlab/design/icon';
import { ToastOverlayService, ToastService } from '@simlab/design/toast';

import { toSignal } from '@angular/core/rxjs-interop';
import { UiMenuPanelModule } from '@simlab/design/menu-panel';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import { EventQueueComponent } from '@simlab/event-queue';
import { QueueService } from '@simlab/event-queue/services';
import { UiButtonModule } from '@simlab/ui/button';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiIconModule } from '@simlab/ui/icon';
import { MODAL_DATA, ModalService } from '@simlab/ui/modal';
import { UiSidenavModule } from '@simlab/ui/sidenav';
import { TitleService, UiTitleModule } from '@simlab/ui/title';
import { ENVIRONMENT_CONFIG, Environment } from '@simlab/util-shared';
import {
  AuthenticatedResult,
  OidcSecurityService,
  UserDataResult
} from 'angular-auth-oidc-client';
import { Observable, defer, filter, firstValueFrom, map, tap } from 'rxjs';
import { HamburgerComponent } from '../hamburger/hamburger.component';
import { MyLicensesDialogComponent } from '../my-licenses-dialog/my-licenses-dialog.component';
import { PersonalSettingsComponent } from '../personal-settings/personal-settings.component';

@Component({
  selector: 'simlab-socket-layout',
  templateUrl: './socket-layout.component.html',
  styleUrls: ['./socket-layout.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PortalModule,
    UiButtonModule,
    DesignProgressSpinnerComponent,
    UiIconModule,
    UiTitleModule,
    UiDividerModule,
    UiMenuPanelModule,
    UiSidenavModule,
    LayoutModule,
    HamburgerComponent,
    DesignFlatButton,
    DesignIconButton,
    DesignIcon,
    DesignCommonModule,
    PersonalSettingsComponent,
    EventQueueComponent
  ],
  providers: [UserPreferenceFacade, ToastService, ToastOverlayService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocketLayoutComponent implements OnInit {
  private readonly environment = inject<Environment>(ENVIRONMENT_CONFIG);
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly _queueService = inject(QueueService);
  private readonly titleService = inject(TitleService);
  private readonly modalService = inject(ModalService);
  private readonly toastService = inject(ToastService);
  private readonly userPreferenceFacade = inject(UserPreferenceFacade);
  private readonly apiFacadeService = inject(ApiFacadeService);
  private readonly router = inject(Router);
  //NOTE: temporary solution to disable organizations

  protected readonly isDocumentsFeatureRendered = toSignal(
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.router.url.includes('documents'))
    ),
    { initialValue: false }
  );

  get production(): boolean {
    return this.environment.production;
  }

  readonly queueState = computed(() => {
    const viewInProgress = this._queueService.viewInProgress();
    const failedResults = this._queueService.failedResults();

    return {
      viewInProgress,
      failedResults
    };
  });

  readonly title$: Observable<string> = defer(() => this.titleService.title$);

  readonly authenticated$: Observable<boolean> = defer(() =>
    this.oidcSecurityService.isAuthenticated$.pipe(
      map((result: AuthenticatedResult) => result.isAuthenticated)
    )
  );

  readonly userName$: Observable<string> = defer(() =>
    this.oidcSecurityService.userData$.pipe(
      map((data: UserDataResult) => {
        return `${data.userData?.name}`;
      })
    )
  );

  readonly userData$: Observable<unknown> = defer(() =>
    this.oidcSecurityService.userData$.pipe(
      map((data: UserDataResult) => {
        return data.userData;
      })
    )
  );

  private _myLicenseLength = 0;
  public get myLicenseLength() {
    return this._myLicenseLength;
  }
  public set myLicenseLength(value) {
    this._myLicenseLength = value;
  }

  ngOnInit(): void {
    this._getUserLicenseLength();
  }

  login(): void {
    this.oidcSecurityService.authorize();
  }

  logout(): void {
    firstValueFrom(this.oidcSecurityService.logoff());
  }

  openPersonal(): void {
    this._openAccountSettingsDialog();
  }

  openMyLicenses(): void {
    this._openMyLicensesDialog();
  }

  goToSimlabCloud(): void {
    window.location.href = this.environment.configuration.simlabCloud;
  }

  private _getUserLicenseLength(): void {
    firstValueFrom(
      this.apiFacadeService.privateLicense.getUserLicenses().pipe(
        tap((userLicenses: UserLicense[]) => {
          this.myLicenseLength = userLicenses.length;
        })
      )
    );
  }

  private _openAccountSettingsDialog(): void {
    firstValueFrom(
      this.userPreferenceFacade.getUserPreference$.pipe(
        tap((userPreferences: UserPreferences | undefined) => {
          this.modalService.createModalWithProviders(
            PersonalSettingsComponent,
            {
              centered: true,
              offset: {
                x: 0,
                y: 0
              },
              maxWidth: 'min(90%, 1060px)',
              minWidth: 'min(90%, 1060px)'
            },
            [
              {
                provide: MODAL_DATA,
                useValue: userPreferences
              },
              {
                provide: ToastService,
                useValue: this.toastService
              }
            ]
          );
        })
      )
    );
  }

  private _openMyLicensesDialog(): void {
    this.modalService.createModalWithProviders(MyLicensesDialogComponent, {
      centered: true,
      offset: {
        x: 0,
        y: 0
      },
      maxWidth: 'min(90%, 665px)',
      minWidth: 'min(90%, 665px)'
    });
  }
}
