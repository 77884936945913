import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DistanceCommand,
  DistanceQuery,
  IAddMeasurementToStage,
  MeasurementCommand,
  TAddDistanceRootNote,
  TAddDistanceStage,
  TAddMeasurementGroup,
  TAddMeasurementGroups,
  TAddMeasurementsToGroup,
  TAssignToRootNote,
  TBaseMeasurement,
  TDistance,
  TDistanceCount,
  TDistanceGroups,
  TRemoveFromGroup,
  TSimpleMeasurement,
  TStageMeasurmentSimpleGroups,
  TUpdateColor,
  TUpdateData,
  TUpdateGroupName,
  TUpdateTitle
} from '@simlab/feature-measurement/models';
import { Observable } from 'rxjs';
import { ApiBase } from './base-api.service';

@Injectable()
export class ApiDistance
  extends ApiBase
  implements DistanceCommand, MeasurementCommand, DistanceQuery
{
  addMeasurement = (body: IAddMeasurementToStage): Observable<string> =>
    this.post(`add-distance-measurement`, body);
  addMeasurementToRootNote = (body: TAddDistanceRootNote): Observable<string> =>
    this.post(`add-distance-measurement-to-root-note`, body);
  addMeasurementGroup = (body: TAddMeasurementGroup): Observable<string> =>
    this.post(`add-distance-measurement-group`, body);

  addMeasurementToGroups = (body: TAddMeasurementGroups): Observable<void> =>
    this.post(`add-distance-measurement-to-groups`, body);

  addMeasurementsToGroup = (
    body: TAddMeasurementsToGroup
  ): Observable<string> =>
    this.post(`add-distance-measurements-to-group`, body);

  assignMeasurementToRootNote = (body: TAssignToRootNote): Observable<void> =>
    this.put(`assign-distance-measurement-to-root-note`, body);

  unAssignMeasurementToRootNote = (body: TBaseMeasurement): Observable<void> =>
    this.put(`unassign-distance-measurement-from-root-note`, body);
  updateMeasurementTitle = (body: TUpdateTitle): Observable<void> =>
    this.put(`update-distance-measurement-title`, body);

  updateMeasurementColor = (body: TUpdateColor): Observable<void> =>
    this.put(`update-distance-measurement-color`, body);

  updateMeasurementData = (body: TUpdateData): Observable<void> =>
    this.put(`update-distance-measurement-data`, body);

  updateMeasurementGroupName = (body: TUpdateGroupName): Observable<void> =>
    this.put(`update-distance-measurement-group-name`, body);

  removeMeasurementFromGroup = (data: TRemoveFromGroup): Observable<void> =>
    this.delete(
      `remove-distance-measurement-from-group?id=${data.id}&groupId=${data.groupId}`
    );

  removeMeasurement = (data: TBaseMeasurement): Observable<void> =>
    this.delete(`remove-distance-measurement?id=${data.id}`);
  removeMeasurementGroup = (data: TBaseMeasurement): Observable<void> =>
    this.delete(`remove-distance-measurement-group?id=${data.id}`);

  getMeasurement = (id: string): Observable<TSimpleMeasurement> =>
    this.get<TSimpleMeasurement>(`get-distance-measurement?id=${id}`);

  getStageMeasurementGroups = (
    stageId: string
  ): Observable<TDistanceGroups[]> =>
    this.get<TDistanceGroups[]>(
      `get-stage-distance-measurement-groups?stageId=${stageId}`
    );

  getStageMeasurementSimpleGroups = (
    stageId: string
  ): Observable<TStageMeasurmentSimpleGroups[]> =>
    this.get<TStageMeasurmentSimpleGroups[]>(
      `get-stage-distance-measurement-simple-groups?stageId=${stageId}`
    );

  getStageMeasurements = (stageId: string): Observable<TDistance[]> =>
    this.get<TDistance[]>(`get-stage-distance-measurements?stageId=${stageId}`);

  exportStageMeasurements = (resultFileName: string): Observable<void> =>
    this.get<void>(
      `export-distance-measurements?resultFileName=${resultFileName}`
    );

  getProjectMeasurementsCount = (
    projectId: string
  ): Observable<TDistanceCount> =>
    this.get<TDistanceCount>(
      `get-project-distance-measurements-count?projectId=${projectId}`
    );

  getStageMeasurementsCount = (stageId: string): Observable<TDistanceCount> =>
    this.get<TDistanceCount>(
      `get-stage-distance-measurements-count?stageId=${stageId}`
    );

  exportMeasurements = (
    resultFileName: string,
    ids: string[]
  ): Observable<HttpResponse<Blob>> => {
    const headers = new HttpHeaders({
      Accept: 'text/csv'
    });
    return this.httpClient.get<Blob>(
      `${
        this.url
      }/construction-site-management-module/export-distance-measurements?resultFileName=${resultFileName}&Ids=${ids.join(
        '&Ids='
      )}`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
        headers: headers
      }
    );
  };
}
