import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApiFacadeService, Note, StageNotes } from '@simlab/data-access';
import { catchError, filter, map, mergeMap, of, switchMap } from 'rxjs';
import * as ShowcaseNotesActions from './showcase-notes.actions';

@Injectable()
export class ShowcaseNotesEffects {
  private readonly actions$ = inject(Actions);
  private readonly apiFacadeService = inject(ApiFacadeService);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcaseNotesActions.init),
      filter((action) => !!action.payload.stageId),
      switchMap((action) =>
        this.apiFacadeService.rootNotes
          .getRootNotesWithMarkersForStages({
            stageId: action.payload.stageId,
            rootNotesFilter: action.payload.rootNotesFilter
          })
          .pipe(
            catchError((error) => {
              console.error(error);
              return of();
            })
          )
      ),
      mergeMap((notes: StageNotes<Note>) =>
        of(
          ShowcaseNotesActions.loadShowcaseNotesSuccess({
            showcaseNotes: notes.items
          })
        )
      ),
      catchError((error) =>
        of(ShowcaseNotesActions.loadShowcaseNotesFailure({ error }))
      )
    )
  );

  hideAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcaseNotesActions.hideAll),
      map(({ hideAll }) => {
        try {
          return ShowcaseNotesActions.hideAllSuccess({ hideAll });
        } catch (error) {
          return ShowcaseNotesActions.hideAllFailure({ error });
        }
      })
    )
  );
  // selected$ = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofType(ShowcaseNotesActions.selectNote),

  //       switchMap((action) => {
  //         if (action.noteId) {
  //           return this.apiFacadeService.rootNotes.getRootNote({
  //             id: action.noteId,
  //           });
  //         } else {
  //           return of({} as Note);
  //         }
  //       }),
  //       tap((note: Note) => {
  //         this.notesFacade.selectedNote(note);
  //       })
  //     ),
  // );

  selected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcaseNotesActions.selectNote),
      switchMap((action) => {
        if (!action.noteId) {
          throw new Error('noteId missing or incorrect!');
        }

        return this.apiFacadeService.rootNotes.getRootNote({
          id: action.noteId
        });
      }),
      mergeMap((note: Note) =>
        of(ShowcaseNotesActions.selectNoteSuccess({ note: note }))
      ),
      catchError((error) =>
        of(ShowcaseNotesActions.selectNoteFailure({ error }))
      )
    )
  );
}
