import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  Page,
  ProcoreConnectionStatusEnum,
  Project
} from '@simlab/data-access';
import * as ProjectsActions from './projects.actions';

export const PROJECTS_FEATURE_KEY = 'projects';

export interface State extends EntityState<Project> {
  metadata?: Page;
  selectedId?: string; // which Projects record has been selected
  loaded: boolean; // has the Projects list been loaded
  error?: string | null; // last known error (if any)
}

export interface ProjectsPartialState {
  readonly [PROJECTS_FEATURE_KEY]: State;
}

export const projectsAdapter: EntityAdapter<Project> =
  createEntityAdapter<Project>();

export const initialState: State = projectsAdapter.getInitialState({
  // set initial required properties
  loaded: false
});

export const projectsReducer = createReducer(
  initialState,
  on(ProjectsActions.initProjects, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),

  on(ProjectsActions.loadProjectsSuccess, (state, { projects, metadata }) =>
    projectsAdapter.setAll(projects, {
      ...state,
      metadata: metadata,
      loaded: true
    })
  ),
  on(ProjectsActions.loadProjectsFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(ProjectsActions.createProject, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(ProjectsActions.createProjectSuccess, (state, { project }) =>
    projectsAdapter.addOne(project, { ...state, loaded: true })
  ),
  on(ProjectsActions.createProjectFailure, (state, { error }) => ({
    ...state,
    error
  })),

  //TODO: validate add // do we need to set up loaded?
  on(ProjectsActions.getProject, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(ProjectsActions.getProjectSuccess, (state, { project }) =>
    projectsAdapter.addOne(project, { ...state, loaded: true })
  ),
  on(ProjectsActions.getProjectFailure, (state, { error }) => ({
    ...state,
    error
  })),

  on(ProjectsActions.toggleFavorite, (state, { projectId, flag }) => {
    const forUpdate: Project = { ...state.entities[projectId] } as Project;
    forUpdate.markedAsFavourite = flag;

    const updatedProject: Update<Project> = {
      changes: forUpdate,
      id: projectId
    };

    return projectsAdapter.updateOne(updatedProject, state);
  }),
  on(ProjectsActions.removeProjectSuccess, (state, { projectId }) => {
    return projectsAdapter.removeOne(projectId, state);
  }),
  on(ProjectsActions.updateProjectSuccess, (state, { project }) => {
    const updatedProject: Update<Project> = {
      changes: { ...project, description: project?.description },
      id: project.id
    };

    return projectsAdapter.updateOne(updatedProject, state);
  }),
  on(ProjectsActions.unassignProjectProcore, (state, { projectId }) => {
    const updatedProject: Update<Project> = {
      changes: {
        ...state,
        procoreConnectionStatus: ProcoreConnectionStatusEnum.Disconnected
      },
      id: projectId
    };

    return projectsAdapter.updateOne(updatedProject, state);
  }),
  on(ProjectsActions.assignProjectProcore, (state, { projectId }) => {
    const updatedProject: Update<Project> = {
      changes: {
        ...state,
        procoreConnectionStatus: ProcoreConnectionStatusEnum.Connected
      },
      id: projectId
    };

    return projectsAdapter.updateOne(updatedProject, state);
  }),
  on(ProjectsActions.disconnectingProcoreProject, (state, { projectId }) => {
    const updatedProject: Update<Project> = {
      changes: {
        ...state,
        procoreConnectionStatus: ProcoreConnectionStatusEnum.Disconnecting
      },
      id: projectId
    };

    return projectsAdapter.updateOne(updatedProject, state);
  }),
  on(ProjectsActions.reconnectingProcoreProject, (state, { projectId }) => {
    const updatedProject: Update<Project> = {
      changes: {
        ...state,
        procoreConnectionStatus: ProcoreConnectionStatusEnum.Reconnecting
      },
      id: projectId
    };

    return projectsAdapter.updateOne(updatedProject, state);
  }),
  on(
    ProjectsActions.initProcoreProjectStatus,
    (state, { projectId, status }) => {
      const updatedProject: Update<Project> = {
        changes: {
          ...state,
          procoreConnectionStatus: status
        },
        id: projectId
      };

      return projectsAdapter.updateOne(updatedProject, state);
    }
  )
);

export function reducer(state: State | undefined, action: Action) {
  return Object.assign(PROJECTS_FEATURE_KEY, projectsReducer);
}
