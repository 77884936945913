import {
  Injectable,
  InjectionToken
} from '@angular/core';
import { ToastComponent } from '../components/toast.component';
import { ToastConfig } from '../models/toast-config';
import { _ToastBase } from './toast-base';

/** @docs-private */
export function TOAST_DEFAULT_OPTIONS_FACTORY(): ToastConfig {
  return new ToastConfig();
}

/** Injection token that can be used to specify default snack bar. */
export const TOAST_DEFAULT_OPTIONS = new InjectionToken<ToastConfig>(
  'toast-default-options',
  {
    providedIn: 'root',
    factory: TOAST_DEFAULT_OPTIONS_FACTORY,
  }
);

@Injectable()
export class ToastService extends _ToastBase {

  protected override toastContainerComponent = ToastComponent;
}
