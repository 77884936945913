import { DesignLabel } from './components/label.directive';
import {
  DesignOption,
  DesignOptionBase,
  DesignOptionSelectionChange,
} from './components/option.directive';
import { DesignPrefix } from './components/prefix.directive';
import { DesignSuffix } from './components/suffix.directive';
import { DesignCommonModule } from './modules/common.module';
import { designLabelToken } from './tokens/label.token';
import { designOptionToken } from './tokens/option.token';
import { designPrefixToken } from './tokens/prefix.token';
import { designSuffixToken } from './tokens/suffix.token';
export * from './helpers/extended-map';
export {
  DesignCommonModule,
  DesignLabel,
  DesignOption,
  DesignOptionBase,
  DesignOptionSelectionChange,
  DesignPrefix,
  DesignSuffix,
  designLabelToken,
  designOptionToken,
  designPrefixToken,
  designSuffixToken,
};
