import { Injectable, inject } from '@angular/core';
import { ToastService, ToastType } from '@simlab/design/toast';
import { BehaviorSubject, Observable, merge, switchMap, tap } from 'rxjs';

type BaseEvent = {
  id: number;
} & ToastEvent;

export type ToastEvent = {
  action: Observable<ToastType>;
  message: Partial<Record<ToastType, string>>;
  performOnce?: boolean;
};

let ID = 1;

@Injectable()
export class ToastEventService {
  private readonly _toastService = inject(ToastService);
  private readonly _source = new BehaviorSubject<BaseEvent[]>([]);

  constructor() {
    this._init();
  }

  private _init() {
    this._source
      .pipe(
        switchMap((events: BaseEvent[]) => {
          return merge(
            ...events.map((toastEvent: BaseEvent) => {
              return toastEvent.action.pipe(
                tap((actionType: ToastType) => {
                  if (toastEvent.message[actionType] === undefined) {
                    throw Error(
                      `Meassage with current actionType (${actionType}) has not been set`
                    );
                  }
                  this._toastService.open(
                    toastEvent.message[actionType]!,
                    actionType
                  );
                  toastEvent.performOnce &&
                    this.removeEventsById(toastEvent.id);
                })
              );
            })
          );
        })
      )
      .subscribe();
  }

  private _removeEvents(events: BaseEvent) {
    if (events.performOnce) {
      this._source.next([
        ...this._source.getValue().filter((e: BaseEvent) => e.id !== events.id)
      ]);
    }
  }

  removeEventsById(id: number) {
    this._source.next([
      ...this._source.getValue().filter((e: BaseEvent) => e.id !== id)
    ]);
  }

  addEvents(events: ToastEvent[]) {
    return events.map((event) => this.addEvent(event));
  }
  addEvent(event: ToastEvent) {
    this._source.next([
      ...this._source.getValue(),
      {
        ...event,
        id: ID
      }
    ]);
    return ID++;
  }
}
