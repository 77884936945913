import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { PrivateLicenseCommand } from '../../models/command/private-license-command';
import {
  AccessType,
  OrganizationLicense,
  UserLicense,
} from '../../models/private-license';
import { PrivateLicenseQuery } from '../../models/query/private-license-query';
import { API_URL } from '../../tokens/api-url.token';

@Injectable({
  providedIn: 'root',
})
export class PrivateLicenseService
  implements PrivateLicenseQuery, PrivateLicenseCommand
{
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);

  getOrganizationLicenses(
    organizationId: string
  ): Observable<OrganizationLicense[]> {
    return this.httpClient.get<OrganizationLicense[]>(
      `${this.url}/subscriptions-module/get-organization-licenses?organizationId=${organizationId}`
    );
  }
  getUserLicenses(): Observable<UserLicense[]> {
    return this.httpClient.get<UserLicense[]>(
      `${this.url}/subscriptions-module/get-user-licenses`
    );
  }
  unassignLicense(licenseId: string, accessType: AccessType): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/subscriptions-module/unassign-license`,
      { licenseId, accessType }
    );
  }

  unsubscribeLicense(licenseId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/subscriptions-module/unsubscribe-license`,
      { licenseId }
    );
  }
  subscribeLicense(licenseId: string): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/subscriptions-module/subscribe-license`,
      { licenseId }
    );
  }

  deleteLicense(licenseId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/subscriptions-module/delete-license?licenseId=${licenseId}`
    );
  }

  assignLicense(payload: {
    licenseId: string;
    userId: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.url}/subscriptions-module/assign-license`,
      payload
    );
  }
}
