import { DesignDialogContent } from './components/dialog-content.directive';
import { DesignDialogFooter } from './components/dialog-footer.directive';
import { DesignDialogHeader } from './components/dialog-header.directive';
import { DesignDialogWrapper } from './components/dialog-wrapper.component';
import { DesignDialogWrapperModule } from './modules/dialog-wrapper.module';

export {
  DesignDialogWrapperModule,
  DesignDialogWrapper,
  DesignDialogHeader,
  DesignDialogFooter,
  DesignDialogContent,
};
