import {
  Injectable,
  NgZone,
  OnDestroy,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
// eslint-disable-next-line @nx/nx/enforce-module-boundaries
import { Mattertag } from '@simlab/matterport/api';
// eslint-disable-next-line @nx/nx/enforce-module-boundaries
import { MpSdk } from 'mpSdk';
import {
  catchError,
  filter,
  forkJoin,
  from,
  fromEvent,
  map,
  mapTo,
  merge,
  mergeMap,
  Observable,
  of,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { Vector3 } from 'three';

import { SpriteConfiguration } from '@simlab/simlab-facility-management/scene-object';
import { MatterportServiceBase } from '../base/matterport-base';
import { MatterportModelTag } from '../helpers/matterport-model-tag';
import {
  Pointer,
  StartPlacingConfig,
  TagNote,
  TagNoteTypes,
} from '../models/dto';
import { Colliders } from '../models/enums';
import { ICONS } from '../models/icons';
import { MatterportComponent } from '../models/matterport-tag-component.type';
import { IMattertags } from '../models/mattertags.interface';
import { MatterportEventsService } from './matterport-events.service';
import { MatterportManagerService } from './matterport-manager.service';
import { MatterportPositionControllerService } from './matterport-position-controller.service';
import { MatterportSceneStateAccessService } from './matterport-scene-state-access.service';
@Injectable()
export class MatterportTagsService
  extends MatterportServiceBase
  implements OnDestroy, IMattertags
{
  private _addingTag = false;
  // private _tagId: string | null = null;
  // private _currentTagPos: Vector3 | null = null;
  private _currentTagNote: TagNote | null = null;

  private readonly _sceneNotes: Record<string, string> = {};

  private readonly _tagClicked: Subject<string> = new Subject<string>();
  readonly tagClicked$ = this._tagClicked.asObservable();
  readonly noteClicked$: Observable<string> = this._tagClicked
    .asObservable()
    .pipe(
      map((sdkTagId: string) => this._getKeyByValue(sdkTagId)),
      filter((key: string | undefined) => key !== undefined),
      map((key: string | undefined) => key as string)
    );
  private _clickSub: Subscription | undefined;
  private _pointerSub: Subscription | undefined;
  readonly tagPlacementAccepted$: Subject<{
    tagNote: TagNote;
    comp: MatterportComponent | undefined;
  }> = new Subject<{
    tagNote: TagNote;
    comp: MatterportComponent | undefined;
  }>();
  private readonly _renderer: Renderer2;
  private readonly _touchObserverDestroy: Subject<void> = new Subject<void>();
  private _placedComponent: MatterportComponent | undefined;
  private _tagId: string | undefined;
  private _currentTag: { position: Vector3; normal: Vector3 } | null = null;

  constructor(
    private readonly matterportManager: MatterportManagerService,
    private readonly matterportEvents: MatterportEventsService,
    private readonly rendererFactory: RendererFactory2,
    private readonly ngZone: NgZone,
    private readonly matterportSceneStateAccess: MatterportSceneStateAccessService,
    private readonly matterportPositionController: MatterportPositionControllerService
  ) {
    super(matterportManager);
    this._renderer = this.rendererFactory.createRenderer(null, null);
  }

  override ngOnDestroy(): void {
    this._removeObserver();
    this._clickSub?.unsubscribe();
    this._pointerSub?.unsubscribe();
    this._touchObserverDestroy.next();
    super.ngOnDestroy();
  }
  protected _init() {
    this.clearAllTagNotes();
    this._removeObserver();
    this.sdk?.on(this.sdk.Mattertag.Event.CLICK, this._onMatterportTagClick);
  }
  private _removeObserver() {
    this.sdk?.off(this.sdk.Mattertag.Event.CLICK, this._onMatterportTagClick);
  }
  public get sceneNotes(): Record<string, string> {
    return this._sceneNotes;
  }

  public addNoteWithOffset$(
    noteId: string,
    markerPosition: Vector3,
    markerType: TagNoteTypes
  ) {
    const transformConverter = this.matterportManager.transformConverter;
    const { x, y, z } = transformConverter.toMatterportPosition(markerPosition);
    return this.addNote$({
      id: noteId,
      noteType: markerType,
      position: new Vector3(x, y, z),
    });
  }

  public addNote$(note: TagNote): Observable<void> {
    if (!this.sdk) throw new Error('Sdk is not ready yet or its undefined');
    if (note.position === undefined) {
      return of(undefined);
    }
    if (
      (this._sceneNotes !== null && this._sceneNotes[note.id]) ||
      !note.id == undefined
    ) {
      console.warn(
        'Such note already exists or no apiId provided, No acction taked'
      );
      return of(undefined);
    }
    this._sceneNotes[note.id] = '';
    return from(
      this.sdk.Mattertag.add({
        label: note.label,
        anchorPosition: {
          x: note.position.x,
          y: note.position.y,
          z: note.position.z,
        },
        // iconId: note.noteType,
        stemVector: {
          // make the Mattertag stick straight up and make it 0.30 meters (~1 foot) tall
          x: 0,
          y: 0,
          z: 0,
        },
        color: {
          // blue disc
          r: 0.0,
          g: 0.0,
          b: 1.0,
        },
        floorIndex: 0, // optional, if not specified the sdk will provide an estimate of the floor index for the anchor position provided.
      })
    ).pipe(
      take(1),
      map((createdTags: string[]) => {
        const createdTagId = createdTags[0];
        this._sceneNotes[note.id] = createdTagId;
        return createdTagId;
      }),
      mergeMap((createdTagId: string) =>
        note.label !== undefined
          ? from(
              this.sdk?.Mattertag.preventAction(createdTagId, {
                opening: true,
                navigating: true,
              })
            ).pipe(map(() => createdTagId))
          : from(
              this.sdk?.Mattertag.preventAction(createdTagId, {
                navigating: true,
                opening: true,
              })
            ).pipe(map(() => createdTagId))
      ),
      mergeMap((createdTagId: string) =>
        this.sdk?.Mattertag.editIcon(createdTagId, note.noteType)
      ),
      mapTo(undefined),
      catchError((e: any) => {
        delete this._sceneNotes[note.id];
        console.log('Add matterport tag throw error', e);
        return of(undefined);
      })
    );
  }

  editIcon(noteId: string, noteType: TagNoteTypes): Observable<void> {
    const createdTagId = this._sceneNotes[noteId];
    return from(this.sdk.Mattertag.editIcon(createdTagId, noteType));
  }
  removeNote$(noteId: string): Observable<void | string[]> {
    const localId = this._sceneNotes[noteId];
    if (localId !== undefined) {
      try {
        delete this._sceneNotes[noteId];
        return from(this.sdk.Mattertag.remove(localId));
      } catch (e) {
        console.log('REMOVE NOTE EXCEPTIONS');
        throw new Error('REMOVE NOTE EXCEPTIONS');
      }
    } else {
      return of(undefined);
    }
  }
  clearAllTagNotes() {
    try {
      if (this._sceneNotes !== null) {
        const sceneTags = Object.keys(this._sceneNotes);

        for (let i = 0; i < sceneTags.length; i++) {
          this.removeNote$(sceneTags[i]).pipe(take(1)).subscribe();
        }
      }
    } catch (e) {
      console.log('Unexpected error in clear all tags', e);
    }
  }
  private _getKeyByValue(value: string): string | undefined {
    if (this._sceneNotes) {
      return Object.keys(this._sceneNotes).find(
        (key: string) => this._sceneNotes[key] === value
      );
    }
    return undefined;
  }
  private _onMatterportTagClick = (mpTagId: string) => {
    this._tagClicked.next(mpTagId);
  };

  startPlacingComponent$(placingConfig: StartPlacingConfig): Observable<void> {
    if (!this._addingTag && !this._tagId) {
      if (placingConfig.mobile) {
        this._mobileTouchObserver(
          this.matterportManager.componentRef.instance.progress.nativeElement
        );
      }
      if (
        (this._sceneNotes !== null &&
          this._sceneNotes[placingConfig.note.id]) ||
        !placingConfig.note.id == undefined
      ) {
        console.error(
          'Such note already exists or no apiId provided, No action taken'
        );
        return of(undefined);
      }
      this._clickEmitterStart(
        placingConfig.mobile ? false : placingConfig.autoFinishByClick
      );
      this._tagId = placingConfig.note.id;
      return this.matterportPositionController.setSweepsActive$(false).pipe(
        switchMap(
          () =>
            this.matterportManager.component.addComponent$({
              id: placingConfig.note.id,
              position: new Vector3(0, 0, 0),
              normal: new Vector3(0, 0, 0),
              stemHeight: 0,
              scale: new Vector3(0.14, 0.14, 0.14),
              objects: [
                new SpriteConfiguration({
                  icon: ICONS[placingConfig.note.noteType],
                }),
              ],
            }) as Observable<MatterportComponent>
        ),
        tap((createdComponent) => {
          this._placedComponent = createdComponent;
          this._addingTag = true;
          this._currentTag = {
            normal: new Vector3(0, 0, 0),
            position: new Vector3(0, 0, 0),
          };
          this._currentTagNote = placingConfig.note;
        }),
        catchError((e: any) => {
          console.log(e);
          this._addingTag = false;
          this._clickSub?.unsubscribe();
          this._pointerSub?.unsubscribe();
          return of(e);
        }),
        mapTo(undefined)
      );
    } else {
      return of(undefined);
    }
  }

  /**
   * @deprecated
   * @param placingConfig
   * @returns
   */
  startPlacement$(placingConfig: StartPlacingConfig): Observable<void> {
    if (!this._addingTag && !this._tagId) {
      if (placingConfig.mobile) {
        this._mobileTouchObserver(
          this.matterportManager.componentRef.instance.progress.nativeElement
        );
      }
      if (
        (this._sceneNotes !== null &&
          this._sceneNotes[placingConfig.note.id]) ||
        !placingConfig.note.id == undefined
      ) {
        console.error(
          'Such note already exists or no apiId provided, No acction taked'
        );
        return of(undefined);
      }
      this._clickEmitterStart(placingConfig.autoFinishByClick);

      return this.matterportPositionController.setSweepsActive$(false).pipe(
        switchMap(() =>
          from(
            this.sdk.Mattertag.add([
              {
                label: placingConfig.note.label,
                anchorPosition: { x: 0, y: 0, z: 0 },
                stemVector: { x: 0, y: 0, z: 0 },
                color: { r: 1, g: 0, b: 0 },
                iconId: placingConfig.note.noteType,
              },
            ])
          )
        ),
        tap((tags: string[]) => {
          this._tagId = tags[0];
          this._addingTag = true;

          this._currentTag = {
            normal: new Vector3(0, 0, 0),
            position: new Vector3(0, 0, 0),
          };
          if (this._sceneNotes) {
            this._sceneNotes[placingConfig.note.id] = this._tagId;
          } else throw 'Scene notes not set';
          this._currentTagNote = placingConfig.note;
        }),
        mergeMap((createdTagsIds: string[]) => {
          const createdTagId = createdTagsIds[0];
          return from(
            this.sdk.Mattertag.preventAction(createdTagId, {
              navigating: true,
              opening: true,
            })
          ).pipe(map(() => createdTagId));
        }),
        catchError((e: any) => {
          console.log(e);
          this._addingTag = false;
          this._clickSub?.unsubscribe();
          this._pointerSub?.unsubscribe();
          return of(e);
        }),
        mapTo(undefined)
      );
    } else {
      return of(undefined);
    }
  }

  /**
   * @deprecated
   * @returns
   */
  finishPlaceTag$(): Observable<TagNote> {
    if (this._tagId && this._addingTag) {
      const resultNote: TagNote = {
        id: this._currentTagNote?.id,
        position: {
          x: this._currentTag?.position.x,
          y: this._currentTag?.position.y,
          z: this._currentTag?.position.z,
        },
        noteType: this._currentTagNote?.noteType,
      } as TagNote;
      this._tagId = undefined;
      this._addingTag = false;
      this._currentTagNote = null;
      this._currentTag = null;

      return this.matterportSceneStateAccess.positionChange$.pipe(
        switchMap((currentPose: MpSdk.Camera.Pose | null) => {
          return this.matterportPositionController
            .setSweepsActive$(true)
            .pipe(mapTo(currentPose));
        }),
        take(1),
        tap(
          () => (
            this._touchObserverDestroy.next(), this._pointerSub?.unsubscribe()
          )
        ),
        take(1),
        catchError((e) => {
          console.log(e);
          return of(resultNote);
        }),
        mapTo(resultNote)
      );
    }

    return throwError(() => new Error(' App is not in placing mode!'));
  }

  private _finishPlaceTag(): TagNote {
    if (this._tagId && this._addingTag) {
      const resultNote: TagNote = {
        id: this._currentTagNote?.id,
        position: { ...this._currentTag?.position },
        normal: { ...this._currentTag?.normal },
        noteType: this._currentTagNote?.noteType,
      } as TagNote;
      this.tagPlacementAccepted$.next({
        tagNote: resultNote,
        comp: this._placedComponent,
      });
      this._placedComponent = undefined;
      this._addingTag = false;
      this._currentTagNote = null;
      this._currentTag = null;
      this._tagId = undefined;
      this._clickSub?.unsubscribe();
      this._pointerSub?.unsubscribe();
      this._touchObserverDestroy.next();
      setTimeout(
        () => this.matterportPositionController.setSweepsActive(true),
        500
      );
    }

    throw new Error(' App is not in placing mode!');
  }
  /**
   * @deprecated
   */
  public abandonPlacing(): void {
    if (this._tagId && this._addingTag) {
      this.sdk.Mattertag.remove(this._tagId);
      if (this._currentTagNote !== null)
        this.removeNote$(this._currentTagNote.id).pipe(take(1)).subscribe();
      this._currentTagNote = null;
      this._tagId = undefined;
      this._placedComponent = undefined;
      this._addingTag = false;

      this._currentTag = null;
      this._clickSub?.unsubscribe();
      this._pointerSub?.unsubscribe();
      this._touchObserverDestroy.next();
    }
    this.matterportPositionController.setSweepsActive(true);
  }

  public abandonPlacingComponent(): void {
    if (this._tagId && this._addingTag) {
      this.matterportManager.component.deleteNote(this._tagId);
      this._currentTagNote = null;
      this._tagId = undefined;
      this._placedComponent = undefined;
      this._addingTag = false;

      this._currentTag = null;
      this._clickSub?.unsubscribe();
      this._pointerSub?.unsubscribe();
      this._touchObserverDestroy.next();
    }
    this.matterportPositionController.setSweepsActive(true);
  }

  private _mobileTouchObserver(progress: HTMLElement) {
    const el = (
      document.getElementById('matterport') as HTMLIFrameElement
    )?.contentWindow?.document.getElementsByTagName('canvas')[0];

    // const progress = this.componentRef.instance.progress.nativeElement;
    const mouseUp$ = fromEvent(el as HTMLCanvasElement, 'touchend').pipe(
      tap(() => {
        this._renderer.setStyle(progress, 'display', 'none');
      })
    );
    const mouseMove$ = fromEvent(el as HTMLCanvasElement, 'touchmove').pipe(
      tap(() => {
        this._renderer.setStyle(progress, 'display', 'none');
      })
    );
    const mouseDown$ = fromEvent(el as HTMLCanvasElement, 'touchstart').pipe(
      switchMap((e: Event) =>
        timer(200).pipe(
          takeUntil(mouseUp$ || mouseMove$),
          mergeMap(() => of(e))
        )
      ),
      tap((e: Event) => {
        this._renderer.setStyle(progress, 'display', 'flex');
        this._renderer.setStyle(
          progress,
          'top',
          `${(e as TouchEvent).changedTouches[0].clientY - 120}px`
        );
        this._renderer.setStyle(
          progress,
          'left',
          `${(e as TouchEvent).changedTouches[0].clientX - 50}px`
        );
      })
    );
    mouseDown$
      .pipe(
        switchMap((down: Event) =>
          timer(1000).pipe(
            takeUntil(mouseUp$ || mouseMove$),
            mergeMap(() => of(down))
          )
        ),
        tap(() => {
          this._renderer.setStyle(progress, 'display', 'none');
        }),
        takeUntil(this._touchObserverDestroy)
      )
      .subscribe(() => {
        this.matterportEvents.emitValue();
      });
  }
  private _clickEmitterStart(finishOnMouseClick = true): void {
    this._pointerSub?.unsubscribe();
    this.ngZone.runOutsideAngular(() => {
      this._pointerSub = this.matterportEvents.pointer$.subscribe(
        (collisionPoint: Pointer | undefined) => {
          if (!!collisionPoint && this._tagId && this._addingTag) {
            if (
              [Colliders.MODEL, Colliders.SWEEP].includes(collisionPoint.object)
            ) {
              const scale = 0.05;
              const newNorm = collisionPoint.normal || new Vector3(0, 1, 0);
              const stem = {
                x: scale * newNorm.x,
                y: scale * newNorm.y,
                z: scale * newNorm.z,
              };
              this._currentTag = {
                position: new Vector3(
                  collisionPoint.position.x + stem.x,
                  collisionPoint.position.y + stem.y,
                  collisionPoint.position.z + stem.z
                ),
                normal: newNorm as Vector3,
              };
              if (this._placedComponent) {
                this._placedComponent.comp.position = {
                  ...this._currentTag.position,
                } as Vector3;
              }
            }
          }
        }
      );
    });

    this._clickSub?.unsubscribe();
    this._clickSub = merge(
      this.matterportEvents.matterportClick$.pipe(
        filter(() => finishOnMouseClick)
      ),
      this.matterportEvents.matterportHold$
    )
      .pipe(
        filter((pointer: Pointer | null) => {
          return !!pointer && this._tagId !== undefined && this._addingTag;
        }),
        filter((pointer: Pointer | null) => {
          return (
            !!pointer &&
            [Colliders.TAG, Colliders.MODEL, Colliders.SWEEP].includes(
              pointer.object
            )
          );
        }),
        take(1),
        tap(() => this._finishPlaceTag())
      )
      .subscribe();
  }

  loadMattertags$(tags: Mattertag[]) {
    const parsedMattertags: MpSdk.Mattertag.MattertagDescriptor[] = tags.map(
      (tag) => new MatterportModelTag(tag)
    );
    Object.keys(this._sceneNotes).forEach((key) => {
      this.sdk.Mattertag.remove(this._sceneNotes[key]);
      delete this._sceneNotes[key];
    });
    return this.isOpen.asObservable().pipe(
      filter((isOpen) => isOpen),
      switchMap(() =>
        from(this.sdk.Mattertag.add(parsedMattertags)).pipe(
          tap((tagsIds: string[]) =>
            tags.forEach((tag, idx) => {
              this._sceneNotes[tag.id] = tagsIds[idx];
            })
          )
        )
      )
    );
  }

  /**
   * @deprecated
   * @returns
   */
  acceptPlacingPosition$(): Observable<TagNote> {
    return this.finishPlaceTag$().pipe(
      tap(() => this._clickSub?.unsubscribe()),
      take(1)
    );
  }
  /**
   * @deprecated
   * @returns
   */
  acceptPlacingPositionAndReplaceWithComponent$(): Observable<TagNote> {
    return this.acceptPlacingPosition$().pipe(
      switchMap((value: TagNote) =>
        forkJoin([
          // this.matterportManager.component.addComponent$(value),
          this.removeNote$(value.id),
        ]).pipe(map(() => value))
      )
    );
  }

  acceptPlacingPosition(): void {
    this._finishPlaceTag();
  }

  /**
   *
   * @deprecated
   * @param newPos
   * @param newNorm
   * @param scale
   * @returns
   */
  private _updateTagPos(
    newPos: Vector3,
    newNorm: Vector3 | undefined = undefined,
    scale: number | undefined = undefined
  ) {
    if (!newPos) return;
    if (!scale) scale = 0.05;
    if (!newNorm) newNorm = new Vector3(0, 1, 0); // { x: 0, y: 1, z: 0 };
    const stem = {
      x: scale * newNorm.x,
      y: scale * newNorm.y,
      z: scale * newNorm.z,
    };
    this._currentTag = {
      position: new Vector3(
        newPos.x + stem.x,
        newPos.y + stem.y,
        newPos.z + stem.z
      ),
      normal: newNorm,
    };

    if (this._tagId !== undefined)
      this.sdk?.Mattertag.editPosition(this._tagId, {
        anchorPosition: newPos,
        stemVector: {
          x: scale * newNorm.x,
          y: scale * newNorm.y,
          z: scale * newNorm.z,
        },
      }).catch((e: any) => {
        console.error(e);
        this._placedComponent = undefined;
        this._addingTag = false;
      });
  }
}
