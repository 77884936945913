<span class="design-expansion-container">
  <ng-content select="design-checkbox"> </ng-content>
</span>
<span class="design-panel-header-label" (click)="emitClick.next()">
  <ng-content select="[designLabel]"> </ng-content>
</span>

@if (displayToggleButton()) {
  <button design-icon-button (click)="openCloseHeader()">
    <i
      [class]="expanded ? 'rotate-270' : 'rotate-90'"
      designIcon="arrow_big"
    ></i>
  </button>
}
