import { InjectionToken } from '@angular/core';
import { DesignRadioAccordion } from '../components/radio-expansion-panel/radio-accordion.directive';
import { DesignAccordionBase } from '../modules/accordion-base.interface';

export const designRadioAccordionToken =
  new InjectionToken<DesignRadioAccordion>('designRadioAccordionToken');

/**
 * Token used to provide a `DesignRadioAccordion` to `DesignRadioExpansionPanel`.
 * Used primarily to avoid circular imports between `DesignRadioAccordion` and `DesignRadioExpansionPanel`.
 */
export const designRadioAccordionBaseToken =
  new InjectionToken<DesignAccordionBase>('designRadioAccordionToken');
