<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i ui-icon name="icon-warning-red" defaultColor customSize="7.25rem"></i>
      <h2 designLabel i18n="@@WARNING">Warning</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    {{ data.text }}
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="cancel()"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="error"
      data-cy="modal-warning-confirm-button"
      (click)="confirm()"
    >
      <ng-container *ngIf="data.buttonText; else Confirm">
        {{ data.buttonText }}
      </ng-container>
      <ng-template #Confirm i18n="@@CONFIRM">Confirm</ng-template>
    </button>
  </ng-container>
</design-dialog-wrapper>
