import { FormControl } from '@angular/forms';

export class FilterChange {
  constructor(
    readonly filter: string | undefined | null,
    readonly sortBy: SortOption | undefined | null
  ) {}
}

export type FilterForm = {
  [Property in keyof FilterChange]: FormControl<FilterChange[Property]>;
};

export type SortOption = {
  display: string;
  propertyName: string;
  direction: 'asc' | 'desc';
};
export const DEFAULT_SORT_OPTIONS: SortOption[] = [
  {
    display: 'User name A-Z (desc)',
    propertyName: 'userName',
    direction: 'asc'
  },
  {
    display: 'User name Z-A (asc)',
    propertyName: 'userName',
    direction: 'desc'
  },
  { display: 'Name A-Z (desc)', propertyName: 'name', direction: 'asc' },
  { display: 'Name Z-A (asc)', propertyName: 'name', direction: 'desc' }
];
