export interface Comment extends CommentBase {
  rootNoteId: string;
  createdAt: string;
  editorName: string;
  inReplyToComment: string;
  replies: CommentReply[];
}

export interface CommentBase {
  id: string;
  text: string;
  isOwner: boolean;
  authorName: string;
  createdAt: string;
  editedAt: string;
  authorId: string;
  isRemoved: boolean;
}
export interface CommentReply extends CommentBase {
  quoteId: string;
}
