import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { IErrorResponse } from '@simlab/data-access';
import {
  IAddMeasurementToStage,
  TAddDistanceRootNote,
  TAddMeasurementGroup,
  TAddMeasurementGroups,
  TAddMeasurementsToGroup,
  TAssignToRootNote,
  TBaseUpdate,
  TDistance,
  TDistanceBase,
  TDistanceCount,
  TDistanceGroups,
  TRemoveMeasurementFromGroup,
  TStageMeasurmentSimpleGroups,
  TUpdateColor,
  TUpdateData,
  TUpdateGroupName,
  TUpdateTitle
} from '@simlab/feature-measurement/models';

export const DistanceApiActions = createActionGroup({
  source: 'DistanceMeasurement/API',
  events: {
    'Clear Store': emptyProps(),
    'Load Measurement': props<{ id: string }>(),
    'Load Measurement Success': props<TDistanceBase>(),
    'Load Measurement Failure': props<{ error: IErrorResponse }>(),

    'Load Stage Measurements': props<{ stageId: string }>(),
    'Load Stage Measurements Success': props<{
      result: TDistance[];
    }>(),
    'Load Stage Measurements Failure': props<{ error: IErrorResponse }>(),

    'Load Stage Measurement Groups': props<{ stageId: string }>(),
    'Load Stage Measurement Groups Success': props<{
      result: TDistanceGroups[];
    }>(),
    'Load Stage Measurement Groups Failure': props<{ error: IErrorResponse }>(),

    'Load Stage Measurement Simple Groups': props<{ stageId: string }>(),
    'Load Stage Measurement Simple Groups Success': props<{
      result: TStageMeasurmentSimpleGroups[];
    }>(),
    'Load Stage Measurement Simple Groups Failure': props<{
      error: IErrorResponse;
    }>(),

    'Load project measurement count': props<{ projectId: string }>(),
    'Load project measurement count Success': props<TDistanceCount>(),
    'Load project measurement count Failure': props<{
      error: IErrorResponse;
    }>(),

    'Load stage measurement count': props<{ stageId: string }>(),
    'Load stage measurement count Success': props<TDistanceCount>(),
    'Load stage measurement count Failure': props<{ error: IErrorResponse }>(),

    'Export Measurement': props<{ resultFileName: string }>(),
    'Export Measurement Success': emptyProps(),
    'Export Measurement Failure': props<{ error: IErrorResponse }>(),

    'Add Measurement': props<{ props: IAddMeasurementToStage }>(),
    'Add Measurement Success': props<TDistance>(),
    'Add Measurement Failure': props<{ error: IErrorResponse }>(),

    'Add Measurement To Root Note': props<{ props: TAddDistanceRootNote }>(),
    'Add Measurement To Root Note Success': props<TDistance>(),
    'Add Measurement To Root Note Failure': props<{ error: IErrorResponse }>(),

    'Add Measurement Group': props<{ body: TAddMeasurementGroup }>(),
    'Add Measurement Group Success': props<{ value: string }>(),
    'Add Measurement Group Failure': props<{ error: IErrorResponse }>(),

    'Add Measurement To Groups': props<TAddMeasurementGroups>(),
    'Add Measurement To Groups Success': emptyProps(),
    'Add Measurement To Groups Failure': props<{ error: IErrorResponse }>(),

    'Add Measurements To Group': props<TAddMeasurementsToGroup>(),
    'Add Measurements To Group Success': emptyProps(),
    'Add Measurements To Group Failure': props<{ error: IErrorResponse }>(),

    'Assign Measurements To Root Note': props<{
      props: TAssignToRootNote;
    }>(),
    'Assign Measurements To Root Note Success': props<TAssignToRootNote>(),
    'Assign Measurements To Root Note Failure': props<{
      error: IErrorResponse;
    }>(),

    'UnAssign Measurements from Root Note': props<{ props: TBaseUpdate }>(),
    'UnAssign Measurements from Root Note Success': props<TBaseUpdate>(),
    'UnAssign Measurements from Root Note Failure': props<{
      error: IErrorResponse;
    }>(),

    'Update Measurement Title': props<{ props: TUpdateTitle }>(),
    'Update Measurement Title Success': props<TUpdateTitle>(),
    'Update Measurement Title Failure': props<{ error: IErrorResponse }>(),

    'Update Measurement Color': props<{ props: TUpdateColor }>(),
    'Update Measurement Color Success': props<TUpdateColor>(),
    'Update Measurement Color Failure': props<{ error: IErrorResponse }>(),

    'Update Measurement Data': props<TUpdateData>(),
    'Update Measurement Data Success': props<TUpdateData>(),
    'Update Measurement Data Failure': props<{ error: IErrorResponse }>(),

    'Update Measurement GroupName': props<TUpdateGroupName>(),
    'Update Measurement GroupName Success': emptyProps(),
    'Update Measurement GroupName Failure': props<{ error: IErrorResponse }>(),

    'Delete Measurement': props<{ id: string }>(),
    'Delete Measurement Success': props<TBaseUpdate>(),
    'Delete Measurement Failure': props<{ error: IErrorResponse }>(),

    'Delete Measurement From Group': props<TRemoveMeasurementFromGroup>(),
    'Delete Measurement From Group Success': emptyProps(),
    'Delete Measurement From Group Failure': props<{ error: IErrorResponse }>(),

    'Delete Measurement Group': props<{ id: string }>(),
    'Delete Measurement Group Success': emptyProps(),
    'Delete Measurement Group Failure': props<IErrorResponse>(),

    'Select Measurement': props<{ id: string | undefined }>(),
    'Select Measurement Success': props<{ id: string | undefined }>(),
    'Select Measurement Failure': props<{ error: IErrorResponse }>(),

    'Show Measurements Segments': props<{ visibility: boolean }>(),
    'Toggle Measurement Segments Visibility': emptyProps(),
    'Hide All Measurements': props<{ hideAll: boolean }>()
  }
});
