import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { TokensCommand } from '../../models/command/tokens-command.interface';
import { TokensQuery } from '../../models/query/tokens-query.interface';
import { API_URL } from '../../tokens/api-url.token';

@Injectable()
export class TokensService implements TokensCommand, TokensQuery {
      private readonly url = inject<string>(API_URL);
      private readonly httpClient = inject(HttpClient);

  removeMatterportUserToken(): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.url}/users-module/remove-matterport-user-token`
    );
  }
  getMatterportUserToken(): Observable<{
    accessToken: string;
    accessTokenExpirationUtc: string;
  }> {
    return this.httpClient.get<{
      accessToken: string;
      accessTokenExpirationUtc: string;
    }>(`${this.url}/users-module/get-matterport-user-token`);
  }
  addMatterportUserToken(payload: { code: string }): Observable<string> {
    return this.httpClient
      .post<{
        accessToken: string;
      }>(`${this.url}/users-module/add-matterport-user-token`, payload)
      .pipe(map((response: { accessToken: string }) => response.accessToken));
  }
}
