/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OverlayContainer } from '@angular/cdk/overlay';
import { DomPortalOutlet } from '@angular/cdk/portal';
// import { OverlayContainer } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactoryResolver,
  Inject,
  Injectable, inject } from '@angular/core';

import { OverlayRef } from './overlay-ref';

@Injectable()
export class ToastOverlayService {
      private _overlayContainer = inject(OverlayContainer);
      private _componentFactoryResolver = inject(ComponentFactoryResolver);
      private _appRef = inject(ApplicationRef);
      private _document = inject<Document>(DOCUMENT);
  private _paneElements: Record<string, HTMLElement> = {};

  /**
   * Creates an overlay.
   * @returns A reference to the created overlay.
   */
  create(positionClass?: string): OverlayRef {
    return this._createOverlayRef(this.getPaneElement(positionClass));
  }

  getPaneElement(
    positionClass: string = 'toast-container-bottom-right'
  ): HTMLElement {
    if (!this._paneElements[positionClass]) {
      this._paneElements[positionClass] =
        this._createPaneElement(positionClass);
    }

    return this._paneElements![positionClass];
  }

  private _createPaneElement(positionClass: string): HTMLElement {
    const pane = this._document.createElement('div');

    pane.id = positionClass;
    pane.classList.add(positionClass);
    this._overlayContainer.getContainerElement().appendChild(pane);
    return pane;
  }

  private _createPortalHost(pane: HTMLElement): DomPortalOutlet {
    return new DomPortalOutlet(
      pane,
      this._componentFactoryResolver,
      this._appRef
    );
  }

  private _createOverlayRef(pane: HTMLElement): OverlayRef {
    return new OverlayRef(this._createPortalHost(pane));
  }
}
