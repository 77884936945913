import { NgModule } from '@angular/core';
import { UiHelperModule } from '../ui-helper/ui-helper.module';
import { AnchorComponent } from './components/anchor/anchor.component';
import {
  ButtonLoaderComponent,
  UiButtonLoaderModule,
} from './components/button/button-loader.component';
import { ButtonComponent } from './components/button/button.component';
import { DirectionDirective } from './directives/direction.directive';
import { FireOnClickDirective } from './directives/fire-on-click.directive';

@NgModule({
  declarations: [
    ButtonComponent,
    AnchorComponent,
    FireOnClickDirective,
    DirectionDirective,
  ],
  imports: [UiHelperModule, UiButtonLoaderModule],
  exports: [
    ButtonComponent,
    AnchorComponent,
    ButtonLoaderComponent,
    FireOnClickDirective,
    DirectionDirective,
  ],
})
export class UiButtonModule {}
