import { InvoiceAddress } from './invoice-address.interface';

export enum ChangeUserPaymentPlanStatus {
  Undefined = 0,
  Success = 1,
  Error = 2,
  CriticalError = 3,
  WaitingForPayment = 4,
  InvalidTokenError = 5,
  SuccessNoPayment = 6,
}

export interface IChangeSubscriptionPlan {
  organizationId: string;
  planDefinitionId: string;
  promoCodesTexts: string[];
  address: InvoiceAddress;
  numberOfSeats?: number;
  numberOfLicenses?: number;
}

export interface ChangedSubscriptionPlan {
  status: ChangeUserPaymentPlanStatus;
  paymentId: string;
}

export interface PaymentStatus {
  status: PayUTransactionStatus;
  notificationEmail: string;
  nextPaymentDate: Date;
}

export enum PayUTransactionStatus {
  Pending = 0,
  Success = 1,
  Failure = 2,
}
