import {
  TAreaMeasurementBase,
  TAreaMeasurementData
} from './area-measurement.interface';

export type Titems = {
  surfaceSize: number;
};

export type TAddAreaToStage = {
  stageId: string;
} & TAreaMeasurementBase;

export type TAddAreaToRootNote = {
  rootNoteId: string;
} & TAreaMeasurementBase;

export type TUpdateArea = {
  id: string;
  data: TAreaMeasurementData;
};
