import { CdkPortalOutlet, TemplatePortal } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  HostBinding,
  OnInit,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
  input,
  viewChild,
} from '@angular/core';

@Component({
  selector: 'design-tab-body',
  templateUrl: './tab-body.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabBodyComponent extends CdkPortalOutlet implements OnInit {
  @HostBinding('class.tab-body') class = true;

  readonly portalHost = viewChild(CdkPortalOutlet);
  readonly content = input<TemplatePortal>();
  constructor() {
    const _document = inject<any>(DOCUMENT);
    const viewContainerRef = inject(ViewContainerRef);
    const componentFactoryResolver = inject(ComponentFactoryResolver);
    super(componentFactoryResolver, viewContainerRef, _document);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.attach(this.content());
  }
}
