import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChild,
  ContentChildren,
  Directive,
  ElementRef,
  OnDestroy,
  QueryList, inject
} from '@angular/core';
import {
  DesignLabel,
  DesignPrefix,
  DesignSuffix,
  designLabelToken,
  designPrefixToken,
  designSuffixToken,
} from '@simlab/design/common';
import { Subject, merge, takeUntil, tap } from 'rxjs';
import { designFormFieldHintToken } from '../tokens/hint.token';
import { DesignFormFieldControl } from './form-field-control.directive';
import { DesignFormFieldHint } from './hint.directive';

declare const ngDevMode: boolean;
let nextUniqueId = 0;
const _FormFieldBase = class { };

@Directive()
export abstract class FormFieldBase
  extends _FormFieldBase
  implements AfterContentInit, OnDestroy {
  public readonly elementRef = inject(ElementRef);
  private readonly _changeDetectorRef = inject(ChangeDetectorRef);
  // Unique id for the internal form field label.
  readonly _labelId = `design-form-field-label-${nextUniqueId++}`;

  protected readonly _destroyed = new Subject<void>();

  @ContentChild(designLabelToken)
  protected _labelChildNonStatic: DesignLabel | undefined;

  @ContentChild(designLabelToken, { static: true })
  protected _labelChildStatic: DesignLabel | undefined;

  @ContentChildren(designPrefixToken, { descendants: true })
  protected _prefixChildren!: QueryList<DesignPrefix>;

  @ContentChildren(designSuffixToken, { descendants: true })
  protected _suffixChildren!: QueryList<DesignSuffix>;

  @ContentChildren(designSuffixToken, { descendants: true })
  protected _labelChildren!: QueryList<DesignSuffix>;

  @ContentChild(DesignFormFieldControl)
  protected _formFieldControl!: DesignFormFieldControl<any>;

  @ContentChildren(designFormFieldHintToken, { descendants: true })
  protected _hintChildren!: QueryList<DesignFormFieldHint>;

  private _isFocused: boolean | null = null;

  /** Gets the current form field control */
  get control(): DesignFormFieldControl<any> {
    return this._explicitFormFieldControl || this._formFieldControl;
  }
  set control(value) {
    this._explicitFormFieldControl = value;
  }

  private _explicitFormFieldControl: DesignFormFieldControl<any> | undefined;

  protected _hasIconPrefix = false;
  protected _hasTextPrefix = false;
  protected _hasIconSuffix = false;
  protected _hasTextSuffix = false;
  protected _hasLabel = false;

  ngAfterContentInit(): void {
    this._initializePrefixAndSuffix();
    this._initializeSubscript();
    this._initializeControl();
  }

  ngOnDestroy(): void {
    this._destroyed.next();
    this._destroyed.complete();
  }

  private _initializePrefixAndSuffix(): void {
    this._checkPrefixAndSuffixTypes();

    // Mark the form field as dirty whenever the prefix or suffix children change. This
    // is necessary because we conditionally display the prefix/suffix containers based
    // on whether there is projected content.
    merge(this._prefixChildren.changes, this._suffixChildren.changes)
      .pipe(
        takeUntil(this._destroyed),
        tap(() => {
          this._checkPrefixAndSuffixTypes();
          this._changeDetectorRef.markForCheck();
        })
      )
      .subscribe();
  }

  private _checkPrefixAndSuffixTypes() {
    this._hasIconPrefix = !!this._prefixChildren.find((p) => !p.isText);
    this._hasTextPrefix = !!this._prefixChildren.find((p) => p.isText);
    this._hasIconSuffix = !!this._suffixChildren.find((s) => !s.isText);
    this._hasTextSuffix = !!this._suffixChildren.find((s) => s.isText);
  }

  /** Initializes the registered form field control. */
  private _initializeControl(): void {
    const control = this.control;

    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges.subscribe(() => {
      this._changeDetectorRef.markForCheck();
    });

    // Run change detection if the value changes.
    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges
        .pipe(takeUntil(this._destroyed))
        .subscribe(() => this._changeDetectorRef.markForCheck());
    }
  }

  private _initializeSubscript(): void {
    this._hintChildren.changes
      .pipe(
        takeUntil(this._destroyed),
        tap(() => {
          this._validateHints();
          this._changeDetectorRef.markForCheck();
        })
      )
      .subscribe();

    // this._errorChildren.changes
    //   .pipe(
    //     takeUntil(this._destroyed),
    //     tap(() => {
    //       this._validateErrors();
    //       this._changeDetectorRef.markForCheck();
    //     })
    //   )
    //   .subscribe();

    // this._validateErrors();
    this._validateHints();
  }

  private _validateHints(): void {
    if (
      this._hintChildren &&
      this._hintChildren.length > 1 &&
      (typeof ngDevMode === 'undefined' || ngDevMode)
    ) {
      throw new Error('Only one hint element is allowed!');
    }
  }

  // private _validateErrors(): void {
  //   if (
  //     this._errorChildren &&
  //     this._errorChildren.length > 1 &&
  //     (typeof ngDevMode === 'undefined' || ngDevMode)
  //   ) {
  //     throw new Error('Only one error element is allowed!');
  //   }
  // }

  /** Determines whether to display hints or errors. */
  getDisplayedMessages(): 'error' | 'hint' {
    return /*this._errorChildren && this._errorChildren.length > 0 && */ this
      .control.errorState
      ? 'error'
      : 'hint';
  }

  /**
   * Gets the id of the label element. If no label is present, returns `null`.
   */
  getLabelId(): string | null {
    return this._hasLabel ? this._labelId : null;
  }
}
