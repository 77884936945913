import { Directive } from '@angular/core';
import { AbstractControlDirective, NgControl } from '@angular/forms';
import { Observable } from 'rxjs';

/** An interface which allows a control to work inside of a `DesignFormField`. */
@Directive()
export abstract class DesignFormFieldControl<T> {
  /** The value of the control. */
  value!: T | null;

  /** Gets the AbstractControlDirective for this control. */
  readonly ngControl!: NgControl | AbstractControlDirective | null;

  /**
   * Stream that emits whenever the state of the control changes such that the parent `DesignFormField`
   * needs to run change detection.
   */
  readonly stateChanges!: Observable<void>;

  /** Whether the control is in an error state. */
  readonly errorState!: boolean;

  /** Whether the control is required. */
  readonly required!: boolean;

  /** Whether the control is disabled. */
  readonly disabled!: boolean;

  /** The placeholder for this control. */
  readonly placeholder!: string;

  /** Whether the control is focused. */
  readonly focused!: boolean;

  /** The element ID for this control. */
  readonly id!: string;

  /** Handles a click on the control's container. */
  abstract onContainerClick(event: MouseEvent): void;
}
