<ng-container *ngIf="type === 'normal'; else customize">
<input
  #input
  type="checkbox"
  role="checkbox"
  (change)="emitChangeEvent($event)"
  (touch)="onTouched()"
  readonly="{{ readonly || null }}"
  [disabled]="disabled"
  [id]="id"
/>
</ng-container>


<ng-template #customize>
    <div class="ui-checkbox-customize">
      <input   #input
      type="checkbox"
      role="checkbox"
      (change)="emitChangeEvent($event)"
      (touch)="onTouched()"
      readonly="{{ readonly || null }}"
      [disabled]="disabled"
      [id]="id"
    />
      <label for="checkbox"></label>
    </div>
  </ng-template>
  <ng-content select="label"></ng-content>