export interface UploadUrl {
  origin: string;
  sas: string;
  containerName: string;
  blobName: string;
}
export type TransferProgressEvent = {
  /**
   * The number of bytes loaded so far.
   */
  loadedBytes: number;
};
