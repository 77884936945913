<ng-template>
  <div
    class="menu-panel-wrapper"
    tabindex="-1"
    role="menu"
    (click)="closed.emit('click')"
  >
    <div
      class="menu-panel-content"
      [ngClass]="customPanelClass()"
      [class.login-menu-class]="uiLoginMenu"
    >
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
