import {
  animate,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const animation = [
  trigger('animation', [
    transition('* => *', [
      query(':enter', style({ transform: '{{transform}}' }), {
        optional: true,
      }),

      query(
        ':enter',
        animate(
          '500ms ease',
          style({
            transform: 'translate(0, 0)',
            opacity: 1,
          }),
        ),
        { optional: true },
      ),

      query(
        ':leave',
        animate(
          '200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({
            opacity: 0,
            transform: '{{transform}}',
          }),
        ),
        { optional: true },
      ),
    ]),
  ]),
];
