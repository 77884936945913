import { AnimationEvent } from '@angular/animations';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostBinding,
  OnInit,
  inject,
} from '@angular/core';
import { ToastConfig } from '../models/toast-config';

@Directive()
export abstract class ToastContainerBase implements OnInit, AfterViewInit {
  private readonly _elementRef = inject(ElementRef);
  private readonly _toastConfig = inject(ToastConfig);
  private readonly _cdr = inject(ChangeDetectorRef);
  private _timer: NodeJS.Timeout | null = null;

  @HostBinding('class')
  get elementClass(): string {
    return `design-toast-${this._toastConfig.toastType.toLocaleLowerCase()}`;
  }

  @HostBinding('@toastAnimation')
  state = {
    value: 'inactive',
    params: {
      easeTime: this._toastConfig.easeTime,
      easing: this._toastConfig.easing,
    },
  };

  ngOnInit(): void {
    this._initToast();
  }

  ngAfterViewInit(): void {
    this.state = { ...this.state, value: 'active' };
  }

  captureDoneEvent(event: AnimationEvent): void {
    if (event.toState === 'removed') {
      this._elementRef.nativeElement.remove();
    }
  }

  protected mouseOver() {
    this._timer && clearTimeout(this._timer);
  }

  protected mouseOut() {
    this._initToast();
  }

  protected _initToast(): void {
    this._timer = setTimeout(() => {
      this.close();
    }, this._toastConfig.timeOut);
  }

  close() {
    this._timer = null;
    this.state = { ...this.state, value: 'removed' };
    this._cdr.markForCheck();
  }
}
