import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { AreaActions } from './area.actions';
import {
  IErrorResponse,
  TAreaMeasurement
} from '@simlab/feature-measurement/models';

export const AREA_MEASUREMENT_FEATURE_KEY = 'areaMeasurement';

export interface AreaMeasurementState extends EntityState<TAreaMeasurement> {
  selectedId?: string | number; // which AreaMeasurement record has been selected
  loaded: boolean; // has the AreaMeasurement list been loaded
  loading: boolean; // has the AreaMeasurement list been loaded
  hideAll: boolean;
  segmentsVisible: boolean;
  error?: IErrorResponse | null; // last known error (if any)
}

export interface AreaMeasurementPartialState {
  readonly [AREA_MEASUREMENT_FEATURE_KEY]: AreaMeasurementState;
}

export const areaMeasurementAdapter: EntityAdapter<TAreaMeasurement> =
  createEntityAdapter<TAreaMeasurement>();

export const initialAreaMeasurementState: AreaMeasurementState =
  areaMeasurementAdapter.getInitialState({
    // set initial required properties
    loaded: false,
    loading: false,
    hideAll: false,
    segmentsVisible: false
  });

const reducer = createReducer(
  initialAreaMeasurementState,
  on(AreaActions.init, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.clearStore, (state) => ({
    ...areaMeasurementAdapter.removeAll(state),
    loaded: false,
    loading: false,
    selectedId: undefined,
    segmentsVisible: false,
    hideAll: false,
    error: null
  })),

  on(AreaActions.loadStageMeasurements, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.loadStageMeasurementsSuccess, (state, { measurements }) =>
    areaMeasurementAdapter.setAll(measurements, {
      ...state,
      loaded: true,
      loading: false
    })
  ),
  on(AreaActions.loadStageMeasurementsFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),

  on(AreaActions.loadMeasurementSuccess, (state, { areaMeasurement }) =>
    areaMeasurementAdapter.setAll(areaMeasurement, {
      ...state,
      loaded: true,
      loading: false
    })
  ),
  on(AreaActions.loadMeasurementFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AreaActions.addMeasurement, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.addMeasurementSuccess, (state, areaMeasurement) => {
    return areaMeasurementAdapter.addOne(areaMeasurement, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(AreaActions.addMeasurementFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AreaActions.addMeasurementToRootNote, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.addMeasurementToRootNoteSuccess, (state, areaMeasurement) => {
    return areaMeasurementAdapter.addOne(areaMeasurement, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(AreaActions.addMeasurementToRootNoteFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AreaActions.attachMeasurementToNote, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(
    AreaActions.attachMeasurementToNoteSuccess,
    (state, { id, rootNoteId }) => {
      const update: Update<TAreaMeasurement> = {
        id,
        changes: {
          rootNoteId
        }
      };
      return areaMeasurementAdapter.updateOne(update, {
        ...state,
        loaded: true,
        loading: false
      });
    }
  ),
  on(AreaActions.attachMeasurementToNoteFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AreaActions.unassignMeasurementFromNote, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.unassignMeasurementFromNoteSuccess, (state, { id }) => {
    const update: Update<TAreaMeasurement> = {
      id,
      changes: {
        rootNoteId: undefined
      }
    };
    return areaMeasurementAdapter.updateOne(update, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(AreaActions.unassignMeasurementFromNoteFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AreaActions.deleteMeasurement, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.deleteMeasurementSuccess, (state, { id }) => {
    return areaMeasurementAdapter.removeOne(id, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(AreaActions.deleteMeasurementFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AreaActions.updateMeasurementColor, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.updateMeasurementColorSuccess, (state, { id, color }) => {
    const updateData: Update<TAreaMeasurement> = {
      id,
      changes: {
        color
      }
    };
    return areaMeasurementAdapter.updateOne(updateData, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(AreaActions.updateMeasurementColorFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AreaActions.updateMeasurementTitle, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.updateMeasurementTitleSuccess, (state, { id, title }) => {
    const updateData: Update<TAreaMeasurement> = {
      id,
      changes: {
        title
      }
    };
    return areaMeasurementAdapter.updateOne(updateData, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(AreaActions.updateMeasurementTitleFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AreaActions.updateMeasurementData, (state) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.hideAllMeasurements, (state, { hideAll }) => ({
    ...state,
    hideAll
  })),
  on(AreaActions.showMeasurementsSegments, (state, { visibility }) => ({
    ...state,
    segmentsVisible: visibility
  })),
  on(AreaActions.toggleMeasurementSegmentsVisibility, (state) => {
    return {
      ...state,
      segmentsVisible: !state.segmentsVisible
    };
  }),
  on(AreaActions.updateMeasurementDataSuccess, (state, { id, data }) => {
    const updateData: Update<TAreaMeasurement> = {
      id,
      changes: {
        data
      }
    };
    return areaMeasurementAdapter.updateOne(updateData, {
      ...state,
      loaded: true,
      loading: false
    });
  }),
  on(AreaActions.assignedNoteHasBeenDeleted, (state, action) => {
    const updateArea: Update<TAreaMeasurement> = {
      id: action.id,
      changes: {
        rootNoteId: undefined
      }
    };
    return areaMeasurementAdapter.updateOne(updateArea, { ...state });
  }),
  on(AreaActions.updateMeasurementDataFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  })),
  on(AreaActions.selectMeasurement, (state, { id }) => ({
    ...state,
    selectedId: id,
    loaded: false,
    loading: true,
    error: null
  })),
  on(AreaActions.selectMeasurementSuccess, (state, { id }) => {
    return {
      ...state,
      selectedId: id,
      segmentsVisible: false,
      loaded: true,
      loading: false
    };
  }),
  on(AreaActions.selectMeasurementFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,
    error
  }))
);

export function areaMeasurementReducer(
  state: AreaMeasurementState | undefined,
  action: Action
) {
  return reducer(state, action);
}
