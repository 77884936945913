import { UserPreferenceFacade } from '@simlab/data-store';
import { FormatDateConfig } from '@simlab/design/format-date';
import { Observable } from 'rxjs';

export function loadFormatData(
  userPreferenceFacade: UserPreferenceFacade
): FormatDateConfig {
  return {
    observable: userPreferenceFacade.getDateFormat$,
    signal: userPreferenceFacade.getDateFormat
  };
}

export function loadFormatDataToDataPicker(
  userPreferenceFacade: UserPreferenceFacade
): Observable<string> {
  return userPreferenceFacade.getDateFormat$;
}
