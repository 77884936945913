import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AreaCommand,
  AreaQuery,
  IAddMeasurementToStage,
  MeasurementCommand,
  MeasurementQuery,
  TAddAreaToRootNote,
  TAddMeasurementGroup,
  TAddMeasurementGroups,
  TAddMeasurementsToGroup,
  TAreaMeasurement,
  TAreaMeasurementGroup,
  TAssignToRootNote,
  TBaseMeasurement,
  TDistanceCount,
  TRemoveFromGroup,
  TSimpleMeasurement,
  TStageMeasurmentSimpleGroups,
  TUpdateArea,
  TUpdateColor,
  TUpdateGroupName,
  TUpdateTitle
} from '@simlab/feature-measurement/models';
import { Observable } from 'rxjs';
import { ApiBase } from './base-api.service';

@Injectable()
export class ApiArea
  extends ApiBase
  implements MeasurementCommand, AreaCommand, MeasurementQuery, AreaQuery
{
  getStageMeasurementGroups = (
    stageId: string
  ): Observable<TAreaMeasurementGroup[]> =>
    this.get<TAreaMeasurementGroup[]>(
      `get-stage-area-measurement-groups?stageId=${stageId}`
    );

  getStageMeasurements = (stageId: string): Observable<TAreaMeasurement[]> =>
    this.get<TAreaMeasurement[]>(
      `get-stage-area-measurements?stageId=${stageId}`
    );

  getMeasurement = (id: string): Observable<TSimpleMeasurement> =>
    this.get<TSimpleMeasurement>(`get-area-measurement?id=${id}`);

  getStageMeasurementSimpleGroups = (
    stageId: string
  ): Observable<TStageMeasurmentSimpleGroups[]> =>
    this.get<TStageMeasurmentSimpleGroups[]>(
      `get-stage-area-measurement-simple-groups?stageId=${stageId}`
    );

  exportStageMeasurements = (
    resultFileName: string,
    ids: string[] = []
  ): Observable<HttpResponse<Blob>> => {
    const headers = new HttpHeaders({
      Accept: 'text/csv'
    });
    return this.get<HttpResponse<Blob>>(
      `export-area-measurements?resultFileName=${resultFileName}&Ids=${ids.join(
        '&Ids='
      )}`,
      {
        observe: 'response',
        responseType: 'blob' as 'json',
        headers: headers
      }
    );
  };

  getProjectMeasurementsCount = (
    projectId: string
  ): Observable<TDistanceCount> =>
    this.get<TDistanceCount>(
      `get-project-area-measurements-count?projectId=${projectId}`
    );

  getStageMeasurementsCount = (stageId: string): Observable<TDistanceCount> =>
    this.get<TDistanceCount>(
      `get-stage-area-measurements-count?stageId=${stageId}`
    );

  addMeasurement = (body: IAddMeasurementToStage): Observable<string> =>
    this.post(`add-area-measurement`, body);

  addMeasurementToRootNote = (body: TAddAreaToRootNote): Observable<string> =>
    this.post(`add-area-measurement-to-root-note`, body);

  updateMeasurementData = (body: TUpdateArea): Observable<void> =>
    this.put(`update-area-measurement-data`, body);

  addMeasurementGroup = (body: TAddMeasurementGroup): Observable<string> =>
    this.post(`add-area-measurement-group`, body);

  addMeasurementToGroups = (body: TAddMeasurementGroups): Observable<void> =>
    this.post(`add-area-measurement-to-groups`, body);

  addMeasurementsToGroup = (
    body: TAddMeasurementsToGroup
  ): Observable<string> => this.post(`add-area-measurements-to-group`, body);

  assignMeasurementToRootNote = (body: TAssignToRootNote): Observable<void> =>
    this.put(`assign-area-measurement-to-root-note`, body);

  unAssignMeasurementToRootNote = (body: TBaseMeasurement): Observable<void> =>
    this.put(`unassign-area-measurement-from-root-note`, body);

  updateMeasurementTitle = (body: TUpdateTitle): Observable<void> =>
    this.put(`update-area-measurement-title`, body);

  updateMeasurementColor = (body: TUpdateColor): Observable<void> =>
    this.put(`update-area-measurement-color`, body);

  updateMeasurementGroupName = (body: TUpdateGroupName): Observable<void> =>
    this.put(`update-area-measurement-group-name`, body);

  removeMeasurement = (data: TBaseMeasurement): Observable<void> =>
    this.delete(`remove-area-measurement?id=${data.id}`);

  removeMeasurementGroup = (data: TBaseMeasurement): Observable<void> =>
    this.delete(`remove-area-measurement-group?id=${data.id}`);

  removeMeasurementFromGroup = (data: TRemoveFromGroup): Observable<void> =>
    this.delete(
      `remove-area-measurement-from-group?id=${data.id}&groupId=${data.groupId}`
    );
}
