import {
  createEntityAdapter,
  EntityAdapter,
  EntityState,
  Update
} from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  ACCEPTED_AUDIO,
  ACCEPTED_DOCUMENT,
  ACCEPTED_PHOTO,
  ACCEPTED_VIDEO,
  Media,
  Note,
  NoteFilters,
  Page
} from '@simlab/data-access';
import * as NotesActions from './notes.actions';
export const NOTE_FEATURE_KEY = 'notes';

export interface State extends EntityState<Note> {
  selectedId?: string | number; // which Note record has been selected
  loaded: boolean; // has the Note list been loaded
  error?: string | null; // last known error (if any)
  selectedNote?: Note | null;
  metadata?: Page;
  filters?: NoteFilters;
}

export interface NotePartialState {
  readonly [NOTE_FEATURE_KEY]: State;
}

export const noteAdapter: EntityAdapter<Note> = createEntityAdapter<Note>();

export const initialState: State = noteAdapter.getInitialState({
  // set initial required properties
  loaded: false
});

export const noteReducer = createReducer(
  initialState,
  on(NotesActions.init, (state, { payload }) => ({
    ...state,
    filters: payload.rootNotesFilter,
    loaded: false,
    error: null
  })),
  on(NotesActions.clearStore, (state) => {
    return { ...noteAdapter.removeAll(state), loaded: false, error: null };
  }),
  on(NotesActions.selectedNoteId, (state, { noteId }) => {
    if (state.selectedId && noteId === state.selectedId) {
      return { ...state };
    }
    return {
      ...state,
      loaded: false,
      selectedId: noteId
    };
  }),
  on(NotesActions.selectedNoteIdAfterDeleteNote, (state, { noteId }) => {
    if (state.selectedId && noteId == state.selectedId) {
      return { ...state, loaded: false, selectedId: undefined };
    }
    return {
      ...state
    };
  }),
  on(NotesActions.deleteNoteLocally, (state) => {
    return {
      ...state,
      loaded: false,
      selectedId: undefined,
      selectedNote: undefined
    };
  }),
  on(NotesActions.metadata, (state, { metadata }) => {
    return {
      ...state,
      metadata
    };
  }),
  on(NotesActions.selectFirst, (state) => {
    return {
      ...state,
      selectedId: (Object.values(state.entities) as Note[])[0]?.id ?? undefined
    };
  }),
  on(NotesActions.addSimpleNoteSuccess, (state, { note }) => {
    return {
      ...noteAdapter.addOne(note, state),
      selectedId: note.id,
      selectedNote: note,
      loaded: true,
      loading: false
    };
  }),
  on(NotesActions.addSimpleNote, (state, { note }) => ({
    ...state,
    loaded: false,
    loading: true,
    error: null
  })),

  on(NotesActions.addSimpleNoteFailure, (state, { error }) => ({
    ...state,
    loaded: false,
    loading: false,

    error
  })),
  on(NotesActions.removeNote, (state, { response }) =>
    noteAdapter.removeOne(response.rootNoteId, state)
  ),

  on(NotesActions.loadNoteSuccess, (state, { note }) =>
    noteAdapter.setAll(note, { ...state, loaded: true })
  ),
  on(NotesActions.removeMarker, (state, { noteId }) => {
    const forUpdate: Note = { ...state.entities[noteId] } as Note;
    if (forUpdate) {
      forUpdate.marker = undefined;
      const updatedNote: Update<Note> = {
        changes: forUpdate,
        id: forUpdate.id
      };
      return noteAdapter.updateOne(updatedNote, state);
    } else {
      return state;
    }
  }),
  on(NotesActions.updateNote, (state, { note }) => {
    const updatedNote: Update<Note> = {
      changes: note,
      id: note.id
    };
    return noteAdapter.updateOne(updatedNote, state);
  }),
  on(NotesActions.selectedNote, (state, { note }) => {
    const imagesProcoreDocuments = (note.procoreDocuments ?? []).filter(
      (procoreDocument) =>
        ACCEPTED_PHOTO.some((acceptedFormat) =>
          procoreDocument.name.includes(acceptedFormat)
        )
    );

    const videoProcoreDocuments = (note.procoreDocuments ?? []).filter(
      (procoreDocument) =>
        ACCEPTED_VIDEO.some((acceptedFormat) =>
          procoreDocument.name.includes(acceptedFormat)
        )
    );

    const audioProcoreDocuments = (note.procoreDocuments ?? []).filter(
      (procoreDocument) =>
        ACCEPTED_AUDIO.some((acceptedFormat) =>
          procoreDocument.name.includes(acceptedFormat)
        )
    );

    const documentsProcoreDocuments = (note.procoreDocuments ?? []).filter(
      (procoreDocument) =>
        ACCEPTED_DOCUMENT.some((acceptedFormat) =>
          procoreDocument.name.includes(acceptedFormat)
        )
    );

    const sortByAddedAtDate = (a: Media, b: Media) =>
      new Date(a.addedAt).getTime() - new Date(b.addedAt).getTime();

    const mappedNote = (<Note>{
      ...note,
      photoNotes: [
        ...(note.photoNotes ?? []),
        ...(note.procoreImages ?? []),
        ...imagesProcoreDocuments
      ].sort(sortByAddedAtDate),
      documentNotes: [
        ...(note.documentNotes ?? []),
        ...(note.procoreDrawings ?? []),
        ...documentsProcoreDocuments
      ].sort(sortByAddedAtDate),
      audioNotes: [...(note.audioNotes ?? []), ...audioProcoreDocuments].sort(
        sortByAddedAtDate
      ),
      videoNotes: [...(note.videoNotes ?? []), ...videoProcoreDocuments].sort(
        sortByAddedAtDate
      )
    }) satisfies Note;

    return {
      ...state,
      selectedNote: mappedNote,
      loaded: true
    };
  }),
  on(NotesActions.selectedNoteLocally, (state, { note }) => {
    return {
      ...state,
      selectedNote: { ...note, hasComments: false },
      selectedId: note.id,
      loaded: true
    };
  }),
  on(NotesActions.addDigitalNote, (state, action) => {
    if (!state.selectedNote) return state;
    const forUpdate: Note = {
      ...state.selectedNote,
      audioNotes: [...state.selectedNote.audioNotes],
      photoNotes: [...state.selectedNote.photoNotes],
      videoNotes: [...state.selectedNote.videoNotes],
      documentNotes: [...state.selectedNote.documentNotes]
    } as Note;

    if (action.mediaType && forUpdate) {
      switch (action.mediaType) {
        case 'audio': {
          forUpdate.audioNotes.push(action.payload);
          break;
        }
        case 'document': {
          forUpdate.documentNotes.push(action.payload);
          break;
        }
        case 'photo': {
          forUpdate.photoNotes.push(action.payload);
          break;
        }
        case 'video': {
          forUpdate.videoNotes.push(action.payload);
          break;
        }
      }
    }
    return {
      ...state,
      selectedNote: forUpdate
    };
  }),
  on(NotesActions.addDigitalNoteByID, (state, action) => {
    if (!state.selectedNote) return state;
    if (action.nodeId !== state.selectedId) return state;

    const forUpdate: Note = {
      ...state.selectedNote,
      audioNotes: [...state.selectedNote.audioNotes],
      photoNotes: [...state.selectedNote.photoNotes],
      videoNotes: [...state.selectedNote.videoNotes],
      documentNotes: [...state.selectedNote.documentNotes]
    } as Note;
    if (action.mediaType) {
      switch (action.mediaType) {
        case 'audio': {
          forUpdate.audioNotes.push(action.payload);

          break;
        }
        case 'document': {
          forUpdate.documentNotes.push(action.payload);
          break;
        }
        case 'photo': {
          forUpdate.photoNotes.push(action.payload);
          break;
        }
        case 'video': {
          forUpdate.videoNotes.push(action.payload);
          break;
        }
      }
    }

    return {
      ...state,
      selectedNote: forUpdate
    };
  }),
  on(NotesActions.updateMedia, (state, action) => {
    try {
      if (!state.selectedNote) return state;
      const forUpdate: Note = {
        ...state.selectedNote,
        audioNotes: [...state.selectedNote.audioNotes],
        photoNotes: [...state.selectedNote.photoNotes],
        videoNotes: [...state.selectedNote.videoNotes],
        documentNotes: [...state.selectedNote.documentNotes]
      } as Note;
      if (action.mediaType) {
        switch (action.mediaType) {
          case 'audio': {
            const index = forUpdate.audioNotes.findIndex(
              (media: Media) => media.id === action.id
            );
            forUpdate.audioNotes[index] = action.file;
            break;
          }
          case 'document': {
            const index = forUpdate.documentNotes.findIndex(
              (media: Media) => media.id === action.id
            );
            forUpdate.documentNotes[index] = action.file;
            break;
          }
          case 'photo': {
            const index = forUpdate.photoNotes.findIndex(
              (media: Media) => media.id === action.id
            );
            forUpdate.photoNotes[index] = action.file;
            break;
          }
          case 'video': {
            const index = forUpdate.videoNotes.findIndex(
              (media: Media) => media.id === action.id
            );
            forUpdate.videoNotes[index] = action.file;
            break;
          }
        }
      }

      return {
        ...state,
        selectedNote: forUpdate
      };
    } catch (err) {
      return {
        ...state
      };
    }
  }),
  on(NotesActions.removeDigitalNote, (state, action) => {
    if (!state.selectedNote) return state;
    const forUpdate: Note = {
      ...state.selectedNote,
      audioNotes: [...state.selectedNote.audioNotes],
      photoNotes: [...state.selectedNote.photoNotes],
      videoNotes: [...state.selectedNote.videoNotes],
      documentNotes: [...state.selectedNote.documentNotes]
    } as Note;

    if (action.mediaType) {
      switch (action.mediaType) {
        case 'audio': {
          const index = forUpdate.audioNotes.findIndex(
            (digit) => digit.id === action.id
          );
          forUpdate.audioNotes.splice(index, 1);

          break;
        }
        case 'document': {
          const index = forUpdate.documentNotes.findIndex(
            (digit) => digit.id === action.id
          );
          forUpdate.documentNotes.splice(index, 1);
          break;
        }
        case 'photo': {
          const index = forUpdate.photoNotes.findIndex(
            (digit) => digit.id === action.id
          );
          forUpdate.photoNotes.splice(index, 1);
          break;
        }
        case 'video': {
          const index = forUpdate.videoNotes.findIndex(
            (digit) => digit.id === action.id
          );
          forUpdate.videoNotes.splice(index, 1);
          break;
        }
      }
    }
    return {
      ...state,
      selectedNote: forUpdate
    };
  }),
  on(NotesActions.removeEmptyDigitalNote, (state, action) => {
    if (!state.selectedNote) return state;
    const forUpdate: Note = {
      ...state.selectedNote,
      photoNotes: [...state.selectedNote.photoNotes],
      videoNotes: [...state.selectedNote.videoNotes]
    } as Note;
    if (action.mediaType) {
      switch (action.mediaType) {
        case 'photo': {
          const index = forUpdate.photoNotes.findIndex(
            (digit) => digit.url === ''
          );
          forUpdate.photoNotes.splice(index, 1);
          break;
        }
        case 'video': {
          const index = forUpdate.videoNotes.findIndex(
            (digit) => digit.url === ''
          );
          forUpdate.videoNotes.splice(index, 1);
          break;
        }
      }
    }

    return {
      ...state,
      selectedNote: forUpdate
    };
  }),
  on(NotesActions.changeNoteStatus, (state, action) => {
    return {
      ...state,
      selectedNote: {
        ...(state.selectedNote as Note),
        status: action.status
      }
    };
  }),

  on(NotesActions.changeNoteDescription, (state, action) => {
    return {
      ...state,
      selectedNote: {
        ...(state.selectedNote as Note),
        description: action.nodeDescription
      }
    };
  }),
  on(NotesActions.changeNoteStakeholder, (state, action) => {
    return {
      ...state,
      selectedNote: {
        ...(state.selectedNote as Note),
        stakeholderId: action.stakeholderId
      }
    };
  }),
  on(NotesActions.changeNoteName, (state, action) => {
    return {
      ...state,
      selectedNote: {
        ...(state.selectedNote as Note),
        name: action.name
      }
    };
  }),
  on(NotesActions.changeNoteType, (state, action) => {
    return {
      ...state,
      selectedNote: {
        ...(state.selectedNote as Note),
        type: action.nodeType
      }
    };
  }),

  on(NotesActions.updateNoteNotification, (state) => ({
    ...state,
    loaded: false,
    error: null
  })),
  on(NotesActions.updateNoteNotificationSuccess, (state, action) => {
    const updatedNote: Update<Note> = {
      changes: {
        ...(state.selectedNote as Note),
        notificationsEnabled: action.areEnabled
      },
      id: action.rootNoteId
    };
    return noteAdapter.updateOne(updatedNote, { ...state, loaded: true });
  }),
  on(NotesActions.updateSelectedNoteNotificationSuccess, (state, action) => {
    return {
      ...state,
      selectedNote: {
        ...(state.selectedNote as Note),
        notificationsEnabled: action.areEnabled
      }
    };
  }),
  on(NotesActions.updateNoteNotificationFailure, (state, { error }) => ({
    ...state,
    loaded: true,
    error
  })),

  on(NotesActions.loadNoteFailure, (state, { error }) => ({ ...state, error })),
  on(NotesActions.addMediaSuccess, (state, { media, mediaType }) => {
    const forUpdate: Note = JSON.parse(JSON.stringify(state.selectedNote));
    //TODO: handle other media types
    switch (mediaType) {
      case 'audio': {
        forUpdate.audioNotes.push(media);
        break;
      }
    }

    return {
      ...state,
      selectedNote: forUpdate
    };
  }),
  on(NotesActions.addMediaFailure, (state, { error }) => ({ ...state, error }))
);

export function reducer(state: State | undefined, action: Action) {
  return noteReducer(state, action);
}
