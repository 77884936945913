import { InjectionToken } from '@angular/core';
import { Color, Object3D, Scene } from 'three';
export const SIMLAB_SCENE = new InjectionToken<SimlabScene>('Simlab scene');

export class SimlabScene {
  private readonly _scene: Scene = new Scene();
  constructor(background?: Color) {
    this._scene.background = background || new Color(0x000000);
  }
  public get scene(): Scene {
    return this._scene;
  }

  add(...object: Object3D[]) {
    this._scene.add(...object);
  }
}
