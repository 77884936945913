export enum DesignColor {
  primary = 'primary',
  secondary = 'secondary',
  accent = 'accent',
  error = 'error',
}

export type DesignColorAsUnion = keyof typeof DesignColor;

export type DesignColorInput = DesignColor | DesignColorAsUnion | undefined;

export enum DesignPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

export type DesignPositionAsUnion = keyof typeof DesignPosition;

export type DesignPositionInput =
  | DesignPosition
  | DesignPositionAsUnion
  | undefined;

export enum DesignSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export type DesignSizeAsUnion = keyof typeof DesignSize;

export type DesignSizeInput = DesignSize | DesignSizeAsUnion | undefined;
