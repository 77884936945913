import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  DesignFlatButtonModule,
  DesignStrokedButton
} from '@simlab/design/button';
import { DesignDialogWrapperModule } from '@simlab/design/dialog';
import { DesignIcon } from '@simlab/design/icon';
import { UiButtonModule } from '@simlab/ui/button';
import { UiDividerModule } from '@simlab/ui/divider';
import { UiIconModule } from '@simlab/ui/icon';
import { ConfirmationModalRef, MODAL_DATA } from '@simlab/ui/modal';
import { UiProgressSpinnerModule } from '@simlab/ui/progress-spinner';
import {
  ImageCroppedEvent,
  ImageCropperModule,
  base64ToFile
} from 'ngx-image-cropper';
import { CroppedImage } from '../../models/cropped-image';
import { CroppedImageModalData } from '../../models/cropped-image-modal-data';

@Component({
  selector: 'simlab-crop-confirmation-dialog',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ImageCropperModule,
    UiDividerModule,
    UiButtonModule,
    UiProgressSpinnerModule,
    DesignDialogWrapperModule,
    DesignFlatButtonModule,
    DesignStrokedButton,
    DesignIcon,
    UiIconModule
  ],
  templateUrl: './crop-confirmation-dialog.component.html',
  styleUrls: ['./crop-confirmation-dialog.component.scss']
})
export class CropConfirmationDialogComponent {
      public readonly modalData = inject<CroppedImageModalData>(MODAL_DATA);
      private readonly modalRef = inject<ConfirmationModalRef<CroppedImage>>(ConfirmationModalRef<CroppedImage>);
  private _croppedImage: string | undefined | null;
  imageSizeExceeded = false;
  readonly MAX_IMAGE_SIZE = 15;
  imageLoaded = false;

  imageCropped(event: ImageCroppedEvent): void {
    this._croppedImage = event.base64;
    this.imageSizeExceeded = this._croppedImage
      ? this._croppedImage.length / 1024 / 1024 > this.MAX_IMAGE_SIZE
      : false;
  }

  handleCancel(): void {
    this.modalRef.emit({ state: false });
    this.modalRef.close();
  }

  handleConfirm(): void {
    const imagePath = base64ToFile(
      this._croppedImage ? this._croppedImage : ''
    );
    const imgFile = new File([imagePath], Date.now().toString() + '.png');

    this.modalRef.emit({
      state: true,
      result: {
        name: this.modalData.imageData.name,
        type: this.modalData.imageData.type,
        base64Image: this._croppedImage ? this._croppedImage : '',
        file: imgFile
      }
    });
    this.modalRef.close();
  }

  loadedImageFailure(): void {
    this.modalRef.emit({
      state: false
    });
    this.modalRef.close();
  }
}
