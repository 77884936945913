import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { map } from 'rxjs';
import { FORMAT_DATE_CONFIG } from './format-date-config.token';

@Pipe({
  standalone: true,
  name: 'formatDate',
})
export class FormatDatePipe implements PipeTransform {
  private readonly _formatDate = inject(FORMAT_DATE_CONFIG);
  private readonly _datePipe = inject(DatePipe);

  transform(value: any, time = '') {
    if (!value) return value;
    return this._formatDate.observable.pipe(
      map((date: string) =>
        this._datePipe.transform(new Date(value), date + time),
      ),
    );
  }
}

@Pipe({
  standalone: true,
  name: 'formatDateSignal',
})
export class FormatDateSignalPipe implements PipeTransform {
  private readonly _formatDate = inject(FORMAT_DATE_CONFIG);
  private readonly _datePipe = inject(DatePipe);

  transform(value: any, time = '') {
    const formatDate = this._formatDate.signal();
    if (!value || !formatDate) return value;

    return this._datePipe.transform(new Date(value), formatDate + time);
  }
}
