import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiButtonModule } from '../ui-button/ui-button.module';
import { UiFormFieldModule } from '../ui-form-field/ui-form-field.module';
import { UiIconModule } from '../ui-icon/public-api';
import { InputComponent } from './components/input/input.component';

@NgModule({
  declarations: [InputComponent],
  imports: [
    CommonModule,
    UiFormFieldModule,
    FormsModule,
    UiIconModule,
    UiButtonModule,
  ],
  exports: [UiFormFieldModule, InputComponent],
})
export class UiInputModule {}
