import {
  FullscreenOverlayContainer,
  OverlayContainer
} from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf
} from '@angular/core';
import { provideServiceWorker } from '@angular/service-worker';
import { provideDataAccess } from '@simlab/data-access';
import { provideDataStore } from '@simlab/data-store';

import { UiIconModule } from '@simlab/ui/icon';
import { UiModalModule } from '@simlab/ui/modal';
import { UiThemeModule } from '@simlab/ui/theme';
import { UiTitleModule } from '@simlab/ui/title';
import { Environment } from '@simlab/util-shared';
import { LogLevel } from 'angular-auth-oidc-client';
import { provideAuthConfig } from './configs/auth-config.module';
import { StoreConfigModule } from './configs/store-config.module';
import { CheckUserGuard } from './guards/check-user.guard';
import { UserPreferencesResolver } from './resolvers/user-preferences.resolver';
import { CheckUserService } from './services/check-user.service';
import { HttpClientBypass, createNewHttpClient } from './services/eula.service';
import { HamburgerService } from './services/hamburger.service';
import { NoteSidePanelService } from './services/note-sidenav.services';

@NgModule({
  imports: [
    CommonModule,
    UiTitleModule.forRoot(),
    UiIconModule,
    UiThemeModule.forRoot({ defaultTheme: 'light' }),
    UiModalModule,
    StoreConfigModule
  ]
})
export class UtilCoreModule {
  static forRoot(
    environment: Environment
  ): ModuleWithProviders<UtilCoreModule> {
    return {
      ngModule: UtilCoreModule,
      providers: [
        HamburgerService,
        NoteSidePanelService,
        CheckUserService,

        CheckUserGuard,
        UserPreferencesResolver,
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
        {
          provide: HttpClientBypass,
          useFactory: createNewHttpClient,
          deps: [HttpBackend]
        },
        provideAuthConfig({
          authority: environment.configuration.identity.authority,
          clientId: environment.configuration.identity.clientId,
          scope: environment.configuration.identity.scope,
          redirectUrl: environment.configuration.identity.redirectUrl,
          postLoginRoute: environment.configuration.identity.postLoginRoute,
          postLogoutRedirectUri:
            environment.configuration.identity.postLogoutRedirectUri,
          responseType: 'code',
          silentRenew: true,
          useRefreshToken: true,
          ngswBypass: environment.production, //NOTE: Required for PWA
          logLevel: environment.configuration.identity.authority.includes('dev')
            ? LogLevel.Error
            : LogLevel.None
        }),
        provideDataStore(environment),
        provideDataAccess(environment.configuration.api.url),
        provideServiceWorker('ngsw-worker.js', {
          enabled: environment.production || environment.staging,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        })
      ]
    };
  }
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: UtilCoreModule
  ) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
