<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i ui-icon name="icon-warning-red" defaultColor customSize="7.25rem"></i>
      <h2 designLabel i18n="@@WARNING">Warning</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent [formGroup]="formGroup">
    <label class="context-dialog" i18n="@@PU_ARE_YOU_SURE_TO_DELETE">
      Are you sure you want to delete {{ modalData.typeToDelete }}
      {{ modalData.name }}? {{ modalData.additionalText }}
    </label>

    <h4 class="text-info-confirm" i18n="@@PU_ENTER_NAME_TO_CONFIRM">
      Enter {{ modalData.typeToDelete }} name to confirm delete:
    </h4>

    <design-form-field [formGroup]="formGroup">
      <label designLabel i18n="@@PU_TYPE_TO_DELETE_NAME">
        {{ modalData.typeToDelete }} name
      </label>
      <input
        data-cy="organization-delete-input-name"
        designInput
        formControlName="organizationName"
      />
    </design-form-field>
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-stroked-button
      designButtonColor="primary"
      (click)="cancel()"
      data-cy="organization-delete-cancel-button"
    >
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button
      design-flat-button
      designButtonColor="error"
      (click)="confirm()"
      [disabled]="formGroup.valid === false"
      data-cy="organization-delete-confirm-button"
    >
      <div i18n="@@DELETE">Delete</div>
    </button>
  </ng-container>
</design-dialog-wrapper>
