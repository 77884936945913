<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel>{{ data.titleModal }}</h2>
  </ng-container>

  <ng-container designDialogContent>
    {{ data.content }}
  </ng-container>

  <ng-container designDialogFooter>
    <button design-stroked-button (click)="cancel()">
      <div i18n="@@CANCEL">Cancel</div>
    </button>
    <button design-flat-button designButtonColor="accent" (click)="confirm()">
      {{ data.buttonText ? data.buttonText : 'Accept' }}
    </button>
  </ng-container>
</design-dialog-wrapper>
