import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewEncapsulation,
  contentChild,
  inject,
  input,
  viewChild,
} from '@angular/core';
import {
  TAB,
  TAB_LABEL,
  TabLabelDirective,
} from '../../directives/tab-label.directive';

@Component({
  selector: 'design-tab',
  templateUrl: './tab.component.html',
  styles: [],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,

  providers: [{ provide: TAB, useExisting: TabComponent }],
})
export class TabComponent implements OnInit {
  private _viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
  private _contentPortal!: TemplatePortal<any> | null;
  readonly templateLabel = contentChild<TabLabelDirective>(TAB_LABEL);
  readonly explicitContent = contentChild(ElementRef, {
    read: TemplateRef,
  });
  readonly implicitContent = viewChild.required<TemplateRef<any>>(TemplateRef);
  readonly label = input('');
  readonly count = input(0);

  ngOnInit(): void {
    const content = this.explicitContent() || this.implicitContent();
    if (!content) return;
    this._contentPortal = new TemplatePortal(content, this._viewContainerRef);
  }

  get content(): TemplatePortal | null {
    return this._contentPortal;
  }
}
