<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <div class="header">
      <i
        ui-font
        fontName="warning-filled"
        fontSize="8rem"
        fontColor="#CE2838"
      ></i>
      <h2 designLabel i18n="@@WARNING">Warning</h2>
    </div>
  </ng-container>

  <ng-container designDialogContent>
    <div class="main-text">{{ data.mainText }}</div>
    <div class="text">{{ data.content }}</div>
  </ng-container>

  <ng-container designDialogFooter>
    <ng-container *ngIf="data.cancelButton">
      <button design-stroked-button (click)="cancel()">Cancel</button>
    </ng-container>
    <button design-flat-button designButtonColor="error" (click)="confirm()">
      {{ data.buttonText ? data.buttonText : 'Ok' }}
    </button>
  </ng-container>
</design-dialog-wrapper>
