/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  NgModule,
  ViewEncapsulation
} from '@angular/core';
import { ColorDirective } from '@simlab/design/helper';
import { DesignIcon } from '@simlab/design/icon';
import { DirectionDirective } from '../../directives/direction.directive';
import { FireOnClickDirective } from '../../directives/fire-on-click.directive';
import { ButtonBase } from '../button-base.directive';
import { ButtonLoaderComponent } from '../button-loader.component';
import { DesignButton } from '../button.component';

@Component({
  selector: 'button[design-fab-button]',
  exportAs: 'designFabButton',
  standalone: true,
  imports: [CommonModule],
  template: '<ng-content></ng-content>',
  styleUrls: ['./fab-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: ButtonBase, useExisting: DesignFabButton }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'design-base-button design-fab-button',
    '[class.design-color--primary]':
      'color === "primary" || color === undefined',
    '[class.design-color--secondary]': 'color === "secondary"',
  },
  inputs: [
    'disabled',
    'disabled: designButtonDisabled',
    'color: designButtonColor',
  ],
})
export class DesignFabButton extends ButtonBase {
}

@NgModule({
  exports: [
    DesignFabButton,
    ColorDirective,
    DesignIcon,
    FireOnClickDirective,
    ButtonLoaderComponent,
  ],
  imports: [
    DesignFabButton,
    ColorDirective,
    DesignIcon,
    FireOnClickDirective,
    DirectionDirective,
    ButtonLoaderComponent,
    DesignButton,
  ],
})
export class DesignFabButtonModule {}
