/* eslint-disable @typescript-eslint/ban-types */
import { EventEmitter } from '@angular/core';
import {
  BehaviorSubject,
  finalize,
  fromEvent,
  Observable,
  Subject,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import {
  BufferGeometry,
  Camera,
  CanvasTexture,
  Color,
  DoubleSide,
  Float32BufferAttribute,
  Group,
  Intersection,
  Line,
  LineBasicMaterial,
  Mesh,
  MeshBasicMaterial,
  NearestFilter,
  Object3D,
  PlaneGeometry,
  Scene,
  ShapeUtils,
  Sprite,
  SpriteMaterial,
  sRGBEncoding,
  Vector3,
} from 'three';
import { TransformControls as ThreeTransformControls } from 'three/examples/jsm/controls/TransformControls';

import {
  CircleCanvasTexture,
  DeviceCanvasTextTexture,
  IAbstractConstructor,
  IVector3,
  randomUUID,
} from '@simlab/simlab-facility-management/scene-object';
import {
  distanceText,
  MeasurementMeshSource,
  MeasurementMode,
  TEMPORARY_POINT_ICONS,
  TMeasurementListenersAction,
  TMeasurementMeshChange,
  Units,
} from '@simlab/simlab-facility-management/sub-features/measurement';
import { clamp } from 'three/src/math/MathUtils';
import { calculateDistance } from '../helpers/measurement.helpers';
import {
  ILineMeasurementComponent,
  IndexedPoint,
  TLineMeasurementElements,
  TMeasurementMesh,
  TMeasurementMeshInputs,
} from '../types/line-measurement.interface';
import { segmentMesh } from './measurement-mesh-segments';
import { Transform, TransformConverter } from '@simlab/transform';

const LAYER = 21;
const DISABLE_LAYER = 22;

const DISTANCE_TO_CLOSEST_POINT = 0.1;
export function mixinMeasurementComponent<T extends IAbstractConstructor<{}>>(
  base: T
) {
  abstract class MeasurementMeshComponent
    extends base
    implements ILineMeasurementComponent
  {

    abstract transform: TransformConverter;
    abstract set collider(colision: boolean);
    abstract get camera(): Camera;
    abstract get scene(): Scene;
    abstract get cameraContainer(): Camera;
    abstract get Group(): typeof Group;
    abstract get SpriteMaterial(): typeof SpriteMaterial;
    abstract get Color(): typeof Color;
    abstract get MeshBasicMaterial(): typeof MeshBasicMaterial;
    abstract get LineBasicMaterial(): typeof LineBasicMaterial;
    abstract get CanvasTexture(): typeof CanvasTexture;
    abstract get ShapeUtils(): typeof ShapeUtils;
    abstract get Float32BufferAttribute(): typeof Float32BufferAttribute;
    abstract get PlaneGeometry(): typeof PlaneGeometry;
    abstract get Mesh(): typeof Mesh;
    abstract get BufferGeometry(): typeof BufferGeometry;
    abstract get Sprite(): typeof Sprite;
    abstract get Line(): typeof Line;
    inputs: TMeasurementMeshInputs = {
      id: randomUUID(),
      points: [],
      size: 0,
      color: '#ff0000',
      unit: Units.Metric,
    };
    clockNow = 0;

    _group!: Group;
    _points: IndexedPoint[] = [];
    _size = 0;
    _mesh!: Mesh<BufferGeometry, MeshBasicMaterial>;
    _linesRefs: Map<string, Line<BufferGeometry, LineBasicMaterial>> =
      new Map();
    _pointsRefs: Map<string, Sprite> = new Map();
    _selected = false;
    _lineMaterial!: LineBasicMaterial;
    _pointMaterial!: SpriteMaterial;
    // _measurementMeshMaterial!: MeshBasicMaterial;
    _temporarySpriteMeshRef: Sprite | undefined;

    _sizeMesh!: Sprite;
    _mode: MeasurementMode = 'create';
    _undo: TMeasurementMeshInputs<IndexedPoint>[] = [];
    _redo: TMeasurementMeshInputs<IndexedPoint>[] = [];
    readonly _selectionChange: Subject<string | undefined> = new Subject<
      string | undefined
    >();
    readonly selectionChange$: Observable<string | undefined> =
      this._selectionChange.asObservable();
    public needUpdate = false;
    readonly _destroyTransformControl: Subject<void> = new Subject<void>();
    _color = '#4374E4';
    _segmentsLabelGroup: Group | undefined ;

    get segmentsVisibility(): boolean {
      return !!this._segmentsLabelGroup;
    }
    set segmentsVisibility(visible: boolean) {
      // if(! (this._points.length >= 2)) return;
      if (visible) {

        this._drawDistanceLabel()

        // this._segmentsLabelGroup && this.scene.add(this._segmentsLabelGroup);
        this._segmentsLabelGroup && (this._segmentsLabelGroup.visible = true)
        this.sizeMesh.visible =false;
      } else {
        // if (this._segmentsLabelGroup) {
        //   this._segmentsLabelGroup.traverse((object) => {
        //     this.scene.remove(object);
        //   });
        //   this.scene.remove(this._segmentsLabelGroup);
        //   this._segmentsLabelGroup = undefined;
        // }
        this._segmentsLabelGroup && (this._segmentsLabelGroup.visible = false)
        this._points.length && (this.sizeMesh.visible =true);
      }
    }
    get canUndo(): boolean {
      return this._undo.length > 0;
    }
    get canRedo(): boolean {
      return this._redo.length > 0;
    }
    readonly measurementChange$: EventEmitter<TMeasurementMeshChange<TMeasurementMesh>
    > = new EventEmitter<TMeasurementMeshChange<TMeasurementMesh>>;


    readonly mode$: BehaviorSubject<MeasurementMode> =
      new BehaviorSubject<MeasurementMode>(this.mode);
    public get unit(): Units | undefined {
      return this.inputs.unit;
    }
    public set unit(value: Units | undefined) {
      this.inputs.unit = value;
      this._setMeasurementTexture();
      this._segmentsLabelGroup &&
        ((this.segmentsVisibility = false), (this.segmentsVisibility = true));
    }
    set disableLayers(layers: {
      points: boolean;
      lines: boolean;
      mesh: boolean;
    }) {
      this._linesRefs.forEach((line) =>
        line.layers.set(layers.lines ? DISABLE_LAYER : LAYER)
      );
      this._pointsRefs.forEach((point) =>
        point.layers.set(layers.points ? DISABLE_LAYER : LAYER)
      );
      this._mesh && this._mesh.layers.set(layers.mesh ? DISABLE_LAYER : LAYER);
    }

    createTemporaryPoint(action: TMeasurementListenersAction) {
      const material = new this.SpriteMaterial({
        depthTest: false,
        depthWrite: false,
      });

      const image = new Image();
      const icon = TEMPORARY_POINT_ICONS[action];

      if (image) {
        image.onload = () => {
          const canvasTextureIcon = new CircleCanvasTexture(
            icon.background || this.color,
            icon.background || this.color,
            100,
            100,
            image
          );
          const texture = new this.CanvasTexture(canvasTextureIcon.ctx.canvas);
          texture.minFilter = NearestFilter;
          texture.encoding = sRGBEncoding;

          material.map = texture;
          material.needsUpdate = true;
        };
      }
      image.src = icon.iconUrl;

      this._temporarySpriteMeshRef = new this.Sprite(material);
      this._temporarySpriteMeshRef.scale.set(0.06, 0.06, 0.06);

      this.scene.add(this._temporarySpriteMeshRef);
      return this._temporarySpriteMeshRef;
    }
    removeTemporaryPoint() {
      this._temporarySpriteMeshRef &&
        this.scene.remove(this._temporarySpriteMeshRef) &&
        (this._temporarySpriteMeshRef = undefined);
    }
    cancelAllChanges() {
      if (this._undo.length) {
        const { size, points } =
          this._undo.shift() as TMeasurementMeshInputs<IndexedPoint>;
        this._size = size;
        if (this._points.length > points.length) {
          const objectToDelete = this._points.filter(
            (actVertex) =>
              !points.some((prevVertex) => prevVertex.id === actVertex.id)
          );
          objectToDelete.forEach((object) => this._deletedObject(object.id));
        }
        this._points = points;
        this._createMesh();
      }
      this._undo = [];
      this._redo = [];
      this.mode = 'read';
      this._emitChanges('cancelAllChanges');
    }

    _emitChanges(changesSource: MeasurementMeshSource) {
      this.measurementChange$.emit(
        new TMeasurementMeshChange<TMeasurementMeshInputs>(
          {
            id: this.id,
            points: this.points,
            size: this.size,
            color: this.color,
            unit: this.unit,
          },
          changesSource
        )
      );
    }

    hide = () => {
      this._segmentsLabelGroup && (this._segmentsLabelGroup.visible = false)
      this._group.visible = false
    };
    show = () => {
      this._segmentsLabelGroup && (this._segmentsLabelGroup.visible = true)
      this._group.visible = true
    };
    undo() {
      if (!this._undo.length) return;
      this._pushRedo();
      const { size, points } =
        this._undo.pop() as TMeasurementMeshInputs<IndexedPoint>;
      this._size = size;
      if (this._points.length > points.length) {
        const objectToDelete = this._points.filter(
          (vertex) => !points.includes(vertex)
        );
        objectToDelete.forEach((object) => this._deletedObject(object.id));
      }
      this._points = points;
      this._createMesh();
      this._emitChanges('undo');
    }

    redo() {
      if (!this._redo.length) return;
      this._pushUndo();
      const { size, points } =
        this._redo.shift() as TMeasurementMeshInputs<IndexedPoint>;
      this._size = size;
      if (this._points.length > points.length) {
        const objectToDelete = this._points.filter(
          (vertex) => !points.includes(vertex)
        );
        objectToDelete.forEach((object) => this._deletedObject(object.id));
      }
      this._points = points as IndexedPoint[];
      this._createMesh();
      this._emitChanges('redo');
    }
    public get color(): string {
      return this._color;
    }
    public set color(value: string) {
      this._color = value;
      this._emitChanges('color');
    }
    public get id(): string {
      return this.inputs.id;
    }
    public set id(value: string) {
      this.inputs.id = value;
    }
    public get mode(): MeasurementMode {
      return this._mode;
    }
    public set mode(value: MeasurementMode) {
      switch (value) {
        case 'read': {
          this._redo = [];
          this._undo = [];
          this._mesh && this._mesh.layers.set(LAYER);

          break;
        }
        case 'update': {
          this._mesh && this._mesh.layers.set(DISABLE_LAYER);
        }
      }
      this._mode = value;
      this.mode$.next(this.mode);
    }
    public get pointMaterial(): SpriteMaterial {
      return this._pointMaterial;
    }
    public set pointMaterial(value: SpriteMaterial) {
      this._pointMaterial = value;
    }

    public get lineMaterial(): LineBasicMaterial {
      return this._lineMaterial;
    }
    public set lineMaterial(value: LineBasicMaterial) {
      this._lineMaterial = value;
    }
    public get selected() {
      return this._selected;
    }
    public set selected(value: boolean) {
      if (this._selected === value) return;
      if (value) {
        this._selectionChange.next(this.id);
        // this.showEditHelperMesh = true;
        this._setPointMaterial(false)

      } else {
        // this.showEditHelperMesh = false;
        this._destroyTransformControl.next();
        this._undo = [];
        this._redo = [];
        this.segmentsVisibility = true;
        this._setPointMaterial(true)
      }
      this._selected = value;
      // this._setAreaMeshMaterial();
    }

    // set showEditHelperMesh(value: boolean) {
    //   if (value) {
    //     this._linesRefs.forEach((line) => (line.visible = true));
    //     this._pointsRefs.forEach((point) => (point.visible = true));
    //   } else {
    //     this._linesRefs.forEach((line) => (line.visible = false));
    //     this._pointsRefs.forEach((point) => (point.visible = false));
    //   }
    // }

    get points(): IVector3[] {
      return this._points;
    }
    get size(): number {
      return this._size;
    }

    get linearColor() {
      return new this.Color(this.color).convertSRGBToLinear();
    }
    onInputsUpdated(previousInputs: TMeasurementMeshInputs) {
      if (this.inputs.color && previousInputs.color !== this.inputs.color) {
        this.color = this.inputs.color;
        this._setLineMaterial();
        this._setPointMaterial(!this.selected);
        // this._setAreaMeshMaterial();
      }
      if (this.inputs.size && previousInputs.size !== this.inputs.size) {
        if (!this.sizeMesh) return;
        this._setMeasurementTexture();
      }
    }
    onInit() {
      this._group = new this.Group();
      this._group.name = this.inputs.id;
      if (this.inputs.color) {
        this.color = this.inputs.color;
      }
      this._setLineMaterial();
      this._setPointMaterial(!this.selected);
      this._segmentsLabelGroup = new this.Group();
      this._segmentsLabelGroup.name = this.inputs.id;
      this.scene.add(this._segmentsLabelGroup);
      this.segmentsVisibility = true;
      this.sizeMesh.visible =false;
      // this._setAreaMeshMaterial();
      if (this.inputs && 'points' in this.inputs && this.inputs.points.length) {
        this.mode = 'read';
        const { size, points } = this.inputs;

        this._points = points.map((point) => ({
          ...point,
          id: randomUUID(),
        }));
        this._size = calculateDistance(this._points);

        this._createMesh();
        // this.showEditHelperMesh = false;
      }
      // this.outputs.objectRoot = this._group;
    }

    // _setAreaMeshMaterial() {
    //   !this._measurementMeshMaterial &&
    //     (this._measurementMeshMaterial = new this.MeshBasicMaterial({
    //       opacity: 0.5,
    //       side: DoubleSide,
    //       transparent: true,
    //     }));
    //   const color = this.selected ? '#FFFFFF' : this.linearColor;
    //   this._measurementMeshMaterial.color.set(color);
    // }

    _setLineMaterial() {
      !this._lineMaterial &&
        (this._lineMaterial = new this.LineBasicMaterial({
          color: new this.Color(this.color),
          clipShadows: false,
          toneMapped: false,
          linewidth: 1, // in world units with size attenuation, pixels otherwise
        }));
      this._lineMaterial.color.set(this.linearColor);
    }

    _setPointMaterial(full:boolean = false) {
      const canvasTextureIcon = new CircleCanvasTexture(
        this.color,
        full ? this.color : '#ffffff',
        100,
        100
      );
      const texture = new this.CanvasTexture(canvasTextureIcon.ctx.canvas);
      texture.minFilter = NearestFilter;
      texture.encoding = sRGBEncoding;
      !this._pointMaterial &&
        (this._pointMaterial = new this.SpriteMaterial({
          depthTest: false,
          depthWrite: false,
        }));
      this._pointMaterial.map = texture;
    }

    onComponentClick(
      transformControlsRef: ThreeTransformControls,
      intersect: Intersection<Object3D<THREE.Event>>
    ) {
      let intersectObjectType: TLineMeasurementElements = intersect.object
        .name as TLineMeasurementElements;
      if (this.mode !== 'update' || this._temporarySpriteMeshRef) return false;
      if (this.selected === false) {
        this.selected = true;
        return false;
      }
      let intersectObject = intersect.object;
      let distanceToClosestPoint = 999;
      this._points.forEach(({ x, y, z, id }) => {
        const distance = new Vector3(x, y, z).distanceTo(intersect.point);
        if (
          distance < DISTANCE_TO_CLOSEST_POINT &&
          distanceToClosestPoint > distance
        ) {
          distanceToClosestPoint = distance;
          intersectObject = this._pointsRefs.get(id) as Sprite;
          intersectObjectType = 'MEASUREMENT_POINT';
        }
      });
      switch (intersectObjectType) {
        case 'MEASUREMENT_POINT': {
          this._pushUndo();
          transformControlsRef && transformControlsRef.attach(intersectObject);
          this.collider = true;
          this._areaPointPositionObserver(transformControlsRef);
          break;
        }
        case 'MEASUREMENT_LINE': {
          break;
        }
      }
      return true;
    }
    removePoint(objectId: string) {
      if (this._points.length <= 2) return;
      this._pushUndo();
      this._points = this._points.filter((vertex) => vertex.id !== objectId);
      this._deletedObject(objectId);
      this._size = calculateDistance(this._points);
      this._createMesh();
      this._emitChanges('removePoint');
    }
    _deletedObject(objectId: string) {
      const deletedObject = this._pointsRefs.get(objectId);
      if (deletedObject) {
        deletedObject.geometry.dispose();
        this._group.remove(deletedObject);
        this.scene.remove(deletedObject);
        this._pointsRefs.delete(objectId);
      }
      const deletedObjectLine = this._linesRefs.get(objectId);

      if (deletedObjectLine) {
        deletedObjectLine.geometry.dispose();
        this._group.remove(deletedObjectLine);
        this.scene.remove(deletedObjectLine);
        this._linesRefs.delete(objectId);
      }
    }

    addPoint(point: Vector3, normal: Vector3, nextIndex?: number) {
      this._pushUndo();

      const position = new Vector3(point.x, point.y, point.z).add(
        new Vector3(normal.x, normal.y, normal.z).multiply(
          new Vector3(0.001, 0.001, 0.001)
        )
      );

      const id = randomUUID();
      const { x, y, z } = position;
      this._points.push({ x, y, z, id });
      this._drawPoints();
      this._drawLines();

      if (this.points.length >= 2) {
        this._size = calculateDistance(this._points);
        this._drawSize();
        this._drawDistanceLabel()
        // this.outputs.collider = this._group;
      }
      this._redo = [];
      this._emitChanges('addPoint');

      return this._pointsRefs.get(id);
    }
    finish() {
      this.mode = 'read';
      this.selected = true;
      this._emitChanges('created');
    }
    _pushUndo() {
      this._undo.push({
        id: this.id,
        size: this._size,
        points: JSON.parse(JSON.stringify(this._points)),
        unit: this.unit,
        color: this._color,
      });
    }
    _pushRedo() {
      const actual: TMeasurementMeshInputs<IndexedPoint> = {
        id: this.id,
        size: this.size,
        unit: this.unit,
        points: JSON.parse(JSON.stringify(this._points)),
        color: this._color,
      };
      this._redo.splice(0, 0, actual);
    }

    addClosestPoint(
      lineId: string,
      point: Vector3,
      normal: Vector3
    ): string | undefined {
      const line = this._linesRefs.get(lineId);
      if (!line) return;
      this._pushUndo();
      const position = point.add(
        new Vector3(normal.x, normal.y, normal.z).multiply(
          new Vector3(0.01, 0.01, 0.01)
        )
      );
      const fromVerticeIdx = this._points.findIndex(
        (vertice) => vertice.id === lineId
      );

      const { x, y, z } = this.getClosestPoint(lineId, position);

      const newVertice = {
        x,
        y,
        z,
        id: randomUUID(),
      };
      this._points.splice(fromVerticeIdx + 1, 0, newVertice);
      this._createMesh();
      this._emitChanges('addPoint');

      return newVertice.id;
    }

    getClosestPoint(
      lineId: string,
      position: Vector3
    ): { x: number; y: number; z: number } {
      const fromVerticeIdx = this._points.findIndex(
        (vertice) => vertice.id === lineId
      );
      const toVerticeIdx =
        this._points.length === fromVerticeIdx + 1 ? 0 : fromVerticeIdx + 1;
      const { x: x1, y: y1, z: z1 } = this._points[fromVerticeIdx];
      const { x: x2, y: y2, z: z2 } = this._points[toVerticeIdx];
      const from = new Vector3(x1, y1, z1);
      const to = new Vector3(x2, y2, z2);
      const heading = to.clone().sub(from);
      const distance = heading.length();
      heading.normalize();
      const t = position.clone().sub(from).dot(heading);

      const dotP = clamp(t, 0, distance);
      return from.clone().add(heading.multiplyScalar(dotP));
    }
    _areaPointPositionObserver(transformControlsRef: ThreeTransformControls) {
      const destroy = new Subject<void>();
      fromEvent(transformControlsRef, 'objectChange')
        .pipe(
          tap(() => {
            destroy.next();
            timer(1000)
              .pipe(takeUntil(destroy))
              .subscribe(() => this._pushUndo());
          }),
          takeUntil(this._destroyTransformControl),
          finalize(() => {
            this.collider = false;
          })
        )
        .subscribe(() => {
          this.needUpdate = true;
        });
    }
    _createMesh() {
      this._drawPoints();
      this._drawLines();
      this._drawSize();
      this._drawDistanceLabel()
    }

    removeLabels(): void{
      if(this._segmentsLabelGroup){
        this._segmentsLabelGroup.children.forEach((object) => {
          object.remove()
         });

         this._segmentsLabelGroup.remove( ...this._segmentsLabelGroup.children)
      }
    };

    _drawDistanceLabel() {
      if(this._segmentsLabelGroup){
        this._segmentsLabelGroup.children.forEach((object) => {
           this.scene.remove(object);
          object.remove()
         });

         this._segmentsLabelGroup.remove( ...this._segmentsLabelGroup.children)
      }

      this._points.forEach((vertex: IndexedPoint, idx: number) => {
        const nextIdx = this._points.length === idx + 1 ? 0 : idx + 1;
        if (!nextIdx || nextIdx === 0) return;
        const secondVertex = this._points[nextIdx];
        const labelPosition = new Vector3(
          (vertex.x + secondVertex.x) * 0.5,
          (vertex.y + secondVertex.y) * 0.5 + 0.1,
          (vertex.z + secondVertex.z) * 0.5
        );
        const firstVertexPosition = new Vector3(vertex.x, vertex.y, vertex.z);
        const secondVertexPosition = new Vector3(
          secondVertex.x,
          secondVertex.y,
          secondVertex.z
        );
        const distance = this.basePosition(firstVertexPosition).distanceTo(this.basePosition(secondVertexPosition));
        const mesh = segmentMesh(
          this,
          distance,
          labelPosition,
          this.inputs.unit
        );
        mesh.layers.set(LAYER);
        this._segmentsLabelGroup && this._segmentsLabelGroup.add(mesh);
      });
    }
    _drawPoints() {
      if (!this._points.length) return;
      this._points.forEach((point: IndexedPoint) => {
        const pointMeshRef = this._getPointMesh(point.id);
        if (!pointMeshRef) return;
        pointMeshRef.position.set(point.x, point.y, point.z);
      });
    }

    basePosition(position:Vector3){
      return this.transform.to3dPosition(position.clone())
    }
    _drawSize() {
      if (!this._size) return;
      this._setMeasurementTexture();

      const center = new Vector3();
      if (this._points.length % 2 === 0) {
        const fromIdx = this._points.length / 2;
        const toIdx = fromIdx - 1;
        const fromPoint = this._points[fromIdx];
        const toPoint = this._points[toIdx];
        center.set(
          (fromPoint.x + toPoint.x) * 0.5,
          (fromPoint.y + toPoint.y) * 0.5 + 0.1,
          (fromPoint.z + toPoint.z) * 0.5
        );
      } else {
        const centerIdx = Math.floor(this._points.length / 2);
        const centerPoint = this._points[centerIdx];
        center.set(centerPoint.x, centerPoint.y + 0.1, centerPoint.z);
      }
      this._sizeMesh.position.copy(center);

      // this._surfaceSizeMesh.material.needsUpdate = true;
    }
    _setMeasurementTexture() {
      const text = distanceText(this._size, this.inputs.unit || Units.Metric);
      const textLength = text.length < 5 ? 5 : text.length;
      const canvasTextureIcon = new DeviceCanvasTextTexture(
        {
          text: `${text}`,
          backgroundColor: new this.Color('#FFFFFF'),
        },
        textLength
      );
      const texture = new this.CanvasTexture(canvasTextureIcon.ctx.canvas);
      const mesh = this.sizeMesh;
      mesh.material.dispose();
      mesh.material.map = texture;
      mesh.scale.set(textLength * 0.03, 0.08, 0);
    }

    _drawLines() {
      if (this._points.length < 2) return;
      this._points.forEach((vertex: IndexedPoint, idx: number) => {
        const nextIdx = this._points.length === idx + 1 ? 0 : idx + 1;
        if (!nextIdx || nextIdx === 0) return;
        const lineGeometry = this._getLineGeometry(vertex.id);

        const nextVertex = this._points[nextIdx];
        if (!nextVertex) return;

        const positions: Float32BufferAttribute =
          new this.Float32BufferAttribute(
            [
              vertex.x,
              vertex.y,
              vertex.z,
              nextVertex.x,
              nextVertex.y,
              nextVertex.z,
            ],
            3
          );
        lineGeometry.dispose();
        lineGeometry.setAttribute('position', positions);
        lineGeometry.computeBoundingBox();
      });
    }

    get sizeMesh() {
      if (!this._sizeMesh) {
        const material = new this.SpriteMaterial({
          alphaTest: 0.2,
          polygonOffset: false,
          opacity: 1,
          transparent: false,
          side: DoubleSide,
          polygonOffsetFactor: 0,
          polygonOffsetUnits: 0,
        });
        this._sizeMesh = new this.Sprite(material);
        this._sizeMesh.layers.set(LAYER);

        this._sizeMesh.name = 'MEASUREMENT_SIZE';
        this._group.add(this._sizeMesh);
      }
      return this._sizeMesh;
    }

    animationFrame(tickDelta: number) {
      if (this.needUpdate) {
        this._points = this._points.map(({ id }) => {
          const { position }: Sprite = this._pointsRefs.get(id) as Sprite;

          const { x, y, z } = position;
          return { x, y, z, id } as IndexedPoint;
        });

        this._createMesh();
        this._size = calculateDistance(this._points);
        this.needUpdate = false;
        this._emitChanges('pointPosition');
      }
    }

    _getPointMesh(id: string): Sprite {
      const pointsMaterial = this._pointMaterial;

      if (!this._pointsRefs.get(id)) {
        const point = new this.Sprite(pointsMaterial);
        point.scale.set(0.05, 0.05, 0.05);
        this._group.add(point);
        point.layers.set(LAYER);
        point.name = 'MEASUREMENT_POINT';
        point.userData = {
          id,
        };
        this._pointsRefs.set(id, point);
        return point;
      }
      return this._pointsRefs.get(id) as Sprite;
    }

    _getLineGeometry(id: string): BufferGeometry {
      const lineMaterial = this._lineMaterial;
      const lineGeometry =
        (this._linesRefs.get(id) && this._linesRefs.get(id)?.geometry) ||
        new this.BufferGeometry();
      if (!this._linesRefs.get(id)) {
        const line = new this.Line(lineGeometry, lineMaterial);
        line.layers.set(LAYER);

        line.name = 'MEASUREMENT_LINE';
        line.userData = {
          id,
        };
        this._group.add(line);
        this._linesRefs.set(id, line);
      }
      return lineGeometry;
    }

    directionVector(startPoint: Vector3, endPoint: Vector3) {
      return startPoint.clone().sub(endPoint);
    }
    destroy() {
      this._lineMaterial.dispose();
      this._pointMaterial.dispose();
      this._points.forEach((vertex) => {
        this._deletedObject(vertex.id);
      });
    }
  }
  return MeasurementMeshComponent;
}
