import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { InjectionToken, Signal, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { Observable, map, shareReplay } from 'rxjs';

export const xsm = 360;
export const sm = 600;
export const md = 960;
export const lg = 1500;

// Reference to _breakpoints -> @mixin
export const CustomBreakpoints = {
  extraSmallMobile: `(max-width: ${xsm - 1}px)`,
  phone: `(max-width: ${sm - 1}px)`,
  lessThanMd: `(max-width: ${md - 1}px)`,
  betweenXsmAndMd: `(min-width: ${xsm - 1}px) and (max-width: ${md - 1}px)`,
  betweenMdAndLg: `(min-width: ${md}px) and (max-width: ${lg - 1}px)`,
  betweenSmAndLg: `(min-width: ${sm}px) and (max-width: ${lg - 1}px)`,
  lg: `(max-width: ${lg - 1}px)`,
};

type ObservableBreakpointsObserverStore = {
  [TKey in keyof typeof CustomBreakpoints as `${TKey}$`]: Observable<boolean>;
};
type SignalBreakpointsObserverStore = {
  [TKey in keyof typeof CustomBreakpoints]: Signal<boolean | undefined>;
};

export type BreakpointsObserverStore = ObservableBreakpointsObserverStore &
  SignalBreakpointsObserverStore;

export const breakpointsObserver = new InjectionToken(
  'Breakpoints Observer token',
  {
    providedIn: 'root',
    factory: () => {
      const breakpoints = inject(BreakpointObserver);

      return Object.entries(CustomBreakpoints).reduce<BreakpointsObserverStore>(
        (observerStore, [key, value]) => {
          const breakpointsObserve$ = breakpoints.observe(value).pipe(
            map(({ matches }: BreakpointState) => matches),
            shareReplay(1),
          );

          observerStore = {
            ...observerStore,
            [`${key}$`]: breakpointsObserve$,
            [key]: toSignal(breakpointsObserve$),
          };

          return observerStore;
        },
        {} as BreakpointsObserverStore,
      );
    },
  },
);
