/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/directive-class-suffix */
import { FocusableOption, FocusOrigin, Highlightable } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ENTER, hasModifierKey, SPACE } from '@angular/cdk/keycodes';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { designOptionToken } from '../tokens/option.token';

/**
 * Option IDs need to be unique across components, so this counter exists outside of
 * the component definition.
 */
let _uniqueIdCounter = 0;

/** Event object emitted by MatOption when selected or deselected. */
export class DesignOptionSelectionChange<T = any> {
  constructor(
    /** Reference to the option that emitted the event. */
    public source: DesignOptionBase<T>,
    /** Whether the change in the option's value was a result of a user action. */
    public isUserInput = false,
  ) {}
}

@Directive()
export abstract class DesignOptionBase<T = any>
  implements FocusableOption, Highlightable, OnDestroy, AfterViewChecked
{
  private _changeDetectorRef = inject(ChangeDetectorRef);
  private _elementRef = inject<ElementRef<HTMLElement>>(
    ElementRef<HTMLElement>,
  );
  /** Event emitted when the option is selected or deselected. */
  // tslint:disable-next-line:no-output-on-prefix
  @Output() readonly selectionChange = new EventEmitter<
    DesignOptionSelectionChange<T>
  >();
  @Input() value!: T;
  private _mostRecentViewValue: any = '';
  @Input() viewLabel = '';

  /** The unique ID of the option. */
  @Input() id = `design-option-${_uniqueIdCounter++}`;

  /** Emits when the state of the option changes and any parents have to be notified. */
  readonly _stateChanges = new Subject<void>();

  /**
   * Implemented as part of ListKeyManagerOption.
   * @docs-private
   */
  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  /**
   * Whether or not the option is currently active and ready to be selected.
   * An active option displays styles as if it is focused, but the
   * focus is actually retained somewhere else. This comes in handy
   * for components like autocomplete where focus must remain on the input.
   */
  get active(): boolean {
    return this._active;
  }
  private _active = false;
  private _selected = false;

  /** Whether or not the option is currently selected. */
  get selected(): boolean {
    return this._selected;
  }

  ngAfterViewChecked(): void {
    // Since parent components could be using the option's label to display the selected values
    // (e.g. `mat-select`) and they don't have a way of knowing if the option's label has changed
    // we have to check for changes in the DOM ourselves and dispatch an event. These checks are
    // relatively cheap, however we still limit them only to selected options in order to avoid
    // hitting the DOM too often.
    if (this._selected) {
      const viewValue = this.value;

      if (viewValue !== this._mostRecentViewValue) {
        if (this._mostRecentViewValue) {
          this._stateChanges.next();
        }

        this._mostRecentViewValue = viewValue;
      }
    }
  }

  ngOnDestroy(): void {
    this._stateChanges.complete();
  }

  /**
   * Implemented as part of FocusableOption.
   * @docs-private
   */
  focus(_origin?: FocusOrigin, options?: FocusOptions): void {
    // Note that we aren't using `_origin`, but we need to keep it because some internal consumers
    // use `MatOption` in a `FocusKeyManager` and we need it to match `FocusableOption`.
    const element = this._elementRef.nativeElement;

    if (typeof element.focus === 'function') {
      element.focus(options);
    }
  }

  /**
   * Implemented as part of ListKeyManagerOption.
   * @docs-private
   */
  getLabel?(): string {
    return this.viewLabel;
  }

  /**
   * Implemented as part of Highlightable.
   * @docs-private
   */
  setActiveStyles(): void {
    if (!this._active) {
      this._active = true;
      this._changeDetectorRef.markForCheck();
    }
  }

  /**
   * Implemented as part of Highlightable.
   * @docs-private
   */
  setInactiveStyles(): void {
    if (this._active) {
      this._active = false;
      this._changeDetectorRef.markForCheck();
    }
  }

  /** Emits the selection change event. */
  private _emitSelectionChangeEvent(isUserInput = false): void {
    this.selectionChange.emit(
      new DesignOptionSelectionChange<T>(this, isUserInput),
    );
  }

  /** Selects the option. */
  select(): void {
    if (!this._selected) {
      this._selected = true;
      this._changeDetectorRef.markForCheck();
      this._emitSelectionChangeEvent();
    }
  }

  /** Deselects the option. */
  deselect(): void {
    if (this._selected) {
      this._selected = false;
      this._changeDetectorRef.markForCheck();
      this._emitSelectionChangeEvent();
    }
  }

  /**
   * `Selects the option while indicating the selection came from the user. Used to
   * determine if the select's view -> model callback should be invoked.`
   */
  _selectViaInteraction(): void {
    if (!this.disabled) {
      this._selected = true;
      this._changeDetectorRef.markForCheck();
      this._emitSelectionChangeEvent(true);
    }
  }

  /** Ensures the option is selected when activated from the keyboard. */
  _handleKeydown(event: KeyboardEvent): void {
    if (
      (event.keyCode === ENTER || event.keyCode === SPACE) &&
      !hasModifierKey(event)
    ) {
      this._selectViaInteraction();

      // Prevent the page from scrolling down and form submits.
      event.preventDefault();
    }
  }
}

@Directive({
  selector: '[designOption]',
  standalone: true,
  host: {
    class: 'design-option',
    role: 'option',
    '[id]': 'id',
    '[class.design-option--active]': 'active',
    '[class.design-option--selected]': 'selected',
    '[attr.aria-selected]': 'selected || null',
    '[class.design-option--disabled]': 'disabled',
    '[attr.aria-disabled]': 'disabled || null',
    '(click)': '_selectViaInteraction()',
    '(keydown)': '_handleKeydown($event)',
  },
  providers: [{ provide: designOptionToken, useExisting: DesignOption }],
})
export class DesignOption<T = any> extends DesignOptionBase<T> {}
