import { NgModule } from '@angular/core';
import { ExpansionPanelHeaderComponent } from '../components/expansion-panel-header/expansion-panel-header.component';

import { CheckboxExpansionPanelHeaderComponent } from '../components/checkbox-expansion-panel-header/checkbox-expansion-panel-header.component';
import { DesignRadioAccordion } from '../components/radio-expansion-panel/radio-accordion.directive';
import { DesignRadioExpansionPanelContent } from '../components/radio-expansion-panel/radio-expansion-panel-content.directive';
import { DesignRadioExpansionPanelHeader } from '../components/radio-expansion-panel/radio-expansion-panel-header.component';
import { DesignRadioExpansionPanel } from '../components/radio-expansion-panel/radio-expansion-panel.component';

@NgModule({
  imports: [
    DesignRadioAccordion,
    DesignRadioExpansionPanel,
    DesignRadioExpansionPanelHeader,
    DesignRadioExpansionPanelContent,
    ExpansionPanelHeaderComponent,
    CheckboxExpansionPanelHeaderComponent,
  ],
  exports: [
    DesignRadioAccordion,
    DesignRadioExpansionPanel,
    DesignRadioExpansionPanelHeader,
    DesignRadioExpansionPanelContent,
    ExpansionPanelHeaderComponent,
    CheckboxExpansionPanelHeaderComponent,
  ],
})
export class DesignRadioAccordionModule {}
