<design-dialog-wrapper cdkTrapFocus>
  <ng-container designDialogHeader>
    <h2 designLabel>{{ data.mainText }}</h2>
  </ng-container>

  <ng-container designDialogContent>
    {{ data.content }}
  </ng-container>

  <ng-container designDialogFooter>
    <button
      design-flat-button
      designButtonColor="secondary"
      (click)="confirm()"
    >
      Ok
    </button>
  </ng-container>
</design-dialog-wrapper>
