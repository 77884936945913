import { inject, Injectable } from '@angular/core';
import { select } from '@ngrx/store';

import { ofType } from '@ngrx/effects';
import {
  IAddMeasurementToStage,
  IUpdateMeasurementColor,
  IUpdateMeasurementTitle,
  TAddDistanceRootNote,
  TAddMeasurementGroup,
  TAddMeasurementGroups,
  TAddMeasurementsToGroup,
  TAssignToRootNote,
  TBaseUpdate,
  TDistance,
  TMeasurement,
  TMeasurementGroupBase,
  TMeasurementGroups,
  TRemoveMeasurementFromGroup,
  TUpdateColor,
  TUpdateData
} from '@simlab/feature-measurement/models';
import { map, Observable } from 'rxjs';
import { ApiDistance } from '../../../data-access/distance-api.service';
import { MeasurementFacade } from '../base.facade';
import { DistanceApiActions } from './distance.actions';
import { DistanceMeasurementSelectors } from './distance.selectors';

@Injectable()
export class DistanceFacade extends MeasurementFacade<
  TDistance,
  TMeasurementGroups
> {
  private _apiDistance = inject(ApiDistance);
  override hideAllMeasurements(hideAll: boolean): void {
    this._store.dispatch(DistanceApiActions.hideAllMeasurements({ hideAll }));
  }

  override addMeasurement(props: IAddMeasurementToStage): void {
    this._store.dispatch(DistanceApiActions.addMeasurement({ props }));
  }

  override addMeasurementToRootNote(body: TAddDistanceRootNote): void {
    this._store.dispatch(
      DistanceApiActions.addMeasurementToRootNote({ props: body })
    );
  }

  readonly updateDataSuccess$ = this._actions$.pipe(
    ofType(DistanceApiActions.updateMeasurementDataSuccess),
    map((data) => data)
  );

  readonly addSuccess$ = this._actions$.pipe(
    ofType(DistanceApiActions.addMeasurementSuccess),
    map((data) => data)
  );
  exportMeasurements(resultFileName: string, ids: string[]) {
    return this._apiDistance.exportMeasurements(resultFileName, ids);
  }
  override getMeasurementsGroup$(
    stageId: string
  ): Observable<TMeasurementGroups[]> {
    return this._apiDistance.getStageMeasurementGroups(stageId).pipe(
      map((e) => {
        return e.map((a) => {
          return {
            ...a,
            measurements: a.items.map((e) => {
              const a: TMeasurement = { ...e } as unknown as TMeasurement;
              return a;
            })
          } as TMeasurementGroups;
        });
      })
    );
  }
  override addMeasurementGroup(value: TAddMeasurementGroup): void {
    this._store.dispatch(
      DistanceApiActions.addMeasurementGroup({ body: value })
    );
  }
  override selectedMeasurement(id: string | undefined): void {
    this._store.dispatch(DistanceApiActions.selectMeasurement({ id }));
  }

  readonly addMeasurementGroupSuccess$: Observable<string> =
    this._actions$.pipe(
      ofType(DistanceApiActions.addMeasurementGroupSuccess),
      map(({ value }) => value)
    );

  readonly allMeasurement$ = this._store.pipe(
    select(DistanceMeasurementSelectors.selectAllAreaMeasurement)
  );

  readonly allMeasurementsHidden$ = this._store.pipe(
    select(DistanceMeasurementSelectors.selectAllAreaHidden)
  );

  readonly selectedMeasurement$ = this._store.pipe(
    select(DistanceMeasurementSelectors.selectEntity)
  );

  readonly selectedMeasurementId$ = this._store
    .pipe(select(DistanceMeasurementSelectors.selectEntity))
    .pipe(map((e) => e?.id));

  readonly showMeasurementsSegments$ = this._store.pipe(
    select(DistanceMeasurementSelectors.showMeasurementsSegments)
  );

  readonly updateColorSuccess$: Observable<TUpdateColor> = this._actions$.pipe(
    ofType(DistanceApiActions.updateMeasurementColorSuccess),
    map(({ id, color }) => ({ id, color }))
  );

  readonly deleteSuccess$: Observable<string> = this._actions$.pipe(
    ofType(DistanceApiActions.deleteMeasurementSuccess),
    map(({ id }) => id)
  );

  /**
   * Use the init action to perform one
   * or more tasks in your Effects.
   */
  init(stageId: string) {
    this._store.dispatch(DistanceApiActions.loadStageMeasurements({ stageId }));
  }

  clearStore() {
    this._store.dispatch(DistanceApiActions.clearStore());
  }
  loadMeasurement(id: string) {
    this._store.dispatch(DistanceApiActions.loadMeasurement({ id }));
  }

  assignMeasurementToNote(props: TAssignToRootNote) {
    this._store.dispatch(
      DistanceApiActions.assignMeasurementsToRootNote({ props })
    );
  }

  unassignMeasurementFromNote(props: TBaseUpdate) {
    this._store.dispatch(
      DistanceApiActions.unAssignMeasurementsFromRootNote({ props })
    );
  }

  deleteMeasurement(id: string) {
    this._store.dispatch(DistanceApiActions.deleteMeasurement({ id }));
  }
  // loadStageMeasurementGroups(stageId: string) {
  //   this._store.dispatch(
  //     DistanceApiActions.loadStageMeasurementGroups({ stageId })
  //   );
  // }

  // loadStageMeasurementSimpleGroups(stageId: string) {
  //   this._store.dispatch(
  //     DistanceApiActions.loadStageMeasurementSimpleGroups({ stageId })
  //   );
  // }

  // loadProjectMeasurementCount(projectId: string) {
  //   this._store.dispatch(
  //     DistanceApiActions.loadProjectMeasurementCount({ projectId })
  //   );
  // }

  // loadStageMeasurementCount(stageId: string) {
  //   this._store.dispatch(
  //     DistanceApiActions.loadStageMeasurementCount({ stageId })
  //   );
  // }

  // addDistanceMeasurementToRootNote(payload: TAddDistanceRootNote) {
  //   this._store.dispatch(DistanceApiActions.addMeasurementToRootNote(payload));
  // }

  // addDistanceMeasurementGroup(payload: TAddDistanceGroup) {
  //   this._store.dispatch(DistanceApiActions.addMeasurementGroup(payload));
  // }

  // addDistanceMeasurementToGroups(payload: TAddDistanceGroups) {
  //   this._store.dispatch(DistanceApiActions.addMeasurementToGroups(payload));
  // }

  addMeasurementsToGroup$(payload: TAddMeasurementsToGroup) {
    return this._apiDistance.addMeasurementsToGroup(payload);
  }

  // assignMeasurementsToRootNote(payload: TAssignMeasurementToRootNote) {
  //   this._store.dispatch(
  //     DistanceApiActions.assignMeasurementsToTootNote(payload)
  //   );
  // }

  // unAssignMeasurementsToRootNote(payload: TBaseUpdate) {
  //   this._store.dispatch(
  //     DistanceApiActions.unAssignMeasurementsFromTootNote(payload)
  //   );
  // }

  updateMeasurementTitle(props: IUpdateMeasurementTitle) {
    this._store.dispatch(DistanceApiActions.updateMeasurementTitle({ props }));
  }

  updateMeasurementColor(props: IUpdateMeasurementColor) {
    this._store.dispatch(DistanceApiActions.updateMeasurementColor({ props }));
  }

  addMeasurementToGroups$(payload: TAddMeasurementGroups) {
    return this._apiDistance.addMeasurementToGroups(payload);
  }

  renameMeasurementGroup(payload: TMeasurementGroupBase) {
    return this._apiDistance.updateMeasurementGroupName(payload);
  }

  deleteMeasurementGroup(id: string) {
    return this._apiDistance.removeMeasurementGroup({ id });
  }

  removeMeasurementFromGroup$(payload: TRemoveMeasurementFromGroup) {
    return this._apiDistance.removeMeasurementFromGroup(payload);
  }

  updateMeasurementData(payload: TUpdateData) {
    this._store.dispatch(DistanceApiActions.updateMeasurementData(payload));
  }

  showMeasurementsSegments(visibility: boolean) {
    this._store.dispatch(
      DistanceApiActions.showMeasurementsSegments({ visibility })
    );
  }

  toggleMeasurementSegmentsVisibility() {
    this._store.dispatch(
      DistanceApiActions.toggleMeasurementSegmentsVisibility()
    );
  }

  // updateMeasurementsGroupName(payload: TUpdateGroupName) {
  //   this._store.dispatch(
  //     DistanceApiActions.updateMeasurementGroupName(payload)
  //   );
  // }

  // deleteMeasurement(id: string) {
  //   this._store.dispatch(DistanceApiActions.deleteMeasurement({ id }));
  // }

  // deleteMeasurementFromGroup(data: TRemoveMeasurementFromGroup) {
  //   this._store.dispatch(DistanceApiActions.deleteMeasurementFromGroup(data));
  // }

  // deleteMeasurementGroup(id: string) {
  //   this._store.dispatch(DistanceApiActions.deleteMeasurementGroup({ id }));
  // }

  // exportDistanceMeasurement(resultFileName: string) {
  //   this._store.dispatch(
  //     DistanceApiActions.exportMeasurement({ resultFileName })
  //   );
  // }
}
