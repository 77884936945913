import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { UiButtonModule } from '../ui-button/ui-button.module';
import { UiDividerModule } from '../ui-divider/ui-divider.module';
import { UiIconModule } from '../ui-icon/ui-icon.module';
import { UiInputModule } from '../ui-input/ui-input.module';
import { UiSlideshowModule } from '../ui-slideshow/public-api';
import { ModalService } from './services/modal.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    UiButtonModule,
    UiIconModule,
    UiInputModule,
    UiDividerModule,
    ReactiveFormsModule,
    UiSlideshowModule,
  ],
  exports: [ImageCropperModule, UiButtonModule, UiIconModule],
  providers: [ModalService],
})
export class UiModalModule {}
