import { NgFor } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UiIconModule } from '@simlab/ui/icon';

@Component({
  standalone: true,
  imports: [UiIconModule, NgFor],
  template: `
    <i ui-icon name="icon_403" size="img"></i>
    <section class="text-section">
      <p i18n="@@LOGIN_ERROR" class="text text--header">
        Error during login session
      </p>
      <p i18n="@@LOGIN_ERROR_MESSAGE_1" class="text text--description">
        We have detected an error during the login session. We recommend the
        following steps:
      </p>
      <p i18n="@@LOGIN_ERROR_MESSAGE_2" class="text text--description">
        a) Refresh the page and try to log in again.
      </p>
      <p i18n="@@LOGIN_ERROR_MESSAGE_3" class="text text--description">
        b) Verify the system time, ensure it is synchronized with UTC time. This
        site may help:<a href="https://time.is" target="_blank">
          https://time.is.</a
        >
      </p>
      <p i18n="@@LOGIN_ERROR_MESSAGE_4" class="text text--description">
        It is best to restore the synchronization of the system clock in the
        system settings and then log in again.
      </p>
      <p i18n="@@LOGIN_ERROR_MESSAGE_5" class="text text--description">
        c) If the above steps do not help, please contact us (<a
          target="_blank"
          href="https://sim-stages.com/contact-us-form"
          >https://sim-stages.com/contact-us-form</a
        >,
        <a target="_blank" href="https://sim-on.com/contact-us-form"
          >https://sim-on.com/contact-us-form</a
        >). Try to describe as many details as possible.
      </p>
      <p i18n="@@LOGIN_ERROR_MESSAGE_6" class="text text--description">
        - The date and time when the problem occurred.
      </p>
      <p i18n="@@LOGIN_ERROR_MESSAGE_7" class="text text--description">
        - The steps taken that led to the error.
      </p>
      <p i18n="@@LOGIN_ERROR_MESSAGE_8" class="text text--description">
        - Do not send us any confidential information!
      </p>
    </section>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      .text-section {
        display: block;
        width: min(600px, 100%);

        margin: auto;
        color: var(--ui-theme-text-primary);
        .text {
          color: var(--ui-theme-text-primary);
        }

        .text--header {
          margin-top: 20px;
          font-weight: 600;
          text-align: center;
          font-size: 2.625rem;
          line-height: calc(2.625rem * 1.5);
        }

        .text--description {
          font-size: 0.75rem;
          line-height: calc(1.5rem * 0.75);
          a {
            color: var(--ui-theme-text-primary);
          }
        }
      }
      i {
        padding-top: 10%;
        display: block;
        margin: auto;
        max-width: 400px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent {}
