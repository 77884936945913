// @ts-nocheck
// disable type checking until typescript problem with mixin doeas not fix
// https://github.com/microsoft/TypeScript/issues/41347
import {
  BufferGeometry,
  Camera,
  CanvasTexture,
  Color,
  Float32BufferAttribute,
  Group,
  Line,
  LineBasicMaterial,
  Mesh,
  MeshBasicMaterial,
  PlaneGeometry,
  Scene,
  ShapeUtils,
  Sprite,
  SpriteMaterial,
} from 'three';

import {
  IMeasurementComponent,
  TAreaMesh,
  TAreaMeshInputs,
  mixinAreaMeasurementComponent,
} from '@simlab/simlab-facility-management/sub-features/measurement';

import {
  IAbstractConstructor,
  PropertiesOf,
} from '@simlab/simlab-facility-management/scene-object';
import { Dict } from '../models/dictionary';
import {
  ComponentInteractionType,
  SceneComponentBase,
} from './scene-component-base';

export const measurementToolType = 'mp.measurementToolType';
interface ISceneComponentBaseConstructor
  extends IAbstractConstructor<SceneComponentBase>,
    PropertiesOf<typeof SceneComponentBase> {}

const _MatterportAreaMeasurementBase =
  mixinAreaMeasurementComponent<ISceneComponentBaseConstructor>(
    SceneComponentBase
  );

export function makeMeasurementToolComponentRenderer() {
  return new AreaMeshComponent();
}

export default class AreaMeshComponent
  extends _MatterportAreaMeasurementBase
  implements TAreaMesh, IMeasurementComponent
{
  override set collider(colision: boolean) {
    this.outputs.collider = colision ? this._group : null;
  }

  override get camera(): Camera {
    return this.context.camera.parent;
  }
  override get scene(): Scene {
    return this.context.scene;
  }
  override get cameraContainer(): Camera {
    return this.context.camera.parent as THREE.Camera;
  }
  override get Group(): typeof Group {
    return this.context.three.Group;
  }
  override get SpriteMaterial(): typeof SpriteMaterial {
    return this.context.three.SpriteMaterial;
  }
  override get Color(): typeof Color {
    return this.context.three.Color;
  }
  override get MeshBasicMaterial(): typeof MeshBasicMaterial {
    return this.context.three.MeshBasicMaterial;
  }
  override get LineBasicMaterial(): typeof LineBasicMaterial {
    return this.context.three.LineBasicMaterial;
  }
  override get CanvasTexture(): typeof CanvasTexture {
    return this.context.three.CanvasTexture;
  }
  override get ShapeUtils(): typeof ShapeUtils {
    return this.context.three.ShapeUtils;
  }
  override get Float32BufferAttribute(): typeof Float32BufferAttribute {
    return this.context.three.Float32BufferAttribute;
  }
  override get PlaneGeometry(): typeof PlaneGeometry {
    return this.context.three.PlaneGeometry;
  }
  override get Mesh(): typeof Mesh {
    return this.context.three.Mesh;
  }
  override get BufferGeometry(): typeof BufferGeometry {
    return this.context.three.BufferGeometry;
  }
  override get Sprite(): typeof Sprite {
    return this.context.three.Sprite;
  }
  override get Line(): typeof Line {
    return this.context.three.Line;
  }
  override events: Dict<boolean> = {
    [ComponentInteractionType.CLICK]: true,
    [ComponentInteractionType.HOVER]: true,
  };

  override onInputsUpdated(previousInputs: TAreaMeshInputs) {
    if (this.inputs.color && previousInputs.color !== this.inputs.color) {
      this.color = this.inputs.color;
      this._setLineMaterial();
      this._setPointMaterial();
      this._setAreaMeshMaterial();
    }
    if (
      this.inputs.surfaceSizeUnit &&
      previousInputs.color !== this.inputs.surfaceSizeUnit
    ) {
      if (!this.surfaceSizeMesh) return;
      this._setSurfaceAreaTexture();
    }
    super.onInputsUpdated && super.onInputsUpdated(previousInputs);
  }
  override onInit() {
    this._group = new this.Group();
    super.onInit();
    this.outputs.objectRoot = this._group;
  }

  override onTick(tickDelta: number) {
    this.animationFrame(tickDelta);
    super.onTick && super.onTick(tickDelta);
  }

  override onDestroy() {
    this.outputs.collider = null;
    this.outputs.objectRoot = null;
    this._context = null;

    super.onDestroy && super.onDestroy();
  }
}
