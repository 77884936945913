import { Euler, Group, Mesh, Sprite, Vector3 } from 'three';

export abstract class ThreeObjectBase {
  _threeContext?: typeof import('three');
  abstract init(threeContext: typeof import('three'), configuration: any): void;
  abstract set position(position: Vector3);
  abstract set rotation(rotation: Euler);
  abstract set scale(scale: Vector3);
  abstract show(): void;
  abstract hide(): void;
  abstract get object3D(): Sprite | Mesh | Group;
  set threeContext(threeContext: typeof import('three')) {
    this._threeContext = threeContext;
  }
}
