import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Stage } from '@simlab/data-access';
import { STAGES_FEATURE_KEY, State, stagesAdapter } from './stages.reducer';

// Lookup the 'Stages' feature state managed by NgRx
export const getStagesState = createFeatureSelector<State>(STAGES_FEATURE_KEY);

const { selectAll, selectEntities } = stagesAdapter.getSelectors();

export const getStagesLoaded = createSelector(
  getStagesState,
  (state: State) => state.loaded
);

export const getStagesLoading = createSelector(
  getStagesState,
  (state: State) => state.loading
);

export const getStagesError = createSelector(
  getStagesState,
  (state: State) => state.error
);

export const getAllStages = createSelector(getStagesState, (state: State) =>
  selectAll(state)
);

export const containsAnyStage = createSelector(
  getStagesState,
  (state: State) => selectAll(state).length > 0
);
export const getAllIds = createSelector(
  getStagesState,
  (state: State) => state.ids as string[]
);

export const getStagesEntities = createSelector(
  getStagesState,
  (state: State) => selectEntities(state)
);

export const getSelectedId = createSelector(
  getStagesState,
  (state: State) => state.selectedId as string
);

export const hasSynchronizedShowcase = createSelector(
  getStagesState,
  (state: State) =>
    state.entities[state.selectedId as string]?.hasSynchronizedMatterport ??
    false
);

export const getName = (stageId: string) =>
  createSelector(getStagesEntities, (state: Dictionary<Stage>) => {
    return { stageId, name: state[stageId]?.name ?? '' };
  });

export const getSelected = createSelector(
  getStagesEntities,
  getSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
);
