import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OrganizationInfo, OrganizationPermission } from '@simlab/data-access';
import { PRIVILEGES_FEATURE_KEY, State } from './privileges.reducer';

export const getPrivilegesState = createFeatureSelector<State>(
  PRIVILEGES_FEATURE_KEY
);

export const getPermission = (permission: OrganizationPermission) =>
  createSelector(
    getPrivilegesState,
    (state: State) => state.permissions[permission]
  );

export const getOrganizationId = createSelector(
  getPrivilegesState,
  (state: State) => state.organizationWithPermissions?.organizationId
);

export const getOrganizationInfo = createSelector(
  getPrivilegesState,
  (state: State) => {
    const description: OrganizationInfo = {
      id: state.organizationWithPermissions?.organizationId,
      owner: state.organizationWithPermissions?.ownerName,
      emailOwner: state.organizationWithPermissions?.ownerEmail,
      description: state.organizationWithPermissions?.description,
      name: state.organizationWithPermissions?.name,
      thumbnail: state.organizationWithPermissions?.thumbnailUrl?.urlWithSas,
      created: state.organizationWithPermissions?.creationDate,
    };
    return description;
  }
);

export const getLoaded = createSelector(
  getPrivilegesState,
  (state: State) => state.loaded
);

export const getRoleId = createSelector(
  getPrivilegesState,
  (state: State) => state.organizationWithPermissions?.roleId
);
