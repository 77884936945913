import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  input,
  output
} from '@angular/core';
import { DesignButtonModule, DesignIconButton } from '@simlab/design/button';
import { DesignIcon } from '@simlab/design/icon';
import { DesignProgressSpinnerComponent } from '@simlab/design/progress-spinner';
import { UiIconModule } from '@simlab/ui/icon';
import { UiProgressBarComponent } from '@simlab/ui/progress-bar';
import { TEventWorkStatus } from '../ui-event-element/ui-event-element.component';

@Component({
  selector: 'event-ui-event-element-without-progress',
  standalone: true,
  imports: [
    CommonModule,
    UiProgressBarComponent,
    DesignProgressSpinnerComponent,
    DesignButtonModule,
    DesignIconButton,
    DesignIcon,
    UiIconModule
  ],
  templateUrl: './ui-event-element-without-progress.component.html',
  styleUrl: './ui-event-element-without-progress.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiEventElementWithoutProgressComponent {
  readonly title = input.required<string>();
  readonly status = input.required<TEventWorkStatus>();

  readonly cancel = output<void>();
}
